import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
class RedsysKo extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    console.log('mount');
  }

  render() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    console.log(params);
    let Ds_SignatureVersion = params.get('Ds_SignatureVersion');
    console.log('Ds_SignatureVersion', Ds_SignatureVersion);

    let Ds_MerchantParameters = params.get('Ds_MerchantParameters');
    console.log('Ds_MerchantParameters', Ds_MerchantParameters);

    let Ds_Signature = params.get('Ds_Signature');
    console.log('Ds_Signature', Ds_Signature);

    return (
      <section>
        <Grid container className="margin-top-10">
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8}>
            <div className=" center">
              <CloseIcon className="iconBig circleIcon" />
            </div>
            <h2 className="center">Su operación no se ha completado</h2>
            <Grid container>
              <Grid item xs={12} sm={5}>
                <div className="center">
                  <a href="/" className="buttonlink">
                    <p className="button">Ir al a pagina principal</p>
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={5}>
                <div className="center">
                  <a href="/perfil" className="buttonlink">
                    <p className="button">Ir al perfil</p>
                  </a>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
      </section>
    );
  }
}
export default RedsysKo;
