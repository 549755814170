import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import { Grid, InputLabel, FormHelperText } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import Divider from "@material-ui/core/Divider";
import { Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkdeinIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";

class ContactEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userToken: props.userToken,
			acceptTemrms: false,
			defaultSubject:
				"Han solicitado un contacto desde la web con los siguientes datos:",
		};

		console.log(props.customStyles);
	}
	componentDidMount() {}

	submitContact = (event) => {
		event.preventDefault();
		var apiurlput = "/api/users/contact-home";
		console.log(event.target.email_contact.value);
		console.log(event.target.name_contact.value);
		console.log(event.target.lastname_contact.value);
		console.log(this.state.userToken);

		const subject = event.target.subject_contact.value
			? event.target.subject_contact.value
			: this.state.defaultSubject;

		const payload = {
			email: event.target.email_contact.value,
			name: event.target.name_contact.value,
			lastname: event.target.lastname_contact.value,
			subject,
		};
		axios({
			url: apiurlput,
			method: "POST",
			data: payload,
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				console.log(response);
				// this.props.enqueueSnackbar('Añadido a la lista', {
				//   variant: 'success',
				// });
				alert("Mensaje enviado!");
			})
			.catch((error) => {
				console.log(error.message);
				alert("Mensaje no enviado");
			});
	};

	changeTerms = () => {
		const { acceptTemrms } = this.state;

		if (acceptTemrms) {
			this.setState({ acceptTemrms: false });
		} else {
			this.setState({
				acceptTemrms: true,
			});
		}
	};

	render() {
		return (
			<Grid item={true} xs={12} sm={8} style={this.props.customStyles}>
				<h3 className="center" style={{ marginTop: "2rem" }}>
					Contáctanos
				</h3>
				<form onSubmit={this.submitContact}>
					<Grid container>
						<Grid item={true} xs={12} sm={12}>
							<FormControl className="width-100">
								<InputLabel htmlFor="contact_email">Asunto </InputLabel>
								<Input
									id="contact_email3"
									name="subject_contact"
									aria-describedby="contact_subject"
									type="text"
									required
								/>
							</FormControl>
						</Grid>

						<Grid item={true} xs={12} sm={12}>
							<FormControl className="width-100">
								<InputLabel htmlFor="contact_email">Email </InputLabel>
								<Input
									id="contact_email3"
									name="email_contact"
									aria-describedby="contact_email"
									type="email"
									required
								/>
								<FormHelperText id="helper-email">
									No compartiremos tu email
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item={true} xs={12} sm={6}>
							<FormControl className="width-100">
								<InputLabel htmlFor="contact_name">Nombre </InputLabel>
								<Input
									id="contact_name"
									name="name_contact"
									aria-describedby="contact_name"
									type="text"
									required
								/>
							</FormControl>
						</Grid>
						<Grid item={true} xs={12} sm={6}>
							<FormControl className="width-100">
								<InputLabel htmlFor="contact_lastname">Apellidos </InputLabel>
								<Input
									id="contact_lastname"
									name="lastname_contact"
									aria-describedby="contact_lastname"
									type="text"
									required
								/>
							</FormControl>
						</Grid>
						<Grid item={true} xs={12} sm={12}>
							<FormGroup row>
								<FormControlLabel
									control={
										<Checkbox
											checked={this.state.acceptTemrms}
											onChange={this.changeTerms}
											name="checkedA"
											required
										/>
									}
									label={
										<span>
											Acepto los{" "}
											<a href="/politica-de-privacidad" target="_blank">
												términos y condiciones
											</a>
										</span>
									}
								/>
							</FormGroup>
							<div className="center">
								<Button
									variant="contained"
									color="primary"
									type="submit"
									className="button"
								>
									¡Adelante!
								</Button>
							</div>
						</Grid>
					</Grid>
					<Divider className="divider" />
					<div className="center socialNetwork">
						<a
							href="https://www.linkedin.com/company/estartaps/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<LinkdeinIcon fontSize="large" />
						</a>
						<a
							href="https://www.facebook.com/Estartaps-104368368159747/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FacebookIcon fontSize="large" />
						</a>
						<a
							href="https://twitter.com/estartaps"
							target="_blank"
							rel="noopener noreferrer"
						>
							<TwitterIcon fontSize="large" />
						</a>
						<a
							href="https://www.instagram.com/estartaps/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<InstagramIcon fontSize="large" />
						</a>
					</div>
					<Divider className="divider" />
				</form>
			</Grid>
		);
	}
}
export default ContactEmail;
