import React, { Component } from 'react';
import axios from 'axios';
import { Container, Table } from 'react-bootstrap';
import './../../../styles/styles.css';

class AuctionHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auctionHistory: [],
    };
  }

  componentDidMount() {
    console.log('auction history', this.state);
    this.getAuctionWordsHistory();
  }

  getAuctionWordsHistory = async () => {
    const payload = { uid: this.props.collection.uid };
    const headers = {
      headers: {
        'x-token': this.props.userToken,
      },
    };

    await axios
      .post('/api/auction/startups/words', payload, headers)
      .then((response) => {
        this.setState({
          auctionHistory: response.data.words,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    return (
      <Container>
        {console.log(`Historial de pujas `, this.state.auctionHistory)}
        <h2>Historial de pujas</h2>
        <Table responsive>
          <thead>
            <tr>
              <th>Palabra</th>
              <th>Pujas</th>
            </tr>
          </thead>
          <tbody>
            {this.state.auctionHistory
              ? this.state.auctionHistory.map((bid) => {
                  return (
                    <tr>
                      <td key={bid.word}>{bid.word}</td>
                      <td key={bid.word + '123'}>
                        {bid.bids.map((amount) => {
                          return <p>{amount} €</p>;
                        })}
                      </td>
                    </tr>
                  );
                })
              : ''}
          </tbody>
        </Table>
      </Container>
    );
  }
}
export default AuctionHistory;
