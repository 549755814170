import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <Container>
        <Grid container>
          <Grid item={true} xs={12} sm={12}>
            <h3>
              <span style={{ fontWeight: 400 }} className="bold">
                T&eacute;rminos y condiciones de uso de la web de Estartaps.com
              </span>
            </h3>
            <p>
              <span style={{ fontWeight: 400 }}>
                Te damos la bienvenida a estartaps.com, la nueva forma de
                entender la INNOVACI&Oacute;N y la creaci&oacute;n de empresas
                innovadoras.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                A continuaci&oacute;n, se exponen los T&Eacute;RMINOS y
                CONDICIONES de USO de la WEB ESTARTAPS.COM,&nbsp; su uso te
                atribuye la condici&oacute;n de usuario del sitio web e
                implicar&aacute; la aceptaci&oacute;n plena y sin reservas de
                todas las disposiciones siguientes:
              </span>
            </p>
            <ol>
              <li>
                <h4 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Propiedad de la p&aacute;gina web
                </h4>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                1.1. La titularidad de la p&aacute;gina web Estartaps.com (en
                adelante SITIO WEB) corresponde a Tamber Solutions S.L.&nbsp;
                con domicilio social en Calle de los Jilgueros, 6,
                Rivas-Vaciamadrid (Madrid) 28523, Espa&ntilde;a, con
                N&uacute;mero de Identificaci&oacute;n Fiscal B-88632997.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                1.2. La titularidad de la marca ESTARTAPS corresponde a Tamber
                Solutions S.L.&nbsp; con domicilio social en Calle de los
                Jilgueros, 6, Rivas-Vaciamadrid (Madrid) 28523, Espa&ntilde;a,
                con N&uacute;mero de Identificaci&oacute;n Fiscal B-88632997.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                1.3. Tamber Solutions S.L empresa propietaria de la web
                ESTARTAPS.COM y de la marca ESTARTAPS est&aacute; inscrita en el
                Registro Mercantil de Madrid, Tomo 40.517, Folio 80,
                secci&oacute;n 8, Hoja M-719270, Inscripci&oacute;n 1.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                1.4. La l&iacute;nea de servicio y atenci&oacute;n al cliente de
                ESTARTAPS.COM es la siguiente: support@estartaps.com&nbsp;
              </span>
            </p>
            <ol start="2">
              <li>
                <h4 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Aceptaci&oacute;n de los t&eacute;rminos de uso
                </h4>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                2.1. La utilizaci&oacute;n del SITIO WEB atribuye la
                condici&oacute;n de usuario del sitio web (en adelante, el "
                Usuario ") e implica la aceptaci&oacute;n plena y sin reservas
                de todas y cada una de las disposiciones de este documento en la
                versi&oacute;n publicada por ESTARTAPS.COM en el momento en que
                el usuario acceda en la WEB. En consecuencia, el usuario debe
                leer atentamente los t&eacute;rminos de uso y la pol&iacute;tica
                de privacidad de cada una de las ocasiones en que se proponga
                utilizar la WEB, ya que pueden ser modificados sin aviso previo.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                2.2. La utilizaci&oacute;n de ciertos servicios ofrecidos a los
                usuarios a trav&eacute;s del sitio web puede estar sujeta a las
                condiciones espec&iacute;ficas que, seg&uacute;n los casos,
                sustituyen, completan y/o modifican el presente documento. Por
                lo tanto, antes de usar estos servicios, el usuario
                tambi&eacute;n ha de leer atentamente las Condiciones
                Especiales.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                2.3. ESTARTAPS.COM se reserva el derecho a cambiar los
                t&eacute;rminos de uso y la pol&iacute;tica de privacidad de la
                WEB, y es deber del usuario comprobar peri&oacute;dicamente
                estas condiciones.
              </span>
            </p>
            <ol start="3">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Objeto
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                3.1. El objetivo de ESTARTAPS.COM es ofrecer una plataforma
                donde las startups se puedan mostrar y darse a conocer, vender
                sus productos o servicios, conseguir la inversi&oacute;n
                necesaria para su nacimiento o crecimiento, obtener servicios de
                otras empresas ya consolidadas a precios ventajosos previamente
                pactados o con descuentos especiales a la
                innovaci&oacute;n,&nbsp; disponer de una bolsa de empleo para la
                incorporaci&oacute;n de los diferentes perfiles
                espec&iacute;ficos que se demanden as&iacute; como estudiantes
                en pr&aacute;cticas. Desde Estartaps.com se generar&aacute;n
                nexos de uni&oacute;n entre startups, inversores y empresas que
                permitan mejorar y testear los productos finales&nbsp; previo a
                su lanzamiento al mercado. Se mostrar&aacute;n y dar&aacute;n a
                cononer al mercado las distintas soluciones generadas por las
                startups intentando conseguir potenciales clientes para sus
                productos. En Estartaps.com&nbsp; tambi&eacute;n nos preocupamos
                de aquellas startups que no hayan podido salir adelante
                ofreci&eacute;ndoles un apartado de segundas oportunidades donde
                pueden ser rescatadas y puestas de nuevo en funcionamiento.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Con nuestro blog podr&aacute;s aprender a como crear tu primera
                startup adem&aacute;s de estar al d&iacute;a de las novedades en
                innovaci&oacute;n.&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Por &uacute;ltimo, solo queda contarte nuestro apartado
                &ldquo;RETO&rdquo; en el que cualquier empresa puede hacer una
                petici&oacute;n concreta para una innovaci&oacute;n especifica
                relacionada con productos o servicios que necesitan y
                ser&aacute;n las startups las que postulen su mejor
                soluci&oacute;n para esa petici&oacute;n. Aquella startup que
                resulte ganadora recibir&aacute; un premio que se
                determinar&aacute; en las bases legales de cada reto.
              </span>
            </p>
            <ol start="4">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Menores de edad
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                4.1. El acceso y uso de la WEB est&aacute; dirigida a personas
                con la edad por encima de los dieciocho (18) a&ntilde;os.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                4.2. Para utilizar los servicios del sitio WEB, los menores
                deben obtener previamente permiso de sus padres, tutores o
                representantes legales, quienes ser&aacute;n considerados
                responsables de todos los actos realizados por el menor bajo su
                tutela.
              </span>
            </p>
            <ol start="5">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Acceso a los t&eacute;rminos y a la web
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.1. La consulta y el uso de la WEB por parte del USUARIO es
                gratuito y no requiere registro. No obstante, si se demanda
                cualquier tipo de servicios proporcionado por el sitio web, el
                usuario debe cumplimentar un formulario de registro y aceptar
                las condiciones de uso y pol&iacute;tica de privacidad.&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.2. El registro por parte de los usuarios es gratuito, sin
                perjuicio de que en un futuro ESTARTAPS.COM pueda establecer un
                precio o tasa por el servicio, del cual ser&aacute;n previamente
                avisado los USUARIOS.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.3. Cuando el usuario se registra en el SITIO WEB debe aceptar
                estos t&eacute;rminos y condiciones y la pol&iacute;tica de
                privacidad. En cualquier momento el usuario podr&aacute;
                modificar y actualizar su informaci&oacute;n de contacto a
                trav&eacute;s del SITIO WEB.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.4. El Usuario declara que toda la informaci&oacute;n
                proporcionada es veraz. A tal efecto, el USUARIO garantiza la
                autenticidad de todos aquellos datos que comunique al
                cumplimentar su registro o&nbsp; modificar y actualizar su
                contacto en la WEB. Del mismo modo, es responsabilidad del
                Usuario mantener toda la informaci&oacute;n facilitada
                permanentemente actualizada de forma que responda, en todo
                momento, a la situaci&oacute;n real del USUARIO. En cualquier
                caso, el usuario ser&aacute; el &uacute;nico responsable de
                cualquier informaci&oacute;n falsa o inexacta suministrada y el
                da&ntilde;o causado a ESTARTAPS.COM o a terceros.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.5. El USUARIO se compromete a hacer un uso correcto de la WEB
                de conformidad con la normativa vigente y los presentes
                T&eacute;rminos y Condiciones de Uso, as&iacute; como un uso
                justo y pac&iacute;fico.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6. El USUARIO se compromete a no publicar en los contenidos de
                la WEB cumpliendo con las siguientes normas de conducta:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.1. No publicar contenidos que, en modo alguno, pueda
                menospreciar o violar los derechos fundamentales y las
                libertades p&uacute;blicas reconocidos en la
                Constituci&oacute;n, los tratados internacionales y otras leyes
                aplicables.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.2. Que induzcan, inciten o promuevan actuaciones delictivas,
                denigrantes, difamatorias, violentas o, en general, contrarias a
                la ley, la moral y buenas costumbres generalmente aceptadas y el
                orden p&uacute;blico.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.3. Contenidos que induzcan, inciten o promuevan actuaciones,
                actitudes o juicios discriminatorios sobre sexo, raza,
                religi&oacute;n, creencias, edad o condici&oacute;n.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.4. Incorporar, poner a disposici&oacute;n o facilitar el
                acceso a productos, elementos, mensajes y/o costumbres
                criminales, violentos, ofensivos, nocivos o, en general,
                contrarios a la Ley, moral y buenas costumbres generalmente
                aceptadas o al orden p&uacute;blico.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.5. Que induzca o induce a otros a un estado inaceptable de
                ansiedad o temor.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.6. Que sea injurioso, ofensivo y/o degradantes.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.7. Que est&eacute; protegida por la propiedad intelectual o
                industrial pertenecientes a terceros, sin que el usuario haya
                obtenido previamente el permiso necesario para llevar a cabo el
                uso que se propone.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.8. Que sea contrario al derecho al honor, personal e
                intimidad o derechos de imagen.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.9. Que viole las normas establecidas en la confidencialidad
                de comunicaciones, protecci&oacute;n de datos, propiedad
                intelectual u otras leyes aplicables.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.10. Que se refieran a la publicidad de sitios que son
                competidores o anunciantes de ESTARTAPS.COM.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.11. Constituir publicidad il&iacute;cita, enga&ntilde;osa o
                desleal.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.6.12. Que provoquen dificultades en el normal funcionamiento
                de los servicios (tales como formato, extensi&oacute;n, etc.).
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.7. ESTARTAPS.COM se reserva el derecho de eliminar de la
                p&aacute;gina web, sin previo aviso, todo el contenido que
                violen estos t&eacute;rminos legales. ESTARTAPS.COM de ninguna
                manera se hace responsable del contenido ni de las opiniones
                expresadas por el USUARIO.
              </span>
            </p>
            <ol start="6">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  Tarifas de ESTARTAPS
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                El acceso a la plataforma y a la comunidad de ESTARTAPS.COM es
                gratuito. Por otra parte, ESTARTAPS.COM cubre sus costes
                operativos aplicando las siguientes tarifas:
              </span>
            </p>
            <p>
              <h3 style={{ fontWeight: 400 }} className="bold">
                6.1 Tarifas aplicables a las startups:
              </h3>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                ESTARTAPS.COM aplicar&aacute; tarifas a las empresas denominadas
                startups en diferentes &aacute;reas de servicios que ofrece como
                son el buscador, dividido&nbsp; en dos apartados:&nbsp;
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Posicionamiento org&aacute;nico, en el cual todas aquellas
                  startups inscritas en ESTARTAPS.COM y que hayan completado el
                  formulario con todos sus campos, aparecer&aacute;n dentro del
                  buscador como resultado a las palabras introducidas por el
                  usuario de la web en el orden que determine el algoritmo de
                  ESTARTAPS.COM.
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Puja por palabras: dentro de este apartado las startups pueden
                  pujar por aquellas palabras o grupos de palabras por los que
                  deseen posicionarse dentro del buscador de ESTARTAPS.COM Las
                  pujas comienzan con un valor de 0,20&euro; y para subir las
                  pujas por una determinada palabra o grupo de palabras se
                  realizar&aacute; de 0,10&euro; en 0,10&euro;. Solo
                  ser&aacute;n visibles en cada palabra o grupo de palabras las
                  tres pujas mas altas las cuales aparecer&aacute;n en las tres
                  primeras posiciones en orden de mayor cantidad pujada a menor.
                  Para la puja por palabras o grupos de palabras las startups
                  dispondr&aacute;n de un monedero donde deber&aacute;n
                  depositar el saldo suficiente para poder comenzar la puja.
                  Solo se descontar&aacute; a la startup el importe de su
                  monedero cuando se den dos condiciones a la vez; que su
                  palabra este entre las tres pujas m&aacute;s altas y que la
                  b&uacute;squeda por parte del usuario coincida con la palabra
                  o grupos de palabras por los que se ha pujado.
                </span>
              </li>
            </ul>{' '}
            <p>&nbsp;</p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  ESTARTAPS.COM aplicar&aacute; tarifas a las compañ&iacute;as
                  promotoras que lancen rondas de ampliación de capital. Dichos
                  honorarios se facturar&aacute; a la startup una vez haya
                  recibido el importe de la ronda. El importe ser&aacute; de
                  5.000€ + IVA en concepto de puesta en marcha y asesoramiento.
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Dentro de este apartado de inversi&oacute;n del tipo
                  crowdfunding, disponemos de un servicio opcional que ofrece a
                  las startups el posicionamiento en los primeros puestos, con
                  un coste de 4,99&euro; para aquellas startups que deseen tener
                  su&nbsp; posicionamiento activo durante 5 d&iacute;as y de
                  11,99&euro; para aquellas startups que deseen tener su
                  posicionamiento activo durante 15 d&iacute;as.
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Por &uacute;ltimo, contamos con el apartado de
                  &ldquo;ZOMBILAND&rdquo;, en el que aquellas startups que no
                  hayan podido desarrollar o dar continuidad&nbsp; a su proyecto
                  por cualquier motivo, puedan inscribirse en este apartado.
                  Para aquellas startups que ya estuvieran inscritas en
                  ESTARTAPS.COM con s&oacute;lo pulsar un&nbsp; bot&oacute;n ya
                  podr&aacute;n pasar a Zombilan, a partir de ese momento
                  quedar&aacute;n visibles como startups a la espera de ser
                  &ldquo;reanimada&rdquo;, bien por un inversor o por cualquier
                  persona o empresa que llegue a un acuerdo con la misma para
                  reactivarla de nuevo. ESTARTAPS.COM cobrar&aacute; por estos
                  servicios un 5%+IVA de la cifra de venta o inversi&oacute;n
                  parcial o total.
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <h3 style={{ fontWeight: 400 }} className="bold">
                6.2 Tarifas aplicables a los inversores:
              </h3>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                ESTARTAPS aplicar&aacute; tarifas a inversores o fondos de
                inversi&oacute;n en diferentes &aacute;reas de servicios que
                ofrece dentro del apartado web &ldquo;Inversi&oacute;n&rdquo;:
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Dentro del apartado inversión, las startups podrán pedir
                  financiación a través del crowdfunding. ESTARTAPS.COM cobrará
                  a los inversores por estos servicios un 6%+IVA de lo que
                  recaude el proyecto. Esta comisión, será únicamente exigible y
                  pagadera cuando la campaña alcance el 90% del total de la
                  inversión solicitada por la Startup.
                </span>
              </li>
            </ul>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Secundarias: este apartado esta pensado para aquellos
                  inversores que ya poseen porcentaje en una startup. Estos
                  inversores una vez logueados como tal, dentro de su perfil
                  tendrán la opción de pulsar sobre “venta de acciones”, en el
                  apartado “inversiones”, de este modo podrán poner a la venta
                  su participación total o parcial siempre que haya sido
                  obtenida anteriormente a través de una búsqueda de fondos o
                  crowdfunding. En este caso, la adquisición del porcentaje de
                  una startup, no tiene porqué haber sido a través de la web de
                  ESTARTAPS.COM. Se pedirá a través un formulario los datos
                  necesarios que demuestren la propiedad de la participación en
                  la startup así como el objetivo de la innovación y todos
                  aquellos datos necesarios para poder publicar la venta de
                  estas acciones secundarias.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>
                ESTARTAPS cobrar&aacute; por estos servicios un 2,5%+IVA del
                precio de venta de estas participaciones, siempre y cuando estas
                startups no est&eacute;n participando en el apartado
                &ldquo;Zombiland&rdquo;, cuya tarifa viene detallada en su
                apartado.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Dentro de este cap&iacute;tulo de inversi&oacute;n del tipo
                secundarias, contamos con un servicio opcional para los
                inversores que desean vender su participaci&oacute;n en su
                totalidad o parcialmente, este servicio ofrece la
                posibilidad&nbsp; de posicionar las participaciones en mercados
                secundarios en los primeros puestos con un coste de 4,99&euro;
                para aquellas participaciones que deseen tener el
                posicionamiento activo durante 5 d&iacute;as y de 11,99&euro;
                para aquellas participaciones que deseen tener su
                posicionamiento activo durante 15 d&iacute;as.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <h3 style={{ fontWeight: 400 }} className="bold">
                6.3 Tarifas aplicables a empresas que quieran prestar sus
                servicios a Startups:
              </h3>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Dentro del apartado servicios a Startups, empresas ya
                consolidadas o empresas maduras podr&aacute;n ofrecer sus
                servicios a nuestras startaps. Para ello deber&aacute;n
                cumplimentar su perfil y proponer un descuento a la
                innovaci&oacute;n, tras el estudio de la oferta podr&aacute;n
                ser aceptadas como empresas de servicios propuestas por
                ESTARTAPS.COM.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Para poder aparecer como servicios a Startups deben disponer de
                saldo en su monedero virtual, s&oacute;lo se realizar&aacute;n
                cargos en el caso de que una startup visite su propuesta de
                producto o servicio y pulse sobre contactar para adquirirlo. De
                forma autom&aacute;tica se proporcionar&aacute;n los datos de la
                startup solicitante a la empresa que ofrece sus servicios para
                que esta pueda interactuar a trav&eacute;s de su red comercial
                con la startup. El precio por cada clic que realice una
                startup&nbsp; sobre el contacto es de 1,20&euro; IVA incluido.
                Este precio se ira descontando del monedero de la empresa
                contactada mientras disponga de saldo, una vez que el saldo sea
                inferior a 1,20&euro; desaparecer&aacute; de la oferta ya que no
                posee fondos para seguir mostr&aacute;ndose.
              </span>
            </p>
            <ol start="7">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Propiedad intelectual
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                7.1. Tamber Solutions S.L. es propietario de los derechos de
                propiedad intelectual, o ha obtenido todas las autorizaciones o
                licencias necesarias para su explotaci&oacute;n, nombre de
                dominio, marcas y logotipos, la aplicaci&oacute;n, y otras obras
                e invenciones relacionadas con el sitio web, as&iacute; como su
                contenido.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                7.2. El contenido de este SITIO WEB, incluyendo dise&ntilde;os,
                aplicaciones, texto, im&aacute;genes y c&oacute;digo fuente (en
                adelante, el " Contenido ") est&aacute;n protegidos por la
                propiedad intelectual e industrial.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                7.3. Tamber Solutions S.L., como el responsable de la
                recopilaci&oacute;n de los datos profesionales publicadas en el
                SITIO WEB, es titular de los derechos de propiedad intelectual
                de la base de datos correspondiente, que no puede ser
                reproducida en su totalidad o en parte, o transmitido ni
                registrado por ning&uacute;n sistema de recuperaci&oacute;n de
                informaci&oacute;n.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                7.4. En ninguna circunstancia se puede utilizar el contenido del
                sitio web, reproducir, copiar o transmitir en cualquier forma
                sin el consentimiento previo y por escrito y expl&iacute;cito de
                Tamber Solutions S.L.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                7.5. Los usuarios autorizan y, si es necesario, ceden a Tamber
                Solutions S.L. el uso de los contenidos incorporados en el sitio
                web para su transformaci&oacute;n, explotaci&oacute;n,
                publicaci&oacute;n, distribuci&oacute;n, comunicaci&oacute;n
                p&uacute;blica, gratuita, universal y sin limitaci&oacute;n
                geogr&aacute;fica o tiempo.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                7.6. ESTARTAPS es una marca sometida al proceso de solicitud de
                TAMBER SOLUTIONS S.L.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                7.7. USUARIOS afirman que poseen todos los derechos, licencias y
                permisos para publicar en el sitio web de los contenidos
                publicados y exonerar Tamber Solutions S.L. de toda
                responsabilidad.
              </span>
            </p>
            <ol start="8">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Pol&iacute;tica de privacidad
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Responsable:&nbsp;TAMBER SOLUTIONS S.L.
                (&ldquo;ESTARTAPS&rdquo;).&nbsp;Finalidad:&nbsp;(i)&nbsp;gestionar
                y tramitar la inscripci&oacute;n del solicitante como usuario de
                la plataforma y la relaci&oacute;n con las personas de contacto
                y la gesti&oacute;n de su cuenta de
                usuario;&nbsp;(ii)&nbsp;remitir informaci&oacute;n relativa a
                las actividades organizadas por ESTARTAPS o aquellas en las que
                participe, as&iacute; como informaci&oacute;n sobre
                oportunidades de inversi&oacute;n, startups que cambiaran el
                mercado, nuestras novedades, beneficios del sitio web y toda
                aquella informaci&oacute;n que consideremos de vuestro
                inter&eacute;s.&nbsp;Legitimaci&oacute;n: Para la finalidad (i)
                el inter&eacute;s leg&iacute;timo del interesado y ESTARTAPS
                para gestionar su relaci&oacute;n de contacto y relaci&oacute;n
                comercial. Para la finalidad (ii) el consentimiento otorgado por
                el usuario.&nbsp;Destinatarios:&nbsp;Est&aacute; prevista la
                cesi&oacute;n a empresas de TAMBER SOLUTIONS S.L.&nbsp;Derechos:
                puede ejercitar los derechos de acceso, rectificaci&oacute;n,
                supresi&oacute;n, oposici&oacute;n, portabilidad y
                limitaci&oacute;n al tratamiento de sus datos personales
                mediante solicitud a la direcci&oacute;n arriba indicada,
                adjuntando copia de documento identificativo en vigor. Asimismo,
                tiene derecho a recabar la tutela de la Agencia Espa&ntilde;ola
                de Protecci&oacute;n de datos a trav&eacute;s de su
                p&aacute;gina web&nbsp;
              </span>
              <a href="http://www.agpd.es">
                <span style={{ fontWeight: 400 }}>www.agpd.es</span>
              </a>
              <span style={{ fontWeight: 400 }}>
                .&nbsp;Informaci&oacute;n adicional:&nbsp;Es obligatorio que sea
                mayor de edad y que nos facilite sus datos para autenticarlos y
                habilitar su cuenta de usuario en el sitio web gestionado por
                ESTARTAPS&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Puede consultar nuestra pol&iacute;tica de protecci&oacute;n de
                datos en la&nbsp;
              </span>
              <a href="https://startupxplore.com/privacy">
                <span style={{ fontWeight: 400 }}>
                  Pol&iacute;tica de Privacidad y las Cookies.
                </span>
              </a>
            </p>
            <ol start="9">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Exclusi&oacute;n de garant&iacute;as y responsabilidad
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.1. ESTARTAPS.COM no garantiza la disponibilidad y continuidad
                del Sitio Web o los Servicios. Cuando sea razonablemente
                posible, ESTARTAPS.COM avisar&aacute; de la p&aacute;gina web y
                servicio de interrupciones. ESTARTAPS.COM no garantiza la
                utilidad del Portal y de los Servicios para la
                realizaci&oacute;n de ninguna actividad en particular, ni su
                infalibilidad ni, en particular, aunque no de modo exclusivo,
                que los usuarios puedan efectivamente utilizar el Portal y los
                Servicios, acceder a las distintas p&aacute;ginas web que forman
                el SITIO WEB o aquellas desde las que se prestan los Servicios.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.2. El SITIO WEB ha sido creado y funciona seg&uacute;n lo
                dise&ntilde;ado por ESTARTAPS.COM; en consecuencia ESTARTAPS.COM
                no asume ninguna responsabilidad por da&ntilde;os y perjuicios
                de toda naturaleza que puedan deberse a la falta de
                disponibilidad o de continuidad del Portal y de los Servicios,
                el incumplimiento de las expectativas de los usuarios o de los
                requisitos espec&iacute;ficos o, en particular, pero no limitado
                a, el la falta de acceso a las diferentes p&aacute;ginas del
                sitio web o aquellas desde las que se prestan los servicios,
                cuando programado para razones ajenas a la voluntad de
                ESTARTAPS.COM.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.3. El acceso y uso de los Servicios ofrecidos por
                ESTARTAPS.COM requieren una computadora y una conexi&oacute;n a
                Internet. Los costos asociados con estos elementos ser&aacute;n
                la &uacute;nica preocupaci&oacute;n del USUARIO. El USUARIO es
                responsable de la conservaci&oacute;n y la diligencia utilizando
                sus claves de acceso.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.4. ESTARTAPS.COM ha implantado todas las medidas de seguridad
                t&eacute;cnicas y medidas organizativas necesarias para
                garantizar la seguridad y la privacidad de la
                informaci&oacute;n, sin perjuicio de los actos m&aacute;s
                all&aacute; de la debida diligencia con intenciones criminales
                que violan las medidas de seguridad implementadas, para lo cual
                ESTARTAPS.COM no ser&aacute; responsable.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.5 ESTARTAPS.COM excluye, dado la naturaleza de la WEB,
                cualquier responsabilidad por los da&ntilde;os y perjuicios de
                toda naturaleza que puedan deberse a terceros que tengan acceso
                a la informaci&oacute;n contenida en el sitio web, as&iacute;
                como para el uso dado por los USUARIOS.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.6 ESTARTAPS.COM no controla ni garantiza la ausencia de virus
                u otros elementos en los contenidos que puedan producir
                alteraciones en su sistema inform&aacute;tico (software o
                hardware) o en los documentos electr&oacute;nicos y ficheros
                almacenados en su sistema inform&aacute;tico.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.7. A pesar de los esfuerzos realizados por ESTARTAPS.COM para
                que la informaci&oacute;n publicada en su WEBSITE mantiene los
                m&aacute;s altos est&aacute;ndares de calidad, ESTARTAPS.COM no
                garantiza la licitud, fiabilidad, actualidad y utilidad de la
                informaci&oacute;n y de los contenidos del WEBSITE.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.8. ESTARTAPS.COM excluye cualquier responsabilidad por los
                da&ntilde;os y perjuicios de toda naturaleza que puedan deberse
                a la transmisi&oacute;n, difusi&oacute;n, almacenamiento,
                disposici&oacute;n, recepci&oacute;n o acceso a los contenidos,
                y en particular, aunque no exclusivamente, por los da&ntilde;os
                que puedan resultar de:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                (a) El incumplimiento de la Ley, moral y buenas costumbres
                generalmente aceptadas o al orden p&uacute;blico como
                consecuencia de la transmisi&oacute;n, difusi&oacute;n,
                almacenamiento, disposici&oacute;n, recepci&oacute;n o acceso a
                los contenidos;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                (b) La infracci&oacute;n de los derechos de propiedad
                intelectual, secretos empresariales, de compromisos
                contractuales de cualquier clase, de los derechos al honor, a la
                intimidad personal y familiar, derechos de imagen, derechos de
                propiedad y los dem&aacute;s derechos que pertenecen a terceros
                como consecuencia de la transmisi&oacute;n, difusi&oacute;n,
                almacenamiento, disponibilidad, recepci&oacute;n o acceso a los
                contenidos;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                (c) La realizaci&oacute;n de actos de competencia desleal y
                publicidad il&iacute;cita como consecuencia de la
                transmisi&oacute;n, difusi&oacute;n, almacenamiento,
                disposici&oacute;n, recepci&oacute;n o acceso a los contenidos;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                (d) La falta de veracidad, exactitud, puntualidad,
                exhaustividad, pertinencia y / o adecuaci&oacute;n del
                contenido;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                (e) La inadecuaci&oacute;n para cualquier prop&oacute;sito y la
                defraudaci&oacute;n de las expectativas generadas por los
                contenidos;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                (f) El incumplimiento, retraso en el cumplimiento, cumplimiento
                defectuoso o terminaci&oacute;n por cualquier causa de las
                obligaciones contra&iacute;das por terceros y contratos
                realizados con terceros a trav&eacute;s de o en relaci&oacute;n
                con el acceso a los contenidos;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                (g) Los vicios y defectos de toda clase de contenidos
                transmitidos, difundidos, almacenados, puestos a
                disposici&oacute;n o de otra forma transmitidos, recibidos,
                obtenidos o acceder a trav&eacute;s del WEBSITE o de los
                servicios.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.9. ESTARTAPS.COM no garantiza la veracidad, exactitud,
                exhaustividad y actualidad de los contenidos de su p&aacute;gina
                web generados por ESTARTAPS.COM o por los usuarios al publicar
                sus opiniones.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.10. ESTARTAPS.COM excluye cualquier responsabilidad por los
                da&ntilde;os y perjuicios de toda naturaleza que puedan deberse
                a la falta de veracidad, exactitud, exhaustividad y / o
                idoneidad del contenido.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.11. ESTARTAPS.COM se reserva el derecho de restringir,
                suspender o terminar la cuenta del usuario si ESTARTAPS.COM
                cree, a su sola discreci&oacute;n, que es necesario para el
                cumplimiento del presente Acuerdo.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.12. ESTARTAPS.COM no garantiza la licitud, fiabilidad y
                utilidad de los servicios prestados por terceros cuyos datos de
                contacto se obtuvieron a trav&eacute;s del SITIO WEB.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.13. ESTARTAPS.COM excluye cualquier responsabilidad por los
                da&ntilde;os y perjuicios de toda naturaleza que puedan deberse
                a los servicios prestados por terceros cuyos datos se publica en
                el SITIO WEB. El USUARIO deber&aacute; cumplir en todo momento
                las condiciones de uso y contrataci&oacute;n, as&iacute; como la
                pol&iacute;tica de privacidad de terceros.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.14. ESTARTAPS.COM es de ninguna manera responsables por el
                contenido o el rendimiento de otros sitios web a los que, en
                algunos caso, se puede acceder por la inclusi&oacute;n de un
                enlace o enlaces de este sitio, ni de los contenidos, servicios
                o productos ofrecidos por los mismos, a menos que otros sitios
                sean propiedad de ESTARTAPS.COM.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                9.15. ESTARTAPS.COM es amable y est&aacute; dispuesto a cumplir
                con la normativa vigente, y tambi&eacute;n hace cumplir la
                misma. En caso de que el usuario sea consciente de cualquier
                violaci&oacute;n de las reglas o normas de conducta que
                aqu&iacute; se describen, por favor h&aacute;ganoslo saber a la
                direcci&oacute;n support@estartaps.com y ESTARTAPS.COM cuenta
                con voluntad de responder a su comunicaci&oacute;n en la mayor
                brevedad posible.
              </span>
            </p>
            <ol start="10">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Confidencialidad
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Toda la informaci&oacute;n a la que tenga acceso el usuario y de
                la cual se indique expresamente su car&aacute;cter de
                confidencial, no tenga naturaleza manifestada como
                p&uacute;blica o provenga de un dominio p&uacute;blico sin
                incurrir en alg&uacute;n tipo de incumplimiento por una
                obligaci&oacute;n de confidencialidad con respecto a
                ESTARTAPS.COM tendr&aacute; la consideraci&oacute;n de
                confidencial. Esto implica que no podr&aacute; ser divulgada sin
                el expreso consentimiento por escrito de ESTARTAPS.COM.
              </span>
            </p>
            <ol start="11">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Ley aplicable y de jurisdicci&oacute;n
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Los t&eacute;rminos y el uso del sitio web se regir&aacute;n e
                interpretar&aacute;n de acuerdo con las Leyes de Espa&ntilde;a,
                as&iacute; como a cualquier controversia derivada de la
                utilizaci&oacute;n de este servicio, las partes se someten a la
                jurisdicci&oacute;n de los tribunales de la Comunidad de Madrid.
              </span>
            </p>
            <ol start="12">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Políticas relacionadas con la newsletter
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Estas políticas se entenderán en todo momento como parte
                complementaria de los términos y condiciones expuestos en el
                sitio web, siendo ambos de igual aplicación al momento de una
                controversia. La política de privacidad y de propiedad
                intelectual aplicada será la misma que se expone en los términos
                y condiciones del sitio web.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Se entenderá como “newsletter” al boletín digital que EL GESTOR
                DEL SITIO WEB realiza de forma periódica y que hace llegar a sus
                suscriptores a través de un proveedor de servicios de correo
                electrónico externo, al cual el usuario se ha suscrito de manera
                voluntaria.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB no está en la obligación de enviar en
                períodos de tiempo definidos la newsletter, por lo que es
                totalmente libre de hacerlo cuando lo estime conveniente. El
                usuario podrá en todo momento ejercer sus derechos de acceso,
                rectificación, cancelación u oposición siguiendo las
                instrucciones que encontrará en el pie de página de la
                newsletter.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Estas políticas se entenderán en todo momento como parte
                complementaria de los términos y condiciones expuestos en el
                sitio web, siendo ambos de igual aplicación al momento de una
                controversia. La política de privacidad y de propiedad
                intelectual aplicada será la misma que se expone en los términos
                y condiciones del sitio web.El usuario no deberá compartir su
                contenido con terceros, ya que esto violaría los derechos de
                autor de EL GESTOR DEL SITIO WEB. El único canal de distribución
                posible es el administrado y/o autorizado por EL GESTOR DEL
                SITIO WEB.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB no se hará responsable por el contenido
                de terceros expuesto en la newsletter, cualquier controversia
                que surja deberá ser tratada directamente con la persona o
                empresa de la cual se hace mención.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Todo el material que se expone en la newsletter está protegido
                por derechos de autor a favor de EL GESTOR DEL SITIO WEB, y en
                el caso de pertenecer a terceros, se entenderá que EL GESTOR DEL
                SITIO WEB tiene la debida autorización para utilizarlo como se
                haya convenido con el autor.
              </span>
            </p>
            <ol start="13">
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Duración del tratamiento
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                En el caso de los datos personales suministrados para
                facturación y compra de productos o servicios, serán guardados
                por el tiempo legalmente aplicable por la plataforma que realice
                la facturación.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                En el caso de los datos personales suministrados para
                facturación y compra de productos o servicios, serán guardados
                por el tiempo legalmente aplicable por la plataforma que realice
                la facturación.En el caso de los datos personales suministrados
                para boletines comerciales electrónicos y comentarios en el
                blog, será por el tiempo que el titular de los datos desee
                permanecer en la lista de suscripción, por lo que podrá darse de
                baja en el momento que así lo desee, de forma automática como se
                indica en cada boletín, o escribiendo al Support@estartaps.com
              </span>
            </p>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default Login;
