import React, { Component } from "react";
import { Grid } from "@material-ui/core";
class ManagementTeam extends Component {
	constructor(props) {
		super(props);

		this.state = {
			member: this.props.member,
		};
	}

	componentDidMount() {}

	render() {
		return (
			<Grid item lg={4} sm={6} xs={12}>
				<div className="management-team member borderShadow">
					<p>{this.state.member.name}</p>
					<p>{this.state.member.position}</p>
					<p>
						<a href={this.state.member.linkedinUrl}>
							{this.state.member.linkedinUrl}
						</a>
					</p>
				</div>
			</Grid>
		);
	}
}
export default ManagementTeam;
