import React, { Component } from "react";
import {
	Grid,
	Container,
	Button,
	InputLabel,
	Input,
	FormControl,
} from "@material-ui/core";
import { getUserData, getUserToken } from "../../../utils/manageUserData";
import axios from "axios";
import { withSnackbar } from "notistack";
import { DataGrid } from "@material-ui/data-grid";
import Switch from "@material-ui/core/Switch";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

class listJobs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userToken: "",
			userData: "",
			jobList: "",
			startupToEdit: "",
			imageToUpload: "",
			croppedImage: "",
			croppedImageFile: "",
			crop: { x: 0, y: 0 },
			croppedAreaPixels: "",
			uploadedImage: "",
			croppedArea: "",
			zoom: 1,
			aspect: 1 / 1,
			cropSize: { width: 300, height: 300 },
			products_array: [],
			teams_array: [],
			update_change_status_services: [],
			allInfoEditJob: "",
		};
	}
	componentDidMount() {
		(async () => {
			await this.getUserInformation();
			await this.getUserToken();
			await this.getAllJobs();
		})();
	}
	getUserToken = async () => {
		var userToken = getUserToken();
		if (userToken !== false) {
			const obj = userToken;
			this.setState({
				userToken: obj,
			});
		}
	};
	getUserInformation = async () => {
		var userData = getUserData();

		if (userData !== false) {
			const obj = JSON.parse(userData);
			this.setState({
				userData: obj,
			});
		}
	};

	getAllJobs = async () => {
		var userToken = this.state.userToken;
		var apiurl = "/api/jobs";

		await axios({
			url: apiurl,
			method: "GET",
			headers: {
				"x-token": userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				this.setState({
					jobList: response.data.jobs,
				});
			})
			.catch((error) => {
				console.log("No startup Found");
			});
	};
	editJob = (jobData) => {
		this.setState({
			allInfoEditJob: jobData,
			jobID: jobData._id,
			emailEditJob: jobData.email,
			formationRequirementsEditJob: jobData.formationRequirements,
			jobEditJob: jobData.job,
			jobDescriptionEditJob: jobData.jobDescription,
			phoneEditJob: jobData.phone,
			salaryEditJob: jobData.salary,
			startupIdEditJob: jobData.startupId,
			startupImgEditJob: jobData.startupImg,
			startupIndustryEditJob: jobData.startupIndustry,
			startupPermalinkEditJob: jobData.startupPermalink,
			townEditJob: jobData.town,
			activeEditJob: jobData.active,
			candidatesEditJob: jobData.candidates,
		});
	};

	submitUpdateJob = (event) => {
		event.preventDefault();
		if (this.state.allInfoEditJob !== "") {
			//console.log(listProducts);

			const payload = {
				active: this.state.allInfoEditJob.active,
				candidates: this.state.allInfoEditJob.candidates,
				country: event.target.edit_job_country.value,
				createdAt: this.state.allInfoEditJob.createdAt,
				email: this.state.allInfoEditJob.email,
				formationRequirements: this.state.formationRequirementsEditJob,
				job: event.target.edit_job_name.value,
				jobDescription: this.state.jobDescriptionEditJob,
				phone: event.target.edit_job_name.value,
				salary: event.target.edit_job_salary.value,
				startupId: this.state.allInfoEditJob.startupId,
				startupImg: this.state.allInfoEditJob.startupImg,
				startupIndustry: event.target.edit_job_startupIndustry.value,
				startupName: event.target.edit_job_startupName.value,
				startupPermalink: this.state.allInfoEditJob.startupPermalink,
				town: event.target.edit_job_town.value,
				type: event.target.edit_job_type.value,
			};
			this.submitAlldata(payload);
		}
	};
	submitAlldata = (payload) => {
		var apiurlput = "/api/jobs/" + this.state.allInfoEditJob._id;
		axios({
			url: apiurlput,
			method: "PUT",
			data: payload,
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				this.props.enqueueSnackbar("Datos guardados con éxito", {
					variant: "success",
				});
				this.getAllJobs();
			})
			.catch((error) => {
				alert("Hemos encontrado un error y no se han guardado los cambios");
			});
	};
	onCropChange = (crop) => {
		this.setState({ crop });
	};

	onCropComplete = (croppedArea, croppedAreaPixels) => {
		this.setState({
			croppedArea: croppedArea,
			croppedAreaPixels: croppedAreaPixels,
		});
	};

	onZoomChange = (zoom) => {
		this.setState({ zoom });
	};
	handleChange = (event) => {
		var image = event.target.files[0];
		if (event.target.files[0]) {
			this.setState({
				uploadedImage: event.target.files[0],
				imageSrc: URL.createObjectURL(image),
			});
		} else {
			this.setState({
				uploadedImage: "",
				imageSrc: "",
			});
		}
	};

	handleChangeStatus = (event) => {
		this.setState({
			update_change_status_services: event.target.value,
		});
	};
	handleChangeStatusActive = (event, id, name, job) => {
		let checked = 0;
		if (event.target.checked === true) {
			checked = 1;
		} else {
			checked = 0;
		}

		var apiurlput = "/api/jobs/" + id;

		const payload = {
			active: checked,
			candidates: job.candidates,
			country: job.country,
			createdAt: job.createdAt,
			email: job.email,
			formationRequirements: job.formationRequirements,
			job: job.job,
			jobDescription: job.jobDescription,
			phone: job.phone,
			salary: job.salary,
			startupId: job.startupId,
			startupImg: job.startupImg,
			startupIndustry: job.startupIndustry,
			startupName: job.startupName,
			startupPermalink: job.startupPermalink,
			town: job.town,
			type: job.type,
		};

		axios({
			url: apiurlput,
			method: "PUT",
			data: payload,
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				this.props.enqueueSnackbar("Cambiado el estado del empleo", {
					variant: "success",
				});
				this.getAllJobs();
			})
			.catch((error) => {
				this.props.enqueueSnackbar(
					"No se ha podido cambiar el estado del empleo",
					{
						variant: "warning",
					}
				);
			});
	};

	deleteJob = async (id) => {
		const headers = {
			headers: {
				"x-token": this.state.userToken,
			},
		};

		const { data } = await axios.delete("/api/jobs/" + id, headers);

		if (Number(data.code) === 200) {
			this.props.enqueueSnackbar("Empleo eliminado", {
				variant: "success",
				autoHideDuration: 3000,
			});
		}

		this.getAllJobs();
	};
	render() {
		const columns = [
			{
				field: "job",
				headerName: "Nombre",
				editable: false,
				flex: 1,
			},

			{
				field: "startupName",
				headerName: "Nombre empresa",
				editable: false,
				flex: 1,
			},
			{
				field: "actions",
				headerName: "Acciones",
				sortable: false,
				flex: 1,
				disableClickEventBubbling: true,
				renderCell: (params) => {
					return (
						<div>
							<Button
								className="button"
								onClick={() => this.editJob(params.row)}
							>
								Ver
							</Button>
						</div>
					);
				},
			},
			{
				field: "accepted",
				headerName: "Estado",
				sortable: false,
				flex: 1,
				disableClickEventBubbling: true,
				renderCell: (params) => {
					return (
						<div>
							<Switch
								defaultChecked={params.row.active}
								onChange={(event) =>
									this.handleChangeStatusActive(
										event,
										params.row._id,
										params.row.name,
										params.row
									)
								}
								inputProps={{ "aria-label": "check-status" }}
							/>
						</div>
					);
				},
			},
			{
				field: "delete",
				headerName: "Borrar",
				sortable: false,
				flex: 1,
				disableClickEventBubbling: true,
				renderCell: (params) => {
					return (
						<div>
							<Button
								className="button"
								onClick={() =>
									window.confirm("¿Estás seguro de borrar el reto?")
										? this.deleteJob(params.row._id)
										: ""
								}
							>
								Borrar
							</Button>
						</div>
					);
				},
			},
		];

		return (
			<Container>
				<Grid container className="borderShadow">
					<Grid item xs={12}>
						<div>
							{this.state.jobList !== "" ? (
								<DataGrid
									rows={this.state.jobList}
									getRowId={(row) => row._id}
									autoHeight
									columns={columns}
									pageSize={5}
								/>
							) : (
								<p>Cargando</p>
							)}
						</div>
						<div>
							{this.state.allInfoEditJob !== "" ? (
								<form
									onSubmit={this.submitUpdateJob}
									className="form"
									key={this.state.jobID}
								>
									<Grid container>
										<Grid item={true} sm={12} xs={12}>
											<FormControl className="width-100">
												<InputLabel htmlFor={"edit_job_name"} shrink={true}>
													Nombre del trabajo*
												</InputLabel>
												<Input
													id={"edit_job_name"}
													name={"edit_job_name"}
													aria-describedby={"edit_job_name"}
													defaultValue={this.state.allInfoEditJob.job}
													type="text"
													required
												/>
											</FormControl>
										</Grid>
										<Grid item={true} sm={12} xs={12}>
											<FormControl className="width-100">
												<InputLabel htmlFor={"edit_job_country"} shrink={true}>
													País
												</InputLabel>
												<Input
													id={"edit_job_country"}
													name={"edit_job_country"}
													aria-describedby={"edit_job_country"}
													defaultValue={this.state.allInfoEditJob.country}
													type="text"
													required
												/>
											</FormControl>
										</Grid>
										<Grid item={true} sm={12} xs={12}>
											<FormControl className="width-100">
												<InputLabel htmlFor={"edit_job_town"} shrink={true}>
													Ciudad
												</InputLabel>
												<Input
													id={"edit_job_town"}
													name={"edit_job_town"}
													aria-describedby={"edit_job_town"}
													defaultValue={this.state.allInfoEditJob.town}
													type="text"
													required
												/>
											</FormControl>
										</Grid>
										<Grid item={true} sm={12} xs={12}>
											<FormControl className="width-100">
												<p>Requerimientos</p>
												<ReactQuill
													name="editChallengeDescription"
													modules={this.modules}
													formats={this.formats}
													onChange={(e) =>
														this.setState({ formationRequirementsEditJob: e })
													}
													value={this.state.formationRequirementsEditJob}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} sm={12} xs={12}>
											<FormControl className="width-100">
												<p>Descripción</p>
												<ReactQuill
													name="editChallengeDescription"
													modules={this.modules}
													formats={this.formats}
													onChange={(e) =>
														this.setState({ jobDescriptionEditJob: e })
													}
													value={this.state.jobDescriptionEditJob}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} sm={12} xs={12}>
											<FormControl className="width-100">
												<InputLabel htmlFor={"edit_job_salary"} shrink={true}>
													Salario
												</InputLabel>
												<Input
													id={"edit_job_salary"}
													name={"edit_job_salary"}
													aria-describedby={"edit_job_salary"}
													defaultValue={this.state.allInfoEditJob.salary}
													type="text"
												/>
											</FormControl>
										</Grid>
										<Grid item={true} sm={12} xs={12}>
											<FormControl className="width-100">
												<InputLabel
													htmlFor={"edit_job_startupIndustry"}
													shrink={true}
												>
													Industria
												</InputLabel>
												<Input
													id={"edit_job_startupIndustry"}
													name={"edit_job_startupIndustry"}
													aria-describedby={"edit_job_startupIndustry"}
													defaultValue={
														this.state.allInfoEditJob.startupIndustry
													}
													type="text"
													disabled
												/>
											</FormControl>
										</Grid>
										<Grid item={true} sm={12} xs={12}>
											<FormControl className="width-100">
												<InputLabel
													htmlFor={"edit_job_startupName"}
													shrink={true}
												>
													Nombre empresa
												</InputLabel>
												<Input
													id={"edit_job_startupName"}
													name={"edit_job_startupName"}
													aria-describedby={"edit_job_startupName"}
													defaultValue={this.state.allInfoEditJob.startupName}
													type="text"
													disabled
												/>
											</FormControl>
										</Grid>
										<Grid item={true} sm={12} xs={12}>
											<FormControl className="width-100">
												<InputLabel htmlFor={"edit_job_type"} shrink={true}>
													Tipo de contrato
												</InputLabel>
												<Input
													id={"edit_job_type"}
													name={"edit_job_type"}
													aria-describedby={"edit_job_type"}
													defaultValue={this.state.allInfoEditJob.type}
													type="text"
												/>
											</FormControl>
										</Grid>
										<Grid item={true} sm={12} xs={12} className="center">
											{" "}
											<Button
												variant="contained"
												color="primary"
												type="submit"
												className="button center"
												ref="buttonRegister"
											>
												Guardar
											</Button>
										</Grid>
									</Grid>
								</form>
							) : (
								<p></p>
							)}
						</div>
					</Grid>
				</Grid>
			</Container>
		);
	}
}
export default withSnackbar(listJobs);
