import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import { getUserToken } from '../components/utils/manageUserData.js';
import axios from 'axios';
import ContactEmail from '../components/utils/contactEmail';
import Divider from '@material-ui/core/Divider';

class CreateFirstStartup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createFirstStartup: '',
      userToken: getUserToken(),
    };
  }
  componentDidMount() {
    this.getCreateFirstStartup();
  }

  getCreateFirstStartup = async () => {
    const apiurl = '/api/admin/create-first-startup';

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.setState({
          createFirstStartup: response.data.createFirstStartup,
        });
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  render() {
    const customStyles = {
      display: 'table',
      margin: '0 auto',
    };
    return (
      <Container className="bodyWrapper create-first-startup">
        <Grid
          container
          className="w-100 float-left  startup"
          style={{ background: '#F3F8FF', padding: '1rem' }}
          sm={12}
          xs={12}
        >
          <Grid className="float-left mainColorBg" sm={12} xs={12}>
            <h1 className="seo-h1 center">Cómo crear una startup</h1>
            <h2 className="seo-h2 center">Qué es una Startup</h2>
            <Grid className="float-left mainColorBg content" sm={12} xs={12}>
              <div
                className="fromEditor"
                dangerouslySetInnerHTML={{
                  __html: this.state.createFirstStartup,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider className="divider" />

        <Grid className="" sm={12} xs={12}>
          <ContactEmail
            userToken={this.state.userToken}
            customStyles={customStyles}
          />
        </Grid>
      </Container>
    );
  }
}
export default CreateFirstStartup;
