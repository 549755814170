import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import Collapse from '@material-ui/core/Collapse';

axios.defaults.baseURL = process.env.REACT_APP_API_URL_PROD;

ReactDOM.render(
  //<React.StrictMode>
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    TransitionComponent={Collapse}
  >
    <App />
  </SnackbarProvider>,
  //</React.StrictMode>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
