import React, { Component } from 'react';

import {
  Container,
  Grid,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import axios from 'axios';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {
  getUserData,
  getUserToken,
  updateDataUser,
} from '../utils/manageUserData';
import { withSnackbar } from 'notistack';
import { FormGroup } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class BasicInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      update_account: '',
      userData: '',
      userToken: '',
      acceptTerms01: false,
      newsletter: false,
    };
  }
  componentDidMount() {
    this.getUserInformation();
    this.getUserToken();
  }

  getUserInformation = () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
    }
  };
  getUserToken = () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  submitRegister = (event) => {
    event.preventDefault();
    let email = document.getElementsByName('update_email')[0].value;
    let name = document.getElementsByName('update_name')[0].value;
    let lastname = document.getElementsByName('update_lastname')[0].value;
    let account = document.getElementsByName('update_account')[0].value;
    let newsletter = this.state.newsletter;

    if (email === '' || account === '' || this.state.acceptTerms === false) {
      alert('faltan campos por rellenar');
    } else {
      if (account === 'SECONDARY_USER') {
        newsletter = true;
      }

      const payload = {
        name: name,
        lastName: lastname,
        email: email.toLowerCase(),
        role: account,
        acceptTerms: this.state.acceptTerms01,
        newsletter,
      };
      var apiurl = '/api/users/' + this.state.userData.uid;
      axios({
        url: apiurl,
        method: 'PUT',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          this.props.enqueueSnackbar('Datos guardados con éxito', {
            variant: 'success',
          });
          updateDataUser(response.data);
          window.location.reload();
          //this.emailVerification(email);
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.msg === 'User can not be updated') {
            this.props.enqueueSnackbar(
              'No se han podido guardar sus datos, asegurese de que la dirección de email es correcta y no la ha usado previamente.',
              {
                variant: 'error',
              }
            );
          }
          console.log('Error');
        });
    }
  };
  handleChangeAccount = (event) => {
    this.setState({
      update_account: event.target.value,
    });
  };
  changeStateTerms = (event) => {
    if (this.state.acceptTerms01 === true) {
      this.setState({
        acceptTerms01: false,
      });
    } else {
      this.setState({
        acceptTerms01: true,
      });
    }
  };
  changeStateNewsletter = (event) => {
    if (this.state.newsletter === true) {
      this.setState({
        newsletter: false,
      });
    } else {
      this.setState({
        newsletter: true,
      });
    }
  };
  render() {
    console.log(this.state.userData);
    return (
      <Container>
        <Grid container>
          {' '}
          <form
            className="form-register"
            onSubmit={this.submitRegister}
            id="formregister"
            autoComplete="off"
            key={this.state.userData}
          >
            <Container>
              <Grid container>
                <Grid item={true} sm={1} xs={12}></Grid>
                <Grid item={true} sm={10} xs={12}>
                  <div className="formDelimiter">
                    <h3 className="center">
                      Campos básicos requeridos para continuar
                    </h3>

                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_email">Email</InputLabel>
                      <Input
                        id="update_email"
                        name="update_email"
                        type="email"
                        ref="update_email"
                        defaultValue={this.state.userData.email}
                        endAdornment={
                          <InputAdornment position="end">
                            <EmailIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_name">Nombre</InputLabel>
                      <Input
                        id="update_name"
                        name="update_name"
                        type="text"
                        ref="update_name"
                        defaultValue={this.state.userData.name}
                      />
                    </FormControl>
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_lastname">
                        Apellidos
                      </InputLabel>
                      <Input
                        id="update_lastname"
                        name="update_lastname"
                        type="text"
                        ref="update_lastname"
                        defaultValue={this.state.userData.lastName}
                      />
                    </FormControl>
                    <FormControl
                      className="width-100"
                      style={{ marginTop: '1rem' }}
                    >
                      <InputLabel id="update_account">
                        Tipo de cuenta
                      </InputLabel>
                      <Select
                        labelId="update_account"
                        id="update_account"
                        name="update_account"
                        value={this.state.update_account}
                        onChange={this.handleChangeAccount}
                        defaultValue={this.state.userData.role}
                      >
                        <MenuItem value={'FINAL_USER'}>
                          Tengo una Startup y me gustaría obtener un mejor
                          posicionamiento y resultados.
                        </MenuItem>
                        <MenuItem value={'CROWDFUNDING_USER'}>
                          Me gustaría comenzar a invertir en Startups.
                        </MenuItem>
                        <MenuItem value={'FINAL_USER_STARTUP'}>
                          Busco prácticas en una Startup.
                        </MenuItem>
                        <MenuItem value={'FINAL_USER_COMPANY'}>
                          Busco empleo en una Startup.
                        </MenuItem>
                        <MenuItem value={'SECONDARY_USER_ADMIN'}>
                          Buscas las mejores soluciones inovadoras para tu
                          empresa
                        </MenuItem>
                        <MenuItem value={'SECONDARY_USER'}>
                          Quieres estar informado sobre las ultimas noticias en
                          innovacion y en el mundo Startup
                        </MenuItem>
                        <MenuItem value={'USER_OFFER_SERVICES'}>
                          ¿Quieres ofrecer los servicios de tu empresa a nuestro
                          ecosistema de Startups?
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.acceptTerms01}
                            onClick={this.changeStateTerms}
                            name="acceptTerms"
                            required
                          />
                        }
                        label={
                          <span onClick={this.changeStateTerms}>
                            Acepto los{' '}
                            <a href="/politica-de-privacidad" target="_blank">
                              términos y condiciones
                            </a>
                          </span>
                        }
                      />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.newsletter}
                            onClick={this.changeStateNewsletter}
                            name="acceptNewsletter"
                          />
                        }
                        label={
                          <span onClick={this.changeStateNewsletter}>
                            Suscríbete a nuestra newsletter
                          </span>
                        }
                      />
                    </FormGroup>
                    <Button
                      form="formregister"
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="button center width-100"
                    >
                      Guardar Información
                    </Button>
                  </div>
                </Grid>
                <Grid item={true} sm={1} xs={12}></Grid>
              </Grid>
            </Container>
          </form>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(BasicInformation);
