import React, { Component } from 'react';

import MenuIcon from '@material-ui/icons/Menu';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import HistoryIcon from '@material-ui/icons/History';
import ChatIcon from '@material-ui/icons/Chat';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CasinoIcon from '@material-ui/icons/Casino';
import BookmarkAddedIcon from '@material-ui/icons/Bookmark';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ForumIcon from '@material-ui/icons/Forum';
import EmailIcon from '@material-ui/icons/Email';
class SidebarProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open1: false,
      open2: false,
      isopen: false,
      actualRole: this.props.roleUser,
      locationSidebar: window.location.pathname.split('/').pop(),
    };
  }
  componentDidMount() {}

  handleDrawerClose = () => {
    if (this.state.open === true) {
      this.setState({
        open: false,
        open1: false,
      });
    } else {
      this.setState({
        open: true,
        open1: true,
      });
    }
  };
  openOption = (id) => {
    if (id === 1) {
      if (this.state.open1 === false) {
        this.setState({ open1: true });
      } else {
        this.setState({ open1: false });
      }
    }
    if (id === 2) {
      if (this.state.open2 === false) {
        this.setState({ open2: true });
      } else {
        this.setState({ open2: false });
      }
    }
  };

  chooseOption = (component) => {
    /*
		let urlToGo =
			"http://" + window.location.hostname + ":3000/perfil/" + component;
		window.location.href = urlToGo;
		*/
    if (!component.includes('noData')) {
      this.props.getClickedElement(component);
      this.setState({
        locationSidebar: component,
      });
    }
  };
  setIsSidebarOpen = () => {
    if (this.state.isopen === true) {
      this.setState({
        isopen: false,
      });
    } else {
      this.setState({
        isopen: true,
      });
    }
  };

  render() {
    // eslint-disable-next-line
    const sidebar1 = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Mi Empresa',
                itemId: 'noData_1321sadas',

                elemBefore: () => <SearchIcon />,
                subNav: [
                  {
                    title: 'Editar Empresa',
                    itemId: 'editar_startups',
                    elemBefore: () => <EditIcon />,
                  },
                ],
              },
              {
                title: 'Servicios a Startups',
                itemId: 'noData_2123',
                elemBefore: () => <WorkIcon />,
                subNav: [
                  {
                    title: 'Ver y editar Servicios',
                    itemId: 'editar_servicios',
                  },
                ],
              },
              {
                title: 'Mensajería',
                itemId: 'chat',
                elemBefore: () => <ChatIcon />,
              },
            ]}
          />
        </div>
      </div>
    );

    const sidebar_crowdfunding = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Crowdfunding',
                itemId: 'crowd_funding',
                elemBefore: () => <ChatIcon />,
                subNav: [
                  {
                    title: 'Listado',
                    itemId: 'listado_crowdfunding',
                  },
                  {
                    title: 'Añadir Empresa',
                    itemId: 'anadir_Crowdfunding',
                  },
                ],
              },
              {
                title: 'Mensajería',
                itemId: 'chat',
                elemBefore: () => <ChatIcon />,
              },
            ]}
          />
        </div>
      </div>
    );

    const sidebar_secundarias = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Crowdfunding',
                itemId: 'crowd_funding',
                elemBefore: () => <ChatIcon />,
                subNav: [
                  {
                    title: 'Listado',
                    itemId: 'listado_crowdfunding',
                  },
                  {
                    title: 'Añadir Empresa',
                    itemId: 'anadir_Crowdfunding',
                  },
                ],
              },
              {
                title: 'Mensajería',
                itemId: 'chat',
                elemBefore: () => <ChatIcon />,
              },
            ]}
          />
        </div>
      </div>
    );
    const sidebar_crowdfunding_user = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Inversiones',
                itemId: 'crowd_funding',
                elemBefore: () => <ChatIcon />,
                subNav: [
                  // {
                  //   title: 'Crowdfunding',
                  //   itemId: 'solicitada_crowd',
                  // },
                  {
                    title: 'Vender acciones',
                    itemId: 'secundarias_crowdfunding',
                  },
                ],
              },
              {
                title: 'Mensajería',
                itemId: 'chat',
                elemBefore: () => <ChatIcon />,
              },
              {
                title: 'Blog Secreto',
                itemId: 'forum',
                elemBefore: () => <ForumIcon />,
              },
            ]}
          />
        </div>
      </div>
    );
    const sidebar_sellActions_user = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Inversiones',
                itemId: 'crowd_funding',
                elemBefore: () => <ChatIcon />,
                subNav: [
                  // {
                  //   title: 'Crowdfunding',
                  //   itemId: 'solicitada_ventaAcciones',
                  // },
                  {
                    title: 'Secundarias',
                    itemId: 'secundarias_crear_venta',
                  },
                ],
              },
              {
                title: 'Mensajería',
                itemId: 'chat',
                elemBefore: () => <ChatIcon />,
              },
            ]}
          />
        </div>
      </div>
    );
    const sidebar2 = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Mi Empresa',
                itemId: 'noData_1321sadas',

                elemBefore: () => <SearchIcon />,
                subNav: [
                  {
                    title: 'Editar Empresa',
                    itemId: 'editar_compania',
                    elemBefore: () => <EditIcon />,
                  },
                ],
              },
              {
                title: 'Mensajería',
                itemId: 'chat',
                elemBefore: () => <ChatIcon />,
              },
              {
                title: 'Reto',
                itemId: 'reto',
                elemBefore: () => <AnnouncementIcon />,
              },
              {
                title: 'Blog Secreto',
                itemId: 'forum',
                elemBefore: () => <ForumIcon />,
              },
            ]}
          />
        </div>
      </div>
    );

    const sidebar_challenges = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Mi Empresa',
                itemId: 'noData_1321sadas',

                elemBefore: () => <SearchIcon />,
                subNav: [
                  {
                    title: 'Editar Empresa',
                    itemId: 'editar_compania',
                    elemBefore: () => <EditIcon />,
                  },
                ],
              },

              {
                title: 'Mensajería',
                itemId: 'chat',
                elemBefore: () => <ChatIcon />,
              },
            ]}
          />
        </div>
      </div>
    );
    const userLookIntern = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Editar Perfil',
                itemId: 'edit_profile_look_intern',
                elemBefore: () => <EditIcon />,
              },
              {
                title: 'Mensajería',
                itemId: 'chat',
                elemBefore: () => <ChatIcon />,
              },
              {
                title: 'Inscripciones de empleo',
                itemId: 'user_view_job_application',
                elemBefore: () => <SearchIcon />,
              },
            ]}
          />
        </div>
      </div>
    );
    const sidebar3 = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Mi Startup',
                itemId: 'noData_1321sadas',

                elemBefore: () => <SearchIcon />,
                subNav: [
                  {
                    title: 'Editar Startup',
                    itemId: 'editar_startups',
                    elemBefore: () => <EditIcon />,
                  },
                ],
              },

              {
                title: 'Puja por palabra',
                itemId: 'puja_por_palabras',
                elemBefore: () => <CasinoIcon />,
              },
              {
                title: 'Historial de pujas',
                itemId: 'historial_pujas',
                elemBefore: () => <HistoryIcon />,
              },
              {
                title: 'Gasto por búsqueda',
                itemId: 'gasto_por_busqueda',
                elemBefore: () => <MoneyOffIcon />,
              },
              {
                title: 'Inversiones',
                itemId: 'noData_1321sadas23423',
                elemBefore: () => <GroupAddIcon />,
                subNav: [
                  {
                    title: 'CrowdFunding',
                    itemId: 'pedir_crowdfunding',
                    elemBefore: () => <GroupAddIcon />,
                  },
                  {
                    title: 'Destacar ficha',
                    itemId: 'destacar_ficha',
                    elemBefore: () => <BookmarkAddedIcon />,
                  },
                ],
              },
              {
                title: 'Mensajería',
                itemId: 'chat',
                elemBefore: () => <ChatIcon />,
              },
              {
                title: 'Ofertas de empleo',
                itemId: 'ofertas_de_empleo',
                elemBefore: () => <WorkIcon />,
              },
              {
                title: 'Reto',
                itemId: 'reto_startups',
                elemBefore: () => <AnnouncementIcon />,
              },
              {
                title: 'Blog Secreto',
                itemId: 'forum',
                elemBefore: () => <ForumIcon />,
              },
            ]}
          />
        </div>
      </div>
    );
    const adminSidebar = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            activeItemId={this.state.locationSidebar}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Sidebar del Administrador(WIP)',
                itemId: '/',

                elemBefore: () => <AccountBalanceWalletIcon />,
              },
              {
                title: 'Startups',
                itemId: 'noData_1321asdas',
                elemBefore: () => <BusinessIcon />,
                subNav: [
                  {
                    title: 'Ver Startups',
                    itemId: 'listado_startups',

                    elemBefore: () => <BusinessIcon />,
                  },
                ],
              },
              {
                title: 'Empresas',
                itemId: 'noData_1321asdfdas',
                elemBefore: () => <BusinessIcon />,
                subNav: [
                  {
                    title: 'Ver Empresas',
                    itemId: 'listado_empresas',

                    elemBefore: () => <BusinessIcon />,
                  },
                ],
              },
              {
                title: 'Usuarios',
                itemId: 'noData_21231',
                elemBefore: () => <PeopleIcon />,
                subNav: [
                  {
                    title: 'Ver Usuarios',
                    itemId: 'listado_usuarios',

                    elemBefore: () => <PeopleIcon />,
                  },
                  {
                    title: 'Crear usuario',
                    itemId: 'crear_usuarios',

                    elemBefore: () => <PeopleIcon />,
                  },
                ],
              },
              {
                title: 'Reto',
                itemId: 'reto_admin',
                elemBefore: () => <AnnouncementIcon />,
              },
              {
                title: 'Facturación',
                itemId: 'listado_transacciones_startups',
                elemBefore: () => <AccountBalanceWalletIcon />,
              },
              {
                title: 'Listado de empleo',
                itemId: 'listado_empleo',
                elemBefore: () => <SearchIcon />,
              },
              {
                title: 'Solicitudes de Servicios',
                itemId: 'solicitud_servicio',
              },
              {
                title: 'Blog',
                itemId: 'blog',
                elemBefore: () => <PostAddIcon />,
                subNav: [
                  {
                    title: 'Entradas',
                    itemId: 'blog_entradas',
                  },
                  {
                    title: 'Banners de publicidad',
                    itemId: 'blog_ads',
                  },
                ],
              },
              {
                title: 'Crea tu primera startup',
                itemId: 'create_first_startup',
              },
              {
                title: 'Venta de acciones',
                itemId: 'solicitada_ventaAcciones',
              },
              {
                title: 'Listado crowdfunding',
                itemId: 'listado_crowdfunding',
              },
              {
                title: 'Blog Secreto',
                itemId: 'forum',
                elemBefore: () => <ForumIcon />,
              },
              {
                title: 'Newsletter',
                itemId: 'newsletter',
                elemBefore: () => <EmailIcon />,
              },
            ]}
          />
        </div>
      </div>
    );
    const userLookJob = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Editar Perfil',
                itemId: 'edit_profile_look_job',
                elemBefore: () => <EditIcon />,
              },
              {
                title: 'Mensajería',
                itemId: 'chat',
                elemBefore: () => <ChatIcon />,
              },
              {
                title: 'Inscripciones de empleo',
                itemId: 'user_view_job_application',
                elemBefore: () => <SearchIcon />,
              },
            ]}
          />
        </div>
      </div>
    );
    const userSubscribed = (
      <div>
        <div>
          <button
            className="btn-menu_sidebar"
            onClick={() => this.setIsSidebarOpen(true)}
            type="button"
            hidden
          >
            <MenuIcon />
          </button>
        </div>
        <div
          className={`sidebarProfiles ${
            this.state.isopen
              ? 'ease-out sidebarProfilesOpen'
              : 'ease-in sidebarProfilesClose'
          }`}
        >
          <Navigation
            className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
              this.state.isopen ? 'block' : 'hidden'
            }`}
            onSelect={({ itemId }) => {
              if (itemId !== '/') {
                this.chooseOption(itemId);
              }
            }}
            items={[
              {
                title: 'Editar Perfil',
                itemId: 'edit_profile_user_newsletter',
                elemBefore: () => <EditIcon />,
              },
            ]}
          />
        </div>
      </div>
    );
    return (
      <div>
        <div>
          {this.props.roleUser === 'CROWDFUNDING_USER'
            ? sidebar_crowdfunding_user
            : ''}
        </div>
        <div>
          {this.props.roleUser === 'CROWDFUNDING_ADMIN'
            ? sidebar_crowdfunding
            : ''}
        </div>
        <div>
          {this.props.roleUser === 'FINAL_USER_COMPANY' ? userLookJob : ''}
        </div>
        <div>{this.props.roleUser === 'FINAL_USER' ? sidebar3 : ''}</div>
        <div>
          {this.props.roleUser === 'FINAL_USER_STARTUP' ? userLookIntern : ''}
        </div>
        <div>
          {/*this.props.roleUser === 'SECONDARY_USER_ADMIN' ? sidebar2 : '' */}
          {this.props.roleUser === 'SECONDARY_USER_ADMIN'
            ? sidebar_secundarias
            : ''}
        </div>
        <div>
          {this.props.roleUser === 'SECONDARY_USER' ? userSubscribed : ''}
        </div>
        <div>
          {this.props.roleUser === 'USER_OFFER_SERVICES'
            ? sidebar_challenges
            : ''}
        </div>
        <div>{this.props.roleUser === 'ADMIN' ? adminSidebar : ''}</div>
        <div>
          {this.props.roleUser === 'RESTRICTED_ADMIN' ? adminSidebar : ''}
        </div>
        <div>
          {this.props.roleUser === 'SELLACTIONS_ADMIN'
            ? sidebar_sellActions_user
            : ''}
        </div>
      </div>
    );
  }
}
export default SidebarProfile;
