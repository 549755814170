import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import { GoogleLogout } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import EmailIcon from '@material-ui/icons/Email';
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Checkbox } from '@material-ui/core';
import {
  Container,
  Grid,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormGroup } from '@material-ui/core';
import { setLogin } from '../utils/manageUserData';
import { withSnackbar } from 'notistack';
//import axios from "axios";
class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: '',
      openModalLogin: false,
      openModalRegister: false,
      showPassword: false,
      password: '',
      facebookName: '',
      facebookEmail: '',
      facebookImage: '',
      register_account: 'FINAL_USER',
      messageError: '',
      showMessageError: false,
      acceptTerms01: false,
      openModalPassword: false,
      newsletter: false,
    };
  }
  componentDidMount() {}

  handleCloseLogin = () => {
    if (this.state.openModalLogin === false) {
      this.setState({
        openModalLogin: true,
        openModalRegister: false,
        openModalPassword: false,
      });
    } else {
      this.setState({
        openModalLogin: false,
        openModalRegister: false,
        openModalPassword: false,
      });
    }
  };
  handleClosePassword = () => {
    if (this.state.openModalPassword === false) {
      this.setState({
        openModalPassword: true,
        openModalLogin: false,
        openModalRegister: false,
      });
    } else {
      this.setState({
        openModalPassword: false,
        openModalLogin: false,
        openModalRegister: false,
      });
    }
  };
  handleCloseRegister = () => {
    if (this.state.openModalRegister === false) {
      this.setState({
        openModalRegister: true,
        openModalLogin: false,
        openModalPassword: false,
      });
    } else {
      this.setState({
        openModalRegister: false,
        openModalLogin: false,
        openModalPassword: false,
      });
    }
  };
  handleClickShowPassword = (event) => {
    if (this.state.showPassword === false) {
      this.setState({
        showPassword: true,
      });
    } else {
      this.setState({
        showPassword: false,
      });
    }
  };
  handleChangeAccount = (event) => {
    this.setState({
      register_account: event.target.value,
    });
  };

  changeStateTerms = (event) => {
    if (this.state.acceptTerms01 === true) {
      this.setState({
        acceptTerms01: false,
      });
    } else {
      this.setState({
        acceptTerms01: true,
      });
    }
  };
  changeStateTermsLink = (event) => {
    window.open('/politica-de-privacidad', '_blank').focus();
    if (this.state.acceptTerms01 === true) {
      this.setState({
        acceptTerms01: false,
      });
    } else {
      this.setState({
        acceptTerms01: true,
      });
    }
  };
  submitLogin = (event) => {
    let email = document.getElementsByName('login_email')[0].value;
    let password = document.getElementsByName('login_password')[0].value;

    if (email === '' || password === '') {
      alert('faltan campos por rellenar');
    } else {
      const payload = {
        email: email.toLowerCase(),
        pwd: password,
      };

      axios({
        url: '/api/auth/login',
        method: 'POST',
        data: payload,
      })
        .then((response) => {
          setLogin(response.data);

          window.location.reload();
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.msg === 'user email not validated') {
            alert(
              'Te hemos enviado un correo de confirmación a tu e-mail, revísalo para poder iniciar sesión'
            );
          } else {
            alert('Comprueba su email o contraseña');
          }
        });
    }
  };
  submitPassword = () => {
    let email = document.getElementsByName('password_email')[0].value;

    if (email === '') {
      alert('faltan campos por rellenar');
    } else {
      alert(
        'Te hemos enviado un email a la dirección de correo electrónico proporcionada. Revísalo para cambiar la contraseña'
      );

      const payload = {
        email: email,
      };

      axios({
        url: '/api/users/password',
        method: 'POST',
        data: payload,
      })
        .then((response) => {})
        .catch((error) => {});
    }
  };
  submitRegister = (event) => {
    let email = document.getElementsByName('register_email')[0].value;
    //let name = document.getElementsByName("register_name")[0].value;
    //let lastname = document.getElementsByName("register_lastname")[0].value;
    let account = document.getElementsByName('register_account')[0].value;
    let password = document.getElementsByName('register_password')[0].value;
    let terms = this.state.acceptTerms01;
    let newsletter = this.state.newsletter;

    if (email === '' || account === '' || password === '' || terms === false) {
      alert('faltan campos por rellenar');
    } else {
      if (account === 'SECONDARY_USER') {
        newsletter = true;
      }

      const payload = {
        email: email,
        pwd: password,
        role: account,
        tyc: 1,
        newsletter,
      };

      axios({
        url: '/api/users',
        method: 'POST',
        data: payload,
      })
        .then((response) => {
          this.props.enqueueSnackbar(
            'Datos guardados con éxito, en breve recibirá un email con la activación de su cuenta.',
            {
              variant: 'success',
            }
          );
          this.emailVerification(email);
        })
        .catch((error) => {
          alert(
            'Ha ocurrido un error durante el registro: el email introducido ya está registrado.'
          );
          console.log(error);
        });
    }
  };
  emailVerification = (email) => {
    const payload = {
      email: email,
    };

    axios({
      url: '/api/auth/email-validation',
      method: 'POST',
      data: payload,
    })
      .then((response) => {
        this.handleCloseLogin();
      })
      .catch((error) => {});
  };

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  loginWithGoogle = (token) => {
    const payload = {
      idToken: token,
    };
    axios({
      url: '/api/auth/google',
      method: 'POST',
      data: payload,
    })
      .then((response) => {
        setLogin(response.data);

        window.location.reload();
      })
      .catch((error) => {});
  };
  loginWithFacebook = (token) => {
    const payload = {
      idToken: token,
    };
    axios({
      url: '/api/auth/facebook',
      method: 'POST',
      data: payload,
    })
      .then((response) => {
        setLogin(response.data);
        window.location.reload();
      })
      .catch((error) => {});
  };
  changeStateNewsletter = (event) => {
    if (this.state.newsletter === true) {
      this.setState({
        newsletter: false,
      });
    } else {
      this.setState({
        newsletter: true,
      });
    }
  };
  render() {
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const responseGoogle = (response) => {
      this.loginWithGoogle(response);
      this.setState({
        userData: response.profileObj,
      });
      this.loginWithGoogle(response.tokenId);
    };
    const responseGoogleFail = (response) => {};
    const logout = (response) => {
      this.setState({
        userData: '',
      });
      alert('Logout');
    };
    const userData = (
      <div>
        <h3>Log in completed</h3>
        <p>Email: {this.state.userData.email}</p>

        <p>Full name: {this.state.userData.name}</p>
        <p>GoogleId: {this.state.userData.googleId}</p>
      </div>
    );
    const faceData = (
      <div>
        <h3>Log in completed with Facebook</h3>
        <p>Email: {this.state.facebookEmail}</p>
        <p>Name: {this.state.facebookName}</p>
        <img src={this.state.facebookImage} alt="Profile"></img>
      </div>
    );
    const responseFacebook = (response) => {
      console.log(response);
      this.loginWithFacebook(response.accessToken);
    };
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          id="buttonLoginTop"
          onClick={this.handleCloseLogin}
        >
          <AccountCircleIcon /> Iniciar Sesión
        </Button>
        <Button
          variant="outlined"
          color="primary"
          id="buttonRegistrationTop"
          onClick={this.handleCloseRegister}
        >
          Registrarse
        </Button>

        <Dialog
          open={this.state.openModalLogin}
          onClose={this.handleCloseLogin}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogContent>
            <Container>
              <Grid container>
                <Grid item={true} xs={12} sm={6} className="leftSideModalLogin">
                  <DialogContentText className="title01 center bold">
                    ¡Hola! Bienvenido de nuevo
                  </DialogContentText>

                  <img
                    src="/images/signin.png"
                    alt="Signin"
                    className="width-100"
                  ></img>
                  <p className="center padding20">
                    ¿Aún no tienes cuenta?{' '}
                    <span
                      onClick={this.handleCloseRegister}
                      className="bold pointer"
                    >
                      Regístrate aqui
                    </span>
                  </p>
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  sm={6}
                  className="rightSideModalLogin"
                >
                  <Grid container>
                    <Grid item={true} sm={1} xs={12}></Grid>
                    <Grid item={true} sm={10} xs={12}>
                      {' '}
                      <h3 className="center">Iniciar Sesión</h3>
                      <form autoComplete="off" className="form-login">
                        <FormControl className="width-100">
                          <InputLabel htmlFor="standard-adornment-email">
                            Email
                          </InputLabel>
                          <Input
                            id="login_email"
                            name="login_email"
                            type="email"
                            endAdornment={
                              <InputAdornment position="end">
                                <EmailIcon />
                              </InputAdornment>
                            }
                          />
                        </FormControl>

                        <br></br>
                        <FormControl
                          className="width-100"
                          style={{ marginTop: '1rem' }}
                        >
                          <InputLabel htmlFor="standard-adornment-password">
                            Password
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            name="login_password"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <p onClick={this.handleClosePassword}>
                          ¿Olvidaste la contraseña?
                        </p>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button width-100"
                          onClick={this.submitLogin}
                        >
                          Login
                        </Button>
                      </form>
                      <Divider className="divider" />
                      <Grid container>
                        <Grid item={true} sm={2} xs={12}></Grid>
                        <Grid item={true} sm={8} xs={12}>
                          {' '}
                          <div className="center clear width-100">
                            <GoogleLogin
                              clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
                              buttonText="Login with Google"
                              onSuccess={responseGoogle}
                              onFailure={responseGoogleFail}
                              cookiePolicy={'single_host_origin'}
                              className="width-100"
                            />

                            {this.state.userData !== '' ? (
                              <GoogleLogout
                                clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
                                buttonText="Logout"
                                onLogoutSuccess={logout}
                              ></GoogleLogout>
                            ) : (
                              ''
                            )}
                            {this.state.userData !== '' ? userData : ''}
                            <br></br>
                            <FacebookLogin
                              appId="238403055163743"
                              autoLoad={false}
                              fields="name,email,picture"
                              callback={responseFacebook}
                              icon="fa-facebook"
                              className="width-100"
                            />

                            {this.state.facebookName !== '' ? faceData : ''}
                          </div>
                        </Grid>
                        <Grid item={true} sm={2} xs={12}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item={true} sm={1} xs={12}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.openModalRegister}
          onClose={this.handleCloseRegister}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogContent>
            <form
              className="form-register"
              onSubmit={this.submitRegister}
              id="formregister"
              autoComplete="off"
            >
              <Container>
                <Grid container>
                  <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    className="leftSideModalLogin"
                  >
                    <DialogContentText className="title01 center bold">
                      Regístrate para comenzar
                    </DialogContentText>

                    <img
                      src="/images/signin.png"
                      alt="Signin"
                      className="width-100"
                    ></img>
                    <p className="center padding20">
                      ¿Ya tienes cuenta?{' '}
                      <span
                        onClick={this.handleCloseLogin}
                        className="bold pointer"
                      >
                        Inicia sesión aquí
                      </span>
                    </p>
                  </Grid>
                  <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    className="rightSideModalLogin"
                  >
                    <Grid container>
                      <Grid item={true} sm={1} xs={12}></Grid>
                      <Grid item={true} sm={10} xs={12}>
                        {' '}
                        <h3 className="center">Únete a nuestra comunidad</h3>
                        <p className="center">
                          Nosotros te ayudamos a conseguir tus objetivos
                        </p>
                        <FormControl className="width-100">
                          <InputLabel htmlFor="register_email">
                            Email
                          </InputLabel>
                          <Input
                            id="register_email"
                            name="register_email"
                            type="email"
                            ref="register_email"
                            endAdornment={
                              <InputAdornment position="end">
                                <EmailIcon />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <FormControl
                          className="width-100"
                          style={{ marginTop: '1rem' }}
                        >
                          <InputLabel htmlFor="register_password">
                            Password
                          </InputLabel>
                          <Input
                            id="register_password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            name="register_password"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <FormControl
                          className="width-100"
                          style={{ marginTop: '1rem' }}
                        >
                          <InputLabel id="register_account">
                            Tipo de cuenta
                          </InputLabel>
                          <Select
                            labelId="register_account"
                            id="register_account"
                            name="register_account"
                            value={this.state.register_account}
                            onChange={this.handleChangeAccount}
                          >
                            <MenuItem value={'FINAL_USER'}>
                              Tengo una Startup y me gustaría obtener un mejor
                              posicionamiento y resultados.
                            </MenuItem>
                            <MenuItem value={'CROWDFUNDING_USER'}>
                              Me gustaría comenzar a invertir en Startups.
                            </MenuItem>
                            <MenuItem value={'FINAL_USER_STARTUP'}>
                              Busco prácticas en una Startup.
                            </MenuItem>
                            <MenuItem value={'FINAL_USER_COMPANY'}>
                              Busco empleo en una Startup.
                            </MenuItem>
                            <MenuItem value={'SECONDARY_USER_ADMIN'}>
                              Busco soluciones innovadoras para mi empresa.
                            </MenuItem>
                            <MenuItem value={'SECONDARY_USER'}>
                              Quiero estar informado de las últimas noticias en
                              innovación y del mundo startup.
                            </MenuItem>
                            <MenuItem value={'USER_OFFER_SERVICES'}>
                              Quiero ofrecer servicios de mi empresa a las
                              Startups del ecosistema.
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.acceptTerms01}
                                onClick={this.changeStateTerms}
                                name="checkedA"
                              />
                            }
                            label={
                              <span onClick={this.changeStateTermsLink}>
                                Acepto los{' '}
                                <a
                                  href="/politica-de-privacidad"
                                  target="_blank"
                                >
                                  términos y condiciones
                                </a>
                              </span>
                            }
                          />
                        </FormGroup>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.newsletter}
                                onClick={this.changeStateNewsletter}
                                name="acceptNewsletter"
                              />
                            }
                            label={
                              <span onClick={this.changeStateNewsletter}>
                                Suscríbete a nuestra newsletter
                              </span>
                            }
                          />
                        </FormGroup>
                        <Button
                          form="formregister"
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button center width-100"
                          onClick={this.submitRegister}
                        >
                          Iniciar Registro
                        </Button>
                        <Divider className="divider" />
                        <Grid container>
                          <Grid item={true} sm={2} xs={12}></Grid>
                          <Grid item={true} sm={8} xs={12}>
                            {' '}
                            <div className="center clear width-100">
                              <GoogleLogin
                                clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
                                buttonText="Login with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogleFail}
                                cookiePolicy={'single_host_origin'}
                                className="width-100"
                              />

                              {this.state.userData !== '' ? (
                                <GoogleLogout
                                  clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
                                  buttonText="Logout"
                                  onLogoutSuccess={logout}
                                ></GoogleLogout>
                              ) : (
                                ''
                              )}
                              {this.state.userData !== '' ? userData : ''}
                              <br></br>
                              <FacebookLogin
                                appId="238403055163743"
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                icon="fa-facebook"
                                className="width-100"
                              />

                              {this.state.facebookName !== '' ? faceData : ''}
                            </div>
                          </Grid>
                          <Grid item={true} sm={2} xs={12}></Grid>
                        </Grid>
                      </Grid>
                      <Grid item={true} sm={1} xs={12}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </form>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.openModalPassword}
          onClose={this.handleClosePassword}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogContent>
            <Container>
              <Grid container>
                <Grid item={true} xs={12} sm={6} className="leftSideModalLogin">
                  <DialogContentText className="title01 center bold">
                    ¡Hola! Bienvenido de nuevo
                  </DialogContentText>

                  <img
                    src="/images/signin.png"
                    alt="Signin"
                    className="width-100"
                  ></img>
                  <p className="center padding20">
                    ¿Aún no tienes cuenta?{' '}
                    <span
                      onClick={this.handleCloseRegister}
                      className="bold pointer"
                    >
                      Regístrate aqui
                    </span>
                  </p>
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  sm={6}
                  className="rightSideModalLogin"
                >
                  <Grid container>
                    <Grid item={true} sm={1} xs={12}></Grid>
                    <Grid item={true} sm={10} xs={12}>
                      {' '}
                      <h3 className="center">Reestablecer contraseña</h3>
                      <form autoComplete="off" className="form-login">
                        <FormControl className="width-100">
                          <InputLabel htmlFor="password_email">
                            Email
                          </InputLabel>
                          <Input
                            id="password_email"
                            name="password_email"
                            type="email"
                            endAdornment={
                              <InputAdornment position="end">
                                <EmailIcon />
                              </InputAdornment>
                            }
                          />
                        </FormControl>

                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button width-100"
                          onClick={this.submitPassword}
                        >
                          Recupera Contraseña
                        </Button>
                      </form>
                    </Grid>
                    <Grid item={true} sm={1} xs={12}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default withSnackbar(LoginModal);
