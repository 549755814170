import React, { Component } from 'react';

import {
  Grid,
  Container,
  Input,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from 'axios';
import Button from '@material-ui/core/Button';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changePasswordState: 2,
      showPassword: false,
    };
  }
  componentDidMount() {}
  changePassword = (event) => {
    console.log('cambio');
    event.preventDefault();
    const token = this.props.match.params.token;

    let password = document.getElementsByName('recovery_password')[0].value;
    const payload = {
      pwd: password,
      pwdRecoveryCode: token,
    };

    if (token) {
      var apiurl = '/api/users/password';
      axios({
        url: apiurl,
        method: 'PATCH',
        data: payload,
      })
        .then((response) => {
          console.log('Data has been sent to the server');
          console.log(response);
          alert('Contraseña actualizada');
          this.setState({
            changePasswordState: 1,
          });
        })
        .catch((error) => {
          console.log(error.response);
          alert('Error al cambiar el password');
          this.setState({
            changePasswordState: 0,
          });
        });
    } else {
      this.setState({
        changePasswordState: 0,
      });
    }
  };
  handleClickShowPassword = (event) => {
    if (this.state.showPassword === false) {
      this.setState({
        showPassword: true,
      });
    } else {
      this.setState({
        showPassword: false,
      });
    }
  };
  render() {
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    return (
      <Container>
        <Grid container>
          <Grid item={true} xs={12} sm={4}></Grid>
          <Grid item={true} xs={12} sm={4}>
            <div className="center">
              <form
                autoComplete="off"
                className="form-login"
                onSubmit={this.changePassword}
                style={{ paddingBottom: '5rem', paddingTop: '5rem' }}
              >
                <FormControl className="width-100">
                  <InputLabel htmlFor="standard-adornment-password">
                    Introduce una nueva contraseña
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    name="recovery_password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="button width-100"
                >
                  Actualizar contraseña
                </Button>
              </form>
            </div>
          </Grid>
          <Grid item={true} xs={12} sm={4}></Grid>
        </Grid>
      </Container>
    );
  }
}
export default ResetPassword;
