import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import ReactPaginate from 'react-paginate';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import {
  getUserData,
  getUserToken,
  forumVisible,
} from '../../utils/manageUserData';
class Forum extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);

    let userData = JSON.parse(getUserData());
    let userToken = getUserToken();

    let visible = forumVisible(userData.role);

    try {
      this.state = {
        forum: [],
        userToken,
        userData,
        ads: [],
        baseImgSrc: process.env.REACT_APP_API_URL_PROD + 'api/uploads/',
        dataLoad: true,
        totalPosts: 1,
        numberOfPage: 1,
        beginItem: 0,
        endItem: 9,
        page: urlParams.get('page') || 1,
        limit: urlParams.get('limit') || 10,
        skip: urlParams.get('skip') || 0,
        visible,
        currentItems: [],
        pageCount: 0,
        itemOffset: 0,
        itemsPerPage: 9,
        open: false,
      };
      console.log(this.state);
    } catch (error) {
      this.state = { visible: false, forum: [] };
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.getForum();
  }

  getForum = async () => {
    const apiurl = '/api/forum';

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-token': this.state.userToken,
      },
    })
      .then((response) => {
        if (this._isMounted) {
          const endOffset = this.state.itemsPerPage;

          this.setState({
            forum: response.data.forum,
            dataLoad: true,
            totalPosts: response.data.total,
            currentItems: response.data.forum.slice(0, endOffset),
            endOffset,
            pageCount: response.data.forum.length / this.state.itemsPerPage,
          });

          console.log(this.state);
        }
      })
      .catch((error) => {});
  };

  goToThread = (post) => {
    console.log(post);

    const newProps = {
      postId: post._id,
    };

    this.props.getClickedElement('forum_ver_hilo', newProps);
  };

  goToCreateThread = () => {
    const newProps = {};

    this.props.getClickedElement('forum_crear_hilo', newProps);
  };

  handlePage = (event) => {
    console.log(event.selected);
    const { forum, itemsPerPage } = this.state;

    const newOffset = event.selected * itemsPerPage;

    console.log(newOffset, newOffset + itemsPerPage);

    console.log(Math.ceil(forum.length / itemsPerPage));

    this.setState({});
    this.setState({
      beginItem: event.selected * itemsPerPage,
      endItem: event.selected * itemsPerPage + 9,
      itemOffset: newOffset,
      endOffset: newOffset + itemsPerPage,
      currentItems: forum.slice(newOffset, newOffset + itemsPerPage),
    });
  };

  handleShow = (comment, id) => {
    if (this.state.open === false) {
      this.setState({
        open: true,
      });
    } else {
      this.setState({
        open: false,
      });
    }
  };

  render() {
    const posts = this.state.currentItems
      ? this.state.currentItems.map((post, index) => {
          if (post) {
            return (
              <Col xs={12} lg={12} className="post" key={post._id}>
                <div className="forumBox">
                  <Link
                    to={{
                      pathname: `/blog-secreto/${post._id}`,
                      state: {
                        userToken: this.state.userToken,
                        userData: this.state.userData,
                        user1: this.state.userData.uid,
                      },
                    }}
                  >
                    <Row className="date">
                      <Col>
                        <div>
                          {new Date(post.createdAt).toLocaleString('es-ES')}{' '}
                        </div>
                      </Col>
                    </Row>
                    <Row className="title">
                      <Col>{post.subject}</Col>
                    </Row>
                    <Row className="username">
                      <Col>
                        {post.userData.name !== undefined
                          ? post.userData.name
                          : ''}
                      </Col>
                    </Row>{' '}
                  </Link>
                </div>
              </Col>
            );
          } else {
            return '';
          }
        })
      : '';

    return this.state.visible !== true ? (
      <Container className="bodyWrapper" fluid>
        <Redirect
          to={{
            pathname: '/',
          }}
        />
        );
      </Container>
    ) : (
      <Container className="MuiContainer-root bodyWrapper MuiContainer-maxWidthLg forum">
        <Row>
          <h1 className="center">Blog Secreto</h1>
        </Row>

        <Row className="create-link">
          <Col>
            <Link
              to={{
                pathname: '/blog-secreto/crear-hilo',
                state: {
                  userToken: this.state.userToken,
                  userData: this.state.userData,
                  user1: this.state.userData.uid,
                },
              }}
              className="button"
            >
              Crear hilo
            </Link>
            <HelpIcon className="help" onClick={() => this.handleShow()} />
          </Col>
        </Row>

        <Modal
          show={this.state.open}
          onHide={this.handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Blog Secreto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Estás a punto de unirte a nuestro Foro de Innovación, en el que
            hemos unido dentro de él a otras Startups, Inversores y Empresarios,
            con el fin de facilitaros como recurso un lugar en el que poder
            pedir ayuda a otros usuarios, únicamente de los tres perfiles
            anteriormente nombrados, generar temas de conversación, prestar
            ayuda comentando los hilos de otros usuarios o generar testeos,
            lluvias de ideas, verificar modelos.. las posibilidades son
            infinitas.
          </Modal.Body>
        </Modal>

        <Row>{posts}</Row>

        <Row>
          <ReactPaginate
            previousLabel={'←'}
            nextLabel={'→'}
            pageCount={this.state.pageCount}
            onPageChange={this.handlePage}
            containerClassName={'pagination'}
            previousLinkClassName={'pagination__link'}
            nextLinkClassName={'pagination__link'}
            disabledClassName={'pagination__link--disabled'}
            activeClassName={'pagination__link--active'}
          />
        </Row>
      </Container>
    );
  }
}
export default Forum;
