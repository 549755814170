import React, { Component } from 'react';
import {
  Grid,
  Container,
  Button,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';

class listUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToken: '',
      userData: '',
      users: '',
      userToEdit: '',
      userToEditUid: '',
      update_account: '',
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.getAllUsers();
    })();
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
    }
  };

  getAllUsers = async () => {
    var userToken = this.state.userToken;
    var apiurl = '/api/users';

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.setState({
          users: response.data.users,
        });
      })
      .catch((error) => {
        console.log('No startup Found');
      });
  };
  editUser = (userData) => {
    this.setState({
      userToEdit: userData,
      userToEditUid: userData.uid,
      update_account: userData.role,
    });
  };
  deleteUser = (idUser) => {
    const payload = {
      uid: idUser,
    };

    var apiurl = '/api/users/' + idUser;
    axios({
      url: apiurl,
      method: 'DELETE',
      data: payload,
      headers: {
        'x-token': this.props.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.props.enqueueSnackbar('Usuario eliminado', {
          variant: 'success',
        });
        this.getAllUsers();
      })
      .catch((error) => {
        this.props.enqueueSnackbar('Error al guardar los datos', {
          variant: 'error',
        });
        console.log(error.response);
        console.log('Error');
      });

    console.log(idUser);
  };
  updateUserData = (event) => {
    event.preventDefault();

    const payload = {
      name: event.target.update_user_name.value,
      lastName: event.target.update_user_lastname.value,
      phone: event.target.update_user_phone.value,
      streetName: event.target.update_user_address.value,
      flatNumber: event.target.update_user_address_number.value,
      postCode: event.target.update_user_pc.value,
      locality: event.target.update_user_locality.value,
      province: event.target.update_user_province.value,
      country: event.target.update_user_country.value,
      //role: this.state.userData.role,
      role: event.target.update_account.value,
    };

    if (event.target.update_user_pwd.value !== null) {
      payload.pwd = event.target.update_user_pwd.value;
    }

    console.log(
      'EVENT_TARGET_UPDATE_USER_PWD',
      event.target.update_user_pwd.value
    );
    console.log(payload);

    var apiurl = '/api/users/' + this.state.userToEdit.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.props.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.setState({
          userData: response.data.user,
        });
        this.props.enqueueSnackbar('Datos guardados con éxito', {
          variant: 'success',
        });
        this.getAllUsers();
        //window.location.reload();
        //this.emailVerification(email);
      })
      .catch((error) => {
        this.props.enqueueSnackbar('Error al guardar los datos', {
          variant: 'error',
        });
        console.log(error.response);
        console.log('Error');
      });
  };
  handleChangeAccount = (event) => {
    this.setState({
      update_account: event.target.value,
    });
  };
  render() {
    const columns = [
      {
        field: 'name',
        headerName: 'Nombre',
        editable: false,
        flex: 1,
      },
      {
        field: 'lastName',
        headerName: 'Apellidos',
        editable: false,
        flex: 1,
      },
      {
        field: 'role',
        headerName: 'Rol',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          console.log(params.row.role);
          var rolName = '';
          if (params.row.role === 'FINAL_USER') {
            rolName = 'Tiene Startup';
          } else if (params.row.role === 'CROWDFUNDING_USER') {
            rolName = 'Invierte en Startups';
          } else if (params.row.role === 'FINAL_USER_STARTUP') {
            rolName = 'Busca prácticas';
          } else if (params.row.role === 'FINAL_USER_COMPANY') {
            rolName = 'Busca Empleo';
          } else if (params.row.role === 'SECONDARY_USER_ADMIN') {
            rolName = 'Busca soluciones';
          } else if (params.row.role === 'SECONDARY_USER') {
            rolName = 'Informado en noticias';
          } else if (params.row.role === 'USER_OFFER_SERVICES') {
            rolName = 'Oferta sus servicios';
          } else if (params.row.role === 'ADMIN') {
            rolName = 'Administrador';
          } else if (params.row.role === 'CROWDFUNDING_ADMIN') {
            rolName = 'Admin Crowdfunding';
          } else {
            rolName = 'No definido';
          }
          return <p>{rolName}</p>;
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        editable: false,
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Acciones',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.editUser(params.row)}
              >
                Editar
              </Button>
            </div>
          );
        },
      },
      {
        field: 'delete',
        headerName: 'Borrar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() =>
                  window.confirm('¿Estás seguro de borrar este usuario?')
                    ? this.deleteUser(params.row.uid)
                    : ''
                }
              >
                Borrar
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <Container>
        <Grid container className="borderShadow">
          <Grid item xs={12}>
            <div>
              {this.state.users !== '' ? (
                <DataGrid
                  rows={this.state.users}
                  getRowId={(row) => row.uid}
                  autoHeight
                  columns={columns}
                  pageSize={5}
                />
              ) : (
                <p>Cargando</p>
              )}
            </div>
            {this.state.userToEditUid !== '' ? (
              <form
                onSubmit={this.updateUserData}
                className="form"
                key={this.state.userToEditUid}
              >
                <Grid container key={this.state.userToEdit}>
                  <Grid item={true} sm={6} xs={12}>
                    {' '}
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_user_name">
                        Nombre{' '}
                      </InputLabel>
                      <Input
                        id="update_user_name"
                        name="update_user_name"
                        aria-describedby="update_user_name"
                        defaultValue={this.state.userToEdit.name}
                        type="text"
                        key={this.state.userToEdit}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} sm={6} xs={12}>
                    {' '}
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_user_lastname">
                        Apellidos{' '}
                      </InputLabel>
                      <Input
                        id="update_user_lastname"
                        name="update_user_lastname"
                        aria-describedby="update_user_lastname"
                        type="text"
                        defaultValue={this.state.userToEdit.lastName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} sm={6} xs={12}>
                    <FormControl className="width-100">
                      <InputLabel htmlFor="create_user_email">
                        Email*
                      </InputLabel>
                      <Input
                        id="create_user_email"
                        name="create_user_email"
                        aria-describedby="create_user_email"
                        type="text"
                        defaultValue={this.state.userToEdit.email}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} sm={6} xs={12}>
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_user_phone" shrink={true}>
                        Teléfono
                      </InputLabel>
                      <Input
                        id="update_user_phone"
                        name="update_user_phone"
                        aria-describedby="update_user_phone"
                        type="text"
                        defaultValue={this.state.userToEdit.phone}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} sm={12} xs={12}>
                    {' '}
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_user_address">
                        Dirección
                      </InputLabel>
                      <Input
                        id="update_user_address"
                        name="update_user_address"
                        aria-describedby="update_user_address"
                        type="text"
                        defaultValue={this.state.userToEdit.streetName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} sm={6} xs={12}>
                    {' '}
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_user_address_number">
                        N/Piso{' '}
                      </InputLabel>
                      <Input
                        id="update_user_address_number"
                        name="update_user_address_number"
                        aria-describedby="update_user_address_number"
                        type="text"
                        defaultValue={this.state.userToEdit.flatNumber}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} sm={6} xs={12}>
                    {' '}
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_user_pc">
                        Código Postal{' '}
                      </InputLabel>
                      <Input
                        id="update_user_pc"
                        name="update_user_pc"
                        aria-describedby="update_user_pc"
                        type="number"
                        defaultValue={this.state.userToEdit.postCode}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} sm={6} xs={12}>
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_user_locality">
                        Localidad
                      </InputLabel>
                      <Input
                        id="update_user_locality"
                        name="update_user_locality"
                        aria-describedby="update_user_locality"
                        type="text"
                        defaultValue={this.state.userToEdit.locality}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} sm={6} xs={12}>
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_user_province">
                        Provincia
                      </InputLabel>
                      <Input
                        id="update_user_province"
                        name="update_user_province"
                        aria-describedby="update_user_province"
                        type="text"
                        defaultValue={this.state.userToEdit.province}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} sm={6} xs={12}>
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_user_country">
                        País
                      </InputLabel>
                      <Input
                        id="update_user_country"
                        name="update_user_country"
                        aria-describedby="update_user_country"
                        type="text"
                        defaultValue={this.state.userToEdit.country}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item={true} sm={6} xs={12}>
                    <FormControl className="width-100">
                      <InputLabel htmlFor="update_user_country">
                        Contraseña
                      </InputLabel>
                      <Input
                        id="update_user_pwd"
                        name="update_user_pwd"
                        aria-describedby="update_user_pwd"
                        type="password"
                        value={this.state.userToEdit.password}
                      />
                    </FormControl>
                  </Grid>

                  {this.state.userData.role === 'ADMIN' ? (
                    <FormControl className="width-100">
                      <InputLabel id="update_account">Rol</InputLabel>
                      <Select
                        labelId="update_account"
                        id="update_account"
                        name="update_account"
                        onChange={this.handleChangeAccount}
                        defaultValue={this.state.userToEdit.role}
                      >
                        <MenuItem value={'CROWDFUNDING_ADMIN'}>
                          Administrador de crowdfunding
                        </MenuItem>
                        <MenuItem value={'SECONDARY_USER_ADMIN'}>
                          Administrador de secundarias
                        </MenuItem>

                        <MenuItem value={'ADMIN'}>SUPER ADMIN</MenuItem>
                        <MenuItem value={'RESTRICTED_ADMIN'}>
                          SUPER ADMIN (RESTRINGIDO)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    ''
                  )}
                  <Grid item={true} sm={12} xs={12} className="center">
                    {' '}
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="button center"
                      ref="buttonRegister"
                    >
                      Guardar y continuar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(listUsers);
