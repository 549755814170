import React, { Component } from "react";
import { getUserData, logout } from "../utils/manageUserData";
import BasicInformation from "../form/basicInformation";

import CheckProfileUser from "./profiles/checkProfileUser";

class ProfileUserBase extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: "",
			levelUser: 0,
		};
	}
	componentDidMount() {
		this.getUserInformation();
	}
	getUserInformation = () => {
		var userData = getUserData();
		const obj = JSON.parse(userData);
		if (userData !== false) {
			this.setState({
				userData: obj,
			});
			this.getUserLevel(obj);
		} else {
			logout();
		}
	};
	getUserLevel(data) {
		console.log("Aqui");
		console.log(data);
		if (
			data.name === "" ||
			data.lastname === "" ||
			data.email === "" ||
			data.role === "" ||
			data.role === "USER"
		) {
			this.setState({
				levelUser: 1,
			});
		} else {
			this.setState({ levelUser: 2 });
		}
	}
	render() {
		return (
			<div>
				{this.state.levelUser === 0 ? (
					<p>Estamos recuperando su información</p>
				) : this.state.levelUser === 1 ? (
					<BasicInformation />
				) : (
					<CheckProfileUser />
				)}
			</div>
		);
	}
}
export default ProfileUserBase;
