import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import './../../../../styles/styles.css';
import { withSnackbar } from 'notistack';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import { InputLabel, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
class BlogCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      htmlText: '',
      userToken: props.userToken,
      post: [],
      title: '',
      htmlContent: '',
      headerImg: '',
      adsBanner: '',
    };
  }

  componentDidMount() {}

  createPost = async () => {
    const apiurl = '/api/blog';

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const { title, htmlContent, headerImg, adsBanner } = this.state;

    const payload = {
      title,
      htmlContent,
      headerImg,
      adsBanner,
    };

    const { data } = await axios.post(apiurl, payload, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.props.enqueueSnackbar('Entrada añadida', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      this.cleanData();
    }
  };

  uploaHeaderImg = async (file) => {
    if (file) {
      console.log('hay fichero');
      let formData = new FormData();
      formData.append('file', file);
      await axios
        .post('/api/uploads', formData)
        .then((res) => {
          this.setState({ headerImg: res.data.fileName });
          console.log(res.data.fileName);
          return res.data.fileName;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido el fichero');
        });
    }
  };

  uploadBannerAd = async (file) => {
    if (file) {
      console.log('hay fichero');
      let formData = new FormData();
      formData.append('file', file);
      await axios
        .post('/api/uploads', formData)
        .then((res) => {
          this.setState({ adsBanner: res.data.fileName });
          console.log(res.data.fileName);
          return res.data.fileName;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido el fichero');
        });
    }
  };

  cleanData = () => {
    this.setState({
      title: '',
      htmlContent: '',
      headerImg: '',
      adsBanner: '',
    });
  };

  imageHandler() {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append('file', file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Move cursor to right side of image
      this.quill.setSelection(range.index + 1);
      console.log('subida de imagen');

      const res = await axios
        .post('/api/uploads', formData)
        .then((res) => {
          console.log(res.data);
          return res.data.fileName;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido la imagen');
        });

      console.log(res);

      var url = 'https://estartaps.com/uploads/' + res;
      console.log(url);

      // Insert uploaded image

      this.quill.insertEmbed(range.index, 'image', url);
    };
  }

  render() {
    const { title, htmlContent } = this.state;
    const fontSizeArr = [
      '8px',
      '10px',
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
    ];

    var Size = Quill.import('attributors/style/size');
    Size.whitelist = fontSizeArr;
    Quill.register(Size, true);
    Quill.register('modules/imageResize', ImageResize);
    return (
      <Container>
        <h1 className="job-page-title">Crear entrada </h1>

        <Row>
          <Col md={12} xs={12}>
            <TextField
              id="standard-name"
              label="Título"
              value={title ? title : ''}
              margin="normal"
              className="forced-black"
              onChange={(e) => {
                this.setState({ title: e.target.value });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Imagen de cabecera
            </InputLabel>
            <input
              type="file"
              onChange={(e) => this.uploaHeaderImg(e.target.files[0])}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Texto
            </InputLabel>

            <ReactQuill
              ref={(el) => {
                this.quill = el;
              }}
              onChange={(e) => this.setState({ htmlContent: e })}
              placeholder="Texto"
              value={htmlContent ? htmlContent : ''}
              modules={{
                imageResize: {
                  parchment: Quill.import('parchment'),
                  modules: ['Resize', 'DisplaySize'],
                },
                toolbar: {
                  container: [
                    [{ header: [1, 2, 3, 4, 5, 6] }],
                    [{ size: fontSizeArr }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link'],
                    ['image'],
                    ['clean'],
                    [{ color: [] }, { background: [] }],
                    [
                      { align: '' },
                      { align: 'center' },
                      { align: 'right' },
                      { align: 'justify' },
                    ],
                  ],
                  handlers: {
                    image: this.imageHandler,
                  },
                },
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Banner de publicidad
            </InputLabel>
            <input
              type="file"
              onChange={(e) => this.uploadBannerAd(e.target.files[0])}
            />
          </Col>
        </Row>

        <Row>
          <Button
            variant="contained"
            style={{
              background: '#6658ea',
              color: 'white',
              marginBottom: '2rem',
              marginLeft: '1rem',
              display: 'table',
              margin: '0 auto',
              marginTop: '2rem',
            }}
            onClick={() => this.createPost()}
          >
            Guardar
          </Button>
        </Row>
      </Container>
    );
  }
}

export default withSnackbar(BlogCreate);
