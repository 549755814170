import React, { Component } from 'react';
import {
  Grid,
  Container,
  Button,
  InputLabel,
  Input,
  FormControl,
  Checkbox,
} from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../utils/cropImage';
import DeleteIcon from '@material-ui/icons/Delete';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
class listStartup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToken: '',
      userData: '',
      userStartup: '',
      startupToEdit: '',
      imageToUpload: '',
      croppedImage: '',
      croppedImageFile: '',
      crop: { x: 0, y: 0 },
      croppedAreaPixels: '',
      uploadedImage: '',
      croppedArea: '',
      zoom: 1,
      aspect: 1 / 1,
      cropSize: { width: 300, height: 300 },
      products_array: [],
      teams_array: [],
      open: false,
      startupToDelete: [],
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.getAllStartups();
    })();
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
    }
  };

  changeStartupState = (value, startupId) => {
    const { userStartup } = this.state;

    let stateValue = false;
    let nameValue = '';

    const newUserStartup = userStartup.map((startup) => {
      if (startup.uid === startupId) {
        console.log(startup.uid + ' === ' + startupId);
        if (startup.state === false) {
          startup.state = true;
          stateValue = true;
        } else {
          startup.state = false;
          stateValue = false;
        }
        nameValue = startup.name;
      }

      return startup;
    });

    console.log(newUserStartup);

    var apiurlput = '/api/startups/' + startupId;

    const payload = { state: stateValue, name: nameValue };
    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.props.enqueueSnackbar('Datos guardados con éxito', {
          variant: 'success',
        });
        this.setState({ userStartup: newUserStartup });
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
      });
  };

  getAllStartups = async () => {
    var userToken = this.state.userToken;
    var apiurl = '/api/startups';

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response.data);
        this.setState({
          userStartup: response.data.startups,
        });
      })
      .catch((error) => {
        console.log('No startup Found');
      });
  };
  editStartup = (startupData) => {
    var products = [];

    var startup = startupData;
    var teams = [];
    startup.products.forEach((element, index, array) => {
      var name = 'product-' + index;
      products.push(name);
    });
    startup.managementTeam.forEach((element, index, array) => {
      var name = 'team-' + index;
      teams.push(name);
    });
    this.setState({
      products_array: products,
      startupToEdit: startupData,
      teams_array: teams,
      startupToEditUid: startupData.uid,
    });

    console.log('--------');
    console.log(startupData);
  };

  deleteStartup = async () => {
    console.log(this.state.startupToDelete);
    var userToken = this.state.userToken;
    var apiurl = '/api/startups/' + this.state.startupToDelete.uid;

    await axios({
      url: apiurl,
      method: 'DELETE',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);

        this.setState({
          startupToDelete: '',
          open: false,
        });
        this.getAllStartups();
      })
      .catch((error) => {
        console.log('No se ha eliminado la startup');
      });
  };

  chooseOptionMature = (option) => {
    var element = document.getElementById('opt1');
    var element2 = document.getElementById('opt2');
    var element3 = document.getElementById('opt3');
    var element4 = document.getElementById('opt4');
    if (option === 1) {
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element.classList.add('choosenOption');
    } else if (option === 2) {
      element.classList.remove('choosenOption');

      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element2.classList.add('choosenOption');
    } else if (option === 3) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');

      element4.classList.remove('choosenOption');
      element3.classList.add('choosenOption');
    } else if (option === 4) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.add('choosenOption');
    }
    this.setState({
      choosenOptionMaturity: option,
    });
  };
  submitUpdateStartup = (event) => {
    event.preventDefault();
    if (this.state.startupToEdit !== '') {
      if (this.state.choosenOptionMaturity !== '') {
        var choosedOption;
        if (this.state.choosenOptionMaturity === 1) {
          choosedOption = 'Pre-semilla';
        } else if (this.state.choosenOptionMaturity === 2) {
          choosedOption = 'Semilla';
        } else if (this.state.choosenOptionMaturity === 3) {
          choosedOption = 'Temprana';
        } else if (this.state.choosenOptionMaturity === 4) {
          choosedOption = 'Consolidada';
        }
      } else {
        choosedOption = this.state.startupToEdit.maturityInfo;
      }

      var update_startup_test_company;
      if (event.target.update_startup_test_company.value === 'True') {
        update_startup_test_company = true;
      } else {
        update_startup_test_company = false;
      }
      var update_startup_aceleration;
      if (event.target.update_startup_aceleration.value === 'True') {
        update_startup_aceleration = true;
      } else {
        update_startup_aceleration = false;
      }
      let listProducts = [];
      var getProducts = new Promise((resolve, reject) => {
        this.state.products_array.forEach((element, index, array) => {
          if (
            document.getElementById(element + '-name') !== null &&
            document.getElementById(element + '-name') !== 'undefined'
          ) {
            var name = document.getElementById(element + '-name').value;
            var description = document.getElementById(
              element + '-description'
            ).value;
            var price = document.getElementById(element + '-price').value;
            var discountPercentage = document.getElementById(
              element + '-discountPercentage'
            ).value;
            var discountedPrice = document.getElementById(
              element + '-discountedPrice'
            ).value;
            let obj = {
              name: name,
              description: description,
              price: price,
              discountPercentage: discountPercentage,
              discountedPrice: discountedPrice,
            };
            listProducts.push(obj);
          }
          if (index === array.length - 1) resolve();
        });
      });
      let listTeam = [];
      var getTeams = new Promise((resolve, reject) => {
        this.state.teams_array.forEach((element, index, array) => {
          if (
            document.getElementById(element + '-name') !== null &&
            document.getElementById(element + '-name') !== 'undefined'
          ) {
            var name = document.getElementById(element + '-name').value;
            var position = document.getElementById(element + '-position').value;
            var order = document.getElementById(element + '-order').value;
            var linkedin = document.getElementById(element + '-linkedin').value;

            let obj = {
              name: name,
              position: position,
              order: order,
              linkedinUrl: linkedin,
            };
            listTeam.push(obj);
          }
          if (index === array.length - 1) resolve();
        });
      });
      getProducts.then(() => {
        console.log('All done!');
      });
      getTeams.then(() => {
        console.log('All done!');
      });
      //console.log(listProducts);
      var url = event.target.update_presentacion_video.value;

      if (url !== '') {
        var regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length === 11) {
          url =
            'https://www.youtube.com/embed/' +
            match[2] +
            '?autoplay=&enablejsapi=1';
        } else {
          this.props.enqueueSnackbar('El enlace a Youtube no es correcto', {
            variant: 'error',
          });
          url = '';
        }
      }
      if (this.state.croppedImageFile) {
        let formData = new FormData();
        formData.append('file', this.state.croppedImageFile);

        axios
          .put(
            '/api/uploads/startups/' + this.state.startupToEdit.uid,
            formData
          )
          .then((res) => {
            console.log(res.data);
            const payload = {
              name: event.target.update_startupName.value,
              testedInfoBoolean: update_startup_test_company,
              shortDescription: event.target.update_startup_description.value,
              longDescription: event.target.update_startup_description2.value,
              testedInfoText: event.target.update_startup_opinion_test.value,
              servicesDescription: event.target.update_startup_products.value,
              teamDescription: event.target.update_startup_team.value,
              cif: event.target.update_cifStartup.value,
              maturityInfo: choosedOption,
              accelerationBoolean: update_startup_aceleration,
              industries: event.target.update_industry.value,
              accelerationProcessInfo:
                event.target.update_startup_aceleration_description.value,
              products: listProducts,
              managementTeam: listTeam,
              mainPhrase: event.target.update_mainPhrase.value,
              workers: event.target.update_workerstartup.value,
              videoPresentation: url,
            };
            this.submitAlldata(payload);
          })
          .catch((error) => {
            console.log(error.response);
            alert('Se ha encontrado un error y no se ha subido la imagen');
          });
      } else {
        const payload = {
          name: event.target.update_startupName.value,
          testedInfoBoolean: update_startup_test_company,
          shortDescription: event.target.update_startup_description.value,
          longDescription: event.target.update_startup_description2.value,
          testedInfoText: event.target.update_startup_opinion_test.value,
          servicesDescription: event.target.update_startup_products.value,
          teamDescription: event.target.update_startup_team.value,
          cif: event.target.update_cifStartup.value,
          maturityInfo: choosedOption,
          accelerationBoolean: update_startup_aceleration,
          industries: event.target.update_industry.value,
          accelerationProcessInfo:
            event.target.update_startup_aceleration_description.value,
          products: listProducts,
          managementTeam: listTeam,
          mainPhrase: event.target.update_mainPhrase.value,
          workers: event.target.update_workerstartup.value,
          videoPresentation: url,
        };
        this.submitAlldata(payload);
      }
    }
  };
  submitAlldata = (payload) => {
    var apiurlput = '/api/startups/' + this.state.startupToEditUid;

    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.props.enqueueSnackbar('Datos guardados con éxito', {
          variant: 'success',
        });
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
      });
  };
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedArea: croppedArea,
      croppedAreaPixels: croppedAreaPixels,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  handleChange = (event) => {
    var image = event.target.files[0];
    if (event.target.files[0]) {
      this.setState({
        uploadedImage: event.target.files[0],
        imageSrc: URL.createObjectURL(image),
      });
    } else {
      this.setState({
        uploadedImage: '',
        imageSrc: '',
      });
    }
  };
  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
      this.state.rotation
    );

    let response = await fetch(croppedImage);
    let data = await response.blob();
    let metadata = {
      type: 'image/png',
    };
    let file = new File([data], 'Startup.png', metadata);

    this.setState({
      croppedImageFile: file,
    });
    this.setState({
      croppedImage: croppedImage,
    });
  };
  appendInput_product() {
    var newInput = `product-${this.state.products_array.length}`;

    this.setState((prevState) => ({
      products_array: prevState.products_array.concat([newInput]),
    }));
  }

  appendInput_team() {
    var newInput = `team-${this.state.teams_array.length}`;

    this.setState((prevState) => ({
      teams_array: prevState.teams_array.concat([newInput]),
    }));
  }

  deleteProduct = async (producto) => {
    alert('vas a borrar el producto: ' + producto);

    document.getElementById('product-' + producto).remove();
  };

  deleteTeam = async (team) => {
    alert('vas a borrar el miembro: ' + team);

    document.getElementById('team-' + team).remove();
  };
  handleShow = (startupData) => {
    if (this.state.open === false) {
      this.setState({
        open: true,
        startupToDelete: startupData,
      });
    } else {
      this.setState({
        open: false,
        startupToDelete: '',
      });
    }
  };
  render() {
    console.log(this.state.userStartup);
    const columns = [
      {
        field: 'name',
        headerName: 'Nombre',
        editable: false,
        flex: 1,
      },
      {
        field: 'maturityInfo',
        headerName: 'Madurez',
        editable: false,
        flex: 1,
      },
      {
        field: 'budget',
        headerName: 'Presupuesto',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <p>{params.value.substr(0, 10)}€</p>
            </div>
          );
        },
      },
      {
        field: 'zombilan',
        headerName: 'Zombilan',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={params.row.state === false}
                    value="0"
                    onChange={(e) => {
                      this.changeStartupState(e.target.value, params.row.uid);
                    }}
                  />
                }
                label="Activo"
              />
            </div>
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Editar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.editStartup(params.row)}
              >
                Editar
              </Button>
            </div>
          );
        },
      },
      {
        field: 'actions2',
        headerName: 'Borrar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.handleShow(params.row)}
              >
                Borrar
              </Button>
            </div>
          );
        },
      },
    ];
    const columnsTrans = [
      {
        field: 'amount',
        headerName: 'Coste',
        editable: false,
        flex: 1,
      },

      {
        field: 'word',
        headerName: 'Palabra ',
        editable: false,
        flex: 1,
      },
      {
        field: 'date',
        headerName: 'Fecha',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Moment format="DD/MM/YYYY hh:mm:ss">{params.value}</Moment>
            </div>
          );
        },
      },
    ];
    return (
      <Container>
        <Modal
          show={this.state.open}
          onHide={this.handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Startup a eliminar</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.startupToDelete.name}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleShow}>
              Cerrar
            </Button>
            <Button variant="secondary" onClick={() => this.deleteStartup()}>
              Borrar
            </Button>
          </Modal.Footer>
        </Modal>
        <Grid container>
          <Grid item xs={12}>
            <div>
              {this.state.userStartup !== '' ? (
                <DataGrid
                  rows={this.state.userStartup}
                  getRowId={(row) => row.uid}
                  autoHeight
                  columns={columns}
                  pageSize={5}
                />
              ) : (
                <p>Cargando</p>
              )}
            </div>
            <div>
              {this.state.startupToEdit !== '' ? (
                <Grid container>
                  <form
                    onSubmit={this.submitUpdateStartup}
                    className="form"
                    key={this.state.startupToEditUid}
                  >
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Información Básica</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item={true} sm={12} xs={12}>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor="update_startupName"
                                shrink={true}
                              >
                                Lo más importante, ¿por qué nombre quieres que
                                conozcamos a tu Startup?{' '}
                              </InputLabel>
                              <Input
                                id="update_startupName"
                                name="update_startupName"
                                aria-describedby="update_startupName"
                                type="text"
                                defaultValue={this.state.startupToEdit.name}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor="update_industry"
                                shrink={true}
                              >
                                ¿Cuál es la industria a la que pertenece tu
                                Startup?
                              </InputLabel>
                              <Input
                                id="update_industry"
                                name="update_industry"
                                aria-describedby="update_industry"
                                type="text"
                                defaultValue={
                                  this.state.startupToEdit.industries
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                Nos gustaría que nos hicieses una breve
                                descripción de a qué se dedica tu Startup{' '}
                              </p>
                              <TextareaAutosize
                                aria-label="minimum height"
                                id="update_startup_description"
                                name="update_startup_description"
                                rowsMin={3}
                                defaultValue={
                                  this.state.startupToEdit.shortDescription
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                Queremos acercar tu idea al mayor número de
                                clientes posibles. Para ello, un equipo de
                                Estartaps.com va a revisar todo lo que nos
                                cuentes de tu solución, intentando entender
                                hasta el mínimo detalle para que el mundo lo
                                conozca
                              </p>
                              <TextareaAutosize
                                aria-label="minimum height"
                                id="update_startup_description2"
                                name="update_startup_description2"
                                rowsMin={3}
                                defaultValue={
                                  this.state.startupToEdit.longDescription
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                Ahora nos gustaría que nos contases los
                                diferentes productos o servicios que ofrece tu
                                Startup
                              </p>
                              <TextareaAutosize
                                aria-label="minimum height"
                                id="update_startup_products"
                                name="update_startup_products"
                                rowsMin={3}
                                defaultValue={
                                  this.state.startupToEdit.servicesDescription
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                Nos gustaría conocer a tu equipo, cuéntanos
                                brevemente sobre ellos.
                              </p>
                              <TextareaAutosize
                                aria-label="minimum height"
                                id="update_startup_team"
                                name="update_startup_team"
                                rowsMin={3}
                                defaultValue={
                                  this.state.startupToEdit.teamDescription
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                ¿Has testeado tu solución con alguna empresa?
                              </p>
                              <RadioGroup
                                aria-label="test_company"
                                name="update_startup_test_company"
                                defaultValue={
                                  this.state.startupToEdit.testedInfoBoolean ===
                                  ''
                                    ? ''
                                    : this.state.startupToEdit
                                        .testedInfoBoolean === true
                                    ? 'True'
                                    : 'False'
                                }
                              >
                                <FormControlLabel
                                  value="True"
                                  control={<Radio />}
                                  label="Si"
                                />
                                <FormControlLabel
                                  value="False"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                En caso de que sea que sí, nos gustaría saber
                                Cómo te fue.
                              </p>
                              <TextareaAutosize
                                aria-label="minimum height"
                                id="update_startup_opinion_test"
                                name="update_startup_opinion_test"
                                rowsMin={3}
                                defaultValue={
                                  this.state.startupToEdit.testedInfoText
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor="update_mainPhrase"
                                shrink={true}
                              >
                                Frase para mostrar en la web
                              </InputLabel>
                              <Input
                                id="update_mainPhrase"
                                name="update_mainPhrase"
                                aria-describedby="update_mainPhrase"
                                type="text"
                                defaultValue={
                                  this.state.startupToEdit.mainPhrase
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            <FormControl className="width-100">
                              <InputLabel htmlFor="update_cifStartup">
                                CIF De Startup
                              </InputLabel>
                              <Input
                                id="update_cifStartup"
                                name="update_cifStartup"
                                aria-describedby="update_cifStartup"
                                type="text"
                                defaultValue={this.state.startupToEdit.cif}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            <FormControl className="width-100">
                              <InputLabel htmlFor="update_workerstartup">
                                Número de empleados
                              </InputLabel>
                              <Input
                                id="update_workerstartup"
                                name="update_workerstartup"
                                aria-describedby="update_workerstartup"
                                type="number"
                                required
                                defaultValue={this.state.startupToEdit.workers}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            <FormControl className="width-100">
                              <InputLabel htmlFor="update_presentacion_video">
                                Video de presentación (Solo admite youtube)
                              </InputLabel>
                              <Input
                                id="update_presentacion_video"
                                name="update_presentacion_video"
                                aria-describedby="update_presentacion_video"
                                type="url"
                                defaultValue={
                                  this.state.startupToEdit.videoPresentation
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid container>
                            {this.state.startupToEdit.img ? (
                              <Grid item={true} sm={12} xs={12}>
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL_PROD +
                                    '/api/uploads/startups/' +
                                    this.state.startupToEdit.uid
                                  }
                                  alt="startup profile"
                                  className="profile-image "
                                />
                              </Grid>
                            ) : (
                              ''
                            )}

                            <Grid item={true} sm={12} xs={12}>
                              {' '}
                              <FormControl className="width-100">
                                <p>Logo de su Startup</p>

                                <Input
                                  id="update_image"
                                  name="update_image"
                                  ref="update_image"
                                  aria-describedby="update_image"
                                  type="file"
                                  onChange={this.handleChange}
                                />
                              </FormControl>
                              {this.state.uploadedImage !== '' ? (
                                <div>
                                  <p>
                                    Ajuste la imagen en el cuadrado blanco y
                                    acepte para guardar la imagen, puede hacer
                                    uso del zoom o mover la imagen
                                  </p>
                                  <div className="crop-container">
                                    <Cropper
                                      image={this.state.imageSrc}
                                      crop={this.state.crop}
                                      minZoom={0.4}
                                      maxZoom={15}
                                      zoom={this.state.zoom}
                                      aspect={this.state.aspect}
                                      onCropChange={this.onCropChange}
                                      onCropComplete={this.onCropComplete}
                                      onZoomChange={this.onZoomChange}
                                      cropSize={this.state.cropSize}
                                      restrictPosition={false}
                                    />
                                  </div>
                                  <Button
                                    onClick={this.showCroppedImage}
                                    variant="contained"
                                    color="primary"
                                  >
                                    Aceptar y ver resultado
                                  </Button>
                                </div>
                              ) : (
                                ''
                              )}
                            </Grid>
                            <Grid item={true} sm={12} xs={12}>
                              {this.state.croppedImage !== '' ? (
                                <img
                                  src={this.state.croppedImage}
                                  alt="Startup"
                                  className="profile-image "
                                ></img>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Madurez de la Startup</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item={true} sm={12} xs={12}>
                            <Grid container>
                              <Grid
                                item={true}
                                sm={6}
                                xs={12}
                                className="containerOptionSquare"
                              >
                                <div
                                  className={
                                    'optionSquare ' +
                                    (this.state.startupToEdit.maturityInfo ===
                                    'Pre-semilla'
                                      ? 'choosenOption'
                                      : '')
                                  }
                                  onClick={() => this.chooseOptionMature(1)}
                                  id="opt1"
                                >
                                  <h5 className="center">Fase Pre-Semilla</h5>
                                  <p>
                                    Compañias muy jovenes, en general con menos
                                    de un año desde su creación, creados por
                                    equipos excelentes.
                                  </p>
                                  <ul>
                                    <li>Pre-money: {'<€'}1M</li>
                                    <li>Antigüedad: Ninguna</li>
                                  </ul>
                                </div>
                              </Grid>
                              <Grid
                                item={true}
                                sm={6}
                                xs={12}
                                className="containerOptionSquare"
                              >
                                <div
                                  className={
                                    'optionSquare ' +
                                    (this.state.startupToEdit.maturityInfo ===
                                    'Semilla'
                                      ? 'choosenOption'
                                      : '')
                                  }
                                  onClick={() => this.chooseOptionMature(2)}
                                  id="opt2"
                                >
                                  <h5 className="center">Fase Semilla</h5>
                                  <p>
                                    Empresas nuevas que están explorando su
                                    concepto y creado un producto mínimo(MVP)
                                    para validar.
                                  </p>
                                  <ul>
                                    <li>Pre-money: {'<€'}2M</li>
                                    <li>Antigüedad: 6 meses</li>
                                  </ul>
                                </div>
                              </Grid>
                              <Grid
                                item={true}
                                sm={6}
                                xs={12}
                                className="containerOptionSquare"
                              >
                                <div
                                  className={
                                    'optionSquare ' +
                                    (this.state.startupToEdit.maturityInfo ===
                                    'Temprana'
                                      ? 'choosenOption'
                                      : '')
                                  }
                                  onClick={() => this.chooseOptionMature(3)}
                                  id="opt3"
                                >
                                  <h5 className="center">Fase Temprana</h5>
                                  <p>
                                    Empresas con producto y métricas que el
                                    quipo puede replicar, incoporar feedback y
                                    mejorar su estrategia.
                                  </p>
                                  <ul>
                                    <li>Pre-money: {'<€'}8M</li>
                                    <li>Antigüedad: 9 meses</li>
                                  </ul>
                                </div>
                              </Grid>
                              <Grid
                                item={true}
                                sm={6}
                                xs={12}
                                className="containerOptionSquare"
                              >
                                <div
                                  className={
                                    'optionSquare ' +
                                    (this.state.startupToEdit.maturityInfo ===
                                    'Consolidada'
                                      ? 'choosenOption'
                                      : '')
                                  }
                                  onClick={() => this.chooseOptionMature(4)}
                                  id="opt4"
                                >
                                  <h5 className="center">Fase Madura</h5>
                                  <p>
                                    Empresas más maduras, que facturan, con
                                    mayores costes y menos retorno potencial,
                                    pero a priori menos riesgo.
                                  </p>
                                  <ul>
                                    <li>Pre-money: {'>€'}8M</li>
                                    <li>Antigüedad: 12 meses</li>
                                  </ul>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                ¿Actualmente su Startup se encuentra en algún
                                proceso de aceleración?
                              </p>
                              <RadioGroup
                                aria-label="test_company"
                                name="update_startup_aceleration"
                                defaultValue={
                                  this.state.startupToEdit
                                    .accelerationBoolean === ''
                                    ? ''
                                    : this.state.startupToEdit
                                        .accelerationBoolean === true
                                    ? 'True'
                                    : 'False'
                                }
                              >
                                <FormControlLabel
                                  value="True"
                                  control={<Radio />}
                                  label="Si"
                                />
                                <FormControlLabel
                                  value="False"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>Si su respuesta es sí ¿en qué aceleradora?</p>
                              <TextareaAutosize
                                aria-label="minimum height"
                                id="update_startup_aceleration_description"
                                name="update_startup_aceleration_description"
                                rowsMin={3}
                                defaultValue={
                                  this.state.startupToEdit
                                    .accelerationProcessInfo
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Productos</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item={true} sm={12} xs={12}>
                            <h3>Productos</h3>
                            {this.state.products_array.map((input, index) => (
                              <Grid
                                item={true}
                                sm={12}
                                xs={12}
                                className="boxed"
                                id={'product-' + index}
                              >
                                <Button
                                  onClick={() => this.deleteProduct(index)}
                                  className="buttonWarning"
                                  startIcon={<DeleteIcon />}
                                >
                                  {' '}
                                  Borrar Producto
                                </Button>
                                <FormControl className="width-100">
                                  <InputLabel
                                    htmlFor={input + '-name'}
                                    shrink={true}
                                  >
                                    Nombre del producto o servicio*
                                  </InputLabel>
                                  <Input
                                    id={input + '-name'}
                                    name={input + '-name'}
                                    aria-describedby={input + '-name'}
                                    type="text"
                                    required
                                    defaultValue={
                                      index + 1 <=
                                      this.state.startupToEdit.products.length
                                        ? this.state.startupToEdit.products[
                                            index
                                          ].name
                                        : ''
                                    }
                                  />
                                </FormControl>
                                <FormControl className="width-100">
                                  <InputLabel
                                    htmlFor={input + '-description'}
                                    shrink={true}
                                  >
                                    Descripción
                                  </InputLabel>
                                  <Input
                                    id={input + '-description'}
                                    name={input + '-description'}
                                    aria-describedby={input + '-description'}
                                    type="text"
                                    defaultValue={
                                      index + 1 <=
                                      this.state.startupToEdit.products.length
                                        ? this.state.startupToEdit.products[
                                            index
                                          ].description
                                        : ''
                                    }
                                  />
                                </FormControl>
                                <FormControl className="width-100">
                                  <InputLabel
                                    htmlFor={input + '-price'}
                                    shrink={true}
                                  >
                                    Precio
                                  </InputLabel>
                                  <Input
                                    id={input + '-price'}
                                    name={input + '-price'}
                                    aria-describedby={input + '-price'}
                                    type="text"
                                    defaultValue={
                                      index + 1 <=
                                      this.state.startupToEdit.products.length
                                        ? this.state.startupToEdit.products[
                                            index
                                          ].price
                                        : ''
                                    }
                                  />
                                </FormControl>
                                <FormControl className="width-100">
                                  <InputLabel
                                    htmlFor={input + '-discountPercentage'}
                                    shrink={true}
                                  >
                                    Descuento
                                  </InputLabel>
                                  <Input
                                    id={input + '-discountPercentage'}
                                    name={input + '-discountPercentage'}
                                    aria-describedby={
                                      input + '-discountPercentage'
                                    }
                                    type="text"
                                    defaultValue={
                                      index + 1 <=
                                      this.state.startupToEdit.products.length
                                        ? this.state.startupToEdit.products[
                                            index
                                          ].discountPercentage
                                        : ''
                                    }
                                  />
                                </FormControl>
                                <FormControl className="width-100">
                                  <InputLabel
                                    htmlFor={input + '-discountedPrice'}
                                    shrink={true}
                                  >
                                    Precio con Descuento
                                  </InputLabel>
                                  <Input
                                    id={input + '-discountedPrice'}
                                    name={input + '-discountedPrice'}
                                    aria-describedby={
                                      input + '-discountedPrice'
                                    }
                                    type="text"
                                    defaultValue={
                                      index + 1 <=
                                      this.state.startupToEdit.products.length
                                        ? this.state.startupToEdit.products[
                                            index
                                          ].discountedPrice
                                        : ''
                                    }
                                  />
                                </FormControl>
                              </Grid>
                            ))}
                          </Grid>

                          <Grid item={true} sm={12} xs={12}>
                            <p
                              onClick={() => this.appendInput_product()}
                              className="button"
                            >
                              Añadir Producto
                            </p>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Miembros</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item={true} sm={12} xs={12}>
                            <h3>Miembros</h3>
                            {this.state.teams_array.map((input, index) => (
                              <Grid
                                item={true}
                                sm={12}
                                xs={12}
                                className="boxed"
                                id={'team-' + index}
                              >
                                <Button
                                  onClick={() => this.deleteTeam(index)}
                                  className="buttonWarning"
                                  startIcon={<DeleteIcon />}
                                >
                                  {' '}
                                  Borrar Miembro
                                </Button>
                                <FormControl className="width-100">
                                  <InputLabel
                                    htmlFor={input + '-name'}
                                    shrink={true}
                                  >
                                    Nombre de miembro*
                                  </InputLabel>
                                  <Input
                                    id={input + '-name'}
                                    name={input + '-name'}
                                    aria-describedby={input + '-name'}
                                    type="text"
                                    defaultValue={
                                      index + 1 <=
                                      this.state.startupToEdit.managementTeam
                                        .length
                                        ? this.state.startupToEdit
                                            .managementTeam[index].name
                                        : ''
                                    }
                                  />
                                </FormControl>
                                <FormControl className="width-100">
                                  <InputLabel
                                    htmlFor={input + '-position'}
                                    shrink={true}
                                  >
                                    Cargo
                                  </InputLabel>
                                  <Input
                                    id={input + '-position'}
                                    name={input + '-position'}
                                    aria-describedby={input + '-position'}
                                    type="text"
                                    defaultValue={
                                      index + 1 <=
                                      this.state.startupToEdit.managementTeam
                                        .length
                                        ? this.state.startupToEdit
                                            .managementTeam[index].position
                                        : ''
                                    }
                                  />
                                </FormControl>
                                <FormControl className="width-100">
                                  <InputLabel
                                    htmlFor={input + '-linkedin'}
                                    shrink={true}
                                  >
                                    Url Linkedin
                                  </InputLabel>
                                  <Input
                                    id={input + '-linkedin'}
                                    name={input + '-linkedin'}
                                    aria-describedby={input + '-linkedin'}
                                    type="text"
                                    defaultValue={
                                      index + 1 <=
                                      this.state.startupToEdit.managementTeam
                                        .length
                                        ? this.state.startupToEdit
                                            .managementTeam[index].linkedinUrl
                                        : ''
                                    }
                                  />
                                </FormControl>
                                <FormControl className="width-100">
                                  <InputLabel
                                    htmlFor={input + '-order'}
                                    shrink={true}
                                  >
                                    Orden
                                  </InputLabel>
                                  <Input
                                    id={input + '-order'}
                                    name={input + '-order'}
                                    aria-describedby={input + '-order'}
                                    type="text"
                                    defaultValue={
                                      index + 1 <=
                                      this.state.startupToEdit.managementTeam
                                        .length
                                        ? this.state.startupToEdit
                                            .managementTeam[index].order
                                        : ''
                                    }
                                  />
                                </FormControl>
                              </Grid>
                            ))}
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            <p
                              onClick={() => this.appendInput_team()}
                              className="button"
                            >
                              Añadir Miembro
                            </p>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Grid item={true} sm={12} xs={12} className="center">
                      {' '}
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="button center"
                        ref="buttonRegister"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </form>
                  <Grid item={true} sm={12} xs={12} className="center">
                    {this.state.startupToEdit.transactions ? (
                      this.state.startupToEdit.transactions.length ? (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Transacciones</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <DataGrid
                              rows={this.state.startupToEdit.transactions}
                              getRowId={(row) => row.date}
                              autoHeight
                              columns={columnsTrans}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                            />
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              ) : (
                <p></p>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(listStartup);
