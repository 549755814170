import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import './../../../styles/styles.css';
import { withSnackbar } from 'notistack';

class JobCandidate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.extraProps.userId,
      user: [],
      userToken: this.props.userToken,
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = async () => {
    const apiurl = '/api/users/' + this.state.userId;
    const { data } = await axios.get(apiurl);
    this.setState({
      user: data.user,
    });

    console.log(data.user);
  };

  render() {
    const name = this.state.user.name + ' ' + this.state.user.lastName;
    const {
      email,
      phone,
      jobLocation,
      streetName,
      flatNumber,
      postCode,
      locality,
      province,
      country,
      studies,
      languages,
      cv,
      courses,
    } = this.state.user;

    const languagesFormatted = languages
      ? languages.map((lng) => {
          return (
            <TextField
              id="standard-languages"
              value={lng.name + ' ' + lng.level}
              margin="normal"
              className="forced-black"
              disabled
            />
          );
        })
      : '';

    const studiesFormatted = studies
      ? studies.map((std) => {
          return (
            <TextField
              id="standard-studies"
              value={
                std.nameStudies +
                ', ' +
                std.nameCenterStudies +
                ' - ' +
                std.typeStudies
              }
              margin="normal"
              className="forced-black"
              disabled
            />
          );
        })
      : '';
    const coursesFormatted = courses
      ? courses.map((crs) => {
          return (
            <div>
              <TextField
                id="standard-studies"
                value={crs.nameCourse + ', ' + crs.nameCenter}
                margin="normal"
                className="forced-black"
                disabled
              />
              <AttachFileIcon />
              {crs.pdf ? (
                <a href={crs.pdf} rel="noreferrer" target="_blank">
                  PDF
                </a>
              ) : (
                ''
              )}
            </div>
          );
        })
      : '';

    return (
      <Container>
        <h1 className="job-page-title">Candidato {name}</h1>
        <Row>
          <Col md={6} xs={12}>
            <TextField
              id="standard-name"
              label="Nombre"
              value={name ? name : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col>
          <Col md={6} xs={12}>
            <TextField
              id="standard-phone"
              label="Teléfono"
              value={phone ? phone : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col>
          <Col md={6} xs={12}>
            <TextField
              id="standard-email"
              label="Email"
              value={email ? email : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} xs={12}>
            <TextField
              id="standard-country"
              label="País"
              value={country ? country : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col>
          <Col md={6} xs={12}>
            <TextField
              id="standard-province"
              label="Provincia"
              value={province ? province : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col>
          <Col md={6} xs={12}>
            <TextField
              id="standard-locality"
              label="Localidad"
              value={locality ? locality : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col>
          <Col md={6} xs={12}>
            <TextField
              id="standard-streetName"
              label="Calle"
              value={streetName ? streetName : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col>
          <Col md={6} xs={12}>
            <TextField
              id="standard-flatnumber"
              label="Número"
              value={flatNumber ? flatNumber : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col>
          <Col md={6} xs={12}>
            <TextField
              id="standard-postCode"
              label="Código postal"
              value={postCode ? postCode : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <label>Estudios</label>
            {studies ? (
              studiesFormatted
            ) : (
              <TextField
                id="standard-studies"
                margin="normal"
                className="forced-black"
                disabled
              />
            )}
          </Col>
          {/* <Col md={6} xs={12}>
            <TextField
              id="standard-studies"
              label="Formación"
              value={studiesInformation ? studiesInformation : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col> */}
          <Col md={12} xs={12}>
            <label>Idiomas</label>
            {languages ? (
              languagesFormatted
            ) : (
              <TextField
                id="standard-studies"
                margin="normal"
                className="forced-black"
                disabled
              />
            )}
          </Col>
          <Col md={12} xs={12}>
            {/* <TextField
              id="standard-internshipInformation"
              label="Cursos y formación"
              value={internshipInformation ? internshipInformation : ''}
              margin="normal"
              className="forced-black"
              disabled
            /> */}
            <label>Cursos y formación</label>
            {courses ? (
              coursesFormatted
            ) : (
              <TextField
                id="standard-studies"
                margin="normal"
                className="forced-black"
                disabled
              />
            )}
          </Col>
          <Col md={6} xs={12}>
            <TextField
              id="standard-joblocation"
              label="Ubicación del empleo"
              value={jobLocation ? jobLocation : ''}
              margin="normal"
              className="forced-black"
              disabled
            />
          </Col>
          {cv ? (
            <Col md={6} xs={12}>
              <label>Currículum</label>
              <AttachFileIcon />
              <a href={cv} rel="noreferrer" target="_blank">
                {cv}
              </a>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Container>
    );
  }
}

export default withSnackbar(JobCandidate);
