//Register form for FINAL_USER_COMPANY
import React, { Component } from 'react';

import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
  StepButton,
} from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  getUserData,
  getUserToken,
  updateDataUser,
} from '../../utils/manageUserData';
class RegistroForm2 extends Component {
  constructor(props) {
    super(props);

    console.log(props);
    this.state = {
      activeStep: 0,
      optionChooseProfile: '',
      userData: '',
      email: '',
      usertoken: '',
      choosenOptionMaturity: '',
      startupFounder: '',
      isFounder: false,
      steps: [
        'Datos Básicos',
        'Tus títulos',
        'Tus Cursos',
        'Idiomas',
        'Programas y softwares',
        'Completar Registro',
      ],
      step1: 0,
      step2: 0,
      step3: 0,
      step4: 0,
      step5: 0,
      step6: 0,
      step3Completed: false,
      step4Completed: false,
      step5Completed: false,
      step6Completed: false,
      studies: [],
      studies_array: [],
      courses: [],
      courses_array: [],
      languages: [],
      languages_array: [],
      softwares: [],
      softwares_array: [],
      acceptTerms01: false,
      newsletter: false,
      acceptOffersEmailControlled: '',
      clickedAcceptOffers: false,
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.checkCompletedSteps();
    })();
  }

  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      console.log(obj);
      this.setState({
        userData: obj,
        acceptOffersEmailControlled:
          obj.acceptOffersEmail === false && this.props.inProfile !== 1
            ? ''
            : obj.acceptOffersEmail,
      });
      this.getUserFounder(obj);
      var studies = [];
      var courses = [];
      var languages = [];
      var softwares = [];
      if (obj.studies) {
        obj.studies.forEach((element, index, array) => {
          var name = 'studies-' + index;
          studies.push(name);
        });
      }
      if (studies.length) {
        this.setState({
          studies_array: studies,
        });
      }
      if (obj.courses) {
        obj.courses.forEach((element, index, array) => {
          var name = 'courses-' + index;
          courses.push(name);
        });
      }
      if (courses.length) {
        this.setState({
          courses_array: courses,
        });
      }
      if (obj.languages) {
        obj.languages.forEach((element, index, array) => {
          var name = 'languages-' + index;
          languages.push(name);
        });
      }
      if (languages.length) {
        this.setState({
          languages_array: languages,
        });
      }
      if (obj.softwares) {
        obj.softwares.forEach((element, index, array) => {
          var name = 'softwares-' + index;
          softwares.push(name);
        });
      }
      if (softwares.length) {
        this.setState({
          softwares_array: softwares,
        });
      }
    }
  };
  changeStateTerms = (event) => {
    if (this.state.acceptTerms01 === true) {
      this.setState({
        acceptTerms01: false,
      });
    } else {
      this.setState({
        acceptTerms01: true,
      });
    }
  };
  changeStateNewsletter = (event) => {
    if (this.state.newsletter === true) {
      this.setState({
        newsletter: false,
      });
    } else {
      this.setState({
        newsletter: true,
      });
    }
  };
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  appendInput_studies() {
    var newInput = `studies-${this.state.studies_array.length}`;

    this.setState((prevState) => ({
      studies_array: prevState.studies_array.concat([newInput]),
    }));
  }
  deleteStudies = async (studies) => {
    alert('vas a borrar el estudio: ' + studies);

    document.getElementById('studies-' + studies).remove();
  };
  appendInput_course() {
    var newInput = `courses-${this.state.courses_array.length}`;

    this.setState((prevState) => ({
      courses_array: prevState.courses_array.concat([newInput]),
    }));
  }
  deleteCourses = async (courses) => {
    alert('vas a borrar el curso: ' + courses);

    document.getElementById('courses-' + courses).remove();
  };
  appendInput_languages() {
    var newInput = `languages-${this.state.languages_array.length}`;

    this.setState((prevState) => ({
      languages_array: prevState.languages_array.concat([newInput]),
    }));
  }
  deleteLanguages = async (languages) => {
    alert('vas a borrar el idioma: ' + languages);

    document.getElementById('languages-' + languages).remove();
  };
  appendInput_software() {
    var newInput = `softwares-${this.state.softwares_array.length}`;

    this.setState((prevState) => ({
      softwares_array: prevState.softwares_array.concat([newInput]),
    }));
  }
  deleteSoftwares = async (software) => {
    alert('vas a borrar el software: ' + software);

    document.getElementById('softwares-' + software).remove();
  };
  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo.uid + '/startups';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.startups.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.startups[0],
          });
          this.checkCompletedSteps();
        }
      })
      .catch((error) => {
        this.checkCompletedSteps();
        console.log(error.response);
        console.log('No startup Found');
      });
  };
  handleChangeProfile = (event) => {
    this.setState({
      optionChooseProfile: event.target.value,
    });
  };
  checkCompletedSteps = async () => {
    var step1Completed = 0;
    //var step2Completed = 0;
    //var step3Completed = 0;
    //var step4Completed = 0;
    //var step5Completed = 0;
    var step6Completed = 0;
    if (
      this.state.userData.jobLocation !== '' &&
      this.state.userData.streetName !== '' &&
      this.state.userData.flatNumber !== '' &&
      this.state.userData.postCode !== '' &&
      this.state.userData.locality !== '' &&
      this.state.userData.province !== '' &&
      this.state.userData.country !== '' &&
      this.state.userData.jobLocation &&
      this.state.userData.streetName &&
      this.state.userData.flatNumber &&
      this.state.userData.postCode &&
      this.state.userData.locality &&
      this.state.userData.province &&
      this.state.userData.country
    ) {
      step1Completed = 1;
      this.setState({
        step1: 1,
      });
    }

    if (
      this.state.userData.studies !== '' &&
      this.state.userData.studies &&
      this.state.userData.studies.length
    ) {
      //step2Completed = 1;
      this.setState({ step2: 1 });
    }
    if (
      (this.state.userData.courses !== '' &&
        this.state.userData.courses &&
        this.state.userData.courses.length) ||
      this.state.step3Completed
    ) {
      //step3Completed = 1;
      this.setState({ step3: 1 });
    }
    if (
      (this.state.userData.languages !== '' &&
        this.state.userData.languages &&
        this.state.userData.languages.length) ||
      this.state.step4Completed
    ) {
      //step4Completed = 1;
      this.setState({ step4: 1 });
    }
    if (
      (this.state.userData.softwares !== '' &&
        this.state.userData.softwares &&
        this.state.userData.softwares.length) ||
      this.state.step5Completed
    ) {
      step1Completed = 5;
      this.setState({ step5: 1 });
    }
    if (
      (this.state.userData.acceptOffersEmail !== '' &&
        this.state.userData.acceptOffersEmail !== undefined) ||
      this.state.step6Completed
    ) {
      step6Completed = 1;
      this.setState({ step6: 1 });
    }
    console.log(step6Completed);
    if (
      step1Completed === 1 &&
      step6Completed === 1 &&
      this.props.inProfile !== 1 &&
      this.state.clickedAcceptOffers === true
    ) {
      window.location.reload();
    }
  };
  submitRegisterStep1 = (event) => {
    event.preventDefault();

    const payload = {
      jobLocation: event.target.register_steps_where_search.value,
      jobField: event.target.register_steps_field.value,
      streetName: event.target.register_steps_address.value,
      flatNumber: event.target.register_steps_address_number.value,
      postCode: event.target.register_steps_pc.value,
      locality: event.target.register_steps_locality.value,
      province: event.target.register_steps_province.value,
      country: event.target.register_steps_country.value,
      phone: event.target.register_steps_phone.value,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep2 = (event) => {
    event.preventDefault();

    let listStudies = [];
    var getStudies = new Promise((resolve, reject) => {
      this.state.studies_array.forEach((element, index, array) => {
        if (
          document.getElementById(element + '-register_steps_type_estudios') !==
            null &&
          document.getElementById(element + '-register_steps_type_estudios') !==
            'undefined'
        ) {
          var typeStudies = document.getElementById(
            element + '-register_steps_type_estudios'
          ).value;
          var nameStudies = document.getElementById(
            element + '-register_steps_title_name'
          ).value;
          var nameCenterStudies = document.getElementById(
            element + '-register_steps_name_university'
          ).value;
          let obj = {
            typeStudies: typeStudies,
            nameStudies: nameStudies,
            nameCenterStudies: nameCenterStudies,
          };
          listStudies.push(obj);
        }
        if (index === array.length - 1) resolve();
      });
    });
    getStudies.then(() => {
      console.log('All done!');
    });
    const payload = {
      studies: listStudies,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep3 = async (event) => {
    event.preventDefault();
    let listCourses = [];
    for (let item of this.state.courses_array) {
      console.log(item);
      var nameCourse = '';
      var nameCenter = '';
      var url = '';

      if (document.getElementById(item + '-register_steps_pdf').files.length) {
        console.log('hay fichero');
        let formData = new FormData();
        formData.append(
          'file',
          document.getElementById(item + '-register_steps_pdf').files[0]
        );
        const pdfDownloaded = await axios
          .post('/api/uploads', formData)
          .then((res) => {
            return res.data.uploadPath;
          })
          .catch((error) => {
            console.log(error.response);
            alert('Se ha encontrado un error y no se ha subido la imagen');
          });

        if (
          document.getElementById(item + '-register_steps_name_studies') !==
            null &&
          document.getElementById(item + '-register_steps_name_studies') !==
            'undefined'
        ) {
          nameCourse = document.getElementById(
            item + '-register_steps_name_studies'
          ).value;
          nameCenter = document.getElementById(
            item + '-register_steps_center'
          ).value;
          url = document.getElementById(item + '-register_steps_url').value;

          let obj = {
            nameCourse: nameCourse,
            nameCenter: nameCenter,
            nameCenterStudies: url,
            pdf: pdfDownloaded,
          };

          listCourses.push(obj);
        }
      } else {
        if (
          document.getElementById(item + '-register_steps_name_studies') !==
            null &&
          document.getElementById(item + '-register_steps_name_studies') !==
            'undefined'
        ) {
          nameCourse = document.getElementById(
            item + '-register_steps_name_studies'
          ).value;
          nameCenter = document.getElementById(
            item + '-register_steps_center'
          ).value;
          url = document.getElementById(item + '-register_steps_url').value;

          let obj = {
            nameCourse: nameCourse,
            nameCenter: nameCenter,
            nameCenterStudies: url,
            pdf: '',
          };

          listCourses.push(obj);
        }
      }
    }

    const payload = {
      courses: listCourses,
      role: this.state.userData.role,
    };
    console.log(listCourses);
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
          step3: 1,
          step3Completed: true,
        });
        alert('Datos guardados con éxito');

        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep4 = (event) => {
    event.preventDefault();

    let listLanguages = [];
    var getLanguages = new Promise((resolve, reject) => {
      this.state.languages_array.forEach((element, index, array) => {
        if (
          document.getElementById(element + '-register_steps_language') !==
            null &&
          document.getElementById(element + '-register_steps_language') !==
            'undefined'
        ) {
          var name = document.getElementById(
            element + '-register_steps_language'
          ).value;
          var level = document.getElementById(
            element + '-register_steps_level_language'
          ).value;
          let obj = {
            name: name,
            level: level,
          };
          listLanguages.push(obj);
        }
        if (index === array.length - 1) resolve();
      });
    });
    getLanguages.then(() => {
      console.log('All done!');
    });
    const payload = {
      languages: listLanguages,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
          step4: 1,
          step4Completed: true,
        });
        alert('Datos guardados con éxito');
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep5 = (event) => {
    event.preventDefault();

    let listSoftwares = [];
    var getSoftwares = new Promise((resolve, reject) => {
      this.state.softwares_array.forEach((element, index, array) => {
        if (
          document.getElementById(
            element + '-register_steps_name_softwares'
          ) !== null &&
          document.getElementById(
            element + '-register_steps_name_softwares'
          ) !== 'undefined'
        ) {
          var name = document.getElementById(
            element + '-register_steps_name_softwares'
          ).value;
          var level = document.getElementById(
            element + '-register_steps_level_softwares'
          ).value;
          let obj = {
            name: name,
            level: level,
          };
          listSoftwares.push(obj);
        }
        if (index === array.length - 1) resolve();
      });
    });
    getSoftwares.then(() => {
      console.log('All done!');
    });
    const payload = {
      softwares: listSoftwares,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
          step5: 1,
          step5Completed: true,
        });
        alert('Datos guardados con éxito');
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep6 = async (event) => {
    event.preventDefault();
    let pdfDownloaded;
    if (document.getElementById('register_cv').files.length) {
      console.log('hay fichero');
      let formData = new FormData();
      formData.append('file', document.getElementById('register_cv').files[0]);
      pdfDownloaded = await axios
        .post('/api/uploads', formData)
        .then((res) => {
          return res.data.uploadPath;
        })
        .catch((error) => {
          alert('Se ha encontrado un error y no se ha subido la imagen');
        });
    }
    console.log(pdfDownloaded);
    console.log(event.target.register_steps_offers.value);
    if (!event.target.register_steps_offers.value) {
      alert('Compruebe los campos obligatorios');
    } else {
      const payload = {
        acceptOffersEmail: event.target.register_steps_offers.value,
        cv: pdfDownloaded,
        role: this.state.userData.role,
      };

      var apiurl = '/api/users/' + this.state.userData.uid;
      axios({
        url: apiurl,
        method: 'PUT',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          console.log('Data has been sent to the server');
          console.log(response);
          updateDataUser(response.data);
          this.setState({
            userData: response.data.user,
            step6: 1,
            step6Completed: true,
          });
          alert('Datos guardados con éxito');
          //window.location.reload();
          //this.emailVerification(email);
          this.checkCompletedSteps();
          this.handleNext();
        })
        .catch((error) => {
          alert('Hemos encontrado un error y no se han guardado los cambios');
          console.log(error.response);
          console.log('Error');
        });
    }
  };

  handleChangeOffers = (e) => {
    console.log(e.target.value);
    const value = JSON.parse(e.target.value.toLowerCase());

    this.setState({
      acceptOffersEmailControlled: value,
      clickedAcceptOffers: true,
    });

    console.log(
      this.state.acceptOffersEmailControlled,
      typeof this.state.acceptOffersEmailControlled
    );
  };

  stepN1 = () => {
    return (
      <div>
        <form
          onSubmit={this.submitRegisterStep1}
          className="form"
          key={this.state.userData}
        >
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_where_search" shrink={true}>
                  ¿Dónde buscas empleo?*
                </InputLabel>
                <Input
                  id="register_steps_where_search"
                  name="register_steps_where_search"
                  required
                  aria-describedby="register_steps_where_search"
                  defaultValue={this.state.userData.jobLocation}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_field">
                  ¿En qué área te gustaría trabajar?*
                </InputLabel>
                <Input
                  id="register_steps_field"
                  name="register_steps_field"
                  aria-describedby="register_steps_field"
                  required
                  defaultValue={this.state.userData.jobField}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_phone" shrink={true}>
                  Para tener una comunicación más directa contigo. ¿A qué
                  teléfono te llamamos?
                </InputLabel>
                <Input
                  id="register_steps_phone"
                  name="register_steps_phone"
                  aria-describedby="register_steps_phone"
                  type="text"
                  defaultValue={this.state.userData.phone}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_address">
                  Dirección*
                </InputLabel>
                <Input
                  id="register_steps_address"
                  name="register_steps_address"
                  aria-describedby="register_steps_address"
                  type="text"
                  required
                  defaultValue={this.state.userData.streetName}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_address_number">
                  N/Piso{' '}
                </InputLabel>
                <Input
                  id="register_steps_address_number"
                  name="register_steps_address_number"
                  aria-describedby="register_steps_address_number"
                  type="text"
                  defaultValue={this.state.userData.flatNumber}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_pc">
                  Código Postal*
                </InputLabel>
                <Input
                  id="register_steps_pc"
                  name="register_steps_pc"
                  aria-describedby="register_steps_pc"
                  type="number"
                  required
                  defaultValue={this.state.userData.postCode}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_locality">
                  Localidad*
                </InputLabel>
                <Input
                  id="register_steps_locality"
                  name="register_steps_locality"
                  aria-describedby="register_steps_locality"
                  type="text"
                  required
                  defaultValue={this.state.userData.locality}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_province">
                  Provincia*
                </InputLabel>
                <Input
                  id="register_steps_province"
                  name="register_steps_province"
                  aria-describedby="register_steps_province"
                  type="text"
                  required
                  defaultValue={this.state.userData.province}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_country">País*</InputLabel>
                <Input
                  id="register_steps_country"
                  name="register_steps_country"
                  aria-describedby="register_steps_country"
                  type="text"
                  required
                  defaultValue={this.state.userData.country}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN2 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep2} className="form">
          <h2>Cuéntanos qué has estudiado</h2>

          <Grid container>
            {this.state.studies_array.map((input, index) => (
              <Grid
                item={true}
                sm={12}
                xs={12}
                className="boxed"
                id={'studies-' + index}
              >
                <p onClick={() => this.deleteStudies(index)}>Borrar Estudio</p>
                <FormControl className="width-100">
                  <InputLabel
                    htmlFor="register_steps_type_estudios"
                    shrink={true}
                  >
                    ¿Cuál es el tipo de estudio que tienes?*
                  </InputLabel>
                  <Input
                    type="text"
                    id={input + '-register_steps_type_estudios'}
                    name={input + '-register_steps_type_estudios'}
                    aria-describedby={input + '-register_steps_type_estudios'}
                    required={true}
                    defaultValue={
                      index + 1 <= this.state.userData.studies.length
                        ? this.state.userData.studies[index].typeStudies
                        : ''
                    }
                  />
                </FormControl>
                <FormControl className="width-100">
                  <InputLabel htmlFor="register_steps_title_name" shrink={true}>
                    ¿Cómo se llama tu título?
                  </InputLabel>
                  <Input
                    id={input + '-register_steps_title_name'}
                    name={input + '-register_steps_title_name'}
                    aria-describedby={input + '-register_steps_title_name'}
                    type="text"
                    defaultValue={
                      index + 1 <= this.state.userData.studies.length
                        ? this.state.userData.studies[index].nameStudies
                        : ''
                    }
                  />
                </FormControl>
                <FormControl className="width-100">
                  <InputLabel
                    htmlFor="register_steps_name_university"
                    shrink={true}
                  >
                    ¿En qué centro o universidad cursaste tus estudios?
                  </InputLabel>
                  <Input
                    id={input + '-register_steps_name_university'}
                    name={input + '-register_steps_name_university'}
                    aria-describedby={input + '-register_steps_name_university'}
                    type="text"
                    defaultValue={
                      index + 1 <= this.state.userData.studies.length
                        ? this.state.userData.studies[index].nameCenterStudies
                        : ''
                    }
                  />
                </FormControl>
              </Grid>
            ))}

            <Grid item={true} sm={12} xs={12} className="center">
              <p onClick={() => this.appendInput_studies()} className="button">
                Añadir Estudio
              </p>{' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN3 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep3} className="form">
          <h2>Cuéntanos qué cursos has realizado.</h2>

          <Grid container>
            {this.state.courses_array.map((input, index) => (
              <Grid
                item={true}
                sm={12}
                xs={12}
                className="boxed"
                id={'courses-' + index}
              >
                <p
                  onClick={() => this.deleteCourses(index)}
                  className="buttonDelete"
                >
                  Borrar Curso
                </p>
                <FormControl className="width-100">
                  <InputLabel
                    htmlFor="register_steps_name_studies"
                    shrink={true}
                  >
                    ¿Cuál es el nombre del curso?*
                  </InputLabel>
                  <Input
                    type="text"
                    id={input + '-register_steps_name_studies'}
                    name={input + '-register_steps_name_studies'}
                    aria-describedby={input + '-register_steps_name_studies'}
                    required={true}
                    defaultValue={
                      index + 1 <= this.state.userData.courses.length
                        ? this.state.userData.courses[index].nameCourse
                        : ''
                    }
                  />
                </FormControl>
                <FormControl className="width-100">
                  <InputLabel htmlFor="register_steps_center" shrink={true}>
                    ¿Qué centro o página web era el encargado de impartirlo?
                  </InputLabel>
                  <Input
                    id={input + '-register_steps_center'}
                    name={input + '-register_steps_center'}
                    aria-describedby={input + '-register_steps_center'}
                    type="text"
                    defaultValue={
                      index + 1 <= this.state.userData.courses.length
                        ? this.state.userData.courses[index].nameCenter
                        : ''
                    }
                  />
                </FormControl>
                <FormControl className="width-100">
                  <InputLabel htmlFor="register_steps_url" shrink={true}>
                    Si quieres, puedes añadir una URL
                  </InputLabel>
                  <Input
                    id={input + '-register_steps_url'}
                    name={input + '-register_steps_url'}
                    aria-describedby={input + '-register_steps_url'}
                    type="text"
                    defaultValue={
                      index + 1 <= this.state.userData.courses.length
                        ? this.state.userData.courses[index].nameCenterStudies
                        : ''
                    }
                  />
                </FormControl>
                <FormControl className="width-100">
                  <InputLabel htmlFor="register_steps_pdf" shrink={true}>
                    ¿Tienes algún certificado que acredite tu participación en
                    el curso?
                  </InputLabel>
                  <Input
                    id={input + '-register_steps_pdf'}
                    name={input + '-register_steps_pdf'}
                    aria-describedby={input + '-register_steps_pdf'}
                    type="file"
                  />
                </FormControl>
                {this.state.userData.courses[index] ? (
                  <FormControl className="width-100">
                    <a
                      href={this.state.userData.courses[index].pdf}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Link certificado actual
                    </a>
                  </FormControl>
                ) : (
                  ''
                )}
              </Grid>
            ))}
          </Grid>

          <Grid item={true} sm={12} xs={12} className="center">
            <p onClick={() => this.appendInput_course()} className="button">
              Añadir Curso
            </p>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="button center"
              ref="buttonRegister"
            >
              Guardar y continuar
            </Button>
          </Grid>
        </form>
      </div>
    );
  };
  stepN4 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep4} className="form">
          <h2>Nivel de idiomas</h2>

          <Grid container>
            {this.state.languages_array.map((input, index) => (
              <Grid
                item={true}
                sm={12}
                xs={12}
                className="boxed"
                id={'languages-' + index}
              >
                <p
                  onClick={() => this.deleteLanguages(index)}
                  className="buttonDelete"
                >
                  Borrar Idioma
                </p>
                <FormControl className="width-100">
                  <InputLabel htmlFor="register_steps_language" shrink={true}>
                    Idioma*
                  </InputLabel>

                  <Input
                    type="text"
                    id={input + '-register_steps_language'}
                    name={input + '-register_steps_language'}
                    aria-describedby={input + '-register_steps_language'}
                    required={true}
                    defaultValue={
                      this.state.userData.languages
                        ? index + 1 <= this.state.userData.languages.length
                          ? this.state.userData.languages[index].name
                          : ''
                        : ''
                    }
                  />
                </FormControl>

                <Grid item={true} sm={12} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <p>Nivel</p>
                    <select
                      id={input + '-register_steps_level_language'}
                      name={input + '-register_steps_level_language'}
                      aria-describedby={
                        input + '-register_steps_level_language'
                      }
                      defaultValue={
                        this.state.userData.languages
                          ? index + 1 <= this.state.userData.languages.length
                            ? this.state.userData.languages[index].level
                            : ''
                          : ''
                      }
                    >
                      <option>A1</option>
                      <option>A2</option>
                      <option>B1</option>
                      <option>B2</option>
                      <option>C1</option>
                      <option>C2</option>
                      <option>Nativo</option>
                    </select>
                  </FormControl>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item={true} sm={12} xs={12} className="center">
            <p onClick={() => this.appendInput_languages()} className="button">
              Añadir Idioma
            </p>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="button center"
              ref="buttonRegister"
            >
              Guardar y continuar
            </Button>
          </Grid>
        </form>
      </div>
    );
  };
  stepN5 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep5} className="form">
          <h2>Cuéntanos qué aplicaciones o softwares usas o softwares usas</h2>

          <Grid container>
            {this.state.softwares_array.map((input, index) => (
              <Grid
                item={true}
                sm={12}
                xs={12}
                className="boxed"
                id={'softwares-' + index}
              >
                <p
                  onClick={() => this.deleteSoftwares(index)}
                  className="buttonDelete"
                >
                  Borrar Software
                </p>
                <FormControl className="width-100">
                  <InputLabel
                    htmlFor="register_steps_name_softwares"
                    shrink={true}
                  >
                    ¿Nombre del software o aplicación?*
                  </InputLabel>
                  <Input
                    type="text"
                    id={input + '-register_steps_name_softwares'}
                    name={input + '-register_steps_name_softwares'}
                    aria-describedby={input + '-register_steps_name_softwares'}
                    required={true}
                    defaultValue={
                      this.state.userData.softwares
                        ? index + 1 <= this.state.userData.softwares.length
                          ? this.state.userData.softwares[index].name
                          : ''
                        : ''
                    }
                  />
                </FormControl>

                <FormControl className="width-100">
                  <p>¿Nivel?</p>
                  <select
                    id={input + '-register_steps_level_softwares'}
                    name={input + '-register_steps_level_softwares'}
                    aria-describedby={input + '-register_steps_level_softwares'}
                    type="text"
                    defaultValue={
                      this.state.userData.softwares
                        ? index + 1 <= this.state.userData.softwares.length
                          ? this.state.userData.softwares[index].level
                          : ''
                        : ''
                    }
                  >
                    <option>Bajo</option>
                    <option>Bajo-medio</option>
                    <option>Medio</option>
                    <option>Medio-alto</option>
                    <option>Alto</option>
                    <option>Experto</option>
                  </select>
                </FormControl>
              </Grid>
            ))}
          </Grid>

          <Grid item={true} sm={12} xs={12} className="center">
            <p onClick={() => this.appendInput_software()} className="button">
              Añadir Software
            </p>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="button center"
              ref="buttonRegister"
            >
              Guardar y continuar
            </Button>
          </Grid>
        </form>
      </div>
    );
  };
  stepN6 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep6} className="form">
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_cv" shrink={true}>
                  Si quieres puedes adjuntar documentos como tu CV o carta de
                  presentación
                </InputLabel>
                <Input
                  id={'register_cv'}
                  name={'register_cv'}
                  aria-describedby={'register_cv'}
                  type="file"
                />
              </FormControl>
            </Grid>{' '}
            <Grid item={true} sm={12} xs={12}>
              {this.state.userData.cv ? (
                <FormControl className="width-100">
                  <a
                    href={this.state.userData.cv}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Link CV actual
                  </a>
                </FormControl>
              ) : (
                ''
              )}
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_offers" shrink={true}>
                  ¿Quieres recibir por correo electrónico ofertas acordes a tu
                  perfil?*
                </InputLabel>
                <RadioGroup
                  aria-label="register_steps_offers"
                  name="register_steps_offers"
                  id="register_steps_offers_news"
                  required
                  value={this.state.acceptOffersEmailControlled}
                  onChange={this.handleChangeOffers}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Si"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  chooseOptionMature = (option) => {
    var element = document.getElementById('opt1');
    var element2 = document.getElementById('opt2');
    var element3 = document.getElementById('opt3');
    var element4 = document.getElementById('opt4');
    if (option === 1) {
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element.classList.add('choosenOption');
    } else if (option === 2) {
      element.classList.remove('choosenOption');

      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element2.classList.add('choosenOption');
    } else if (option === 3) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');

      element4.classList.remove('choosenOption');
      element3.classList.add('choosenOption');
    } else if (option === 4) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.add('choosenOption');
    }
    this.setState({
      choosenOptionMaturity: option,
    });
  };

  responseGoogle = (response) => {
    console.log('aqui');
    this.setState({
      userData: response.profileObj,
      email: response.profileObj.email,
    });
    console.log(response);
  };
  responseGoogleFail = (response) => {
    console.log(response);
  };
  logout = (response) => {
    this.setState({
      userData: '',
    });
  };

  responseFacebook = (response) => {
    this.setState({
      facebookName: response.name,
      facebookEmail: response.email,
      facebookImage: response.picture.data.url,
    });
    console.log(response);
  };
  socialLogin = () => {
    return (
      <div>
        <p className="center">Registro con Redes Sociales</p>
        <div className="center clear width-100">
          <GoogleLogin
            clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogleFail}
            cookiePolicy={'single_host_origin'}
          />
          <br></br>
          <FacebookLogin
            appId="238403055163743"
            autoLoad={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
            icon="fa-facebook"
          />
        </div>
      </div>
    );
  };

  getStepContent = (step) => {
    var stepReal;

    stepReal = step;

    switch (stepReal) {
      case 0:
        return this.stepN1();
      case 1:
        return this.stepN2();
      case 2:
        return this.stepN3();
      case 3:
        return this.stepN4();
      case 4:
        return this.stepN5();
      case 5:
        return this.stepN6();
      default:
        return 'Paso no reconocido';
    }
  };
  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };
  handleNext = () => {
    if (this.state.activeStep + 1 >= this.state.steps.length) {
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };
  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };
  submitRegister = (event) => {
    event.preventDefault();
    this.refs.buttonRegister.setAttribute('disabled', 'disabled');
    console.log(event.target.register_steps_email.value);
    console.log(event.target.register_steps_name.value);
    console.log(event.target.register_steps_lastname.value);
    if (
      event.target.register_steps_name.value === '' ||
      event.target.register_steps_lastname.value === '' ||
      event.target.register_steps_email.value === ''
    ) {
      alert('Faltan campos requeridos por completar');
      this.refs.buttonRegister.removeAttribute('disabled');
    } else {
      const payload = {
        name: event.target.register_steps_name.value,
        lastName: event.target.register_steps_lastname.value,
        companyName: event.target.register_steps_company.value,
        industryInterested: event.target.register_steps_industry.value,
        phone: event.target.register_steps_phone.value,
        email: event.target.register_steps_email.value,
        publicContactEmail: event.target.register_steps_email.value,
        pwd: event.target.register_steps_password.value,
        role: 'ADMIN',
        streetName: event.target.register_steps_address.value,
        postCode: event.target.register_steps_postalcode.value,
        locality: event.target.register_steps_locality.value,
        province: event.target.register_steps_province.value,
        country: event.target.register_steps_country.value,
        tyc: 1,
        newsletter: 1,
      };

      axios({
        url: '/api/users',
        method: 'POST',
        data: payload,
      })
        .then(() => {
          console.log('Data has been sent to the server');
        })
        .catch((error) => {
          console.log(error.response);
          console.log('Error');
        });

      console.log(event.target.register_steps_email.value);
      console.log(event.target.register_steps_name.value);
      console.log(event.target.register_steps_lastname.value);
      console.log(event.target.register_steps_phone.value);
      console.log(event.target.register_steps_password.value);
      //console.log(event.target.register_steps_startup.value);
      console.log(event.target.register_steps_company.value);
      console.log(event.target.register_steps_industry.value);
      console.log(event.target.register_steps_address.value);
      console.log(event.target.register_steps_postalcode.value);
      console.log(event.target.register_steps_locality.value);
      console.log(event.target.register_steps_province.value);
      console.log(event.target.register_steps_country.value);
      console.log(event.target.register_steps_newsletter.value);
      console.log(event.target.register_steps_terms.value);
      console.log('registro');

      this.refs.buttonRegister.removeAttribute('disabled');
      //this.buttonRegister.removeAttribute("disabled");
    }
  };
  handleStep = (event) => {
    var stepReal;

    stepReal = event;

    this.setState({
      activeStep: stepReal,
    });
  };
  render() {
    return (
      <Container>
        <Grid container xs={12}>
          <Grid item={true} xs={12} sm={12} className="formDelimiter">
            <Stepper
              nonLinear
              alternativeLabel
              activeStep={this.state.activeStep}
            >
              {this.state.steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => this.handleStep(index)}>
                    {label}
                    {index === 0 && this.state.step1 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 1 && this.state.step2 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 2 && this.state.step3 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 3 && this.state.step4 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 4 && this.state.step5 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 5 &&
                    this.state.step6 === 1 &&
                    this.props.inProfile === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                  </StepButton>
                </Step>
              ))}
            </Stepper>

            <div>
              {this.state.activeStep === this.state.steps.length ? (
                <div>
                  <Typography>Ya no quedan mas pasos</Typography>
                </div>
              ) : (
                <div>
                  {this.getStepContent(this.state.activeStep)}

                  <div className="buttons-registration">
                    <Button
                      disabled={this.state.activeStep === 0}
                      onClick={() => {
                        this.handleBack();
                      }}
                    >
                      Anterior
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="button-nextStep"
                      onClick={() => {
                        this.handleNext();
                      }}
                    >
                      {this.state.activeStep === this.state.length - 1
                        ? 'Finzalizar'
                        : 'Siguiente'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default RegistroForm2;
