import React, { Component } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

class ResultProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: this.props.product,
      permalink: this.props.permalink,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Box m={2} ml={4}>
        <Grid item xs={12} sm={6} ml={6} container style={{ float: 'left' }}>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="h6">
                <Link
                  to={{
                    pathname: `/startups/${this.state.permalink}`,
                    props: { from: 'search' },
                  }}
                >
                  {this.state.product.name || ''}
                </Link>
              </Typography>
              <Box mt={1}>
                <Typography variant="body2" gutterBottom>
                  {this.state.product.description || ''}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default ResultProduct;
