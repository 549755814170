import React, { Component } from 'react';
import axios from 'axios';
import { TextField, FormControl, Button } from '@material-ui/core';
import './../../../styles/styles.css';
import ReactPaginate from 'react-paginate';
import 'react-quill/dist/quill.snow.css';
import { withSnackbar } from 'notistack';
import { Col, Container, Row } from 'react-bootstrap';
import {
  getUserData,
  getUserToken,
  forumVisible,
  getFounderData,
} from '../../utils/manageUserData';

import { Redirect } from 'react-router-dom';
class ForumThread extends Component {
  constructor(props) {
    super(props);

    console.log(props.location);

    let userData = JSON.parse(getUserData());
    let userToken = getUserToken();
    let visible = forumVisible(userData.role);

    this.state = {
      userData,
      userId: userData.uid,
      userToken,
      postId: props.location.pathname.split('/').pop(),
      post: [],
      msgs: [],
      newMsg: '',
      currentItems: [],
      pageCount: 0,
      itemOffset: 0,
      visible,
      itemsPerPage: 20,
      founderData: '',
    };
    console.log(this.state);
  }

  componentDidMount() {
    this.getPost();
    this.getFounderInformation();
  }
  getFounderInformation = async () => {
    let founderData = await getFounderData(
      this.state.userData.role,
      this.state.userData.uid
    );
    this.setState({
      founderData,
    });
  };
  getPost = async () => {
    const apiurl = '/api/forum/' + this.state.postId;

    const { data } = await axios.get(apiurl, {
      headers: {
        'content-type': 'application/json',
        'x-token': this.state.userToken,
      },
    });

    let endOffset = this.state.itemsPerPage % data.forum[0].messages.length;

    // necesario cuando solo hay 1 msg. se empieza contando de 0
    if (endOffset === 0) {
      endOffset = this.state.itemsPerPage;
    }

    console.log(this.state.itemsPerPage + '%' + data.forum[0].messages.length);

    this.setState({
      post: data.forum[0],
      msgs: data.forum[0].messages,
      newMsg: '',
      dataLoad: true,
      currentItems: data.forum[0].messages.slice(0, endOffset),
      endOffset,
      pageCount: data.forum[0].messages.length / this.state.itemsPerPage,
    });

    console.log(this.state);
    window.scrollTo(0, 0);
  };

  createMsg = async () => {
    const apiurl = '/api/forum/' + this.state.postId;

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const { userData, userId, newMsg, founderData } = this.state;

    const payload = {
      from: userId,
      fromName: userData.name,
      contactName: userData.contactName,
      body: newMsg,
      founderData,
    };

    if (newMsg.length === 0) {
      alert('El mensaje no puede estar vacío.');
      return false;
    }
    const { data } = await axios.patch(apiurl, payload, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.props.enqueueSnackbar('Mensaje enviado', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      // go to created thread
      this.getPost();
    }
  };

  handlePage = (event) => {
    const { msgs, itemsPerPage } = this.state;

    const newOffset = event.selected * itemsPerPage;

    this.setState({});
    this.setState({
      itemOffset: newOffset,
      endOffset: newOffset + itemsPerPage,
      currentItems: msgs.slice(newOffset, newOffset + itemsPerPage),
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  deleteThread = async () => {
    const { postId } = this.state;

    const apiurl = '/api/forum/' + postId;

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const { data } = await axios.delete(apiurl, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.props.enqueueSnackbar('Mensaje enviado', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      // goto thread
      // modificar aqui cuando tengamos la restriccion por rol
      window.location = ' /blog-secreto';
    }
  };

  render() {
    console.log(this.state);
    const msgs = this.state.currentItems
      ? this.state.currentItems.map((msg, index) => {
          return (
            <Row className="msg">
              <Col className="user" xs={6} sm={4} lg={2}>
                <div className="name">{msg.fromName ? msg.fromName : ''}</div>

                <div className="name">
                  {msg.contactName ? msg.contactName : ''}
                </div>

                <div className="name">
                  {msg.founderData ? msg.founderData : ''}
                </div>

                <div className="w-100 date">
                  {new Date(msg.date).toLocaleString('es-ES')}{' '}
                </div>
              </Col>

              <Col
                className={
                  index === 0 &&
                  this.state.endOffset === this.state.itemsPerPage // si es el primer mensaje y el offset es el de la primera pagina
                    ? 'op body'
                    : 'normal body'
                }
              >
                {msg.body}
              </Col>
            </Row>
          );
        })
      : '';

    return this.state.visible !== true ? (
      <Container className="bodyWrapper" fluid>
        <Redirect
          to={{
            pathname: '/',
            state: {
              userToken: this.state.userToken,
              userData: this.state.userData,
              user1: this.state.userData.uid,
            },
          }}
        />
        );
      </Container>
    ) : (
      <Container
        className="MuiContainer-root bodyWrapper MuiContainer-maxWidthLg forum thread"
        fluid
      >
        <h2 className="title center">{this.state.post.subject}</h2>

        {this.state.userData.role === 'ADMIN' ? (
          <div className="right">
            <Button
              variant="contained"
              color="error"
              type="submit"
              className="button error"
              onClick={() =>
                window.confirm(
                  `Estás seguro de borrar el hilo "${this.state.post.subject}"?`
                )
                  ? this.deleteThread()
                  : false
              }
            >
              Borrar hilo
            </Button>
          </div>
        ) : (
          ''
        )}

        <div className="messages">
          {msgs}

          <Row>
            <ReactPaginate
              previousLabel={'←'}
              nextLabel={'→'}
              pageCount={this.state.pageCount}
              onPageChange={this.handlePage}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          </Row>

          <div className="add-msg-box">
            <FormControl className="width-100">
              <TextField
                id="outlined"
                name="new-msg"
                type="text"
                required
                multiline
                variant="outlined"
                rows={3}
                label="Texto"
                value={this.state.newMsg}
                onChange={(e) => this.setState({ newMsg: e.target.value })}
              />
            </FormControl>

            <div className="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button"
                onClick={() => this.createMsg()}
              >
                Enviar
              </Button>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default withSnackbar(ForumThread);
