import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import './../../../styles/styles.css';
import { withSnackbar } from 'notistack';
import ReactQuill from 'react-quill';
import { InputLabel, Button, Select, MenuItem } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import AttachFile from '@material-ui/icons/AttachFile';
class ChallengeEdit extends Component {
  constructor(props) {
    super(props);

    console.log('challenge edit component');

    this.state = {
      challenge: props.extraProps.challenge || [],
      fromAdmin: props.extraProps.fromAdmin || false,
      userToken: props.userToken,
      challengeTitle: props.extraProps.challenge.challengeTitle,
      challengeDescription: props.extraProps.challenge.challengeDescription,
      conditions: props.extraProps.challenge.conditions,
      offers: props.extraProps.challenge.offers,
      expirationDate: props.extraProps.challenge.expirationDate,
      active: props.extraProps.challenge.active,
      legalBasesDoc: props.extraProps.challenge.legalBasesDoc,
    };
  }

  updateChallenge = async () => {
    console.log('update challenge');

    const apiurl =
      '/api/company/' +
      this.state.challenge.companyId +
      '/challenge/' +
      this.state.challenge._id;

    console.log(apiurl);

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const {
      challengeTitle,
      challengeDescription,
      conditions,
      offers,
      legalBasesDoc,
      expirationDate,
      active,
    } = this.state;

    const payload = {
      challengeTitle,
      challengeDescription,
      conditions,
      offers,
      legalBasesDoc,
      expirationDate,
      active,
    };

    const { data } = await axios.patch(apiurl, payload, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.props.enqueueSnackbar('Reto actualizado', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  };

  uploadLegalBases = async (file) => {
    if (file) {
      console.log('hay fichero');
      let formData = new FormData();
      formData.append('file', file);
      await axios
        .post('/api/uploads', formData)
        .then((res) => {
          this.setState({ legalBasesDoc: res.data.fileName });
          console.log(res.data.fileName);
          return res.data.fileName;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido el fichero');
        });
    }
  };

  render() {
    const {
      challengeTitle,
      challengeDescription,
      conditions,
      offers,
      legalBasesDoc,
    } = this.state;

    const showAdminFields = (
      <Row>
        <Col md={6} xs={12}>
          <InputLabel
            htmlFor="expirationdate"
            shrink={true}
            style={{ marginTop: '1.8rem' }}
          >
            Activo
          </InputLabel>
          <Select
            value={this.state.active}
            onChange={(e) => this.setState({ active: e.target.value })}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Sí</MenuItem>
          </Select>
        </Col>

        <Col md={6} xs={12}>
          <InputLabel
            htmlFor="expirationdate"
            shrink={true}
            style={{ marginTop: '1.8rem' }}
          >
            Fecha de expiración
          </InputLabel>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            value={new Date(this.state.expirationDate)
              .toLocaleString()
              .substr(0, 10)}
            onChange={(e) => this.setState({ expirationDate: e })}
          />
        </Col>
      </Row>
    );
    return (
      <Container>
        <h1 className="job-page-title">Reto {challengeTitle} </h1>
        <Row>
          <Col md={12} xs={12}>
            <TextField
              id="standard-name"
              label="Título"
              value={challengeTitle ? challengeTitle : ''}
              margin="normal"
              className="forced-black"
              onChange={(e) => {
                this.setState({ challengeTitle: e.target.value });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Descripción del reto
            </InputLabel>
            <ReactQuill
              name="editChallengeDescription"
              modules={this.modules}
              formats={this.formats}
              onChange={(e) => this.setState({ challengeDescription: e })}
              value={challengeDescription ? challengeDescription : ''}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Condiciones
            </InputLabel>
            <ReactQuill
              name="editChallengeDescription"
              modules={this.modules}
              formats={this.formats}
              onChange={(e) => this.setState({ conditions: e })}
              value={conditions ? conditions : ''}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              ¿Qué ofrece?
            </InputLabel>
            <ReactQuill
              name="editChallengeDescription"
              modules={this.modules}
              formats={this.formats}
              onChange={(e) => this.setState({ offers: e })}
              value={offers ? offers : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col md={9} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Bases legales del reto
            </InputLabel>
            <input
              type="file"
              onChange={(e) => this.uploadLegalBases(e.target.files[0])}
            />
          </Col>
          <Col md={3} xs={12}>
            {legalBasesDoc !== undefined ? (
              <a
                rel="noreferrer"
                href={
                  process.env.REACT_APP_API_URL_PROD +
                  '/api/uploads/' +
                  legalBasesDoc
                }
                target="_blank"
                style={{
                  display: 'table',
                  margin: '0 auto',
                  marginTop: '3.5rem',
                }}
              >
                Bases actuales <AttachFile />
              </a>
            ) : (
              ''
            )}
          </Col>
        </Row>

        {this.state.fromAdmin ? showAdminFields : ''}

        <Row>
          <Button
            variant="contained"
            style={{
              background: '#6658ea',
              color: 'white',
              marginBottom: '2rem',
              marginLeft: '1rem',
              display: 'table',
              margin: '0 auto',
              marginTop: '2rem',
            }}
            onClick={() => this.updateChallenge()}
          >
            Guardar reto
          </Button>
        </Row>
      </Container>
    );
  }
}

export default withSnackbar(ChallengeEdit);
