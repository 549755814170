import React, { Component } from 'react';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { getUserData } from '../utils/manageUserData';
import { withSnackbar } from 'notistack';
class Contact extends Component {
  constructor(props) {
    super(props);

    console.log('PROPS', props);

    this.state = {
      userData: '',
      subject: props.subject,
      user2id: props.user2id,
      user2name: props.user2name,
      user2company: props.user2company,
      user2type: props.user2type,
      userToken: props.userToken,
      budget: props.budget,
      user2lastanme: props.user2lastanme,
      user2phone: props.user2phone,
      user2email: props.user2email,
      fromJob: props.fromJob || false,
      fromChallenge: props.fromChallenge || false,
      alternativeContactText: props.alternativeContactText,
      alternativeContactTextLogin: props.alternativeContactTextLogin,
      alternativeTextCantContact: props.alternativeTextCantContact,
      jobId: props.jobId,
      challengeCompanyId: props.challengeCompanyId,
      challengeStartupId: props.challengeStartupId,
      challengeId: props.challengeId,
      challengeStartups: props.challengeStartups,
      displayHelp: 'none',
      displayHelpButton: 'block',
      fromStartup: props.fromStartup || false,
    };
  }

  componentDidMount() {
    (async () => {
      await this.getUserInformation();
    })();
  }

  getUserInformation = async () => {
    var userData = getUserData();
    this.setState({
      loadingData: true,
    });
    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
    }
    this.setState({
      loadingData: false,
    });
  };

  login = () => {
    document.getElementById('buttonLoginTop').click();
  };

  getUserFounder = (userInfo) => {
    console.log('entra aqui');
    if (
      userInfo.role === 'SECONDARY_USER' ||
      userInfo.role === 'SECONDARY_USER_ADMIN' ||
      userInfo.role === 'USER_OFFER_SERVICES'
    ) {
      var apiurl2 = '/api/users/' + userInfo.uid + '/company';

      axios({
        url: apiurl2,
        method: 'GET',
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          if (response.data.companies.length) {
            const objUser1 = {
              _id: this.state.userData.uid,
              type: this.state.userData.role,
              name: this.state.userData.name,
              lastname: this.state.userData.lastname,
              phone: this.state.userData.phone,
              email: this.state.userData.email,
              company: response.data.companies[0].name,
            };
            const objUser2 = {
              _id: this.state.user2id,
              type: this.state.user2type,
              name: this.state.user2name,
              lastname: this.state.user2lastanme,
              phone: this.state.user2phone,
              email: this.state.user2email,
              company: this.state.user2company,
            };

            this.sendmessage(objUser1, objUser2);
          }
        })
        .catch((error) => {
          this.props.enqueueSnackbar(
            'Hemos encontrado un error y no se han guardado los cambios',
            {
              variant: 'error',
            }
          );
        });
    } else {
      var apiurl = '/api/users/' + userInfo.uid + '/startups';
      axios({
        url: apiurl,
        method: 'GET',
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          if (response.data.startups.length) {
            const objUser1 = {
              _id: this.state.userData.uid,
              type: this.state.userData.role,
              name: this.state.userData.name,
              lastname: this.state.userData.lastname,
              phone: response.data.startups[0].phone,
              email: response.data.startups[0].email,
              company: response.data.startups[0].name,
            };
            const objUser2 = {
              _id: this.state.user2id,
              type: this.state.user2type,
              name: this.state.user2name,
              company: this.state.user2company,
              lastname: this.state.user2lastanme,
              phone: this.state.user2phone,
              email: this.state.user2email,
            };

            this.sendmessage(objUser1, objUser2);
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.enqueueSnackbar(
            'Hemos encontrado un error y no se han guardado los cambios',
            {
              variant: 'error',
            }
          );
        });
    }
  };

  sendmessage = (infoUser1, infoUser2) => {
    var apiurlpost = '/api/messages';
    const payload = {
      subject: this.state.subject,
      user1: infoUser1,
      user2: infoUser2,
    };
    axios({
      url: apiurlpost,
      method: 'POST',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.props.enqueueSnackbar(
          'Conversacion iniciada, redirigiendo al chat',
          {
            variant: 'success',
          }
        );
        window.location.href = '/perfil?goChat=true';
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          'Hemos encontrado un error y no se han guardado los cambios',
          {
            variant: 'error',
          }
        );
      });
  };

  sendmessageJobApplication = (infoUser1, infoUser2) => {
    console.log('sending msg job app');
    console.log('infoUser1', infoUser1);
    console.log('infoUser2', infoUser2);

    var apiurlpost = '/api/messages';
    const payload = {
      subject: this.state.subject,
      user1: infoUser1,
      user2: infoUser2,
    };
    axios({
      url: apiurlpost,
      method: 'POST',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.props.enqueueSnackbar(
          'Conversacion iniciada, redirigiendo al chat',
          {
            variant: 'success',
          }
        );
        window.location.href = '/perfil?goChat=true';
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          'Hemos encontrado un error y no se han guardado los cambios',
          {
            variant: 'error',
          }
        );
      });
  };
  startSellActions = () => {
    console.log('test');
    console.log(this.state.userData);
    if (
      this.state.userData.uid &&
      this.state.userData.role &&
      this.state.userData.name
    ) {
      this.props.enqueueSnackbar('Proceso de crowdfunding iniciando', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const payload = {
        subject: 'Oportunidad Crowdfunding',
        user1: {
          _id: this.state.userData.uid,
          type: 'Crowdfunding_user',
          name: this.state.userData.name,
          phone: this.state.userData.phone,
          email: this.state.userData.email,
        },
        idStartuptCrowd: this.props.startupIdCrowd,
        nameStartupCrowd: this.props.startupNameCrowd,
      };
      const apiurlpost = '/api/messages/sellActions';
      axios({
        url: apiurlpost,
        method: 'POST',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          this.props.enqueueSnackbar('Proceso iniciado, redirigiendo al chat', {
            variant: 'success',
          });
          window.location.href = '/perfil?goChat=true';
        })
        .catch((error) => {
          this.props.enqueueSnackbar(
            'Hemos encontrado un error y no se han guardado los cambios',
            {
              variant: 'error',
            }
          );
        });
    } else {
      this.props.enqueueSnackbar('Error', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  onClickHelp = () => {
    if (this.state.displayHelp === 'none') {
      this.setState({ displayHelp: 'block' });
    } else {
      this.setState({ displayHelp: 'none' });
    }
  };

  startCrowfunding = () => {
    console.log('test');
    console.log(this.state.userData);
    if (
      this.state.userData.uid &&
      this.state.userData.role &&
      this.state.userData.name
    ) {
      this.props.enqueueSnackbar('Proceso de crowdfunding iniciando', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const payload = {
        subject: 'Oportunidad Crowdfunding',
        user1: {
          _id: this.state.userData.uid,
          type: 'Crowdfunding_user',
          name: this.state.userData.name,
          phone: this.state.userData.phone,
          email: this.state.userData.email,
        },
        idStartuptCrowd: this.props.startupIdCrowd,
        nameStartupCrowd: this.props.startupNameCrowd,
      };
      const apiurlpost = '/api/messages/crowdfunding';
      axios({
        url: apiurlpost,
        method: 'POST',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          this.props.enqueueSnackbar('Proceso iniciado, redirigiendo al chat', {
            variant: 'success',
          });
          window.location.href = '/perfil?goChat=true';
        })
        .catch((error) => {
          this.props.enqueueSnackbar(
            'Hemos encontrado un error y no se han guardado los cambios',
            {
              variant: 'error',
            }
          );
        });
    } else {
      this.props.enqueueSnackbar('Error', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  addCandidate = async () => {
    console.log('update job');

    const apiurl = '/api/jobs/' + this.state.jobId + '/candidate';

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const payload = {
      userId: this.state.userData.uid,
      name: this.state.userData.name,
      lastname: this.state.userData.lastname,
      email: this.state.userData.email,
      phone: this.state.userData.phone,
      state: 'REVISIÓN',
    };

    console.log(payload);

    const { data } = await axios.patch(apiurl, payload, { headers });

    console.log('code', data);

    if (data.code === 200) {
      // const user1 = {
      //   _id: this.state.userData.uid,
      //   type: this.state.userData.role,
      //   name: this.state.userData.name,
      //   lastname: this.state.userData.lastname,
      //   phone: this.state.userData.phone,
      //   email: this.state.userData.email,
      // };
      // const user2 = {
      //   _id: this.state.user2id,
      //   type: this.state.user2type,
      //   name: this.state.user2name,
      //   company: this.state.user2company,
      //   lastname: this.state.user2lastanme,
      //   phone: this.state.user2phone,
      //   email: this.state.user2email,
      // };

      // this.sendmessageJobApplication(user1, user2);

      this.props.enqueueSnackbar('Te has inscrito en la oferta de empleo', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else if (data.code === 250) {
      this.props.enqueueSnackbar('Ya estás inscrito en esta oferta de empleo', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  contact = () => {
    if (!this.state.fromJob && !this.state.fromChallenge) {
      this.addContactPO(this.props.collectionId);
    }

    if (this.state.fromChallenge) {
      this.addStartupToChallenge(this.props.collectionId);
    }

    if (
      this.state.subject &&
      this.state.userData.uid &&
      this.state.user2id &&
      this.state.user2name &&
      this.state.user2type &&
      this.state.userData.name
    ) {
      if (!this.state.fromJob) {
        this.getUserFounder(this.state.userData);
      } else {
        this.addCandidate();
      }
    } else {
      console.log('CONTACT CLICK - not enough info');
      console.log('subject', this.state.subject);
      console.log('user data uid', this.state.userData.uid);
      console.log('user2id', this.state.user2id);
      console.log('user2name', this.state.user2name);
      console.log('user2type', this.state.user2type);
      console.log('user data name', this.state.userData.name);
    }
  };

  addContactPO = async (uid) => {
    const { userToken } = this.state;

    let apiurl;
    if (this.state.user2type === 'Company') {
      apiurl = '/api/company/' + uid;
    } else if (this.state.user2type === 'Startup') {
      apiurl = '/api/startups/' + uid;
    }

    const payload = {
      organicPositioning: {
        contacts: true,
      },
      searcher: Cookies.get('searcher'),
    };

    await axios({
      url: apiurl,
      method: 'PATCH',
      data: payload,
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (this._isMounted) {
          Cookies.set('searcher', '');
        }
      })
      .catch((error) => {});

    // restar 1,2 €
    // falta obtener el tipo de coleccion y su id y meterlos aqui
    const paymentSubstractPayload = {
      collection: this.props.user2type,
      collectionId: this.props.collectionId,
      amountToSubstract: '1.2',
    };

    await axios({
      url: '/api/payments/substract',
      method: 'POST',
      data: paymentSubstractPayload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {})
      .catch((error) => {});
  };

  addStartupToChallenge = async () => {
    const apiurl =
      '/api/company/' +
      this.state.challengeCompanyId +
      '/challenge/' +
      this.state.challengeId;

    console.log('startup to challenge', apiurl);
    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const payload = {
      startup: this.state.challengeStartupId,
    };

    const { data } = await axios.patch(apiurl, payload, { headers });

    console.log('code', data);
  };

  render() {
    const button = (
      <Button
        form="formContact"
        variant="contained"
        color="primary"
        type="submit"
        className="button center width-100"
        onClick={() => this.contact()}
      >
        Contactar
      </Button>
    );
    const buttonAlternative = (
      <Button
        form="formContact"
        variant="contained"
        color="primary"
        type="submit"
        className="button center width-100"
        onClick={() => this.contact()}
      >
        {this.state.alternativeContactText}
      </Button>
    );

    const buttonAlternativeCantContact = (
      <Button
        form="formContact"
        variant="contained"
        color="primary"
        type="submit"
        className="button center width-100"
        onClick={() => this.contact()}
      >
        {this.state.alternativeTextCantContact}
      </Button>
    );
    const login = (
      <Button
        form="formContact"
        variant="contained"
        color="primary"
        type="submit"
        className="button center width-100"
        onClick={() => this.login()}
      >
        Haz login para contactar
      </Button>
    );

    const loginAlternative = (
      <Button
        form="formContact"
        variant="contained"
        color="primary"
        type="submit"
        className="button center width-100"
        onClick={() => this.login()}
      >
        {this.state.alternativeContactTextLogin}
      </Button>
    );

    const buttonOwnCompany = (
      <Button
        form="formContact"
        variant="contained"
        color="primary"
        type="submit"
        className="button center width-100"
      >
        No puede contactar
      </Button>
    );

    const buttonCrowd = (
      <Button
        form="formContact"
        variant="contained"
        color="primary"
        type="submit"
        className="button center width-100"
        onClick={() => this.startCrowfunding()}
      >
        Contactar oferta
      </Button>
    );
    const buttonSellActions = (
      <Button
        form="formContact"
        variant="contained"
        color="primary"
        type="submit"
        className="button center width-100"
        onClick={() => this.startSellActions()}
      >
        Contactar oferta
      </Button>
    );
    // default
    let returnButton = buttonOwnCompany;

    if (this.state.userData !== '') {
      console.log('Información del usuario');
      console.log(this.state.userData);
      if (this.state.userData.uid === this.state.user2id) {
        if (this.state.budget > 2 && !this.props.fromInversion) {
          returnButton = button;
        }
      }
      if (
        this.props.fromInversion &&
        this.state.userData.role === 'CROWDFUNDING_USER'
      ) {
        if (this.props.type === 'SellActions') {
          returnButton = buttonSellActions;
        } else {
          returnButton = buttonCrowd;
        }
      }
      if (
        this.props.fromServices &&
        this.state.userData.role === 'FINAL_USER' &&
        this.props.budget >= 1.2
      ) {
        returnButton = button;
      }
      if (
        this.state.fromJob &&
        (this.state.userData.role === 'FINAL_USER_COMPANY' ||
          this.state.userData.role === 'FINAL_USER_STARTUP')
      ) {
        console.log('Fromjob');
        returnButton = buttonAlternative;
      }

      if (this.state.fromChallenge) {
        console.log('fromChallenge');

        let alreadyInscribed = false;

        if (this.state.challengeStartupId) {
          this.state.challengeStartups.map((startupInscribed) => {
            if (startupInscribed === this.state.challengeStartupId) {
              alreadyInscribed = true;
              return true;
            }
            return false;
          });
        }

        if (alreadyInscribed) {
          console.log('already inscribed startup to challenge');
          returnButton = buttonAlternativeCantContact;
        } else {
          returnButton = buttonAlternative;
        }
      }

      // anadido por javi
      if (
        this.state.userData.role === 'SECONDARY_USER_ADMIN' &&
        this.state.fromStartup
      ) {
        returnButton = button;
      }

      // anadido por javi
      if (this.state.userData.role === 'USER_OFFER_SERVICES') {
        returnButton = buttonOwnCompany;
        this.setState({ displayHelpButton: 'block' });
      }
    } else {
      if (this.state.fromJob) {
        returnButton = loginAlternative;
      } else {
        returnButton = login;
      }
    }

    return (
      <div className="margin-bottom-20">
        {console.log('userData', this.state.userData)}
        {/* {this.state.userData !== ''
          ? this.state.userData.uid === this.state.user2id
            ? buttonOwnCompany
            : +this.state.budget > 2
            ? button
            : buttonOwnCompany
          : login} */}
        {returnButton}
        <HelpIcon
          style={{ cursor: 'pointer', display: this.state.displayHelpButton }}
          onClick={this.onClickHelp}
        />

        <p style={{ display: this.state.displayHelp }}>
          Si ves "NO PUEDE CONTACTAR" se debe a que tu perfil de registro no
          concuerda con el ROL para contactar.
        </p>
      </div>
    );
  }
}
export default withSnackbar(Contact);
