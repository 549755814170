import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Grid, Box } from '@material-ui/core';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { Container } from '@material-ui/core';
import ReactPaginate from 'react-paginate';

class challenges extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(props.location.search);

    this.state = {
      searchString: urlParams.get('q') || '',
      challenges: [],
      showChallenges: [],
      itemsPerPage: 9,
      numberOfPage: 1,
      beginItem: 0,
      endItem: 9,
      page: urlParams.get('page') || 1,
      limit: urlParams.get('limit') || 10,
      skip: urlParams.get('skip') || 0,
      filterTags: [],
    };
  }

  componentDidMount() {
    (async () => {
      await this.getChallenges();
    })();
  }

  getChallenges = () => {
    const { searchString, page, skip, limit } = this.state;

    let params =
      '?q=' +
      searchString +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&skip=' +
      skip;

    var apiurl = '/api/company/challenges/public/list' + params;

    console.log(apiurl);
    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        var pages;
        if (response.data.total) {
          pages = response.data.total / this.state.itemsPerPage;
        } else {
          pages = 1;
        }

        console.log(response);
        this.setState({
          challenges: response.data.challenges,
          showChallenges: response.data.challenges,
          numberOfItems: response.data.total,
          numberOfPage: pages,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePage = (event) => {
    console.log(event.selected);

    this.setState({
      beginItem: event.selected * this.state.itemsPerPage,
      endItem: event.selected * this.state.itemsPerPage + 9,
    });
  };

  render() {
    const challenges = this.state.showChallenges
      .slice(this.state.beginItem, this.state.endItem)
      .map((challenge, index) => {
        if (challenge) {
          return (
            <Grid item lg={3} sm={3} xs={12} key={index}>
              <Link
                to={{
                  pathname: '/reto/' + challenge._id,
                }}
                className="noDecoration companyBoxWrapper"
              >
                <div className="companyBox">
                  <Grid container>
                    <Grid item sm={12} xs={12}>
                      <h4>{challenge.companyName}</h4>
                    </Grid>

                    <Grid item sm={12} xs={12} style={{ marginBottom: '1rem' }}>
                      {challenge.companyImg ? (
                        <img
                          src={
                            process.env.REACT_APP_API_URL_PROD +
                            'api/uploads/company/' +
                            challenge.companyId
                          }
                          alt={challenge.companyPermalink}
                          className="width-100 companyDirectoryImage"
                        />
                      ) : (
                        <div className="noImage">
                          <BrokenImageIcon className="icon" fontSize="large" />
                        </div>
                      )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <h5>{challenge.challengeTitle}</h5>
                    </Grid>

                    <Grid item sm={12} xs={12}>
                      <div className=" button">
                        <span>Más información</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Link>
            </Grid>
          );
        } else {
          return '';
        }
      });

    const renderChallenges = (
      <Container className="bodyWrapper">
        <section>
          <Grid container>
            <Grid item={true} xs={12} sm={12} className="left">
              <Box m={2}>
                <h1 className="seo-h1">Reto</h1>
                <h2 className="seo-h2">Innovación a la carta</h2>
              </Box>
            </Grid>
            <Grid container xs={12} sm={12} className="challenge-txt">
              <p>
                Dentro de este apartado, tu Startup podrá beneficiarse de
                acuerdos entre ESTARTAPS.COM y Corporates. Estas grandes
                empresas solicitan innovación a la carta, es decir, buscan la
                mejor solución que resuelva un problema determinado que tengan
                dentro de su actividad.
              </p>
              <p>
                Si crees que tu Startup resuelve de la mejor manera el problema
                planteado por la empresa,
              </p>
              <p>NO LO DUDES MÁS, INSCRÍBETE!!!</p>
              <p>
                Si tu idea resulta ser la ganadora, recibirás el premio que se
                especifica en cada reto.
              </p>
            </Grid>
            <Grid container xs={12} sm={12}>
              {challenges}
            </Grid>
            <ReactPaginate
              previousLabel={'←'}
              nextLabel={'→'}
              pageCount={this.state.numberOfPage}
              onPageChange={this.handlePage}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          </Grid>
        </section>
      </Container>
    );
    return renderChallenges;
  }
}
export default challenges;
