import React, { Component } from "react";
import {
	Grid,
	Container,
	Button,
	InputLabel,
	Input,
	FormControl,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { getUserData, getUserToken } from "../../../utils/manageUserData";
import axios from "axios";
import { withSnackbar } from "notistack";
import { DataGrid } from "@material-ui/data-grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import getCroppedImg from "../../../utils/cropImage";

class listServices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userToken: "",
			userData: "",
			userStartup: "",
			startupToEdit: "",
			imageToUpload: "",
			croppedImage: "",
			croppedImageFile: "",
			crop: { x: 0, y: 0 },
			croppedAreaPixels: "",
			uploadedImage: "",
			croppedArea: "",
			zoom: 1,
			aspect: 1 / 1,
			cropSize: { width: 300, height: 300 },
			products_array: [],
			teams_array: [],
			update_change_status_services: [],
		};
	}
	componentDidMount() {
		(async () => {
			await this.getUserInformation();
			await this.getUserToken();
			await this.getAllStartups();
		})();
	}
	getUserToken = async () => {
		var userToken = getUserToken();
		if (userToken !== false) {
			const obj = userToken;
			this.setState({
				userToken: obj,
			});
		}
	};
	getUserInformation = async () => {
		var userData = getUserData();

		if (userData !== false) {
			const obj = JSON.parse(userData);
			this.setState({
				userData: obj,
			});
		}
	};

	getAllStartups = async () => {
		var userToken = this.state.userToken;
		var apiurl = "/api/company/services-check";

		await axios({
			url: apiurl,
			method: "GET",
			headers: {
				"x-token": userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				console.log("data new here");
				console.log(response.data);
				this.setState({
					userStartup: response.data.companies,
				});
			})
			.catch((error) => {
				console.log("No startup Found");
			});
	};
	editStartup = (startupData) => {
		var products = [];

		var startup = startupData;
		startup.services.forEach((element, index, array) => {
			var name = "product-" + index;
			products.push(name);
		});
		this.setState({
			products_array: products,
			startupToEdit: startupData,
			startupToEditUid: startupData._id,
			update_change_status_services:
				startupData.statusOfferServicesOtherCompanies,
		});
		console.log(startupData);
	};
	chooseOptionMature = (option) => {
		var element = document.getElementById("opt1");
		var element2 = document.getElementById("opt2");
		var element3 = document.getElementById("opt3");
		var element4 = document.getElementById("opt4");
		if (option === 1) {
			element2.classList.remove("choosenOption");
			element3.classList.remove("choosenOption");
			element4.classList.remove("choosenOption");
			element.classList.add("choosenOption");
		} else if (option === 2) {
			element.classList.remove("choosenOption");

			element3.classList.remove("choosenOption");
			element4.classList.remove("choosenOption");
			element2.classList.add("choosenOption");
		} else if (option === 3) {
			element.classList.remove("choosenOption");
			element2.classList.remove("choosenOption");

			element4.classList.remove("choosenOption");
			element3.classList.add("choosenOption");
		} else if (option === 4) {
			element.classList.remove("choosenOption");
			element2.classList.remove("choosenOption");
			element3.classList.remove("choosenOption");
			element4.classList.add("choosenOption");
		}
		this.setState({
			choosenOptionMaturity: option,
		});
	};
	submitUpdateStartup = (event) => {
		event.preventDefault();
		if (this.state.startupToEdit !== "") {
			if (this.state.choosenOptionMaturity !== "") {
				let listProducts = [];
				var getProducts = new Promise((resolve, reject) => {
					this.state.products_array.forEach((element, index, array) => {
						if (
							document.getElementById(element + "-name") !== null &&
							document.getElementById(element + "-name") !== "undefined"
						) {
							var name = document.getElementById(element + "-name").value;
							var description = document.getElementById(
								element + "-description"
							).value;
							var price = document.getElementById(element + "-price").value;
							var discountPercentage = document.getElementById(
								element + "-discountPercentage"
							).value;
							var discountedPrice = document.getElementById(
								element + "-discountedPrice"
							).value;
							let obj = {
								name: name,
								description: description,
								price: price,
								discountPercentage: discountPercentage,
								discountedPrice: discountedPrice,
							};
							listProducts.push(obj);
						}
						if (index === array.length - 1) resolve();
					});
				});

				getProducts.then(() => {
					console.log("All done!");
				});

				//console.log(listProducts);

				const payload = {
					name: this.state.startupToEdit.name,
					industries: event.target.industries.value,
					products: listProducts,
					statusOfferServicesOtherCompanies:
						this.state.update_change_status_services,
				};
				this.submitAlldata(payload);

				this.props.getStartupInformation();
			}
		}
	};
	submitAlldata = (payload) => {
		var apiurlput = "/api/company/" + this.state.startupToEdit._id;
		axios({
			url: apiurlput,
			method: "PUT",
			data: payload,
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				console.log(response);
				this.props.enqueueSnackbar("Datos guardados con éxito", {
					variant: "success",
				});
				this.getAllStartups();
			})
			.catch((error) => {
				console.log(error.response);
				alert("Hemos encontrado un error y no se han guardado los cambios");
			});
	};
	onCropChange = (crop) => {
		this.setState({ crop });
	};

	onCropComplete = (croppedArea, croppedAreaPixels) => {
		this.setState({
			croppedArea: croppedArea,
			croppedAreaPixels: croppedAreaPixels,
		});
	};

	onZoomChange = (zoom) => {
		this.setState({ zoom });
	};
	handleChange = (event) => {
		var image = event.target.files[0];
		if (event.target.files[0]) {
			this.setState({
				uploadedImage: event.target.files[0],
				imageSrc: URL.createObjectURL(image),
			});
		} else {
			this.setState({
				uploadedImage: "",
				imageSrc: "",
			});
		}
	};
	showCroppedImage = async () => {
		const croppedImage = await getCroppedImg(
			this.state.imageSrc,
			this.state.croppedAreaPixels,
			this.state.rotation
		);

		let response = await fetch(croppedImage);
		let data = await response.blob();
		let metadata = {
			type: "image/png",
		};
		let file = new File([data], "Startup.png", metadata);

		this.setState({
			croppedImageFile: file,
		});
		this.setState({
			croppedImage: croppedImage,
		});
	};
	appendInput_product() {
		var newInput = `product-${this.state.products_array.length}`;

		this.setState((prevState) => ({
			products_array: prevState.products_array.concat([newInput]),
		}));
	}

	appendInput_team() {
		var newInput = `team-${this.state.teams_array.length}`;

		this.setState((prevState) => ({
			teams_array: prevState.teams_array.concat([newInput]),
		}));
	}

	deleteProduct = async (producto) => {
		alert("vas a borrar el producto: " + producto);

		document.getElementById("product-" + producto).remove();
	};

	deleteTeam = async (team) => {
		alert("vas a borrar el miembro: " + team);

		document.getElementById("team-" + team).remove();
	};

	handleChangeStatus = (event) => {
		this.setState({
			update_change_status_services: event.target.value,
		});
	};

	render() {
		console.log(this.state.update_change_status_services);
		const columns = [
			{
				field: "name",
				headerName: "Nombre",
				editable: false,
				flex: 1,
			},

			{
				field: "statusOfferServicesOtherCompanies",
				headerName: "Estado ",
				editable: false,
				flex: 1,
				renderCell: (params) => {
					return (
						<div>
							<p>{params.value ? params.value : "No definido"}</p>
						</div>
					);
				},
			},
			{
				field: "actions",
				headerName: "Acciones",
				sortable: false,
				flex: 1,
				disableClickEventBubbling: true,
				renderCell: (params) => {
					return (
						<div>
							<Button
								className="button"
								onClick={() => this.editStartup(params.row)}
							>
								Ver
							</Button>
						</div>
					);
				},
			},
		];
		console.log(this.state.userStartup);
		return (
			<Container>
				<Grid container className="borderShadow">
					<Grid item xs={12}>
						<div>
							{this.state.userStartup !== "" ? (
								<DataGrid
									rows={this.state.userStartup}
									getRowId={(row) => row._id}
									autoHeight
									columns={columns}
									pageSize={5}
								/>
							) : (
								<p>Cargando</p>
							)}
						</div>
						<div>
							{this.state.startupToEdit !== "" ? (
								<form
									onSubmit={this.submitUpdateStartup}
									className="form"
									key={this.state.startupToEditUid}
								>
									<Grid container>
										<Grid item={true} sm={12} xs={12}>
											<FormControl className="width-100">
												<InputLabel
													htmlFor={"name_contact_company"}
													shrink={true}
												>
													Nombre del Responsable*
												</InputLabel>
												<Input
													id={"name_contact_company"}
													name={"name_contact_company"}
													aria-describedby={"name_contact_company"}
													defaultValue={
														this.state.startupToEdit
															.name_contact_company_services
													}
													type="text"
													required
												/>
											</FormControl>
										</Grid>

										<Grid item={true} sm={12} xs={12}>
											<hr></hr>
											<p>
												¿Desea aceptar la solicitud para que estos servicios
												sean publicos?
											</p>
											<Select
												labelId="update_change_status_services"
												id="update_change_status_services"
												name="update_change_status_services"
												value={this.state.update_account}
												onChange={this.handleChangeStatus}
												placeholder="Seleccionar opcion"
												defaultValue={this.state.update_change_status_services}
											>
												<MenuItem value="Aceptada">Aceptar</MenuItem>
												<MenuItem value="Rechazada">Rechazar</MenuItem>
												<MenuItem value="Revision">Revision</MenuItem>
											</Select>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl className="width-100">
												<InputLabel htmlFor="industries" shrink={true}>
													Industria*
												</InputLabel>
												<Input
													id="industries"
													name="industries"
													aria-describedby="industries"
													type="text"
													required
													defaultValue={
														"industries" in this.state.startupToEdit
															? this.state.startupToEdit.industries
															: ""
													}
												/>
											</FormControl>
										</Grid>
										<Grid container>
											<h3>Productos</h3>
											{this.state.products_array.map((input, index) => (
												<Grid
													item={true}
													sm={12}
													xs={12}
													className="boxed"
													id={"product-" + index}
												>
													<Button
														onClick={() => this.deleteProduct(index)}
														className="buttonWarning"
														startIcon={<DeleteIcon />}
													>
														{" "}
														Borrar Producto
													</Button>
													<FormControl className="width-100">
														<InputLabel htmlFor={input + "-name"} shrink={true}>
															Nombre del producto o servicio*
														</InputLabel>
														<Input
															id={input + "-name"}
															name={input + "-name"}
															aria-describedby={input + "-name"}
															type="text"
															required
															defaultValue={
																index + 1 <=
																this.state.startupToEdit.services.length
																	? this.state.startupToEdit.services[index]
																			.name
																	: ""
															}
														/>
													</FormControl>
													<FormControl className="width-100">
														<InputLabel
															htmlFor={input + "-description"}
															shrink={true}
														>
															Descripción
														</InputLabel>
														<Input
															id={input + "-description"}
															name={input + "-description"}
															aria-describedby={input + "-description"}
															type="text"
															defaultValue={
																index + 1 <=
																this.state.startupToEdit.services.length
																	? this.state.startupToEdit.services[index]
																			.description
																	: ""
															}
														/>
													</FormControl>
													<FormControl className="width-100">
														<InputLabel
															htmlFor={input + "-price"}
															shrink={true}
														>
															Precio
														</InputLabel>
														<Input
															id={input + "-price"}
															name={input + "-price"}
															aria-describedby={input + "-price"}
															type="text"
															defaultValue={
																index + 1 <=
																this.state.startupToEdit.services.length
																	? this.state.startupToEdit.services[index]
																			.price
																	: ""
															}
														/>
													</FormControl>
													<FormControl className="width-100">
														<InputLabel
															htmlFor={input + "-discountPercentage"}
															shrink={true}
														>
															Descuento
														</InputLabel>
														<Input
															id={input + "-discountPercentage"}
															name={input + "-discountPercentage"}
															aria-describedby={input + "-discountPercentage"}
															type="text"
															defaultValue={
																index + 1 <=
																this.state.startupToEdit.services.length
																	? this.state.startupToEdit.services[index]
																			.discountPercentage
																	: ""
															}
														/>
													</FormControl>
													<FormControl className="width-100">
														<InputLabel
															htmlFor={input + "-discountedPrice"}
															shrink={true}
														>
															Precio con Descuento
														</InputLabel>
														<Input
															id={input + "-discountedPrice"}
															name={input + "-discountedPrice"}
															aria-describedby={input + "-discountedPrice"}
															type="text"
															defaultValue={
																index + 1 <=
																this.state.startupToEdit.services.length
																	? this.state.startupToEdit.services[index]
																			.discountedPrice
																	: ""
															}
														/>
													</FormControl>
												</Grid>
											))}
										</Grid>

										<Grid item={true} sm={12} xs={12} className="center">
											{" "}
											<Button
												variant="contained"
												color="primary"
												type="submit"
												className="button center"
												ref="buttonRegister"
											>
												Guardar
											</Button>
										</Grid>
									</Grid>
								</form>
							) : (
								<p></p>
							)}
						</div>
					</Grid>
				</Grid>
			</Container>
		);
	}
}
export default withSnackbar(listServices);
