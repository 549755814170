import React, { Component } from "react";
import {
	Grid,
	Container,
	InputLabel,
	Input,
	FormControl,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { getUserData, getUserToken } from "../../../utils/manageUserData";
import axios from "axios";
import { withSnackbar } from "notistack";
import getCroppedImg from "../../../utils/cropImage";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
class AddCrowdFunding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startupFounder: [],
			editStartup: "",
			choosenOptionMaturity: "",
			userToken: "",
			userData: "",
			imageSrc: "",
			imageToUpload: "",
			croppedImage: "",
			croppedImageFile: "",
			crop: { x: 0, y: 0 },
			croppedAreaPixels: "",
			uploadedImage: "",
			croppedArea: "",
			zoom: 1,
			aspect: 1 / 1,
			cropSize: { width: 300, height: 300 },
			products_array: [],
			teams_array: [],
			listStartupsConversation: [],
			longDescription: "",
		};
	}
	componentDidMount() {
		(async () => {
			await this.getUserInformation();
			await this.getUserToken();
		})();
		this.setState({
			longDescription: this.props.informationStartup.longDescription,
		});
		var products = [];
		var teams = [];

		var startup = this.props.informationStartup;
		if (startup.products) {
			startup.products.forEach((element, index, array) => {
				var name = "product-" + index;
				products.push(name);
			});
		}
		if (startup.managementTeam) {
			startup.managementTeam.forEach((element, index, array) => {
				var name = "team-" + index;
				teams.push(name);
			});
		}
		this.setState({
			products_array: products,
			teams_array: teams,
		});
	}
	getUserToken = async () => {
		var userToken = getUserToken();
		if (userToken !== false) {
			const obj = userToken;
			this.setState({
				userToken: obj,
			});
			this.getUserConversations(this.state.userData);
		}
	};
	getUserInformation = async () => {
		var userData = getUserData();

		if (userData !== false) {
			const obj = JSON.parse(userData);
			this.setState({
				userData: obj,
			});
			this.getUserFounder(obj);
		}
	};
	getUserConversations = (userInfo) => {
		console.log("Dentro de obtener mensajes");

		var apiurl = "/api/messages/" + userInfo.uid;
		axios({
			url: apiurl,
			method: "GET",
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				console.log(response);
				let arrayStartups = [];
				for (const item of response.data.messages) {
					if (item.user1.startupId) {
						const obj = {
							startupId: item.user1.startupId,
							startupName: item.user1.company,
						};
						arrayStartups.push(obj);
					}
				}
				console.log(arrayStartups);
				this.setState({
					listStartupsConversation: arrayStartups,
				});
			})
			.catch((error) => {
				console.log(error.response);
			});
	};
	getUserFounder = (userInfo) => {
		var apiurl = "/api/users/" + userInfo.uid + "/startups";
		axios({
			url: apiurl,
			method: "GET",
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				if (response.data.startups.length) {
					this.setState({
						isFounder: true,
						startupFounder: response.data.startups,
					});
				}
			})
			.catch((error) => {});
	};

	submitAlldata = (data) => {
		var apiurlput = "/api/startups/" + this.state.editStartup;
		let name = "";
		for (let item of this.state.listStartupsConversation) {
			if (item.startupId === this.state.editStartup) {
				name = item.startupName;
			}
		}

		const payload = {
			name: name,
			crowdFundingInformation: data.crowdFundingInformation,
		};
		console.log(payload);

		axios({
			url: apiurlput,
			method: "PUT",
			data: payload,
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				console.log(response);
				this.props.enqueueSnackbar("Datos guardados con éxito", {
					variant: "success",
				});
				this.getUserFounder(this.state.userData);
				this.props.getClickedElement("listado_crowdfunding");
			})
			.catch((error) => {
				console.log(error.response);
				alert("Hemos encontrado un error y no se han guardado los cambios");
			});
	};
	submitUpdateStartup = async (event) => {
		event.preventDefault();
		if (this.state.editStartup !== "") {
			let listTeam = [];
			var getTeams = new Promise((resolve, reject) => {
				this.state.teams_array.forEach((element, index, array) => {
					if (
						document.getElementById(element + "-name") !== null &&
						document.getElementById(element + "-name") !== "undefined"
					) {
						var name = document.getElementById(element + "-name").value;
						var position = document.getElementById(element + "-position").value;
						var order = document.getElementById(element + "-order").value;
						var linkedin = document.getElementById(element + "-linkedin").value;

						let obj = {
							name: name,
							position: position,
							order: order,
							linkedinUrl: linkedin,
						};
						listTeam.push(obj);
					}
					if (index === array.length - 1) resolve();
				});
			});

			getTeams.then(() => {
				console.log("All done!");
			});
			//console.log(listProducts);
			//check youtube url
			var url = event.target.crowd_startupVideo.value;

			if (url !== "") {
				var regExp =
					/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
				var match = url.match(regExp);
				if (match && match[2].length === 11) {
					url =
						"https://www.youtube.com/embed/" +
						match[2] +
						"?autoplay=0&enablejsapi=1";
				} else {
					this.props.enqueueSnackbar("El enlace a Youtube no es correcto", {
						variant: "error",
					});
					url = "";
				}
			}
			const crowdFundingInformation = {
				type: "Crowdfunding",
				oportunityExplanation: event.target.crowd_startupOportunity.value,
				stage: event.target.crowd_startupStage.value,
				industry: event.target.crowd_startupIndustry.value,
				otherInvestors: this.state.longDescription,
				managementTeam: listTeam,
				accelaeration: event.target.crowd_startupAceleraciones.value,
				reasons: event.target.crowd_startupMotivos.value,
				keywords: event.target.crowd_startupKeywords.value,
				videoPresentation: url,
				accepted: 0,
				permalinkStartupCrowd: this.state.editStartup.permalink,
			};

			const payload = {
				crowdFundingInformation,
			};
			console.log(payload);
			this.submitAlldata(payload);

			//this.props.getStartupInformation();
		}
	};
	handleClick = () => {
		this.props.enqueueSnackbar("Successfully fetched the data.");
	};
	onCropChange = (crop) => {
		this.setState({ crop });
	};

	onCropComplete = (croppedArea, croppedAreaPixels) => {
		this.setState({
			croppedArea: croppedArea,
			croppedAreaPixels: croppedAreaPixels,
		});
	};

	onZoomChange = (zoom) => {
		this.setState({ zoom });
	};
	handleChange = (event) => {
		var image = event.target.files[0];
		if (event.target.files[0]) {
			this.setState({
				uploadedImage: event.target.files[0],
				imageSrc: URL.createObjectURL(image),
			});
		} else {
			this.setState({
				uploadedImage: "",
				imageSrc: "",
			});
		}
	};
	showCroppedImage = async () => {
		const croppedImage = await getCroppedImg(
			this.state.imageSrc,
			this.state.croppedAreaPixels,
			this.state.rotation
		);

		let response = await fetch(croppedImage);
		let data = await response.blob();
		let metadata = {
			type: "image/png",
		};
		let file = new File([data], "Startup.png", metadata);

		//croppedImageFile.lastModifiedDate = new Date();
		//croppedImageFile.name = "uploadfile";

		this.setState({
			croppedImageFile: file,
		});
		this.setState({
			croppedImage: croppedImage,
		});
	};
	appendInput_product() {
		var newInput = `product-${this.state.products_array.length}`;

		this.setState((prevState) => ({
			products_array: prevState.products_array.concat([newInput]),
		}));
	}

	appendInput_team() {
		var newInput = `team-${this.state.teams_array.length}`;

		this.setState((prevState) => ({
			teams_array: prevState.teams_array.concat([newInput]),
		}));
	}

	deleteProduct = async (producto) => {
		alert("vas a borrar el producto: " + producto);

		document.getElementById("product-" + producto).remove();
	};

	deleteTeam = async (team) => {
		alert("vas a borrar el miembro: " + team);

		document.getElementById("team-" + team).remove();
	};

	handleChangeDescription = (value) => {
		this.setState({ longDescription: value });
	};
	modules = {
		toolbar: [
			["bold", "italic", "underline", "strike", "blockquote"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
			],
			["link"],
			["clean"],
		],
	};
	formats = [
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
	];
	startuphandleChange = (event) => {
		this.setState({ editStartup: event.target.value });
	};

	render() {
		const optionStartups = this.state.listStartupsConversation.map(
			(startup, index) => (
				<MenuItem value={startup.startupId} key={index}>
					{startup.startupName}
				</MenuItem>
			)
		);
		return (
			<Container>
				<Grid container className="borderShadow">
					<Grid item xs={12}>
						<div>
							<p>Seleccione una Startup del listado para continuar</p>
							<Select
								labelId="select-startup"
								id="select-startup"
								label="Seleccionar Startup"
								value={this.state.editStartup}
								onChange={this.startuphandleChange}
							>
								{optionStartups}
							</Select>
							{this.state.editStartup !== "" ? (
								<form
									onSubmit={this.submitUpdateStartup}
									className="form"
									key={this.state.editStartup}
								>
									<Accordion>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<Typography>Información Básica</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container>
												<Grid item={true} sm={12} xs={12}>
													<FormControl className="width-100">
														<InputLabel
															htmlFor="crowd_startupOportunity"
															shrink={true}
														>
															En qué consiste la oportunidad de inversión
														</InputLabel>
														<Input
															id="crowd_startupOportunity"
															name="crowd_startupOportunity"
															aria-describedby="crowd_startupOportunity"
															type="text"
														/>
													</FormControl>
												</Grid>
												<Grid item={true} sm={12} xs={12}>
													{" "}
													<FormControl className="width-100">
														<InputLabel
															htmlFor="crowd_startupStage"
															shrink={true}
														>
															Stage de la inversión
														</InputLabel>
														<Input
															id="crowd_startupStage"
															name="crowd_startupStage"
															aria-describedby="crowd_startupStage"
															type="text"
															defaultValue={this.state.editStartup.industries}
														/>
													</FormControl>
												</Grid>
												<Grid item={true} sm={12} xs={12}>
													{" "}
													<FormControl className="width-100">
														<InputLabel
															htmlFor="crowd_startupIndustry"
															shrink={true}
														>
															Mercados/ industria
														</InputLabel>
														<Input
															id="crowd_startupIndustry"
															name="crowd_startupIndustry"
															aria-describedby="crowd_startupIndustry"
															type="text"
														/>
													</FormControl>
												</Grid>
												<Grid item={true} sm={12} xs={12}>
													<FormControl className="width-100">
														<InputLabel
															htmlFor="crowd_startupVideo"
															shrink={true}
														>
															Video de presentación (Solo admite youtube)
														</InputLabel>
														<Input
															id="crowd_startupVideo"
															name="crowd_startupVideo"
															aria-describedby="crowd_startupVideo"
															type="url"
														/>
													</FormControl>
												</Grid>
												<Grid item={true} sm={12} xs={12}>
													<FormControl className="width-100">
														<p>Otros inversores actuales de la empresa</p>
														<ReactQuill
															name="longDescription"
															modules={this.modules}
															formats={this.formats}
															onChange={this.handleChangeDescription}
															defaultValue={this.state.longDescription}
														/>
													</FormControl>
												</Grid>

												<Grid item={true} sm={12} xs={12}>
													<FormControl className="width-100">
														<InputLabel
															htmlFor="crowd_startupAceleraciones"
															shrink={true}
														>
															Aceleraciones
														</InputLabel>
														<Input
															id="crowd_startupAceleraciones"
															name="crowd_startupAceleraciones"
															aria-describedby="crowd_startupAceleraciones"
															type="text"
														/>
													</FormControl>
												</Grid>
												<Grid item={true} sm={12} xs={12}>
													<FormControl className="width-100">
														<InputLabel
															htmlFor="crowd_startupMotivos"
															shrink={true}
														>
															Motivos por los que invertir
														</InputLabel>
														<Input
															id="crowd_startupMotivos"
															name="crowd_startupMotivos"
															aria-describedby="crowd_startupMotivos"
															type="text"
														/>
													</FormControl>
												</Grid>
												<Grid item={true} sm={12} xs={12}>
													<FormControl className="width-100">
														<InputLabel
															htmlFor="crowd_startupKeywords"
															shrink={true}
														>
															Palabras clave
														</InputLabel>
														<Input
															id="crowd_startupKeywords"
															name="crowd_startupKeywords"
															aria-describedby="crowd_startupKeywords"
															type="text"
														/>
													</FormControl>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>

									<Accordion>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<Typography>Miembros</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container>
												<h3>Miembros</h3>
												{this.state.teams_array.map((input, index) => (
													<Grid
														item={true}
														sm={12}
														xs={12}
														className="boxed"
														id={"team-" + index}
													>
														<Button
															onClick={() => this.deleteTeam(index)}
															className="buttonWarning"
															startIcon={<DeleteIcon />}
														>
															{" "}
															Borrar Miembro
														</Button>
														<FormControl className="width-100">
															<InputLabel
																htmlFor={input + "-name"}
																shrink={true}
															>
																Nombre de miembro*
															</InputLabel>
															<Input
																id={input + "-name"}
																name={input + "-name"}
																aria-describedby={input + "-name"}
																type="text"
																required
															/>
														</FormControl>
														<FormControl className="width-100">
															<InputLabel
																htmlFor={input + "-position"}
																shrink={true}
															>
																Cargo
															</InputLabel>
															<Input
																id={input + "-position"}
																name={input + "-position"}
																aria-describedby={input + "-position"}
																type="text"
															/>
														</FormControl>
														<FormControl className="width-100">
															<InputLabel
																htmlFor={input + "-linkedin"}
																shrink={true}
															>
																Url Linkedin
															</InputLabel>
															<Input
																id={input + "-linkedin"}
																name={input + "-linkedin"}
																aria-describedby={input + "-linkedin"}
																type="text"
															/>
														</FormControl>
														<FormControl className="width-100">
															<InputLabel
																htmlFor={input + "-order"}
																shrink={true}
															>
																Orden
															</InputLabel>
															<Input
																id={input + "-order"}
																name={input + "-order"}
																aria-describedby={input + "-order"}
																type="text"
															/>
														</FormControl>
													</Grid>
												))}
												<Grid item={true} sm={12} xs={12}>
													<p
														onClick={() => this.appendInput_team()}
														className="button"
													>
														Añadir Miembro
													</p>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>
									<Grid container>
										<Grid item={true} sm={12} xs={12} className="center">
											{" "}
											<Button
												variant="contained"
												color="primary"
												type="submit"
												className="button center"
												ref="buttonRegister"
											>
												Guardar
											</Button>
										</Grid>
									</Grid>
								</form>
							) : (
								<p></p>
							)}
						</div>
					</Grid>
				</Grid>
			</Container>
		);
	}
}
export default withSnackbar(AddCrowdFunding);
