import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import axios from 'axios';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import { Col, Container, Row } from 'react-bootstrap';

class Blog extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(props.location.search);

    this.state = {
      blog: [],
      ads: [],
      baseImgSrc: process.env.REACT_APP_API_URL_PROD + 'uploads/',
      totalPosts: 1,
      dataLoad: false,
      itemsPerPage: 9,
      numberOfPage: 1,
      beginItem: 0,
      endItem: 9,
      page: urlParams.get('page') || 1,
      limit: urlParams.get('limit') || 10,
      skip: urlParams.get('skip') || 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getBlog();
    this.getAds();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getBlog = async () => {
    const apiurl = '/api/blog/';

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            blog: response.data.blog,
            dataLoad: true,
          });
        }
      })
      .catch((error) => {});
  };

  handlePage = (event) => {
    console.log(event.selected);

    this.setState({
      beginItem: event.selected * this.state.itemsPerPage,
      endItem: event.selected * this.state.itemsPerPage + 9,
    });
  };

  printAds = (position) => {
    const { ads } = this.state;

    return ads.map((ad) => {
      if (ad.position === position) {
        return (
          <a
            href={ad.href}
            target="_blank"
            rel="noreferrer"
            className={`width-100 blog-ad-banner-a ${ad.position} ${ad.orientation}`}
          >
            <img
              src={this.state.baseImgSrc + ad.img}
              className={`width-100 blog-ad-banner ${ad.position} ${ad.orientation}`}
              alt="ad banner"
            />
          </a>
        );
      } else {
        return '';
      }
    });
  };

  getAds = () => {
    const apiurl = '/api/blog/admin/ads/';

    console.log('apiurl', apiurl);

    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        this.setState({
          ads: response.data.ads,
        });
        console.log(response.data.ads);
      })
      .catch((error) => {
        return '';
      });
  };

  render() {
    const leftAds = this.printAds('Izquierda');
    const rightAds = this.printAds('Derecha');
    const topAds = this.printAds('Top');

    const posts = this.state.blog.map((post, index) => {
      if (post) {
        return (
          <Col xs={12} lg={4} className="blog-card">
            <div
              className="companyBox pointer"
              onClick={() => (window.location.href = '/blog/' + post.permalink)}
            >
              <Row className="header-img">
                <Col xs={12}>
                  {post.headerImg ? (
                    <img
                      src={
                        process.env.REACT_APP_API_URL_PROD +
                        'uploads/' +
                        post.headerImg
                      }
                      alt={'post ' + post.title}
                      className="width-100 "
                    />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Row className="title">
                <Col>
                  <h4>{post.title}</h4>
                </Col>
              </Row>
              <Row className="content">
                <Col>
                  <div className="blog-content-card">
                    {post.htmlContent
                      .replace(/(<([^>]+)>)/gi, ' ')
                      .replace(/(&nbsp;)/gi, ' ')}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className=" button">
                    <span>Ver más</span>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        );
      } else {
        return '';
      }
    });

    return this.state.dataLoad !== true ? (
      <Container className="bodyWrapper" fluid>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </Container>
    ) : (
      <Container className="MuiContainer-root bodyWrapper MuiContainer-maxWidthLg">
        <Grid container>
          <Row>
            <Col lg={12}>
              <h1 className="seo-h1">Noticias innovación</h1>
              <h2 className="seo-h2">Noticias Startups</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="width-100">{topAds}</div>
            </Col>

            <Col lg={12} className="separatorTop">
              <Row>
                <Col sm={2} className="bannerAdsLAteral">
                  {leftAds}
                </Col>
                <Col sm={8} xs={12}>
                  <Row>{posts}</Row>
                </Col>
                <Col sm={2} className="bannerAdsLAteral">
                  {rightAds}
                </Col>
              </Row>
            </Col>
          </Row>

          <ReactPaginate
            previousLabel={'←'}
            nextLabel={'→'}
            pageCount={this.state.totalPosts}
            onPageChange={this.handlePage}
            containerClassName={'pagination'}
            previousLinkClassName={'pagination__link'}
            nextLinkClassName={'pagination__link'}
            disabledClassName={'pagination__link--disabled'}
            activeClassName={'pagination__link--active'}
          />
        </Grid>
      </Container>
    );
  }
}
export default Blog;
