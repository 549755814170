import React, { Component } from 'react';
import { Button, Grid } from '@material-ui/core';
import AuctionBid from './auctionBid';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import FormControl from '@material-ui/core/FormControl';
import Modal from 'react-bootstrap/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import './../../../styles/styles.css';

class Auction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auction: [],
      word: '',
      bid: '',
      errorRepeatedWord: 'none',
      showModal: false,
      displayBidDone: 'none',
    };
  }

  getAuctionBids = async () => {
    const { data } = await axios.get('/api/auction');
    this.setState({ auction: data.auction });
  };

  getAuctionBidsBySearchString = async () => {
    const payload = { word: this.state.searchString };
    const headers = {
      headers: {
        'x-token': this.props.userToken,
      },
    };

    console.log(payload);
    const { data } = await axios.post('/api/auction/words', payload, headers);
    return data;
  };

  submitSearch = async () => {
    console.log(`new search `);
    const newAuction = await this.getAuctionBidsBySearchString();
    this.setState({ auction: newAuction.auction });
  };

  componentDidMount() {
    this.getAuctionBids();
  }

  createBid = async () => {
    const apiUrl = '/api/auction';

    const headers = {
      'x-token': this.props.userToken,
      'content-type': 'application/json',
    };

    const createBidData = {
      word: this.state.word,
      initialBid: this.state.bid,
    };

    await axios
      .post(apiUrl, createBidData, {
        headers,
      })
      .then((response) => {
        console.log(response);
        this.getAuctionBids();
        // this.setState({ displayBidDone: true });

        this.handleClose();
      })
      .catch((error) => {
        alert('esta palabra ya existe');
        console.log(error);
      });
  };

  submitBid = async () => {
    const { bid } = this.state;
    const divisibleMin = 0.1;
    const divisibleBid = Number(bid / divisibleMin);

    console.log(`${bid} % ${divisibleMin} = `, divisibleBid);

    if (bid >= 0.2) {
      if (bid <= this.props.budget) {
        this.createBid();
      }
    } else {
      alert('Puja no válida.');
    }
  };

  setShowModal = () => {
    this.setState({
      showModal: true,
    });
  };
  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    return (
      <Container>
        <Row>
          <Button
            variant="contained"
            style={{
              background: '#6658ea',
              color: 'white',
              marginBottom: '2rem',
              marginLeft: '1rem',
            }}
            onClick={() =>
              this.setState({ showModal: true, displayBidDone: 'none' })
            }
          >
            Añadir puja
          </Button>
          <span style={{ marginLeft: '1rem' }}>
            Presupuesto actual: {parseFloat(this.props.budget).toFixed(2)} €
          </span>
        </Row>
        <Row>
          <span style={{ marginBottom: '1rem', marginLeft: '1rem' }}>
            Por cada visualización se cobrará el valor de la puja que haya
            realizado.
          </span>
        </Row>
        <Row>
          <FormControl className="search-home">
            <Input
              style={{
                marginBottom: '2.5rem',
                minWidth: '320px',
                marginLeft: '1rem',
              }}
              placeholder="Buscar Palabras"
              className="formOutline"
              name="q"
              value={this.state.searchString}
              onChange={(e) => this.setState({ searchString: e.target.value })}
              onKeyPress={(event) =>
                event.code === 'Enter' ? this.submitSearch() : ''
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />

            <Button type="submit" style={{ display: 'none' }}></Button>

            <Grid container></Grid>
          </FormControl>
        </Row>
        <Row>
          {this.state.auction.length > 0
            ? this.state.auction.map((bid, key) => {
                return (
                  <Col style={{ marginBottom: '2rem' }} key={key + bid._id}>
                    <AuctionBid
                      bid={bid}
                      key={bid._id}
                      budget={this.props.budget}
                      userToken={this.props.userToken}
                      getAuctionBids={this.getAuctionBids}
                    />
                  </Col>
                );
              })
            : ''}
        </Row>
        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Puja por palabras
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <div>
                  <Row style={{ padding: '2rem', width: '100%' }}>
                    <Row>
                      <Col xs={12} sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Introduce la palabra"
                          variant="outlined"
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            this.setState({ word: e.target.value })
                          }
                        />
                      </Col>

                      <Col xs={12} sm={12}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          style={{
                            color: 'red',
                            fontSize: '0.8rem',
                            fontWeight: '600',
                            display: this.state.errorRepeatedWord,
                          }}
                        >
                          * Esta palabra ya existe
                        </Typography>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '2rem' }}>
                      <Col xs={12} sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Introduce tu puja inicial"
                          variant="outlined"
                          style={{ width: '100%' }}
                          type="number"
                          inputProps={{ min: 0.2 }}
                          onChange={(e) =>
                            this.setState({ bid: e.target.value })
                          }
                        />
                      </Col>

                      <Col xs={12} sm={12}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          style={{
                            color: '#332c75',
                            fontSize: '0.8rem',
                            fontWeight: '600',
                          }}
                        >
                          * Puja mínima: 0.20 €
                        </Typography>
                      </Col>
                      <Col xs={12} sm={12}>
                        {/* <Typography
                        color="textSecondary"
                        gutterBottom
                        style={{
                          color: '#332c75',
                          fontSize: '0.8rem',
                          fontWeight: '600',
                        }}
                      >
                        * Tu puja debe ser múltiplo de 0.10
                      </Typography> */}
                        <p
                          style={{
                            marginTop: '1rem',
                            fontWeight: '600',
                            display: this.state.displayBidDone,
                          }}
                        >
                          Has pujado correctamente por una nueva palabra.
                        </p>
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <Button
                      variant="contained"
                      style={{
                        background: '#6658ea',
                        color: 'white',
                        display: 'table',
                        margin: '0 auto',
                      }}
                      onClick={this.submitBid}
                    >
                      Pujar
                    </Button>
                  </Row>
                </div>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Cerrar</Button>
          </Modal.Footer>
        </Modal>{' '}
      </Container>
    );
  }
}
export default Auction;
