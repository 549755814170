import React, { Component } from 'react';
import axios from 'axios';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, Box } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { Container } from '@material-ui/core';
import ReactPaginate from 'react-paginate';

class InversionesSecundarias extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(props.location.search);

    this.state = {
      searchString: urlParams.get('q') || '',
      baseSearchUrl: '/buscador-de-secundarias',
      investments: [],
      showInvestments: [],
      industries: [],
      itemsPerPage: 9,
      numberOfPage: 1,
      beginItem: 0,
      endItem: 9,
      page: urlParams.get('page') || 1,
      limit: urlParams.get('limit') || 10,
      skip: urlParams.get('skip') || 0,
      filterTags: [],
    };
  }

  componentDidMount() {
    (async () => {
      await this.getInvestments();
    })();
  }

  getInvestments = (userInfo) => {
    const { searchString, page, skip, limit } = this.state;

    let params =
      '?q=' +
      searchString +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&skip=' +
      skip;

    var apiurl = '/api/investment/filter/search' + params;

    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        console.log(response);
        var pages;

        if (response.data.total > 0) {
          pages = response.data.total / this.state.itemsPerPage;
        } else {
          pages = 1;
        }

        this.setState({
          investments: response.data.investments,
          showInvestments: response.data.investments,
          numberOfItems: response.data.total,
          numberOfPage: pages,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePage = (event) => {
    console.log(event.selected);

    this.setState({
      beginItem: event.selected * this.state.itemsPerPage,
      endItem: event.selected * this.state.itemsPerPage + 9,
    });
  };

  addFilterTag = (tag) => {
    let tempFilterTags = this.state.filterTags;

    if (!tempFilterTags.includes(tag)) {
      tempFilterTags.push(tag);

      this.setState({ filterTags: tempFilterTags });
    }

    this.filterInvestments(tempFilterTags);
  };

  removeFilterTag = (tag) => {
    let tempFilterTags = this.state.filterTags.filter((filterTag) => {
      if (filterTag !== tag) {
        return filterTag;
      }
      return '';
    });

    this.setState({ filterTags: tempFilterTags });

    this.filterInvestments(tempFilterTags);
  };

  filterInvestments = (filterTags) => {
    let tempInvestments = this.state.investments;

    if (filterTags.length === 0) {
      this.setState({ showInvestments: this.state.investments });
    } else {
      this.setState({ showInvestments: tempInvestments });
    }
  };

  goTo = (dest) => {
    console.log(dest);
  };

  getDiscount = (services) => {
    try {
      const percentage = Math.max.apply(
        Math,
        services.map(function (o) {
          return o.discountPercentage;
        })
      );
      return (
        <p className="topDiscount center">
          Hasta un <span className="dicountPercentage">{percentage}%</span> de
          descuento
        </p>
      );
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const investments = this.state.showInvestments
      .slice(this.state.beginItem, this.state.endItem)
      .map((investment, index) => {
        if (investment) {
          console.log(investment);
          return (
            <Grid item lg={3} sm={3} xs={12} key={index}>
              <a
                href={'/invertir-en-secundarias/' + investment.permalink}
                className="noDecoration companyBoxWrapper"
              >
                <div className="companyBox">
                  <Grid container>
                    <Grid item sm={12} xs={12}>
                      <h4>{investment.investorName}</h4>
                    </Grid>

                    <Grid item sm={12} xs={12}>
                      <div className=" button">
                        <span>Ver más...</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </a>
            </Grid>
          );
        } else {
          return '';
        }
      });

    return (
      <Container className="bodyWrapper">
        <section>
          <Grid container>
            <Grid item={true} xs={12} sm={12} className="left">
              <Box m={2}>
                <h1 className="seo-h1">Inversión de secundarias</h1>
                <h2 className="seo-h2">Invierte en las mejores secundarias</h2>
                <form method="GET">
                  <FormControl className="search-home">
                    <Input
                      style={{ marginTop: '2.5rem' }}
                      placeholder="Buscar Secundarias"
                      className="formOutline"
                      name="q"
                      value={this.state.searchString}
                      onChange={(e) =>
                        this.setState({ searchString: e.target.value })
                      }
                      onKeyPress={(event) =>
                        event.key === 'Enter' ? this.getInvestments : ''
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                    <Button type="submit" style={{ display: 'none' }}></Button>
                  </FormControl>
                </form>
              </Box>
            </Grid>

            <Grid container xs={12} sm={12} style={{ marginTop: '1rem' }}>
              <div
                className="industry-tag"
                style={{ position: 'relative' }}
                onClick={() => {
                  window.location.href = '/invertir-en-startups';
                }}
              >
                Crowdfunding
              </div>
              <div
                className="industry-tag-selected"
                style={{ position: 'relative' }}
                onClick={() => {
                  console.log('secundarias');
                }}
              >
                Secundarias
              </div>
            </Grid>

            <Grid container xs={12} sm={12}>
              {investments}
            </Grid>
            <ReactPaginate
              previousLabel={'←'}
              nextLabel={'→'}
              pageCount={this.state.numberOfPage}
              onPageChange={this.handlePage}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          </Grid>
        </section>
      </Container>
    );
  }
}
export default InversionesSecundarias;
