import React, { Component } from 'react';
import axios from 'axios';
import Contact from '../../../components/utils/contact';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { Container, Grid } from '@material-ui/core';
import {
  getUserData,
  getUserToken,
} from '../../../components/utils/manageUserData.js';
import AttachFile from '@material-ui/icons/AttachFile';

class ChallengePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      challenge: [],
      challengeId: props.match.params.id || null,
      userToken: '',
      dataLoaded: false,
      carouselIndex: 0,
      userData: '',
      company: [],
      founderData: [],
      startup: [],
    };
  }

  componentDidMount() {
    this.getChallenge();
    this.getUserInformation();
    this.setState({ userToken: getUserToken() });
  }

  getChallenge = async () => {
    console.log('userToken', getUserToken());
    const userToken = '';
    const { challengeId } = this.state;

    const apiurl = '/api/company/challenges/' + challengeId;

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.setState({
          challenge: response.data.challenge[0],
          dataLoad: true,
        });

        //     console.log('challenge data', response.data.challenge[0]);

        this.getCompany(response.data.challenge[0].companyId);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  getUserInformation = async () => {
    var userData = await getUserData();
    this.setState({
      loadingData: true,
    });
    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });

      this.getStartup(obj.uid);
    }
  };

  getFounderData = (userId) => {
    var apiurl = '/api/users/' + userId;
    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        this.setState({
          founderData: response.data.user,
        });
        // console.log('founder Data', response.data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCompany = async (id) => {
    const { userToken } = this.state;

    const apiurl = '/api/company/id/' + id;
    // console.log(apiurl);

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.setState({
          company: response.data.company[0],
        });
        // console.log('get company', response.data.company[0]);
        this.getFounderData(response.data.company[0].founder);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getStartup = async (id) => {
    const { userToken } = this.state;

    const apiurl = '/api/users/' + id + '/startups';
    console.log(apiurl);

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.setState({
          startup: response.data.startups[0],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { challenge } = this.state;

    return this.state.dataLoad !== true ? (
      <Container className="bodyWrapper">
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </Container>
    ) : (
      <Container className="bodyWrapper" alignItems="stretch">
        <Grid
          container
          className="w-100 float-left  startup"
          style={{ background: '#F3F8FF' }}
          alignItems="stretch"
        >
          <Grid className="float-left mainColorBg padding-10" sm={9} xs={12}>
            <h1 className="job-page-title">Reto</h1>
            <h2 className="job-page-subtitle">{challenge.challengeTitle}</h2>

            <Grid className="float-left mainColorBg" sm={12} xs={12}>
              <div className="challenge-zone">
                <h4>Descripción</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: challenge.challengeDescription,
                  }}
                />
              </div>
              <div className="challenge-zone">
                <h4>Condiciones</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: challenge.conditions,
                  }}
                />
              </div>
              <div className="challenge-zone">
                <h4>¿Qué ofrece?</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: challenge.offers,
                  }}
                />
              </div>
              <div className="challenge-zone">
                <a
                  rel="noreferrer"
                  href={
                    process.env.REACT_APP_API_URL_PROD +
                    '/api/uploads/' +
                    challenge.legalBasesDoc
                  }
                  target="_blank"
                >
                  Bases legales <AttachFile />
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid
            className="float-left secondaryColorBg  padding-10 "
            sm={3}
            xs={12}
          >
            <div className="w-100 ">
              <h2 className="black">
                {this.state.company.name ? this.state.company.name : ''}
              </h2>
            </div>
            <div className="w-100  ">
              <p>{}</p>
            </div>

            <p>
              {this.state.company.shortDescription
                ? this.state.company.shortDescription
                : ''}
            </p>

            <div className="margin-bottom-20">
              {this.state.founderData.uid !== undefined ? (
                <Contact
                  key={challenge._id + challenge.challengeTitle}
                  challengeId={challenge._id}
                  subject={'Contacto Reto'}
                  user2id={this.state.founderData.uid}
                  user2name={this.state.founderData.name}
                  user2company={
                    this.state.company !== [] ? this.state.company.name : ''
                  }
                  user2type={'Company'}
                  user2email={this.state.founderData.email}
                  user2phone={this.state.founderData.phone}
                  user2lastname={this.state.founderData.lastname}
                  collectionId={this.state.company._id}
                  userToken={this.state.userToken}
                  alternativeContactText="Deseo inscribirme"
                  alternativeContactTextLogin="Inicia sesión para inscribirte"
                  alternativeTextCantContact="Ya estás inscrito en este reto"
                  fromChallenge={true}
                  challengeCompanyId={challenge.companyId}
                  challengeStartupId={
                    this.state.startup !== undefined
                      ? this.state.startup.uid
                      : ''
                  }
                  challengeStartups={challenge.startups}
                />
              ) : (
                ''
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default ChallengePage;
