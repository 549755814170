import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, Box } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { Container } from '@material-ui/core';
import ReactPaginate from 'react-paginate';

class Jobs extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(props.location.search);

    this.state = {
      searchString: urlParams.get('q') || '',
      baseSearchUrl: '/buscador-de-startups',
      jobs: [],
      showJobs: [],
      industries: [],
      itemsPerPage: 9,
      numberOfPage: 1,
      beginItem: 0,
      endItem: 9,
      page: urlParams.get('page') || 1,
      limit: urlParams.get('limit') || 10,
      skip: urlParams.get('skip') || 0,
      filterTags: [],
      jobId: props.match.params.id || null,
      searcherType: urlParams.get('type') || 'ALL',
    };

    //	searcherType options: ALL, Prácticas, Empleo

    console.log('ID', this.state.jobId);
  }

  componentDidMount() {
    this.getJobs();
  }

  getJobs = () => {
    const { searchString, page, skip, limit, searcherType } = this.state;

    let params =
      '?q=' +
      searchString +
      '&type=' +
      searcherType +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&skip=' +
      skip;

    let apiurl = '/api/jobs/search' + params;

    console.log(apiurl);

    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        let pages;
        if (response.data.total) {
          pages = response.data.total / this.state.itemsPerPage;
        } else {
          pages = 1;
        }

        console.log(response);
        this.setState({
          jobs: response.data.jobs,
          showJobs: response.data.jobs,
          industries: response.data.industries,
          numberOfItems: response.data.total,
          numberOfPage: pages,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getJobsSubmit = (event) => {
    event.preventDefault();

    const { searchString, page, skip, limit, searcherType } = this.state;

    console.log('eh ' + searcherType);
    let params =
      '?q=' +
      searchString +
      '&type=' +
      searcherType +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&skip=' +
      skip;

    let apiurl = '/api/jobs/search' + params;

    console.log(apiurl);

    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        let pages;
        if (response.data.total) {
          pages = response.data.total / this.state.itemsPerPage;
        } else {
          pages = 1;
        }

        console.log(response);
        this.setState({
          jobs: response.data.jobs,
          showJobs: response.data.jobs,
          industries: response.data.industries,
          numberOfItems: response.data.total,
          numberOfPage: pages,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePage = (event) => {
    console.log(event.selected);

    this.setState({
      beginItem: event.selected * this.state.itemsPerPage,
      endItem: event.selected * this.state.itemsPerPage + 9,
    });
  };

  addFilterTag = (tag) => {
    let tempFilterTags = this.state.filterTags;

    if (!tempFilterTags.includes(tag)) {
      tempFilterTags.push(tag);

      this.setState({ filterTags: tempFilterTags });
    }

    this.filterJobs(tempFilterTags);
  };

  removeFilterTag = (tag) => {
    let tempFilterTags = this.state.filterTags.filter((filterTag) => {
      if (filterTag !== tag) {
        return filterTag;
      }
      return '';
    });

    this.setState({ filterTags: tempFilterTags });

    this.filterJobs(tempFilterTags);
  };

  filterJobs = (filterTags) => {
    let tempJobs = this.state.jobs;

    tempJobs = tempJobs.map((job) => {
      if (filterTags.includes(job.industries)) {
        return job;
      }
      return '';
    });

    if (filterTags.length === 0) {
      this.setState({ showJobs: this.state.jobs });
    } else {
      this.setState({ showJobs: tempJobs });
    }
  };

  changeJobs = async (type) => {
    console.log('type ' + type);
    await this.setState({ searcherType: type });
    console.log('AH ' + this.state.searcherType);
    this.getJobs();
  };

  render() {
    const jobs = this.state.showJobs
      .slice(this.state.beginItem, this.state.endItem)
      .map((job, index) => {
        if (job) {
          return (
            <Grid item lg={3} sm={3} xs={12} key={index}>
              <Link
                to={{
                  pathname: '/empleos/' + job._id,
                }}
                className="noDecoration companyBoxWrapper"
              >
                <div className="companyBox">
                  <Grid container>
                    <Grid item sm={12} xs={12}>
                      <h4>{job.startupName}</h4>
                    </Grid>

                    <Grid item sm={12} xs={12} style={{ marginBottom: '1rem' }}>
                      {job.startupImg ? (
                        <img
                          src={
                            process.env.REACT_APP_API_URL_PROD +
                            '/api/uploads/startups/' +
                            job.startupId
                          }
                          alt={job.startupPermalink}
                          className="width-100 companyDirectoryImage"
                        />
                      ) : (
                        <div className="noImage">
                          <BrokenImageIcon className="icon" fontSize="large" />
                        </div>
                      )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <h5>{job.job}</h5>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <h6>{job.startupIndustry}</h6>
                    </Grid>

                    <Grid item sm={12} xs={12}>
                      <div className=" button">
                        <span>Más información</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Link>
            </Grid>
          );
        } else {
          return '';
        }
      });

    const renderJobs = (
      <Container className="bodyWrapper">
        <section>
          <Grid container>
            <Grid item={true} xs={12} sm={12} className="left">
              <Box m={2}>
                <h1 className="seo-h1">Empleo</h1>
                <h2 className="seo-h2">
                  Encuentra empleo y prácticas en Startups
                </h2>
                <form
                  method="GET"
                  onSubmit={(event) => this.getJobsSubmit(event)}
                >
                  <FormControl className="search-home">
                    <Input
                      style={{ marginTop: '2.5rem' }}
                      placeholder="Buscar empleos"
                      className="formOutline"
                      name="q"
                      value={this.state.searchString}
                      onChange={(e) =>
                        this.setState({ searchString: e.target.value })
                      }
                      onKeyPress={(event) =>
                        event.key === 'Enter'
                          ? this.getJobs(this.state.searcherType, event)
                          : ''
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />

                    <Grid container xs={12} sm={12}>
                      <p style={{ fontSize: '0.9rem', margin: '0.5rem' }}>
                        {this.state.filterTags.length > 0
                          ? ' Filtrando por:'
                          : ''}
                      </p>
                      {this.state.filterTags.map((filterTag) => {
                        return (
                          <div
                            className="industry-tag-selected"
                            style={{ position: 'relative' }}
                            onClick={() => {
                              this.removeFilterTag(filterTag);
                            }}
                          >
                            {filterTag} <span className="equis-oscar"> X </span>
                          </div>
                        );
                      })}
                    </Grid>

                    <Button type="button" style={{ display: 'none' }}></Button>
                  </FormControl>
                </form>
              </Box>
            </Grid>

            <Grid container xs={12} sm={12}>
              <div
                className="industry-tag"
                style={
                  this.state.searcherType === 'ALL' ? { opacity: '0.6' } : {}
                }
                onClick={() => {
                  this.changeJobs('ALL');
                }}
              >
                Todos
              </div>
              <div
                className="industry-tag"
                style={
                  this.state.searcherType === 'Prácticas'
                    ? { opacity: '0.6' }
                    : {}
                }
                onClick={() => {
                  this.changeJobs('Prácticas');
                }}
              >
                Prácticas
              </div>
              <div
                className="industry-tag"
                style={
                  this.state.searcherType === 'Empleo' ? { opacity: '0.6' } : {}
                }
                onClick={() => {
                  this.changeJobs('Empleo');
                }}
              >
                Empleo
              </div>
            </Grid>
            <Grid container xs={12} sm={12}>
              {jobs}
            </Grid>
            <ReactPaginate
              previousLabel={'←'}
              nextLabel={'→'}
              pageCount={this.state.numberOfPage}
              onPageChange={this.handlePage}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          </Grid>
        </section>
      </Container>
    );
    return renderJobs;
  }
}
export default Jobs;
