import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import { GoogleLogout } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Button } from '@material-ui/core';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: '',
      facebookName: '',
      facebookEmail: '',
      facebookImage: '',
      showPassword: false,
      password: '',
    };
  }
  componentDidMount() {}

  handleClickShowPassword = (event) => {
    if (this.state.showPassword === false) {
      this.setState({
        showPassword: true,
      });
    } else {
      this.setState({
        showPassword: false,
      });
    }
  };
  render() {
    const responseGoogle = (response) => {
      this.setState({
        userData: response.profileObj,
      });
      console.log(response);
    };
    const responseGoogleFail = (response) => {
      console.log(response);
    };
    const logout = (response) => {
      this.setState({
        userData: '',
      });
      alert('Logout');
    };
    const userData = (
      <div>
        <h3>Log in completed</h3>
        <p>Email: {this.state.userData.email}</p>
        <p>Name: {this.state.userData.givenName}</p>
        <p>Lastname: {this.state.userData.familyName}</p>
        <p>Full name: {this.state.userData.name}</p>
        <p>GoogleId: {this.state.userData.googleId}</p>
      </div>
    );
    const faceData = (
      <div>
        <h3>Log in completed with Facebook</h3>
        <p>Email: {this.state.facebookEmail}</p>
        <p>Name: {this.state.facebookName}</p>
        <img src={this.state.facebookImage} alt="Profile"></img>
      </div>
    );
    const responseFacebook = (response) => {
      this.setState({
        facebookName: response.name,
        facebookEmail: response.email,
        facebookImage: response.picture.data.url,
      });
      console.log(response);
    };
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    return (
      <Container>
        <Grid container xs={12}>
          <Grid item={true} xs={3} sm={3}></Grid>
          <Grid item={true} xs={12} sm={6}>
            <div className="center clear width-100">
              <p>Login </p>
              <form noValidate autoComplete="off" className="form-login">
                <FormControl className="width-100">
                  <InputLabel htmlFor="standard-adornment-email">
                    Email
                  </InputLabel>
                  <Input id="email-login" type="email" />
                </FormControl>

                <br></br>
                <FormControl
                  className="width-100"
                  style={{ marginTop: '1rem' }}
                >
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="button"
                >
                  Login
                </Button>
              </form>
            </div>
            <p className="center">
              <a href="/registro">
                Si no tienes cuenta haz click aqui para ir al registro
              </a>
            </p>
            <p className="center">Login o registro con Redes Sociales</p>
            <div className="center clear width-100">
              <GoogleLogin
                clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogleFail}
                cookiePolicy={'single_host_origin'}
              />

              {this.state.userData !== '' ? (
                <GoogleLogout
                  clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
                  buttonText="Logout"
                  onLogoutSuccess={logout}
                ></GoogleLogout>
              ) : (
                ''
              )}
              {this.state.userData !== '' ? userData : ''}
              <br></br>
              <FacebookLogin
                appId="238403055163743"
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                icon="fa-facebook"
              />

              {this.state.facebookName !== '' ? faceData : ''}
            </div>
          </Grid>
          <Grid item={true} xs={3} sm={3}></Grid>
        </Grid>
      </Container>
    );
  }
}
export default Login;
