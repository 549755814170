import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import axios from 'axios';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Divider, Input } from '@material-ui/core';
import './../../../styles/styles.css';

import { Container, Row, Col } from 'react-bootstrap';

const useStyles = makeStyles({
  root: {
    width: 340,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  col: {
    width: '50%',
  },
  spanNumber: {
    marginRight: '1rem',
  },
  littleH5: {
    fontSize: '1.1rem',
  },
  littleH6: {
    fontSize: '0.9rem',
  },
});

export default function AuctionBid(props) {
  const classes = useStyles();
  const [newBid, setNewBid] = useState(false);
  const userToken = props.userToken;

  const submitBid = async () => {
    const apiUrl = '/api/auction';

    const headers = {
      'x-token': userToken,
      'content-type': 'application/json',
    };

    const updateBidData = {
      newBid,
      word: props.bid.word,
    };

    console.log(updateBidData);

    const minBid = Number(props.bid.maxBid);

    if (Number(newBid) > Number(minBid)) {
      if (Number(newBid) <= Number(props.budget)) {
        const { data: response } = await axios.put(apiUrl, updateBidData, {
          headers,
        });

        console.log(response);

        alert(`Has pujado por la palabra ${props.bid.word}: ${newBid} €`);
        props.getAuctionBids();
      } else {
        alert(
          `Error al pujar por la palabra ${props.bid.word}. Presupuesto insuficiente.`
        );
      }
    } else {
      console.log(Number(newBid), Number(props.bid.maxBid));

      alert(`Tu puja debe ser superior a  ${Number(props.bid.maxBid)} €`);
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent style={{ width: '100%' }}>
        <Container>
          <Row>
            <Col>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Palabra
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                className={classes.littleH5}
                style={{ wordWrap: 'break-word' }}
              >
                {props.bid.word}
              </Typography>
            </Col>
            <Col>
              <Typography
                variant="h5"
                component="h2"
                style={{ fontSize: '12px' }}
              >
                Puja actual
              </Typography>
              <Typography variant="h5" component="h2">
                {props.bid.maxBid} €
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                style={{ marginTop: '1rem' }}
              >
                Pujas realizadas
              </Typography>
              {/** Returns 3 first bids only  */}
              {props.bid.bids.map((singleBid, key) => {
                if (key < 3) {
                  return (
                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.littleH6}
                      key={key}
                    >
                      <span className={classes.spanNumber}>
                        {key + 1}º {singleBid.startupName} {singleBid.bid} €
                      </span>
                    </Typography>
                  );
                }
                return '';
              })}
            </Col>
          </Row>
        </Container>
      </CardContent>
      <Divider style={{ margin: '1rem' }} />
      <CardActions>
        <Input
          type="number"
          label="Outlined"
          variant="outlined"
          placeholder="00,00 "
          inputProps={{ min: Number(props.bid.maxBid) + 0.1 }}
          onChange={(e) => setNewBid(e.target.value)}
        />
        <span>€</span>
        <Button
          variant="contained"
          style={{ background: '#6658ea', color: 'white' }}
          onClick={submitBid}
        >
          Pujar
        </Button>
      </CardActions>
    </Card>
  );
}
