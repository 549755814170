import React, { Component } from 'react';
import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import getCroppedImg from '../../../utils/cropImage';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AttachFileIcon from '@material-ui/icons/AttachFile';

class addSellActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startupFounder: [],
      editInvestment: '',
      choosenOptionMaturity: '',
      userToken: '',
      userData: '',
      imageSrc: '',
      imageToUpload: '',
      croppedImage: '',
      croppedImageFile: '',
      crop: { x: 0, y: 0 },
      croppedAreaPixels: '',
      uploadedImage: '',
      croppedArea: '',
      zoom: 1,
      aspect: 1 / 1,
      cropSize: { width: 300, height: 300 },
      products_array: [],

      listStartupsConversation: [],
      longDescription: '',
      investmentList: [],
      // investment fields
      investor: '',
      investorName: '',
      document: '',
      oportunity: '',
      stage: '',
      industries: '',
      video: '',
      anotherInvestors: '',
      acceleration: '',
      reasonsToInvest: '',
      keywords: '',
      published: 0,
      members: [],
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
    })();
    this.setState({
      longDescription: this.props.informationStartup.longDescription,
    });
    var products = [];
    var teams = [];

    var startup = this.props.informationStartup;
    if (startup.products) {
      startup.products.forEach((element, index, array) => {
        var name = 'product-' + index;
        products.push(name);
      });
    }
    if (startup.managementTeam) {
      startup.managementTeam.forEach((element, index, array) => {
        var name = 'team-' + index;
        teams.push(name);
      });
    }
    this.setState({
      products_array: products,
      //  members: teams,
    });
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
      this.getUserConversations(this.state.userData);
    }
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
      this.getUserFounder(obj);
    }
  };
  getUserConversations = (userInfo) => {
    console.log('Dentro de obtener mensajes');

    var apiurl = '/api/investment';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        this.setState({
          investmentList: response.data.investment,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo.uid + '/startups';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.startups.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.startups,
          });
        }
      })
      .catch((error) => {});
  };

  submitUpdateStartup = async (event) => {
    event.preventDefault();
    if (this.state.editInvestment !== '') {
      let newMembers = [];
      var getTeams = new Promise((resolve, reject) => {
        this.state.members.forEach((element, index, array) => {
          if (
            document.getElementById(element + '-name') !== null &&
            document.getElementById(element + '-name') !== 'undefined'
          ) {
            var name = document.getElementById(element + '-name').value;
            var position = document.getElementById(element + '-position').value;
            var order = document.getElementById(element + '-order').value;
            var linkedin = document.getElementById(element + '-linkedin').value;

            let obj = {
              name: name,
              position: position,
              order: order,
              linkedinUrl: linkedin,
            };
            newMembers.push(obj);
          }
          if (index === array.length - 1) resolve();
        });
      });

      getTeams.then(() => {
        console.log('All done!');
      });

      var url = event.target.crowd_startupVideo.value;

      if (url !== '') {
        var regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length === 11) {
          url =
            'https://www.youtube.com/embed/' +
            match[2] +
            '?autoplay=0&enablejsapi=1';
        } else {
          // this.props.enqueueSnackbar('El enlace a Youtube no es correcto', {
          //   variant: 'error',
          // });
          // url = '';
        }
      }

      const payload = {
        investor: this.state.investor,
        investorName: this.state.investorName,
        // document: this.state.document,
        oportunity: this.state.oportunity,
        stage: this.state.stage,
        industries: this.state.industries,
        video: this.state.video,
        anotherInvestors: this.state.anotherInvestors,
        acceleration: this.state.acceleration,
        reasonsToInvest: this.state.reasonsToInvest,
        keywords: this.state.keywords,
        //  members: newMembers,
        published: this.state.published,
      };

      this.updateInvestment(this.state.editInvestment, payload);
    }
  };

  updateInvestment = async (id, payload) => {
    console.log('update investment');

    const apiurl = '/api/investment/' + id;

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const { data } = await axios.put(apiurl, payload, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.props.enqueueSnackbar('Datos de inversión actualizados', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  };

  handleClick = () => {
    this.props.enqueueSnackbar('Successfully fetched the data.');
  };
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedArea: croppedArea,
      croppedAreaPixels: croppedAreaPixels,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  handleChange = (event) => {
    var image = event.target.files[0];
    if (event.target.files[0]) {
      this.setState({
        uploadedImage: event.target.files[0],
        imageSrc: URL.createObjectURL(image),
      });
    } else {
      this.setState({
        uploadedImage: '',
        imageSrc: '',
      });
    }
  };
  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
      this.state.rotation
    );

    let response = await fetch(croppedImage);
    let data = await response.blob();
    let metadata = {
      type: 'image/png',
    };
    let file = new File([data], 'Startup.png', metadata);

    //croppedImageFile.lastModifiedDate = new Date();
    //croppedImageFile.name = "uploadfile";

    this.setState({
      croppedImageFile: file,
    });
    this.setState({
      croppedImage: croppedImage,
    });
  };
  appendInput_product() {
    var newInput = `product-${this.state.products_array.length}`;

    this.setState((prevState) => ({
      products_array: prevState.products_array.concat([newInput]),
    }));
  }

  appendInput_team() {
    var newInput = `team-${this.state.members.length}`;

    this.setState((prevState) => ({
      members: prevState.members.concat([newInput]),
    }));
  }

  deleteProduct = async (producto) => {
    alert('vas a borrar el producto: ' + producto);

    document.getElementById('product-' + producto).remove();
  };

  deleteTeam = async (team) => {
    alert('vas a borrar el miembro: ' + team);

    // document.getElementById('team-' + team).remove();

    const index = team;
    if (index > -1) {
      const newMembers = this.state.members;

      newMembers.splice(index, 1);
      console.log(newMembers);

      this.setState({ members: newMembers });
    }
  };

  handleChangeDescription = (value) => {
    this.setState({ anotherInvestors: value });
  };
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
    ],
  };
  formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];
  investmentHandleChange = async (event) => {
    const apiurl = '/api/investment/' + event.target.value;

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const { data } = await axios.get(apiurl, { headers });

    this.setState({
      editInvestment: event.target.value,
      investor: data.investment.investor,
      investorName: data.investment.investorName,
      document: data.investment.document,
      oportunity: data.investment.oportunity,
      stage: data.investment.stage,
      industries: data.investment.industries,
      video: data.investment.video,
      anotherInvestors: data.investment.anotherInvestors,
      acceleration: data.investment.acceleration,
      reasonsToInvest: data.investment.reasonsToInvest,
      keywords: data.investment.keywords,
      members: data.investment.members,
      published: data.investment.published === 1 ? 1 : 0,
    });
  };

  render() {
    const optionStartups = this.state.investmentList.map((inv) => (
      <MenuItem value={inv._id} key={inv._id}>
        {inv.investorName}
      </MenuItem>
    ));
    return (
      <Container>
        <Grid container className="borderShadow">
          <Grid item xs={12}>
            <div>
              <p>Seleccione una Startup del listado para continuar</p>
              <Select
                labelId="select-startup"
                id="select-startup"
                label="Seleccionar Startup"
                value={this.state.editInvestment}
                onChange={this.investmentHandleChange}
              >
                {optionStartups}
              </Select>

              {this.state.document ? (
                <AttachFileIcon style={{ marginLeft: '1rem' }} />
              ) : (
                ''
              )}

              {this.state.document ? (
                <a
                  href={
                    process.env.REACT_APP_API_URL_PROD +
                    '/uploads/' +
                    this.state.document
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  Ver fichero
                </a>
              ) : (
                ''
              )}

              {this.state.editInvestment !== '' ? (
                <form
                  onSubmit={this.submitUpdateStartup}
                  className="form"
                  key={this.state.editInvestment}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Información Básica</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel htmlFor="oportunity" shrink={true}>
                              En qué consiste la oportunidad de inversión
                            </InputLabel>
                            <Input
                              id="oportunity"
                              name="oportunity"
                              aria-describedby="crowd_startupOportunity"
                              type="text"
                              value={this.state.oportunity}
                              onChange={(e) =>
                                this.setState({ oportunity: e.target.value })
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupStage"
                              shrink={true}
                            >
                              Stage de la inversión
                            </InputLabel>
                            <Input
                              id="crowd_startupStage"
                              name="crowd_startupStage"
                              aria-describedby="crowd_startupStage"
                              type="text"
                              value={this.state.stage}
                              onChange={(e) =>
                                this.setState({ stage: e.target.value })
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupIndustry"
                              shrink={true}
                            >
                              Mercados/ industria
                            </InputLabel>
                            <Input
                              id="crowd_startupIndustry"
                              name="crowd_startupIndustry"
                              aria-describedby="crowd_startupIndustry"
                              type="text"
                              value={this.state.industries}
                              onChange={(e) =>
                                this.setState({ industries: e.target.value })
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupVideo"
                              shrink={true}
                            >
                              Video de presentación (Solo admite youtube)
                            </InputLabel>
                            <Input
                              id="crowd_startupVideo"
                              name="crowd_startupVideo"
                              aria-describedby="crowd_startupVideo"
                              type="url"
                              value={this.state.video}
                              onChange={(e) =>
                                this.setState({ video: e.target.value })
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <p>Otros inversores actuales de la empresa</p>
                            <ReactQuill
                              name="longDescription"
                              modules={this.modules}
                              formats={this.formats}
                              onChange={this.handleChangeDescription}
                              defaultValue={this.state.anotherInvestors}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupAceleraciones"
                              shrink={true}
                            >
                              Aceleraciones
                            </InputLabel>
                            <Input
                              id="crowd_startupAceleraciones"
                              name="crowd_startupAceleraciones"
                              aria-describedby="crowd_startupAceleraciones"
                              type="text"
                              value={this.state.acceleration}
                              onChange={(e) =>
                                this.setState({ acceleration: e.target.value })
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupMotivos"
                              shrink={true}
                            >
                              Motivos por los que invertir
                            </InputLabel>
                            <Input
                              id="crowd_startupMotivos"
                              name="crowd_startupMotivos"
                              aria-describedby="crowd_startupMotivos"
                              type="text"
                              value={this.state.reasonsToInvest}
                              onChange={(e) =>
                                this.setState({
                                  reasonsToInvest: e.target.value,
                                })
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupKeywords"
                              shrink={true}
                            >
                              Palabras clave
                            </InputLabel>
                            <Input
                              id="crowd_startupKeywords"
                              name="crowd_startupKeywords"
                              aria-describedby="crowd_startupKeywords"
                              type="text"
                              value={this.state.keywords}
                              onChange={(e) =>
                                this.setState({ keywords: e.target.value })
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel htmlFor="Publicado" shrink={true}>
                              Publicado
                            </InputLabel>
                            <Select
                              labelId="select-published"
                              id="select-published"
                              label="Publicado"
                              value={this.state.published}
                              onChange={(e) => {
                                this.setState({ published: e.target.value });
                              }}
                            >
                              <MenuItem value={0} key={0}>
                                No
                              </MenuItem>
                              <MenuItem value={1} key={1}>
                                Si
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Miembros</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <h3>Miembros</h3>
                        {this.state.members.map((input, index) => (
                          <Grid
                            item={true}
                            sm={12}
                            xs={12}
                            className="boxed"
                            id={'team-' + index}
                          >
                            <Button
                              onClick={() => this.deleteTeam(index)}
                              className="buttonWarning"
                              startIcon={<DeleteIcon />}
                            >
                              {' '}
                              Borrar Miembro
                            </Button>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-name'}
                                shrink={true}
                              >
                                Nombre de miembro*
                              </InputLabel>
                              <Input
                                id={input + '-name'}
                                name={input + '-name'}
                                aria-describedby={input + '-name'}
                                type="text"
                                value={input.name}
                                required
                              />
                            </FormControl>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-position'}
                                shrink={true}
                              >
                                Cargo
                              </InputLabel>
                              <Input
                                id={input + '-position'}
                                name={input + '-position'}
                                aria-describedby={input + '-position'}
                                type="text"
                                value={input.position}
                              />
                            </FormControl>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-linkedin'}
                                shrink={true}
                              >
                                Url Linkedin
                              </InputLabel>
                              <Input
                                id={input + '-linkedin'}
                                name={input + '-linkedin'}
                                aria-describedby={input + '-linkedin'}
                                type="text"
                                value={input.linkedinUrl}
                              />
                            </FormControl>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-order'}
                                shrink={true}
                              >
                                Orden
                              </InputLabel>
                              <Input
                                id={input + '-order'}
                                name={input + '-order'}
                                aria-describedby={input + '-order'}
                                type="text"
                                value={input.order}
                              />
                            </FormControl>
                          </Grid>
                        ))}
                                                 
                        <Grid item={true} sm={12} xs={12}>
                          <p
                            onClick={() => this.appendInput_team()}
                            className="button"
                          >
                            Añadir Miembro
                          </p>
                        </Grid> 
                      </Grid>
                    </AccordionDetails>
                  </Accordion> */}
                  <Grid container>
                    <Grid item={true} sm={12} xs={12} className="center">
                      {' '}
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="button center"
                        ref="buttonRegister"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <p></p>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(addSellActions);
