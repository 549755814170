import React, { Component } from 'react';
import {
  Grid,
  Container,
  Input,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import 'react-quill/dist/quill.snow.css';
import { DataGrid } from '@material-ui/data-grid';
import Moment from 'react-moment';

class inversionsCrowd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToken: '',
      userData: '',
      userStartup: '',
      startupToEdit: '',
      imageToUpload: '',
      croppedImage: '',
      croppedImageFile: '',
      crop: { x: 0, y: 0 },
      croppedAreaPixels: '',
      uploadedImage: '',
      croppedArea: '',
      zoom: 1,
      aspect: 1 / 1,
      cropSize: { width: 300, height: 300 },
      products_array: [],
      teams_array: [],
      editStartup: '',
      longDescription: 0,
      listCrowdStartups: [],
      sellCompany: '',
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
    })();
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
      this.getUserConversations(this.state.userData);
    }
  };
  getUserConversations = (userInfo) => {
    var apiurl = '/api/messages/' + userInfo.uid;
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        let arrayItem = [];
        for (let item of response.data.messages) {
          if (item.subject === 'Venta acciones') {
            arrayItem.push(item);
          }
        }

        this.setState({
          listCrowdStartups: arrayItem,
        });
      })
      .catch((error) => {});
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
    }
  };

  submitSellActions = async (event) => {
    event.preventDefault();
    let pdfDownloaded;
    if (document.getElementById('document_actions').files.length) {
      let formData = new FormData();
      formData.append(
        'file',
        document.getElementById('document_actions').files[0]
      );
      pdfDownloaded = await axios
        .post('/api/uploads', formData)
        .then((res) => {
          return res.data.fileName;
        })
        .catch((error) => {
          alert('Se ha encontrado un error  y no se ha subido la imagen');
        });

      const payload = {
        subject: 'Venta acciones',
        user1: {
          _id: this.state.userData.uid,
          type: 'sell_actions',
          name: this.state.userData.name,
          phone: this.state.userData.phone,
          email: this.state.userData.email,
        },
        nameStartupSellActions: event.target.nameCompany.value,
        docSellActions: pdfDownloaded,
      };

      const apiurlpost = '/api/messages/sellActions';
      axios({
        url: apiurlpost,
        method: 'POST',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          this.props.enqueueSnackbar(
            'Proceso iniciado, Puede seguir el estado en la seccion de mensajeria',
            {
              variant: 'success',
            }
          );
          this.props.getClickedElement('chat');
        })
        .catch((error) => {
          this.props.enqueueSnackbar(
            'Hemos encontrado un error y no se han guardado los cambios',
            {
              variant: 'error',
            }
          );
        });
    } else {
      this.props.enqueueSnackbar('El documento es obligatorio', {
        variant: 'error',
      });
    }
  };

  render() {
    const columns = [
      {
        field: 'nameStartupSellActions',
        headerName: 'Nombre de compañia',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },
      },

      {
        field: 'docSellActions',
        headerName: 'Documento',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <a
                href={
                  process.env.REACT_APP_API_URL_PROD + 'uploads/' + params.value
                }
                target="_blank"
                rel="noreferrer"
              >
                Ver documento
              </a>
            </div>
          );
        },
      },
      {
        field: 'createdAt',
        headerName: 'Fecha',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <Moment format="DD/MM/YYYY">{params.value}</Moment>
            </div>
          );
        },
      },
    ];
    return (
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <form onSubmit={this.submitSellActions}>
              <p>
                Para vender sus acciones rellene el formulario con el nombre de
                la compañia y el documento que acredite que posee dichas
                acciones.
              </p>
              <Grid item={true} xs={12} sm={12}>
                <FormControl className="width-100">
                  <InputLabel htmlFor="nameCompany">
                    Nombre de la compañia{' '}
                  </InputLabel>
                  <Input
                    id="nameCompany"
                    name="nameCompany"
                    aria-describedby="nameCompany"
                    type="text"
                    required
                  />
                </FormControl>{' '}
                <p>
                  <br />
                </p>
                <FormControl className="width-100">
                  <InputLabel htmlFor="document_actions" shrink={true}>
                    Puedes adjuntar tu acreditacion de acciones en la empresa
                  </InputLabel>
                  <Input
                    id={'document_actions'}
                    name={'document_actions'}
                    aria-describedby={'document_actions'}
                    type="file"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item={true} sm={12} xs={12} className="center">
                {' '}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="button center"
                  ref="buttonRegister"
                >
                  Guardar
                </Button>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12}>
            <p>Peticiones de venta de acciones realizadas</p>
            <div>
              <DataGrid
                rows={this.state.listCrowdStartups}
                getRowId={(row) => row.createdAt}
                autoHeight
                columns={columns}
                pageSize={5}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(inversionsCrowd);
