import React, { Component } from 'react';
import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { updateDataUser } from '../../../utils/manageUserData';
import { withSnackbar } from 'notistack';

class EditSecondaryUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
    };
  }
  componentDidMount() {
    this.setState({
      userData: this.props.informationUser,
    });
  }
  updateUserData = (event) => {
    event.preventDefault();

    const payload = {
      name: event.target.update_user_name.value,
      lastName: event.target.update_user_lastname.value,
      email: event.target.update_user_email.value,
      role: this.state.userData.role,
    };

    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.props.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        this.props.enqueueSnackbar('Datos guardados con éxito', {
          variant: 'success',
        });
        //window.location.reload();
        //this.emailVerification(email);
      })
      .catch((error) => {
        this.props.enqueueSnackbar('Error al guardar los datos', {
          variant: 'error',
        });
        console.log(error.response);
        console.log('Error');
      });
  };
  render() {
    return (
      <Container>
        <Grid container className="borderShadow">
          <Grid item xs={12}>
            {' '}
            <form
              onSubmit={this.updateUserData}
              className="form"
              key={this.state.userData}
            >
              <Grid container>
                <Grid item={true} sm={6} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_name">Nombre </InputLabel>
                    <Input
                      id="update_user_name"
                      name="update_user_name"
                      aria-describedby="update_user_name"
                      defaultValue={this.state.userData.name}
                      type="text"
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_lastname">
                      Apellidos{' '}
                    </InputLabel>
                    <Input
                      id="update_user_lastname"
                      name="update_user_lastname"
                      aria-describedby="update_user_lastname"
                      type="text"
                      defaultValue={this.state.userData.lastName}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_lastname">
                      Email{' '}
                    </InputLabel>
                    <Input
                      id="update_user_email"
                      name="update_user_email"
                      aria-describedby="update_user_email"
                      type="email"
                      defaultValue={this.state.userData.email}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={12} xs={12} className="center">
                  {' '}
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="button center"
                    ref="buttonRegister"
                  >
                    Guardar y continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(EditSecondaryUser);
