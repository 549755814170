import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import SidebarProfile from './components/sidebar_Profile';
import EditStartup from './components/basic/edit_Startup';
import EditCompany from './components/basic/edit_Company';
import EditServices from './components/basic/edit_Services';
import EditUser from './components/basic/edit_User';
import EditUserCrowd from './components/basic/edit_User_Crowd';
import EditSecondaryUser from './components/basic/edit_secondary_user';
import PublishUpdate from './components/basic/publish_Update';
import ProfileLanding from './components/profile_landing';
import { getUserData, getUserToken } from '../utils/manageUserData';
import axios from 'axios';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import ListadoStartup from './components/admin/list_startups';
import ListadoEmpresas from './components/admin/list_companies';
import ListadoUsuarios from './components/admin/list_users';
import ListadoServicios from './components/admin/list_services';
import ListadoCrowdfunding from './components/crowd/list_crowdfunding';
import AnadirCrowdfunding from './components/crowd/add_crowdfunding';
import AnadirCredito from './components/basic/add_credit';
import CrearStartup from './components/basic/create_Startup';
import ChatMessages from './components/basic/chat';
import Auction from './components/auction';
import './../../styles/styles.css';
import MenuTopProfile from './components/topMenuProfile';
import AuctionHistory from './components/auctionHistory';
import AuctionSpending from './components/auctionSpending';
import ListTransactions from './components/admin/list_transactions';
import ListTransactionsCompany from './components/admin/list_transactions_company';
import AskCrowdFunding from './components/basic/askCrowdFunding';
import ProfileJob from './components/profileJobs';
import EditJob from './components/editJob';
import JobCandidates from './components/jobCandidates';
import RegistroForm2 from '../form/basicInformation/register_user_2';
import RegistroForm3 from '../form/basicInformation/register_user_3';
import HighlightCrowd from './components/basic/highlightCrowd';
import JobCandidate from './components/jobCandidate';
import JobApplications from './components/jobApplications';
import SolicitadasCrowd from './components/crowd/list_solicited_petition';
import InversionsCrowd from './components/crowd/inversiones_crowd';
import SellActionsAdd from './components/sellActions/add_sellActions';
import ListSellActions from './components/sellActions/list_sellActions';
import Challenge from './components/challenge';
import ChallengeEdit from './components/challengeEdit';
import ChatMessagesPrivate from './components/private_chat/chat';
import CreatePrivateChat from './components/private_chat/create_chat';
import ChallengeStartup from './components/challengeStartup';
import CreateUser from './components/admin/create_user';
import ListChallenges from './components/admin/list_challenges';
import CreateFirstStartup from './components/admin/create_first_startup';
import BlogEntries from './components/admin/blog_entries';
import BlogCreate from './components/admin/blog_create';
import BlogEdit from './components/admin/blog_edit';
import BlogBanners from './components/admin/blog_banners';
import ListadoEmpleos from './components/admin/list_jobs';
import Newsletter from './components/admin/newsletter';
import BlogBannerCreate from './components/admin/blog_banner_create';
import BlogBannerEdit from './components/admin/blog_banner_edit';
import ForumThread from './components/forumThread';
import ForumThreadCreate from './components/forumThreadCreate';

class ProfileUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: '',
      levelUser: 0,
      choosedOption: '',
      startupFounder: [],
      editStartup: [],
      choosenOptionMaturity: '',
      userToken: '',
      loadingData: false,
      menuProfileOpen: 0,
      anchorEl: '',
      extraProps: null,
    };
  }
  componentDidMount() {
    const windowUrl = window.location.search;
    const urlParams = new URLSearchParams(windowUrl);
    const goToChat = urlParams.get('goChat');

    console.log(goToChat);
    if (goToChat === 'true') {
      this.setState({
        choosedOption: 'chat',
      });
    }
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
    })();
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    this.setState({
      loadingData: true,
    });
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
    this.setState({
      loadingData: false,
    });
  };
  getUserInformation = async () => {
    var userData = getUserData();
    this.setState({
      loadingData: true,
    });
    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });

      this.getUserFounder(obj);
    }
    this.setState({
      loadingData: false,
    });
  };
  getUserInformationRefresh = async () => {
    var userData = getUserData();
    this.setState({
      loadingData: true,
    });
    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });

      this.getUserFounderRefresh(obj);
    }
    this.setState({
      loadingData: false,
    });
  };
  getClickedElement = (element, extraProps = null) => {
    console.log(element);

    this.setState({
      choosedOption: element,
      extraProps,
    });
  };
  getUserFounder = (userInfo) => {
    if (
      userInfo.role === 'SECONDARY_USER_ADMIN' ||
      userInfo.role === 'USER_OFFER_SERVICES'
    ) {
      var apiurl2 = '/api/users/' + userInfo.uid + '/company';

      axios({
        url: apiurl2,
        method: 'GET',
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          if (response.data.companies.length) {
            this.setState({
              isFounder: true,
              startupFounder: response.data.companies,
              editStartup: response.data.companies[0],
            });
            this.checkCompletedSteps();
          }
        })
        .catch((error) => {});
    } else {
      var apiurl = '/api/users/' + userInfo.uid + '/startups';
      axios({
        url: apiurl,
        method: 'GET',
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          console.log(response);
          if (response.data.startups.length) {
            this.setState({
              isFounder: true,
              startupFounder: response.data.startups,
              editStartup: response.data.startups[0],
            });
            this.checkCompletedSteps();
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  getUserFounderRefresh = (userInfo) => {
    if (
      userInfo.role === 'SECONDARY_USER_ADMIN' ||
      userInfo.role === 'USER_OFFER_SERVICES'
    ) {
      var apiurl2 = '/api/users/' + userInfo.uid + '/company';

      axios({
        url: apiurl2,
        method: 'GET',
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          if (response.data.companies.length) {
            this.setState({
              isFounder: true,
              startupFounder: response.data.companies,
              editStartup: response.data.companies[0],
            });
            this.checkCompletedSteps();
          }
        })
        .catch((error) => {});
    } else {
      var apiurl = '/api/users/' + userInfo.uid + '/startups';
      axios({
        url: apiurl,
        method: 'GET',
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          console.log(response);
          if (response.data.startups.length) {
            this.setState({
              isFounder: true,
              startupFounder: response.data.startups,
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  selectStartup = (event) => {
    console.log(event.target.value);
    if (event.target.value !== '') {
      this.setState({
        editStartup: this.state.startupFounder[event.target.value],
      });
    } else {
      this.setState({
        editStartup: '',
      });
    }
    this.refreshState();
  };
  changeStartup = (event) => {
    event.preventDefault();
    if (event.target.choosedStartup.value !== '') {
      this.setState({
        editStartup:
          this.state.startupFounder[event.target.choosedStartup.value],
      });
    } else {
      this.setState({
        editStartup: '',
      });
    }
    this.refreshState();
  };
  refreshState = () => {
    var date = new Date();
    this.setState({
      refreshed: date,
    });
  };
  handleOpenMenu = (event) => {
    if (this.state.menuProfileOpen === 0) {
      this.setState({
        menuProfileOpen: 1,
        anchorEl: event.currentTarget,
      });
    } else {
      this.setState({
        menuProfileOpen: 0,
        anchorEl: '',
      });
    }
  };
  handleChooseOption = (option) => {
    if (option) {
      this.setState({
        choosedOption: option,
      });
    }
  };

  render() {
    const startups = this.state.startupFounder.map((startup, index) => {
      if (index === 0) {
        return (
          <h3 value={index} data-position={index} className="center">
            {startup.name}
          </h3>
        );
      } else {
        return '';
      }
    });

    return (
      <div className="profileUser bodyWrapper">
        <Container>
          {this.state.getUserInformation === true ? (
            <Loader type="Puff" color="#00BFFF" height={100} width={100} />
          ) : (
            <Grid container>
              <Grid item xs={12} sm={12}></Grid>

              <Grid item xs={12} sm={3} className="sidebarProfile mainColorBg">
                <Grid container></Grid>
                <div key={this.state.startupFounder}>
                  {this.props.userData.role === 'ADMIN' ? (
                    ''
                  ) : (
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        {startups}
                      </Grid>
                    </Grid>
                  )}
                </div>
                <Grid item xs={12}>
                  <div className="divider"></div>
                </Grid>
                <SidebarProfile
                  getClickedElement={this.getClickedElement}
                  roleUser={this.props.userData.role}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                key={this.state.choosedOption + this.state.refreshed}
                className=" spacingComponents"
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <MenuTopProfile
                      getClickedElement={this.getClickedElement}
                      nameUser={this.props.userData.name}
                      roleUser={this.props.userData.role}
                    />
                  </Grid>
                </Grid>
                {this.state.choosedOption === '' ||
                this.state.choosedOption === 'profile_landing' ? (
                  <ProfileLanding
                    informationStartup={this.state.editStartup}
                    informationUser={this.state.userData}
                    getClickedElement={this.getClickedElement}
                    key={this.state.userData + this.state.editStartup}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'anadir_Crowdfunding' ? (
                  <AnadirCrowdfunding
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                    informationStartup={this.state.editStartup}
                    getStartupInformation={this.getUserInformationRefresh}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'listado_crowdfunding' ? (
                  <ListadoCrowdfunding
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'secundarias_crowdfunding' ? (
                  <InversionsCrowd
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'solicitada_crowd' ? (
                  <SolicitadasCrowd
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'listado_usuarios' ? (
                  <ListadoUsuarios
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'crear_startups' ? (
                  <CrearStartup
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                    getStartupInformation={this.getUserInformationRefresh}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'anadir_credito' ? (
                  <AnadirCredito
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                    informationStartup={this.state.editStartup}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'listado_startups' ? (
                  <ListadoStartup
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'listado_empresas' ? (
                  <ListadoEmpresas
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'solicitud_servicio' ? (
                  <ListadoServicios
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                    getStartupInformation={this.getUserInformationRefresh}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'editar_startups' ? (
                  <EditStartup
                    informationStartup={this.state.editStartup}
                    getStartupInformation={this.getUserInformationRefresh}
                    key={this.state.editStartup}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'editar_compania' ? (
                  <EditCompany
                    informationStartup={this.state.editStartup}
                    getStartupInformation={this.getUserInformationRefresh}
                    key={this.state.editStartup}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'editar_servicios' ? (
                  <EditServices
                    informationStartup={this.state.editStartup}
                    getStartupInformation={this.getUserInformationRefresh}
                    key={this.state.editStartup}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'publicar_actualizacion' ? (
                  <PublishUpdate
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'edit_profile_look_intern' ? (
                  <RegistroForm3
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    inProfile={1}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'edit_profile_look_job' ? (
                  <RegistroForm2
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    inProfile={1}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'alcance' ? <EditUser /> : ''}
                {this.state.choosedOption === 'editar_usuario' ? (
                  <EditUser
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'editar_usuario_crowd' ? (
                  <EditUserCrowd
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'edit_profile_user_newsletter' ? (
                  <EditSecondaryUser
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'chat' ? (
                  <ChatMessages
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {/* Editado por javi */}
                {this.state.choosedOption === 'puja_por_palabras' ? (
                  <Auction
                    budget={this.state.editStartup.budget}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'ofertas_de_empleo' ? (
                  <ProfileJob
                    startup={this.state.editStartup}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'newsletter' ? (
                  <Newsletter
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'editar_oferta_empleo' ? (
                  <EditJob
                    startup={this.state.editStartup}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                    extraProps={this.state.extraProps}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'oferta_empleo_ver_candidatos' ? (
                  <JobCandidates
                    startup={this.state.editStartup}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                    extraProps={this.state.extraProps}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption ===
                'oferta_empleo_ver_candidatos_single' ? (
                  <JobCandidate
                    startup={this.state.editStartup}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                    extraProps={this.state.extraProps}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'historial_pujas' ? (
                  <AuctionHistory
                    budget={this.state.editStartup.budget}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    collection={this.state.editStartup}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'destacar_ficha' ? (
                  <HighlightCrowd
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                    informationStartup={this.state.editStartup}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'gasto_por_busqueda' ? (
                  <AuctionSpending
                    budget={this.state.editStartup.budget}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    collection={this.state.editStartup}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'user_view_job_application' ? (
                  <JobApplications
                    startup={this.state.editStartup}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                    userData={this.state.userData}
                  />
                ) : (
                  ''
                )}

                {/* Fin javi */}
                {this.state.choosedOption ===
                'listado_transacciones_startups' ? (
                  <ListTransactions
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                    getStartupInformation={this.getUserInformationRefresh}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption ===
                'listado_transacciones_company' ? (
                  <ListTransactionsCompany
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                    getStartupInformation={this.getUserInformationRefresh}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'pedir_crowdfunding' ? (
                  <AskCrowdFunding
                    informationStartup={this.state.editStartup}
                    getStartupInformation={this.getUserInformationRefresh}
                    key={this.state.editStartup}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'secundarias_crear_venta' ? (
                  <SellActionsAdd
                    informationStartup={this.state.editStartup}
                    getStartupInformation={this.getUserInformationRefresh}
                    key={this.state.editStartup}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'solicitada_ventaAcciones' ? (
                  <ListSellActions
                    informationStartup={this.state.editStartup}
                    getStartupInformation={this.getUserInformationRefresh}
                    key={this.state.editStartup}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'reto' ? (
                  <Challenge
                    startup={this.state.editStartup}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'reto_ver_single' ? (
                  <ChallengeEdit
                    startup={this.state.editStartup}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                    extraProps={this.state.extraProps}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'ver_mensajeria_interna' ? (
                  <ChatMessagesPrivate
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'reto_startups' ? (
                  <ChallengeStartup
                    startup={this.state.editStartup}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'reto_admin' ? (
                  <ListChallenges
                    startup={this.state.editStartup}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'create_first_startup' ? (
                  <CreateFirstStartup
                    startup={this.state.editStartup}
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'crear_mensajeria_interna' ? (
                  <CreatePrivateChat
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'crear_usuarios' ? (
                  <CreateUser
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'forum_crear_hilo' ? (
                  <ForumThreadCreate
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'blog_entradas' ? (
                  <BlogEntries
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'blog_ads' ? (
                  <BlogBanners
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'blog_create_post' ? (
                  <BlogCreate
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'blog_create_banner' ? (
                  <BlogBannerCreate
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'blog_edit_banner' ? (
                  <BlogBannerEdit
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                    extraProps={this.state.extraProps}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'forum' ? (
                  <Redirect
                    to={{
                      pathname: '/blog-secreto',
                      state: {
                        visible: true,
                        userToken: this.state.userToken,
                        userData: this.state.userData,
                      },
                    }}
                  />
                ) : (
                  //  <Forum
                  //     informationUser={this.state.userData}
                  //     userToken={this.state.userToken}
                  //     getClickedElement={this.getClickedElement}
                  //     extraProps={this.state.extraProps}
                  //   />
                  ''
                )}

                {this.state.choosedOption === 'forum_ver_hilo' ? (
                  <ForumThread
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                    extraProps={this.state.extraProps}
                  />
                ) : (
                  ''
                )}

                {this.state.choosedOption === 'listado_empleo' ? (
                  <ListadoEmpleos
                    key={this.state.userData + this.state.editStartup}
                    userToken={this.state.userToken}
                  />
                ) : (
                  ''
                )}
                {this.state.choosedOption === 'blog_edit_post' ? (
                  <BlogEdit
                    informationUser={this.state.userData}
                    userToken={this.state.userToken}
                    getClickedElement={this.getClickedElement}
                    extraProps={this.state.extraProps}
                  />
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
    );
  }
}
export default ProfileUser;
