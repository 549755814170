import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import './../../../styles/styles.css';
import { withSnackbar } from 'notistack';
import { Button, FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Redirect } from 'react-router-dom';
import {
  getUserData,
  getUserToken,
  forumVisible,
} from '../../utils/manageUserData';
class ForumThreadCreate extends Component {
  constructor(props) {
    super(props);

    let userData = JSON.parse(getUserData());
    let userToken = getUserToken();

    let visible = forumVisible(userData.role);

    try {
      this.state = {
        userToken,
        userData,
        user1: userData.uid,
        post: [],
        subject: '',
        messages: [],
        type: 'ALL',
        newMsg: '',
        visible,
        startup: [],
      };
    } catch (error) {
      this.state = { post: [], messages: [], visible: false };
    }
    console.log(this.state);
  }

  componentDidMount() {}

  goToThread = (post) => {
    console.log(post);
    this.props.history.push({
      pathname: `/blog-secreto/${post}`,
      state: {
        userToken: this.state.userToken,
        userData: this.state.userData,
        user1: this.state.userData.uid,
        postId: post,
      },
    });
  };

  getStartup = async () => {
    const { userToken } = this.state;

    const apiurl = '/api/startups/user/id' + this.state.user1;
    console.log(apiurl);

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.setState({
          startup: response.data.startup,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createPost = async () => {
    const apiurl = '/api/forum';

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const { subject, userData, userId, type, newMsg } = this.state;
    let messages = this.state.messages;
    messages.push({
      from: userId,
      fromName: userData.name,
      contactName: userData.contactName,
      body: newMsg,
      seen: false,
      date: new Date(),
    });

    const payload = {
      subject,
      userData,
      userId,
      type,
      messages,
    };

    const { data } = await axios.post(apiurl, payload, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.props.enqueueSnackbar('Hilo creado', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      // go to created thread
      this.goToThread(data.forum._id);
    }
  };

  render() {
    const { subject, newMsg } = this.state;

    return this.state.visible !== true ? (
      <Container className="bodyWrapper" fluid>
        <Redirect
          to={{
            pathname: '/',
            state: {
              userToken: this.state.userToken,
              userData: this.state.userData,
              user1: this.state.userData.uid,
            },
          }}
        />
        );
      </Container>
    ) : (
      <Container className="forum create">
        <h1 className="job-page-title">Crear hilo </h1>

        <Container className="create">
          <Row>
            <Col md={12} xs={12}>
              <TextField
                id="standard-name"
                label="Título"
                required
                value={subject ? subject : ''}
                margin="normal"
                className="forced-black"
                onChange={(e) => {
                  this.setState({ subject: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row>
            <FormControl className="width-100 new-msg">
              <TextField
                id="outlined"
                name="new-msg"
                type="text"
                required
                multiline
                variant="outlined"
                rows={3}
                label="Texto"
                value={newMsg}
                onChange={(e) => this.setState({ newMsg: e.target.value })}
              />
            </FormControl>
          </Row>
          <Row>
            <Button
              variant="contained"
              style={{
                background: '#6658ea',
                color: 'white',
                marginBottom: '2rem',
                marginLeft: '1rem',
                display: 'table',
                margin: '0 auto',
                marginTop: '2rem',
              }}
              onClick={() => this.createPost()}
            >
              Guardar
            </Button>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default withSnackbar(ForumThreadCreate);
