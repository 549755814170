import { Divider } from "@material-ui/core";
import React, { Component } from "react";
import { Grid } from "@material-ui/core";

class Products extends Component {
	constructor(props) {
		super(props);

		this.state = {
			product: this.props.product,
		};
	}

	componentDidMount() {}

	render() {
		let priceStyles = {
			fontWeight: "600",
			fontSize: "1.5rem",
		};

		let discountStyles = {
			fontWeight: "600",
			fontSize: "1.5rem",
		};

		if (this.state.product.discountedPrice) {
			priceStyles.color = "black";

			discountStyles.color = "black";
		}

		return (
			<Grid container>
				<Grid item xs={12} sm={12} className="product-name float-left">
					{this.state.product.name}
				</Grid>

				<p className="w-100 description">{this.state.product.description}</p>
				<Divider style={{ margin: "2rem" }} />
			</Grid>
		);
	}
}
export default Products;
