import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Homepage from './pages/homepage';
import Login from './pages/login';
import Registro from './pages/registro';
import Buscador from './pages/buscador';
import './styles/styles.css';
import EmailValidation from './components/form/email_validation';
import ProfileUser from './components/profile/profile_User';
import Startups from './pages/startups';
import Companies from './pages/companies';
import Company from './pages/company';
import Cookies from 'js-cookie';
import ResetPassword from './components/form/reset_password';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import RedsysOk from './pages/redsysOk';
import RedsysKo from './pages/redsysKo';
import Jobs from './pages/empleos';
import JobPage from './components/jobs/jobPage';
import Inversiones from './pages/inversiones';
import Inversion from './pages/inversion';
import InversionSecundarias from './pages/inversionSecundarias';
import InversionesSecundarias from './pages/inversiones_secundarias';
import Politica from './pages/politicaPrivacidad';
import Challenges from './pages/challenges';
import ChallengePage from './components/form/challenges/challengePage';
import CreateFirstStartup from './pages/createFirstStartup';
import Blog from './pages/blog';
import BlogPost from './pages/blogPost';
import Forum from './components/profile/components/forum';
import ForumThread from './components/profile/components/forumThread';
import ForumThreadCreate from './components/profile/components/forumThreadCreate';
import Zombilan from './pages/zombilan';
import AvisoLegal from './pages/avisoLegal';
import PoliticaCookies from './pages/politicaCookies';
const queryClient = new QueryClient();

// import {createStore} from "redux";

// const store = createStore()

require('dotenv').config();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Header />
        <Switch>
          <Route path="/" exact component={Homepage} />
          <Route path="/login" exact component={Login} />
          <Route path="/registro" exact component={Registro} />
          <Route path="/politica-de-privacidad" exact component={Politica} />
          <Route
            path="/password-recovery/:token"
            exact
            component={ResetPassword}
          />
          <Route
            path="/email-validation/:token"
            exact
            component={EmailValidation}
          />
          <Route
            path="/perfil"
            exact
            render={() =>
              !Cookies.get('tokenLogin') ? <Homepage /> : <ProfileUser />
            }
          />
          <Route
            path="/perfil/:path"
            exact
            render={() =>
              !Cookies.get('tokenLogin') ? <Homepage /> : <ProfileUser />
            }
          />
          <Route path="/aviso-legal" exact component={AvisoLegal} />
          <Route
            path="/politica-de-cookies"
            exact
            component={PoliticaCookies}
          />
          <Route path="/buscador-de-startups" exact component={Buscador} />
          <Route path="/zombilan" exact component={Zombilan} />
          <Route path="/startups" component={Startups} />
          <Route path="/startups/:name" component={Startups} />
          <Route path="/servicios-a-startups" exact component={Companies} />
          <Route path="/servicios-a-startups/:name" exact component={Company} />
          <Route path="/pago-ok" component={RedsysOk} />
          <Route path="/pago-ok/:redsys-response" component={RedsysOk} />
          <Route path="/pago-ko" component={RedsysKo} />
          <Route path="/pago-ko/:redsys-response" component={RedsysKo} />
          <Route path="/empleos" exact component={Jobs} />
          <Route path="/empleos/:id" component={JobPage} />
          <Route path="/reto" exact component={Challenges} />
          <Route path="/reto/:id" exact component={ChallengePage} />
          <Route path="/invertir-en-startups" exact component={Inversiones} />
          <Route
            path="/invertir-en-startups/:name"
            exact
            component={Inversion}
          />
          <Route
            path="/invertir-en-secundarias"
            exact
            component={InversionesSecundarias}
          />
          <Route
            path="/invertir-en-secundarias/:name"
            exact
            component={InversionSecundarias}
          />
          <Route path="/blog/" exact component={Blog} />
          <Route path="/blog/:post" exact component={BlogPost} />
          <Route
            path="/crea-tu-primera-startup"
            component={CreateFirstStartup}
          />

          <Route path="/blog-secreto" exact component={Forum} />
          <Route
            path="/blog-secreto/crear-hilo"
            exact
            component={ForumThreadCreate}
          />
          <Route path="/blog-secreto/:id" exact component={ForumThread} />
        </Switch>
      </BrowserRouter>
      <Footer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
