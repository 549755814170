import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';

class PoliticaCookies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <Container>
        <Grid container>
          <Grid item={true} xs={12} sm={12} className="politica-cookies">
            <h3>
              <center>
                <span style={{ fontWeight: 400 }} className="bold">
                  POLÍTICA DE COOKIES
                </span>
              </center>
            </h3>
            <ol>
              <li>
                <h4 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Consideraciones previas
                </h4>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Se entenderá por usuario toda persona que navegue en el sitio
                web
                <b>
                  <a href="www.estartaps.com">www.estartaps.com</a>
                </b>{' '}
                en lo adelante, simplemente el sitio web.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Se entenderá por editor el dueño del sitio web, quien es Tamber
                Solutions S.L y está identificado en el aviso legal.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Las cookies utilizadas en el navegador del usuario se han
                instalado con su autorización. Si en algún momento desea revocar
                esta autorización, podrá hacerlo sin obstáculo alguno. Para ello
                puede visualizar la sección{' '}
                <b>Desactivación o eliminación de cookies</b>, que se encuentra
                descrita en estas políticas.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                El Editor está en total libertad de realizar los cambios que
                considere pertinentes en el sitio web. Para ello podrá añadir
                apartados, funcionalidades o cualquier elemento que quizás pueda
                generar el uso de nuevas cookies, por lo que se aconseja al
                usuario que verifique la presente política de cookies cada vez
                que acceda al sitio web, para que pueda mantenerse actualizado
                por cualquier cambio que se pueda haber dado desde la última
                visita realizada.
              </span>
            </p>
            <h3>
              <center>
                <span style={{ fontWeight: 400 }} className="bold">
                  Definición y función de las cookies
                </span>
              </center>
            </h3>
            <p>
              <span style={{ fontWeight: 400 }}>
                Las cookies son informaciones que se almacenan en el navegador
                del usuario de un sitio web para poder consultar la actividad
                previa que ha tenido, así como recordar ciertos datos para una
                próxima visita. También pueden ser llamadas web beacons, píxel,
                bugs, rastreadores, pero a efectos de estas políticas, se
                entenderán solamente como <b>cookies</b>.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Suelen almacenar datos de carácter técnico, estadísticas de uso,
                personalización de perfiles, enlaces a redes sociales,
                administración de preferencias personales, entre otras
                funciones, con el fin de poder adaptar el sitio web a las
                necesidades y configuraciones del usuario, mejorando así la
                experiencia de la navegación, el no aceptarlas podría entorpecer
                el servicio que el sitio web desea ofrecer.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                No son archivos de virus, spam, troyanos, gusanos, spyware, ni
                programación publicitaria ya sea estática en la página o en
                formato de ventana emergente (pop-up).
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                La información almacenada se refiere al navegador (Internet
                Explorer, Safari, Chrome, Firefox, etc.), y no al usuario, para
                constatar esto puede entrar en el mismo dominio web y verificar
                en dos navegadores diferentes que puede configurar diferentes
                preferencias en cada uno.
              </span>
            </p>
            <ol>
              <li>
                <h4 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Tipos de cookies según la entidad que las gestiona
                </h4>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>Las cookies pueden ser:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                1. <b>Propias</b>: cuando son gestionadas por el propio editor
                del sitio web. Se envían al equipo terminal del usuario desde un
                equipo o dominio gestionado por el propio editor y desde el que
                se presta el servicio solicitado por el usuario.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                2. <b>De terceros</b>: cuando son gestionadas por una personas
                física o jurídica diferente al editor.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Este sitio web utiliza cookies propias y de terceros.
              </span>
            </p>

            <ol>
              <li>
                <h4 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Tipos de cookies según su finalidad
                </h4>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Dependiendo de la función que cumplan, las cookies pueden ser:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                1. <b>Técnicas</b>: Permiten la gestión operativa del sitio web,
                para que el usuario pueda navegar sin problema en el mismo.
                Pueden ayudar a controlar el tráfico y la comunicación de datos,
                identificar la sesión, acceder a partes de acceso restringido,
                recordar los elementos que integran un pedido, realizar el
                proceso de compra de un producto o servicio, gestionar el pago,
                controlar el fraude, realizar la solicitud de inscripción o
                participación en un evento, utilizar elementos de seguridad
                durante la navegación, almacenar contenidos para la difusión de
                vídeos o sonido, habilitar contenidos dinámicos (por ejemplo,
                animación de carga de un texto o imagen) o compartir contenidos
                a través de redes sociales, entre muchas otras funciones que
                puede tener.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                2. <b>De preferencias o personalización</b>: son aquellas que
                permiten recordar información para que el usuario navegue en el
                sitio web con determinadas características personalizadas, como,
                por ejemplo, el idioma, el número de resultados a mostrar cuando
                el usuario realiza una búsqueda, el aspecto o contenido del
                sitio web en función del tipo de navegador del usuario, colores
                de fondo empleados, imagen de perfil, entre otras.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                3. <b>De análisis o medición</b>: permiten el seguimiento y
                análisis del comportamiento de los usuarios en el sitio web y en
                otros que sean propiedad del gestor de las cookies, por ejemplo,
                el tiempo que demoran en una determinada página, las horas y
                días de mayor tráfico.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                4. <b>De publicidad comportamental</b>: son aquellas que
                almacenan información del comportamiento de los usuarios
                obtenida a través de la observación continuada de sus hábitos de
                navegación, lo que permite desarrollar un perfil específico para
                mostrar publicidad en función de este. La intención es mostrar
                anuncios relevantes y atractivos para el usuario.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5. <b>Publicitarias</b>: Son aquéllas que permiten la gestión,
                de la forma más eficaz posible, de los espacios publicitarios
                que el sitio web posea, con base a criterios como el contenido
                editado o la frecuencia en la que se muestran los anuncios.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Este sitio web utiliza cookies técnicas, de personalización, de
                análisis, de publicidad y de publicidad comportamental.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Algunas cookies pueden ser polivalentes, es decir, cumplir con 2
                o más funciones.
              </span>
            </p>

            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Tipos de cookies según su duración:
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Según el plazo de tiempo que permanecen activadas en el equipo
                terminal, las cookies pueden ser:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                a. <b>De sesión</b>: Son un tipo de cookies diseñadas para
                recabar y almacenar datos mientras el usuario accede a una
                página web. Se suelen emplear para almacenar información que
                solo interesa conservar para la prestación del servicio
                solicitado por el usuario en una sola ocasión y desaparecen al
                terminar la sesión, por ejemplo, una lista de productos
                elegidos, una selección determinada de fotografías, etc.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                b. <b>Persistentes</b>: Son un tipo de cookies en el que los
                datos siguen almacenados en el terminal y pueden ser accedidos y
                tratados durante un período definido por el responsable de la
                cookie, y que puede ir de unos minutos a varios años.
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Consecuencias de desactivar las cookies
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                A manera de ejemplo, los posibles efectos por no autorizar el
                uso de cookies pueden ser los siguientes:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ fontWeight: 400 }}>
                  No se cargarán los productos elegidos en la tienda, por lo que
                  no se creará un carro de compras, impidiendo la posibilidad de
                  finalizar con la adquisición de los productos deseados.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  No podrá acceder al área de cliente, área personal, ni ninguna
                  que requiera de la confirmación
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  No se podrán recoger los datos necesarios para analizar el
                  sitio web, impidiendo al editor saber cómo mejorar
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  No podrá compartir el contenido del sitio web en redes
                  sociales tales como Facebook, Twitter, Instagram, entre otros.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  No podrá realizar comentarios en el blog
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  No podrá usar ninguna red social, ya que todas usan cookies
                  para poder ofrecer su servicio.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  No se podrá mostrar publicidad de terceros, disminuyendo los
                  ingresos para quien use esta modalidad
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  En determinados sitios de dominios web podrá considerarse su
                  navegación como spam por no poder ser identificada
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Varios. Esta lista es meramente enunciativa, no limitativa.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>
                Si el usuario ha eliminado las cookies, y desea navegar
                nuevamente en este sitio web, deberá aceptar una vez más las
                cookies.
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  Cookies utilizadas en este sitio web
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Este sitio web hace uso de las siguientes cookies: técnicas, de
                personalización, de análisis, de publicidad comportamental. A
                continuación, se detallan las cookies que utiliza este sitio
                web, sin embargo, debido a la imposibilidad técnica que esto
                puede generar, no se puede mostrar con exactitud las cookies
                utilizadas por terceros, pero se ha hecho el mayor esfuerzo para
                que el usuario tenga toda la información necesaria.
              </span>
            </p>
            {/* tabla aqui */}
            <table style={{ width: '100%' }}>
              <tr>
                <th>Nombre</th>
                <th>Proveedor</th>
                <th>Descripción</th>
                <th>Duración</th>
                <th>Tipo</th>
              </tr>
              <tr>
                <td>CookieConsent</td>
                <td>
                  <a href="www.estartaps.com">www.estartaps.com</a>
                </td>
                <td>
                  Almacena el estado de consentimiento de cookies del usuario
                  para el dominio actual
                </td>
                <td>30 días</td>
                <td>Necesaria</td>
              </tr>
              <tr>
                <td>wc_cart_hash_#</td>
                <td>
                  <a href="www.estartaps.com">www.estartaps.com</a>
                </td>
                <td>Recuerda lo que hay en el carrito</td>
                <td>Persistente</td>
                <td>Necesaria</td>
              </tr>
              <tr>
                <td>wc_fragments_#</td>
                <td>
                  <a href="www.estartaps.com">www.estartaps.com</a>
                </td>
                <td>
                  almacena la información de inicio de sesión de los visitantes
                  para que no tengan que seguir iniciando sesión si el navegador
                  está cerrado.
                </td>
                <td>Sesión</td>
                <td>Necesaria</td>
              </tr>
              <tr>
                <td>_ga</td>
                <td>
                  <a href="www.estartaps.com">www.estartaps.com</a>
                </td>
                <td>
                  Registra una identificación única que se utiliza para generar
                  datos estadísticos acerca de cómo utiliza el visitante el
                  sitio web.
                </td>
                <td>2 años</td>
                <td>Estadística</td>
              </tr>
              <tr>
                <td>_gat</td>
                <td>
                  <a href="www.estartaps.com">www.estartaps.com</a>
                </td>
                <td>
                  Utilizado por Google Analytics para controlar la tasa de
                  peticiones
                </td>
                <td>Sesión</td>
                <td>Estadística</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>
                  <a href="www.estartaps.com">www.estartaps.com</a>
                </td>
                <td>
                  Registra una identificación única que se utiliza para generar
                  datos estadísticos acerca de cómo utiliza el visitante el
                  sitio web.
                </td>
                <td>Sesión</td>
                <td>Estadística</td>
              </tr>
              <tr>
                <td>tk_ai</td>
                <td>
                  <a href="www.estartaps.com">www.estartaps.com</a>
                </td>
                <td>
                  Registra datos del comportamient o del visitante en la web.
                  Esto se utiliza para el análisis interno y la optimización de
                  la web.
                </td>
                <td>Sesión</td>
                <td>Estadística</td>
              </tr>
              <tr>
                <td>Collect</td>
                <td>
                  <a href="google-analytics.com">google-analytics.com</a>
                </td>
                <td>
                  Se utiliza para enviar datos a Google Analytics sobre el
                  dispositivo del visitante y su comportamient o. Rastrea al
                  visitante a través de dispositivos y canales de marketing
                </td>
                <td>Sesión</td>
                <td>Estadística</td>
              </tr>
              <tr>
                <td>r/collect</td>
                <td>
                  <a href="doubleclick.net">doubleclick.net</a>
                </td>
                <td>
                  Esta cookie se utiliza para enviar datos a Google Analytics
                  sobre el dispositivo y comportamient o del visitante. Hace un
                  seguimiento del visitante en todos los dispositivos y canales
                  de marketing,
                </td>
                <td>Sesión</td>
                <td>Marketing</td>
              </tr>
            </table>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  Desactivación o eliminación de cookies
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                En cualquier momento el usuario podrá ejercer su derecho a
                negarse a las cookies y podrá en consecuencia desactivarlas o
                eliminarlas, salvo aquellas que sean necesarias para el
                funcionamiento del sitio web.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                El usuario podrá eliminar y/o bloquear las cookies, para toda
                navegación que realice o para un dominio web determinado. Esta
                configuración debe realizarla en cada navegador, si lo hace en
                un navegador y no en los restantes del mismo dispositivo, se
                asumirá que en los otros si acepta el uso de cookies. El Editor
                no puede realizar esta acción por el usuario, deberá realizarla
                él de forma responsable y particular en cada navegador y/o
                dispositivo que así lo desee.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Como cada navegador posee configuraciones diferentes, el usuario
                deberá dirigirse a la parte de ayuda de cada uno para ver el
                caso en particular. En esta sección se hace mención a los más
                comunes, no obstante por actualizaciones de los navegadores o
                del sistema operativo que esté utilizando, esto puede variar. El
                usuario siempre deberá acudir a los manuales de uso de su
                navegador, esta guía es meramente orientativa.
              </span>
            </p>
            <ul>
              <li>
                <span style={{ fontWeight: 400 }}>
                  <b>Chrome</b>: Ir a Menú - Configuración (en Mac:
                  Preferencias) – Ajustes Avanzados – Privacidad – Aquí elige si
                  configurar las cookies o eliminar las cookies existentes. Para
                  más información puede visitar la ayuda de Chrome.
                </span>
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=es"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.google.com/chrome/answer/95647?hl=es
                </a>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  <b>Internet Explorer</b>:Ir a Herramientas – Opciones de
                  Internet – Privacidad – Privacidad – Configuración – Opciones
                  avanzadas. Configure lo deseado. Para más información puede
                  visitar la sección de ayuda propia del navegador
                </span>
                <a
                  href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  <b>Firefox</b>:Ir a Menú – Preferencias – Privacidad – Mostrar
                  cookies – Configurar según lo que desee. Para más información
                  puede visitar la sección de ayuda del navegador
                </span>
                <a
                  href="https://www.mozilla.org/es-ES/privacy/websites/#cookies"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.mozilla.org/es-ES/privacy/websites/#cookies
                </a>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  <b>Safari</b>: Safari – Preferencias – Privacidad – Realice la
                  configuración deseada. Para más información puede visitar la
                  ayuda de Apple
                </span>
                <a
                  href="https://support.apple.com/es-
                  es/guide/safari/sfri11471/mac"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.apple.com/es- es/guide/safari/sfri11471/mac
                </a>
              </li>
            </ul>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  Avisos relacionados con las cookies
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                El Editor no se hace responsable por los fallos técnicos que el
                usuario pueda encontrar en el sitio web o en su navegador por
                manipular de manera errónea la desactivación o eliminación de
                las cookies, en todo momento deberá el usuario verificar la
                información sobre su desactivación o eliminación en la sección
                de ayuda del navegador que le corresponda. El usuario asume la
                responsabilidad de eliminar o desactivar las cookies en su
                navegador y en por ende, asume las consecuencias que se deriven
                de esto.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                En todo momento el usuario podrá comunicarse con el editor a
                través de la dirección de correo: <u>support@estartaps.com</u>
              </span>
            </p>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default PoliticaCookies;
