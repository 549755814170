import React, { Component } from 'react';

import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
  FormHelperText,
  StepButton,
} from '@material-ui/core';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../utils/cropImage';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import {
  getUserData,
  getUserToken,
  updateDataUser,
} from '../../utils/manageUserData';

class RegistroForm4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      optionChooseProfile: '',
      userData: '',
      email: '',
      usertoken: '',
      choosenOptionMaturity: '',
      startupFounder: '',
      isFounder: false,
      steps: ['Datos Básicos', 'Tu Empresa', 'Información Personal'],
      step1: 0,
      step2: 0,
      step3: 0,
      step4: 0,
      step5: 0,
      step6: 0,
      imageSrc: '',
      imageToUpload: '',
      croppedImage: '',
      croppedImageFile: '',
      crop: { x: 0, y: 0 },
      croppedAreaPixels: '',
      uploadedImage: '',
      croppedArea: '',
      zoom: 1,
      aspect: 1 / 1,
      cropSize: { width: 300, height: 300 },
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.checkCompletedSteps();
    })();
  }

  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
      this.getUserFounder(obj);
    }
  };
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedArea: croppedArea,
      croppedAreaPixels: croppedAreaPixels,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo.uid + '/company';

    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.companies.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.companies[0],
          });
          this.checkCompletedSteps();
        }
      })
      .catch((error) => {
        this.checkCompletedSteps();
        console.log(error.response);
        console.log('No startup Found');
      });
  };
  handleChangeProfile = (event) => {
    this.setState({
      optionChooseProfile: event.target.value,
    });
  };

  handleChange = (event) => {
    var image = event.target.files[0];
    if (event.target.files[0]) {
      this.setState({
        uploadedImage: event.target.files[0],
        imageSrc: URL.createObjectURL(image),
      });
    } else {
      this.setState({
        uploadedImage: '',
        imageSrc: '',
      });
    }
  };

  checkCompletedSteps = async () => {
    var step1Completed = 0;
    var step2Completed = 0;
    var step3Completed = 0;

    if (
      this.state.userData.email !== '' &&
      this.state.userData.name !== '' &&
      this.state.userData.lastName !== '' &&
      this.state.userData.phone !== '' &&
      this.state.userData.email &&
      this.state.userData.name &&
      this.state.userData.lastName &&
      this.state.userData.phone
    ) {
      step1Completed = 1;
      this.setState({
        step1: 1,
      });
    }
    console.log(this.state.startupFounder);
    if (
      this.state.startupFounder.name !== '' &&
      this.state.startupFounder.shortDescription !== '' &&
      //  this.state.startupFounder.longDescription !== '' &&
      this.state.startupFounder.name &&
      this.state.startupFounder.shortDescription &&
      this.state.startupFounder.industries
      //&& this.state.startupFounder.longDescription
    ) {
      step2Completed = 1;
      this.setState({ step2: 1 });
    }
    if (
      this.state.userData.streetName !== '' &&
      this.state.userData.flatNumber !== '' &&
      this.state.userData.locality !== '' &&
      this.state.userData.province !== '' &&
      this.state.userData.country !== '' &&
      this.state.userData.postCode !== '' &&
      this.state.userData.streetName &&
      this.state.userData.flatNumber &&
      this.state.userData.locality &&
      this.state.userData.province &&
      this.state.userData.country
    ) {
      step3Completed = 1;
      this.setState({ step3: 1 });
    }
    if (step1Completed === 1 && step2Completed === 1 && step3Completed === 1) {
      window.location.reload();
    }
  };
  submitRegisterStep1 = (event) => {
    event.preventDefault();

    const payload = {
      name: event.target.register_steps_name.value,
      lastName: event.target.register_steps_lastname.value,
      email: event.target.register_steps_email.value,
      phone: event.target.register_steps_phone.value,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });

    //
  };
  submitRegisterStep2 = (event) => {
    event.preventDefault();

    // alert(
    //   'Se van a guardar estos datos: ' +
    //     event.target.register_steps_startupName.value +
    //     ' ' +
    //     event.target.register_steps_startup_description.value +
    //     ' '
    //   //  +  event.target.register_steps_startup_description2.value
    // );

    let formData = new FormData();
    formData.append('file', this.state.croppedImageFile);

    console.log(this.state.startupFounder._id, this.state.startupFounder);
    axios
      .put('/api/uploads/company/' + this.state.startupFounder._id, formData)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error.response);
        alert('Se ha encontrado un error y no se ha subido la imagen');
      });

    console.log(event.target.register_steps_industry.value);
    const payload = {
      name: event.target.register_steps_startupName.value,
      industries: event.target.register_steps_industry.value,
      shortDescription: event.target.register_steps_startup_description.value,
      //  longDescription: event.target.register_steps_startup_description2.value,
      founder: this.state.userData.uid,
      cif: event.target.register_steps_startup_cifStartup.value,
    };

    if (this.state.isFounder === true) {
      var apiurlput = '/api/company/' + this.state.startupFounder._id;
      axios({
        url: apiurlput,
        method: 'PUT',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          console.log('Data has been sent to the server');
          console.log(response);
          //updateDataUser(response.data);
          alert('Datos guardados con éxito');
          this.getUserFounder(this.state.userData);
          //window.location.reload();
          //this.emailVerification(email);
          this.handleNext();
        })
        .catch((error) => {
          alert('Hemos encontrado un error y no se han guardado los cambios');
          console.log(error.response);
          console.log('Error');
        });
    } else {
      var apiurlpost = '/api/company';
      axios({
        url: apiurlpost,
        method: 'POST',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          console.log('Data has been sent to the server');
          console.log(response);
          //updateDataUser(response.data);
          alert('Datos guardados con éxito');
          this.getUserFounder(this.state.userData);
          //window.location.reload();
          //this.emailVerification(email);
          this.checkCompletedSteps();
          this.handleNext();
        })
        .catch((error) => {
          alert('Hemos encontrado un error y no se han guardado los cambios');
          console.log(error.response);
          console.log('Error');
        });
    }

    //if already saved go here:
  };
  submitRegisterStep3 = (event) => {
    event.preventDefault();
    console.log(this.state.startupFounder.uid);
    console.log(this.state.userToken);
    var register_steps_startup_test_company;
    if (event.target.register_steps_startup_test_company.value === 'True') {
      register_steps_startup_test_company = true;
    } else {
      register_steps_startup_test_company = false;
    }
    console.log(register_steps_startup_test_company);
    console.log(event.target.register_steps_startup_opinion_test.value);
    console.log(event.target.register_steps_startup_products.value);
    console.log(event.target.register_steps_startup_team.value);
    console.log(event.target.register_steps_startup_cifStartup.value);
    const payload = {
      name: this.state.startupFounder.name,
      testedInfoBoolean: register_steps_startup_test_company,
      testedInfoText: event.target.register_steps_startup_opinion_test.value,
      servicesDescription: event.target.register_steps_startup_products.value,
      teamDescription: event.target.register_steps_startup_team.value,
    };

    var apiurlput = '/api/company/' + this.state.startupFounder.uid;
    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        //updateDataUser(response.data);
        alert('Datos guardados con éxito');
        console.log(this.state.userData);
        this.getUserFounder(this.state.userData);
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep4 = (event) => {
    event.preventDefault();
    console.log(this.state.choosenOptionMaturity);
    if (this.state.choosenOptionMaturity === '') {
      alert('No ha elegido opcion');
    } else {
      alert('Ha elegido la opcion: ' + this.state.choosenOptionMaturity);
      var choosedOption;
      if (this.state.choosenOptionMaturity === 1) {
        choosedOption = 'Pre-semilla';
      } else if (this.state.choosenOptionMaturity === 2) {
        choosedOption = 'Semilla';
      } else if (this.state.choosenOptionMaturity === 3) {
        choosedOption = 'Temprana';
      } else if (this.state.choosenOptionMaturity === 4) {
        choosedOption = 'Consolidada';
      }

      const payload = {
        name: this.state.startupFounder.name,
        maturityInfo: choosedOption,
      };

      var apiurlput = '/api/company/' + this.state.startupFounder.uid;
      axios({
        url: apiurlput,
        method: 'PUT',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          console.log('Data has been sent to the server');
          console.log(response);
          //updateDataUser(response.data);
          this.getUserFounder(this.state.userData);
          alert('Datos guardados con éxito');
          //window.location.reload();
          //this.emailVerification(email);
          this.checkCompletedSteps();
          this.handleNext();
        })
        .catch((error) => {
          alert('Hemos encontrado un error y no se han guardado los cambios');
          console.log(error.response);
          console.log('Error');
        });
    }
  };
  submitRegisterStep5 = (event) => {
    event.preventDefault();

    alert(
      'Se van a guardar estos datos: ' +
        event.target.register_steps_startup_aceleration.value +
        ' ' +
        event.target.register_steps_startup_aceleration_description.value
    );
    var register_steps_startup_aceleration;
    if (event.target.register_steps_startup_aceleration.value === 'True') {
      register_steps_startup_aceleration = true;
    } else {
      register_steps_startup_aceleration = false;
    }
    const payload = {
      name: this.state.startupFounder.name,
      accelerationBoolean: register_steps_startup_aceleration,
      accelerationProcessInfo:
        event.target.register_steps_startup_aceleration_description.value,
    };

    var apiurlput = '/api/company/' + this.state.startupFounder.uid;
    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        //updateDataUser(response.data);
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);

        console.log('Error');
      });
  };
  submitRegisterStep6 = (event) => {
    event.preventDefault();

    const payload = {
      streetName: event.target.register_steps_address.value,
      flatNumber: event.target.register_steps_address_number.value,
      postCode: event.target.register_steps_pc.value,
      locality: event.target.register_steps_locality.value,
      province: event.target.register_steps_province.value,
      country: event.target.register_steps_country.value,

      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  stepN1 = () => {
    return (
      <div>
        <form
          onSubmit={this.submitRegisterStep1}
          className="form"
          key={this.state.userData}
        >
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_email" shrink={true}>
                  Correo al que está asociada su cuenta
                </InputLabel>
                <Input
                  id="register_steps_email"
                  name="register_steps_email"
                  aria-describedby="register_steps_email"
                  type="email"
                  defaultValue={this.state.userData.email}
                  required
                  readonly
                />
                <FormHelperText id="helper-email">
                  No compartiremos tu email
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_name">Nombre* </InputLabel>
                <Input
                  id="register_steps_name"
                  name="register_steps_name"
                  aria-describedby="register_steps_name"
                  defaultValue={this.state.userData.name}
                  type="text"
                  required
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_lastname">
                  Apellidos*
                </InputLabel>
                <Input
                  id="register_steps_lastname"
                  name="register_steps_lastname"
                  aria-describedby="register_steps_lastname"
                  type="text"
                  defaultValue={this.state.userData.lastName}
                  required
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={6} xs={6}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_phone" shrink={true}>
                  Para tener una comunicación más directa contigo. ¿A qué
                  teléfono te llamamos?
                </InputLabel>
                <Input
                  id="register_steps_phone"
                  name="register_steps_phone"
                  aria-describedby="register_steps_phone"
                  type="text"
                  defaultValue={this.state.userData.phone}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN2 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep2} className="form">
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_startupName" shrink={true}>
                  Lo más importante, ¿por qué nombre quieres que conozcamos a tu
                  Empresa?*
                </InputLabel>
                <Input
                  id="register_steps_startupName"
                  name="register_steps_startupName"
                  aria-describedby="register_steps_startupName"
                  type="text"
                  defaultValue={this.state.startupFounder.name}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={6}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_phone" shrink={true}>
                  CIF o DNI *
                </InputLabel>
                <Input
                  id="register_steps_startup_cifStartup"
                  name="register_steps_startup_cifStartup"
                  aria-describedby="register_steps_startup_cifStartup"
                  type="text"
                  required
                  defaultValue={this.state.startupFounder.cif}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={6}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_industry" shrink={true}>
                  ¿Cuál es la industria a la que pertenece tu Empresa?*
                </InputLabel>
                <Input
                  id="register_steps_industry"
                  name="register_steps_industry"
                  aria-describedby="register_steps_industry"
                  type="text"
                  defaultValue={this.state.startupFounder.industries}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <p>
                  Nos gustaría que nos hicieses una breve descripción de a qué
                  se dedica tu Empresa*
                </p>
                <TextareaAutosize
                  aria-label="minimum height"
                  id="register_steps_startup_description"
                  name="register_steps_startup_description"
                  rowsMin={3}
                  defaultValue={this.state.startupFounder.shortDescription}
                  required
                />
              </FormControl>
            </Grid>
            {/* <Grid item={true} sm={12} xs={12}>
							{" "}
							<FormControl className="width-100">
								<p>
									Queremos acercar tu idea al mayor número de clientes posibles.
									Para ello, un equipo de Estartaps.com va a revisar todo lo que
									nos cuentes de tu solución, intentando entender hasta el
									mínimo detalle para que el mundo lo conozca*
								</p>
								<TextareaAutosize
									aria-label="minimum height"
									id="startupFounderdescription2"
									name="register_steps_startup_description2"
									rowsMin={3}
									defaultValue={this.state.startupFounder.longDescription}
									required
								/>
							</FormControl>
							<FormControl className="width-100">
								<InputLabel htmlFor="register_steps_startup_cifStartup">
									CIF de la empresa
								</InputLabel>
								<Input
									id="register_steps_startup_cifStartup"
									name="register_steps_startup_cifStartup"
									aria-describedby="register_steps_startup_cifStartup"
									type="text"
									defaultValue={this.state.startupFounder.cif}
								/>
							</FormControl>
						</Grid> */}
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <p>Logo de su Empresa</p>

                <Input
                  id="update_image"
                  name="update_image"
                  ref="update_image"
                  aria-describedby="update_image"
                  type="file"
                  inputProps={{
                    accept: ['image/png', 'image/jpg', 'image/jpeg'],
                  }}
                  onChange={this.handleChange}
                />
              </FormControl>
              {this.state.uploadedImage !== '' ? (
                <div>
                  <p>
                    Ajuste la imagen en el cuadrado blanco y acepte para guardar
                    la imagen, puede hacer uso del zoom o mover la imagen
                  </p>
                  <div className="crop-container">
                    <Cropper
                      image={this.state.imageSrc}
                      crop={this.state.crop}
                      minZoom={0.4}
                      maxZoom={15}
                      zoom={this.state.zoom}
                      aspect={this.state.aspect}
                      onCropChange={this.onCropChange}
                      onCropComplete={this.onCropComplete}
                      onZoomChange={this.onZoomChange}
                      cropSize={this.state.cropSize}
                      restrictPosition={false}
                    />
                  </div>
                  <Button
                    onClick={this.showCroppedImage}
                    variant="contained"
                    color="primary"
                  >
                    Aceptar y ver resultado
                  </Button>
                </div>
              ) : (
                ''
              )}
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {this.state.croppedImage !== '' ? (
                <img
                  src={this.state.croppedImage}
                  alt="Startup"
                  className="profile-image "
                ></img>
              ) : (
                ''
              )}
            </Grid>
            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN3 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep6} className="form">
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_address">
                  Dirección
                </InputLabel>
                <Input
                  id="register_steps_address"
                  name="register_steps_address"
                  aria-describedby="register_steps_address"
                  type="text"
                  defaultValue={this.state.userData.streetName}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_address_number">
                  N/Piso{' '}
                </InputLabel>
                <Input
                  id="register_steps_address_number"
                  name="register_steps_address_number"
                  aria-describedby="register_steps_address_number"
                  type="text"
                  defaultValue={this.state.userData.flatNumber}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_pc">
                  Código Postal{' '}
                </InputLabel>
                <Input
                  id="register_steps_pc"
                  name="register_steps_pc"
                  aria-describedby="register_steps_pc"
                  type="number"
                  defaultValue={this.state.userData.postCode}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_locality">
                  Localidad
                </InputLabel>
                <Input
                  id="register_steps_locality"
                  name="register_steps_locality"
                  aria-describedby="register_steps_locality"
                  type="text"
                  defaultValue={this.state.userData.locality}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_province">
                  Provincia
                </InputLabel>
                <Input
                  id="register_steps_province"
                  name="register_steps_province"
                  aria-describedby="register_steps_province"
                  type="text"
                  defaultValue={this.state.userData.province}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_country">País</InputLabel>
                <Input
                  id="register_steps_country"
                  name="register_steps_country"
                  aria-describedby="register_steps_country"
                  type="text"
                  defaultValue={this.state.userData.country}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };

  chooseOptionMature = (option) => {
    var element = document.getElementById('opt1');
    var element2 = document.getElementById('opt2');
    var element3 = document.getElementById('opt3');
    var element4 = document.getElementById('opt4');
    if (option === 1) {
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element.classList.add('choosenOption');
    } else if (option === 2) {
      element.classList.remove('choosenOption');

      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element2.classList.add('choosenOption');
    } else if (option === 3) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');

      element4.classList.remove('choosenOption');
      element3.classList.add('choosenOption');
    } else if (option === 4) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.add('choosenOption');
    }
    this.setState({
      choosenOptionMaturity: option,
    });
  };

  responseGoogle = (response) => {
    this.setState({
      userData: response.profileObj,
      email: response.profileObj.email,
    });
    console.log(response);
  };
  responseGoogleFail = (response) => {
    console.log(response);
  };
  logout = (response) => {
    this.setState({
      userData: '',
    });
    alert('Logout');
  };

  responseFacebook = (response) => {
    this.setState({
      facebookName: response.name,
      facebookEmail: response.email,
      facebookImage: response.picture.data.url,
    });
    console.log(response);
  };

  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
      this.state.rotation
    );

    let response = await fetch(croppedImage);
    let data = await response.blob();
    let metadata = {
      type: 'image/png',
    };
    let file = new File([data], 'Startup.png', metadata);

    //croppedImageFile.lastModifiedDate = new Date();
    //croppedImageFile.name = "uploadfile";

    this.setState({
      croppedImageFile: file,
    });
    this.setState({
      croppedImage: croppedImage,
    });
  };

  socialLogin = () => {
    return (
      <div>
        <p className="center">Registro con Redes Sociales</p>
        <div className="center clear width-100">
          <GoogleLogin
            clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogleFail}
            cookiePolicy={'single_host_origin'}
          />
          <br></br>
          <FacebookLogin
            appId={238403055163743}
            autoLoad={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
            icon="fa-facebook"
          />
        </div>
      </div>
    );
  };

  getStepContent = (step) => {
    var stepReal;
    if (
      (step === 2 || step === 3 || step === 4) &&
      this.state.isFounder === false
    ) {
      stepReal = 1;
    } else {
      stepReal = step;
    }

    switch (stepReal) {
      case 0:
        return this.stepN1();
      case 1:
        return this.stepN2();
      case 2:
        return this.stepN3();
      case 3:
        return this.stepN4();
      case 4:
        return this.stepN5();
      case 5:
        return this.stepN6();
      default:
        return 'Paso no reconocido';
    }
  };
  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };
  handleNext = () => {
    if (this.state.activeStep + 1 >= this.state.steps.length) {
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };
  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };
  submitRegister = (event) => {
    event.preventDefault();
    this.refs.buttonRegister.setAttribute('disabled', 'disabled');
    console.log(event.target.register_steps_email.value);
    console.log(event.target.register_steps_name.value);
    console.log(event.target.register_steps_lastname.value);
    if (
      event.target.register_steps_name.value === '' ||
      event.target.register_steps_lastname.value === '' ||
      event.target.register_steps_email.value === ''
    ) {
      alert('Faltan campos requeridos por completar');
      this.refs.buttonRegister.removeAttribute('disabled');
    } else {
      const payload = {
        name: event.target.register_steps_name.value,
        lastName: event.target.register_steps_lastname.value,
        companyName: event.target.register_steps_company.value,
        industryInterested: event.target.register_steps_industry.value,
        phone: event.target.register_steps_phone.value,
        email: event.target.register_steps_email.value,
        publicContactEmail: event.target.register_steps_email.value,
        pwd: event.target.register_steps_password.value,
        role: 'ADMIN',
        streetName: event.target.register_steps_address.value,
        postCode: event.target.register_steps_pc.value,
        locality: event.target.register_steps_locality.value,
        province: event.target.register_steps_province.value,
        country: event.target.register_steps_country.value,
        tyc: 1,
        newsletter: 1,
      };

      axios({
        url: '/api/users',
        method: 'POST',
        data: payload,
      })
        .then(() => {
          console.log('Data has been sent to the server');
        })
        .catch((error) => {
          console.log(error.response);
          console.log('Error');
        });

      console.log(event.target.register_steps_email.value);
      console.log(event.target.register_steps_name.value);
      console.log(event.target.register_steps_lastname.value);
      console.log(event.target.register_steps_phone.value);
      console.log(event.target.register_steps_password.value);
      //console.log(event.target.register_steps_startup.value);
      console.log(event.target.register_steps_company.value);
      console.log(event.target.register_steps_industry.value);
      console.log(event.target.register_steps_address.value);
      console.log(event.target.register_steps_pc.value);
      console.log(event.target.register_steps_locality.value);
      console.log(event.target.register_steps_province.value);
      console.log(event.target.register_steps_country.value);
      console.log(event.target.register_steps_newsletter.value);
      console.log(event.target.register_steps_terms.value);
      console.log('registro');

      this.refs.buttonRegister.removeAttribute('disabled');
      //this.buttonRegister.removeAttribute("disabled");
    }
  };
  handleStep = (event) => {
    var stepReal;
    if (
      (event === 2 || event === 3 || event === 4) &&
      this.state.isFounder === false
    ) {
      stepReal = 1;
    } else {
      stepReal = event;
    }
    this.setState({
      activeStep: stepReal,
    });
  };
  render() {
    return (
      <Container>
        <Grid container xs={12}>
          <Grid item={true} xs={12} sm={12} className="formDelimiter">
            <Stepper
              nonLinear
              alternativeLabel
              activeStep={this.state.activeStep}
            >
              {this.state.steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => this.handleStep(index)}>
                    {label}
                    {index === 0 && this.state.step1 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 1 && this.state.step2 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 2 && this.state.step3 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 3 && this.state.step4 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 4 && this.state.step5 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 5 && this.state.step6 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                  </StepButton>
                </Step>
              ))}
            </Stepper>

            <div>
              {this.state.activeStep === this.state.steps.length ? (
                <div>
                  <Typography>Ya no quedan mas pasos</Typography>
                </div>
              ) : (
                <div>
                  {this.getStepContent(this.state.activeStep)}

                  <div className="buttons-registration">
                    <Button
                      disabled={this.state.activeStep === 0}
                      onClick={() => {
                        this.handleBack();
                      }}
                    >
                      Anterior
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="button-nextStep"
                      onClick={() => {
                        this.handleNext();
                      }}
                    >
                      {this.state.activeStep === this.state.length - 1
                        ? 'Finzalizar'
                        : 'Siguiente'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default RegistroForm4;
