import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  TextareaAutosize,
  Button,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { withSnackbar } from 'notistack';
import { getUserData, getUserToken } from '../components/utils/manageUserData';

class BlogPost extends Component {
  constructor(props) {
    super(props);

    const permalink = this.props.match.params.post;

    console.log('props', props);

    this.state = {
      userToken: getUserToken(),
      userData: JSON.parse(getUserData()),
      post: [],
      ads: [],
      permalink,
      tyc: 0,
      name: '',
      lastname: '',
      email: '',
      username: '',
      comment: '',
      baseImgSrc: process.env.REACT_APP_API_URL_PROD + 'uploads/',
    };

    console.log('state', this.state);
  }

  componentDidMount() {
    this.getPost();
  }

  getPost = () => {
    const apiurl = '/api/blog/post/' + this.state.permalink;

    console.log('apiurl', apiurl);

    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        this.setState({
          post: response.data.post,
        });
        console.log(response.data.post);
      })
      .catch((error) => {});
  };

  verifyFields() {
    const {
      userToken,
      comment,
      username,
      tyc,
      name,
      lastname,
      password,
      email,
    } = this.state;

    console.log(this.state);

    if (!userToken) {
      console.log('no user token');

      if (
        !name ||
        !username ||
        !lastname ||
        !password ||
        !this.validateEmail(email) ||
        tyc !== 1 ||
        !comment
      ) {
        return false;
      }

      return true;
    } else {
      if (!comment) {
        return false;
      }
      return true;
    }
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  sendComment = () => {
    console.log(this.state);

    var apiurl = '/api/blog/' + this.state.post._id + '/comments';

    const {
      userToken,
      comment,
      username,
      tyc,
      name,
      lastname,
      password,
      email,
    } = this.state;

    if (!this.verifyFields()) {
      this.props.enqueueSnackbar('Por favor, rellene los campos', {
        variant: 'error',
        autoHideDuration: 3000,
      });

      return false;
    }

    if (comment.length < 5) {
      this.props.enqueueSnackbar(
        'El comentario no puede ser menor de 5 caracteres de longitud',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
      return false;
    }

    if (!userToken) {
      const payload = {
        comment,
        username,
        tyc,
        name,
        lastname,
        password,
        email,
        type: 'NEW_USER',
      };

      axios({
        url: apiurl,
        method: 'PATCH',
        data: payload,
      })
        .then((response) => {
          console.log(response);
          this.props.enqueueSnackbar(
            'Comentario añadido! Será revisado por el administrador antes de su publicación.',
            {
              variant: 'success',
              autoHideDuration: 3000,
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const payload = {
        comment,
        username: this.state.userData.username
          ? this.state.userData.username
          : this.state.userData.name,
        name: this.state.userData.name,
        lastname: this.state.userData.lastName,
        email: this.state.userData.email,
        type: 'USER_EXISTS',
      };

      axios({
        url: apiurl,
        method: 'PATCH',
        data: payload,
      })
        .then((response) => {
          console.log(response);
          this.props.enqueueSnackbar(
            'Comentario añadido! Será revisado por el administrador antes de su publicación.',
            {
              variant: 'success',
              autoHideDuration: 3000,
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    const guestForm = (
      <Row className="w-100">
        <Row className="w-100">
          <Col>
            <FormControl className="w-100">
              <InputLabel htmlFor="form_comment">Nombre </InputLabel>
              <Input
                id="form_name"
                name="form_name"
                aria-describedby="form_name"
                type="text"
                required
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
              />
            </FormControl>
          </Col>
          <Col>
            <FormControl className="w-100">
              <InputLabel htmlFor="form_comment">Apellidos </InputLabel>
              <Input
                id="form_lastname"
                name="form_lastname"
                aria-describedby="form_lastname"
                type="text"
                required
                onChange={(e) => {
                  this.setState({ lastname: e.target.value });
                }}
              />
            </FormControl>
          </Col>
        </Row>
        <Row className="w-100">
          <Col>
            <FormControl className="w-100">
              <InputLabel htmlFor="form_comment">Nombre de usuario</InputLabel>
              <Input
                id="form_username"
                name="form_username"
                aria-describedby="form_username"
                type="text"
                required
                onChange={(e) => {
                  this.setState({ username: e.target.value });
                }}
              />
            </FormControl>
          </Col>
          <Col>
            <FormControl className="w-100">
              <InputLabel htmlFor="form_password">Contraseña</InputLabel>
              <Input
                id="form_password"
                name="form_password"
                aria-describedby="form_password"
                type="password"
                required
                onChange={(e) => {
                  this.setState({ password: e.target.value });
                }}
              />
            </FormControl>
          </Col>
        </Row>
        <Row className="w-100">
          <Col>
            <FormControl className="w-100">
              <InputLabel htmlFor="form_password">Email</InputLabel>
              <Input
                id="form_email"
                name="form_email"
                aria-describedby="form_email"
                type="email"
                required
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
            </FormControl>
          </Col>
          <Col>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    this.state.tyc === 0 ? this.setState({ tyc: 1 }) : 0
                  }
                  name="checkedA"
                  required
                />
              }
              label={
                <span>
                  Acepto los{' '}
                  <a href="/politica-de-privacidad" target="_blank">
                    términos y condiciones
                  </a>
                </span>
              }
            />
          </Col>
        </Row>
        <Row className="w-100">
          <Col>
            <FormControl className="w-100">
              <p>Comentario</p>
              <TextareaAutosize
                id="form_comment"
                name="form_comment"
                aria-describedby="form_comment"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  this.setState({ comment: e.target.value });
                }}
                style={{ minHeight: '80px' }}
              />
            </FormControl>
          </Col>
        </Row>{' '}
      </Row>
    );

    const userForm = (
      <Row className="w-100">
        <Row className="w-100">
          <Col>
            <FormControl className="w-100">
              <p>Comentario</p>
              <TextareaAutosize
                id="form_comment"
                name="form_comment"
                aria-describedby="form_comment"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  this.setState({ comment: e.target.value });
                }}
                style={{ minHeight: '80px' }}
              />
            </FormControl>
          </Col>
        </Row>{' '}
      </Row>
    );

    const adBanner = this.state.baseImgSrc + this.state.post.adsBanner;

    const headerImg = this.state.baseImgSrc + this.state.post.headerImg;

    return (
      <Container
        className="MuiContainer-root bodyWrapper MuiContainer-maxWidthLg bodyWrapper blog"
        style={{ background: '#F3F8FF' }}
        fluid
      >
        <Row>
          {this.state.post.headerImg !== null &&
          this.state.post.headerImg !== undefined &&
          this.state.post.headerImg !== '' ? (
            <div
              style={{ background: `url(${headerImg})` }}
              className="limited-img"
            ></div>
          ) : (
            ''
          )}
        </Row>
        <Row>
          <div className="w-100 content">
            <h2 className="center mb-3">{this.state.post.title}</h2>
            <div
              className="fromEditor"
              dangerouslySetInnerHTML={{
                __html: this.state.post.htmlContent,
              }}
            ></div>
          </div>
        </Row>

        <Row>
          {adBanner !== null && adBanner !== undefined && adBanner !== '' ? (
            <div
              style={{ background: `url(${adBanner})` }}
              className="limited-img"
            ></div>
          ) : (
            ''
          )}
        </Row>

        <Row>
          <h3 className="center mb-3">Comentarios</h3>
        </Row>
        <Row style={{ paddingLeft: '3rem', paddingRight: '2rem' }}>
          {this.state.userToken ? userForm : guestForm}
          <Row className="w-100" style={{ marginBottom: '2rem' }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="button center-table"
              style={{ display: 'table', margin: '0 auto' }}
              onClick={this.sendComment}
            >
              Enviar comentario
            </Button>
          </Row>

          <Row>
            {this.state.post.comments
              ? this.state.post.comments.map((comment) => {
                  if (comment.approved) {
                    return (
                      <div className="center comment ">
                        <div className="username">
                          {comment.username ? comment.username : comment.name}
                        </div>

                        <div className="content "> {comment.comment}</div>
                      </div>
                    );
                  } else {
                    return '';
                  }
                })
              : ''}
          </Row>
        </Row>
      </Container>
    );
  }
}
export default withSnackbar(BlogPost);
