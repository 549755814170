import React, { Component } from "react";
import { Grid, Container, Button, Divider } from "@material-ui/core";
import { getUserData, getUserToken } from "../../../utils/manageUserData";
import axios from "axios";
import { withSnackbar } from "notistack";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import { CSVLink } from "react-csv";
class listTransactions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userToken: "",
			userData: "",
			startups: "",
			startDate: "",
			endDate: "",
		};
	}
	componentDidMount() {
		var date = new Date();
		var date1 = date.setDate(date.getDate());
		var date2 = date.setDate(date.getDate() - 30);
		this.setState({
			startDate: date2,
			endDate: date1,
		});
		(async () => {
			await this.getUserInformation();
			await this.getUserToken();
			await this.getAllStartups(date2, date1);
		})();
	}
	getUserToken = async () => {
		var userToken = getUserToken();
		if (userToken !== false) {
			const obj = userToken;
			this.setState({
				userToken: obj,
			});
		}
	};
	getUserInformation = async () => {
		var userData = getUserData();

		if (userData !== false) {
			const obj = JSON.parse(userData);
			this.setState({
				userData: obj,
			});
		}
	};

	getAllStartups = async (dateInit, dateEnd) => {
		var date1 = moment(dateInit).format("YYYY-MM-DD");
		var date2 = moment(dateEnd).format("YYYY-MM-DD");
		console.log(date1);
		console.log(date2);
		var userToken = this.state.userToken;
		var apiurl = "/api/startups/transactions";

		const payload = {
			dateInit: date1,
			dateEnd: date2,
		};
		axios({
			url: apiurl,
			method: "POST",
			data: payload,
			headers: {
				"x-token": userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				var listOfMovements = [];
				for (const item of response.data.transactions[0]) {
					if (item.substractCalc !== "0.00") {
						const obj = {
							name: item.name,
							permalink: "/startups/" + item.permalink,
							substractCalc: item.substractCalc,
							_id: item._id,
							type: "Startup",
						};
						listOfMovements.push(obj);
					}
				}
				for (const item of response.data.transactions[1]) {
					if (item.substractCalc !== "0.00") {
						const obj = {
							name: item.name,
							permalink: "/company/" + item.permalink,
							substractCalc: item.substractCalc,
							_id: item._id,
							type: "Company",
						};
						listOfMovements.push(obj);
					}
				}
				this.setState({
					startups: listOfMovements,
				});
			})
			.catch((error) => {});
	};

	setStartDate = (date) => {
		this.setState({
			startDate: date,
		});
	};
	setEndDate = (date) => {
		this.setState({
			endDate: date,
		});
	};
	filterDates = () => {
		if (this.state.startDate !== "" && this.state.endDate !== "") {
			var startTime = new Date(this.state.startDate);
			var endTime = new Date(this.state.endDate);
			if (startTime > endTime) {
				this.props.enqueueSnackbar(
					"La fecha de incicio es superior a la fecha de finalización.",
					{
						variant: "error",
						autoHideDuration: 3000,
					}
				);
			} else {
				this.getAllStartups(this.state.startDate, this.state.endDate);
			}
		}
	};
	render() {
		const columns = [
			{
				field: "name",
				headerName: "Nombre",
				editable: false,
				sortable: true,
				flex: 1,
			},

			{
				field: "permalink",
				headerName: "Ir a pagina",
				sortable: false,
				flex: 1,
				disableClickEventBubbling: true,
				renderCell: (params) => {
					return (
						<div>
							<a href={params.value} target="_blank" rel="noreferrer">
								Ir a {params.row.type === "Startup" ? " Startup" : " Empresa"}
							</a>
						</div>
					);
				},
			},
			{
				field: "substractCalc",
				headerName: "Total",
				type: "number",
				editable: false,
				sortable: true,
				flex: 1,
				renderCell: (params) => {
					return (
						<div>
							<p>{params.value}€</p>
						</div>
					);
				},
			},
		];
		const headersCSV = [
			{ label: "Nombre Startup", key: "name" },
			{ label: "Total en €", key: "substractCalc" },
		];
		return (
			<Container>
				<Grid container>
					<Grid item xs={12}>
						<p>Seleccione el rango de fechas para ver las transacciones</p>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<p>Fecha de comienzo</p>
								<div>
									<DatePicker
										dateFormat="dd/MM/yyyy"
										selected={this.state.startDate}
										onChange={(date) => this.setStartDate(date)}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>Fecha de Finalizacion</p>
								<div>
									<DatePicker
										dateFormat="dd/MM/yyyy"
										selected={this.state.endDate}
										onChange={(date) => this.setEndDate(date)}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Button
									className="button"
									onClick={this.filterDates}
									disabled={!this.state.startDate || !this.state.endDate}
								>
									Filtrar
								</Button>
							</Grid>
							<Grid item xs={12} sm={6}>
								<CSVLink
									data={this.state.startups}
									headers={headersCSV}
									filename={"Exportacion_Transacciones.csv"}
								>
									<Button className="button">Exportar</Button>
								</CSVLink>
							</Grid>
							<Grid item xs={12}>
								<Divider className="separator-30" />
							</Grid>
						</Grid>
						<Grid item xs={12}>
							{this.state.startups === "" ? (
								<p>No hay Información</p>
							) : (
								<DataGrid
									rows={this.state.startups}
									getRowId={(row) => row._id}
									autoHeight
									columns={columns}
									pageSize={5}
									key={this.state.startups}
									localeText={{
										noRowsLabel: "No hay datos",
									}}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		);
	}
}
export default withSnackbar(listTransactions);
