import React, { Component } from 'react';
import axios from 'axios';
import { Container } from '@material-ui/core';
import {
  InputLabel,
  Input,
  FormControl,
  Grid,
  Select,
  Button,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import './../../../styles/styles.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { withSnackbar } from 'notistack';

class EditJob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startup: this.props.startup,
      userToken: this.props.userToken,
      jobId: this.props.extraProps.jobId,
      job: [],
      editJobTitle: '',
      editJobDescription: '',
      editJobTown: '',
      editJobCountry: '',
      editJobFormationRequirements: '',
      editJobSalary: '',
      editJobType: '',
      editJobCustomType: '',
      editJobActive: 1,
      editJobPhone: '',
      editJobEmail: '',
    };
  }

  componentDidMount() {
    this.getJob();
  }

  getJob = async () => {
    const apiurl = '/api/jobs/' + this.state.jobId;
    const { data } = await axios.get(apiurl);
    this.setState({
      job: data.job,
      editJobTitle: data.job.job || '',
      editJobDescription: data.job.jobDescription || '',
      editJobTown: data.job.town || '',
      editJobCountry: data.job.town || '',
      editJobFormationRequirements: data.job.formationRequirements || '',
      editJobSalary: data.job.salary || '',
      editJobType: data.job.type || '',
      editJobCustomType: data.job.customType || '',
      editJobActive: data.job.active === true ? 1 : 0,
      editJobPhone: data.job.phone || '',
      editJobEmail: data.job.email || '',
    });
  };

  updateJob = async () => {
    console.log('update job');

    const apiurl = '/api/jobs/' + this.state.jobId;

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const payload = {
      jobId: this.state.jobId,
      job: this.state.editJobTitle,
      jobDescription: this.state.editJobDescription,
      town: this.state.editJobTown,
      country: this.state.editJobCountry,
      formationRequirements: this.state.editJobFormationRequirements,
      salary: this.state.editJobSalary,
      type: this.state.editJobType,
      customType: this.state.editJobCustomType,
      active: this.state.editJobActive,
      phone: this.state.editJobPhone,
      email: this.state.editJobEmail,
    };

    const { data } = await axios.put(apiurl, payload, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.props.enqueueSnackbar('Oferta de empleo actualizada', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  };

  render() {
    return (
      <Container>
        <h1 className="job-page-title">
          {this.state.editJobTitle || 'Creando oferta de empleo'}
        </h1>
        <form
          onSubmit={this.submitRegisterStep1}
          className="form"
          key={this.state.userData}
        >
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  Título de la oferta de empleo
                </InputLabel>
                <Input
                  id="editJobTitle"
                  name="editJobTitle"
                  aria-describedby="editJobTitle"
                  type="text"
                  value={this.state.editJobTitle}
                  onChange={(e) =>
                    this.setState({ editJobTitle: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  Descripción de la oferta de empleo
                </InputLabel>
                <ReactQuill
                  style={{ marginTop: '1.8rem' }}
                  name="editJobDescription"
                  modules={this.modules}
                  formats={this.formats}
                  onChange={(e) => this.setState({ editJobDescription: e })}
                  value={this.state.editJobDescription}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={6} xs={6}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  País
                </InputLabel>
                <Input
                  id="editJobCountry"
                  name="editJobCountry"
                  aria-describedby="editJobCountry"
                  type="text"
                  value={this.state.editJobCountry}
                  onChange={(e) =>
                    this.setState({ editJobCountry: e.target.value })
                  }
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={6} xs={6}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  Ciudad
                </InputLabel>
                <Input
                  id="editJobTown"
                  name="editJobTown"
                  aria-describedby="editJobTown"
                  type="text"
                  value={this.state.editJobTown}
                  onChange={(e) =>
                    this.setState({ editJobTown: e.target.value })
                  }
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  Formación requerida
                </InputLabel>
                <ReactQuill
                  style={{ marginTop: '1.8rem' }}
                  name="editJobFormationRequirements"
                  modules={this.modules}
                  formats={this.formats}
                  onChange={(e) =>
                    this.setState({ editJobFormationRequirements: e })
                  }
                  value={this.state.editJobFormationRequirements}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={6} xs={6}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  Salario
                </InputLabel>
                <Input
                  id="editJobSalary"
                  name="editJobSalary"
                  aria-describedby="editJobSalary"
                  type="text"
                  value={this.state.editJobSalary}
                  onChange={(e) =>
                    this.setState({
                      editJobSalary: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={6} xs={6}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  Tipo de oferta
                </InputLabel>
                <Select
                  id="editJobType"
                  name="editJobType"
                  aria-describedby="editJobType"
                  type="text"
                  value={this.state.editJobType}
                  onChange={(e) =>
                    this.setState({
                      editJobType: e.target.value,
                    })
                  }
                >
                  <MenuItem value={'Prácticas'}>Prácticas</MenuItem>
                  <MenuItem value={'Empleo'}>Empleo</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item={true} sm={6} xs={6}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  Tipo de contrato
                </InputLabel>
                <Input
                  id="editJobType"
                  name="editJobType"
                  aria-describedby="editJobType"
                  type="text"
                  value={this.state.editJobCustomType}
                  onChange={(e) =>
                    this.setState({
                      editJobCustomType: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={6} xs={6}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  Teléfono
                </InputLabel>
                <Input
                  id="editJobPhone"
                  name="editJobPhone"
                  aria-describedby="editJobPhone"
                  type="text"
                  value={this.state.editJobPhone}
                  onChange={(e) =>
                    this.setState({
                      editJobPhone: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={5} xs={5}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  Email
                </InputLabel>
                <Input
                  id="editJobEmail"
                  name="editJobEmail"
                  aria-describedby="editJobEmail"
                  type="text"
                  value={this.state.editJobEmail}
                  onChange={(e) =>
                    this.setState({
                      editJobEmail: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={2} xs={2} style={{ marginLeft: '20px' }}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_name_company_crowfunding"
                  shrink={true}
                >
                  Oferta activa
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.editJobActive}
                  label="Age"
                  onChange={(e) =>
                    this.setState({ editJobActive: e.target.value })
                  }
                >
                  <MenuItem value={1}>Sí</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <Button
                variant="contained"
                style={{
                  background: '#6658ea',
                  color: 'white',
                  marginBottom: '2rem',
                  marginLeft: '1rem',
                  display: 'table',
                  margin: '0 auto',
                  marginTop: '2rem',
                }}
                onClick={() => this.updateJob()}
              >
                Guardar oferta de empleo
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

export default withSnackbar(EditJob);
