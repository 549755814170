import React, { Component } from "react";
import {
	Grid,
	Container,
	Button,
	InputLabel,
	Input,
	FormControl,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getUserData, getUserToken } from "../../../utils/manageUserData";
import axios from "axios";
import { withSnackbar } from "notistack";

class createUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userToken: "",
			userData: "",
			users: "",
			userToEdit: "",
			userToEditUid: "",
			update_account: "",
		};
	}
	componentDidMount() {
		(async () => {
			await this.getUserInformation();
			await this.getUserToken();
			await this.getAllUsers();
		})();
	}
	getUserToken = async () => {
		var userToken = getUserToken();
		if (userToken !== false) {
			const obj = userToken;
			this.setState({
				userToken: obj,
			});
		}
	};
	getUserInformation = async () => {
		var userData = getUserData();

		if (userData !== false) {
			const obj = JSON.parse(userData);
			this.setState({
				userData: obj,
			});
		}
	};

	getAllUsers = async () => {
		var userToken = this.state.userToken;
		var apiurl = "/api/users";

		await axios({
			url: apiurl,
			method: "GET",
			headers: {
				"x-token": userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				this.setState({
					users: response.data.users,
				});
			})
			.catch((error) => {
				console.log("No startup Found");
			});
	};
	editUser = (userData) => {
		this.setState({
			userToEdit: userData,
			userToEditUid: userData.uid,
		});
		console.log(userData);
	};
	createUserData = (event) => {
		event.preventDefault();

		const payload = {
			name: event.target.create_user_name.value,
			lastName: event.target.create_user_lastname.value,
			phone: event.target.create_user_phone.value,
			streetName: event.target.create_user_address.value,
			flatNumber: event.target.create_user_address_number.value,
			postCode: event.target.create_user_pc.value,
			locality: event.target.create_user_locality.value,
			province: event.target.create_user_province.value,
			country: event.target.create_user_country.value,
			pwd: event.target.create_user_pwd.value,
			email: event.target.create_user_email.value,
			role: this.state.update_account,
			emailValidated: true,
		};

		var apiurl = "/api/users";
		axios({
			url: apiurl,
			method: "POST",
			data: payload,
			headers: {
				"x-token": this.props.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				console.log("Data has been sent to the server");

				this.props.enqueueSnackbar("Datos guardados con éxito", {
					variant: "success",
				});
				this.getAllUsers();
				//window.location.reload();
				//this.emailVerification(email);
			})
			.catch((error) => {
				this.props.enqueueSnackbar("Error al guardar los datos", {
					variant: "error",
				});
				console.log(error.response);
				console.log("Error");
			});
	};
	handleChangeAccount = (event) => {
		console.log(event.target.value);
		this.setState({
			update_account: event.target.value,
		});
	};
	render() {
		console.log(this.state.userData);
		return (
			<Container>
				<Grid container className="borderShadow">
					<Grid item xs={12}>
						{this.state.userData.role === "ADMIN" ? (
							<form
								onSubmit={this.createUserData}
								className="form"
								key={this.state.userToEditUid}
							>
								<Grid container>
									<Grid item={true} sm={12} xs={12}>
										<FormControl className="width-100">
											<InputLabel id="update_account">Rol</InputLabel>
											<Select
												labelId="update_account"
												id="update_account"
												name="update_account"
												onChange={this.handleChangeAccount}
											>
												<MenuItem value={"CROWDFUNDING_ADMIN"}>
													Administrador de crowdfunding
												</MenuItem>
												<MenuItem value={"SECONDARY_USER_ADMIN"}>
													Administrador de secundarias
												</MenuItem>

												<MenuItem value={"ADMIN"}>SUPER ADMIN</MenuItem>
												<MenuItem value={"RESTRICTED_ADMIN"}>
													SUPER ADMIN (RESTRINGIDO)
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item={true} sm={6} xs={12}>
										{" "}
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_name">
												Nombre{" "}
											</InputLabel>
											<Input
												id="create_user_name"
												name="create_user_name"
												aria-describedby="create_user_name"
												type="text"
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={6} xs={12}>
										{" "}
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_lastname">
												Apellidos{" "}
											</InputLabel>
											<Input
												id="create_user_lastname"
												name="create_user_lastname"
												aria-describedby="create_user_lastname"
												type="text"
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={6} xs={12}>
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_email">
												Email*
											</InputLabel>
											<Input
												id="create_user_email"
												name="create_user_email"
												aria-describedby="create_user_email"
												type="text"
												required
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={6} xs={12}>
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_phone">
												Teléfono
											</InputLabel>
											<Input
												id="create_user_phone"
												name="create_user_phone"
												aria-describedby="create_user_phone"
												type="text"
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={12} xs={12}>
										{" "}
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_address">
												Dirección
											</InputLabel>
											<Input
												id="create_user_address"
												name="create_user_address"
												aria-describedby="create_user_address"
												type="text"
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={6} xs={12}>
										{" "}
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_address_number">
												N/Piso{" "}
											</InputLabel>
											<Input
												id="create_user_address_number"
												name="create_user_address_number"
												aria-describedby="create_user_address_number"
												type="text"
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={6} xs={12}>
										{" "}
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_pc">
												Código Postal{" "}
											</InputLabel>
											<Input
												id="create_user_pc"
												name="create_user_pc"
												aria-describedby="create_user_pc"
												type="number"
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={6} xs={12}>
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_locality">
												Localidad
											</InputLabel>
											<Input
												id="create_user_locality"
												name="create_user_locality"
												aria-describedby="create_user_locality"
												type="text"
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={6} xs={12}>
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_province">
												Provincia
											</InputLabel>
											<Input
												id="create_user_province"
												name="create_user_province"
												aria-describedby="create_user_province"
												type="text"
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={6} xs={12}>
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_country">
												País
											</InputLabel>
											<Input
												id="create_user_country"
												name="create_user_country"
												aria-describedby="create_user_country"
												type="text"
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={6} xs={12}>
										<FormControl className="width-100">
											<InputLabel htmlFor="create_user_pwd">
												Password*
											</InputLabel>
											<Input
												id="create_user_pwd"
												name="create_user_pwd"
												aria-describedby="create_user_pwd"
												type="text"
												required
											/>
										</FormControl>
									</Grid>
									<Grid item={true} sm={12} xs={12} className="center">
										{" "}
										<Button
											variant="contained"
											color="primary"
											type="submit"
											className="button center"
											ref="buttonRegister"
										>
											Guardar y continuar
										</Button>
									</Grid>
								</Grid>
							</form>
						) : (
							<p>No tiene permisos para crear usuarios</p>
						)}
					</Grid>
				</Grid>
			</Container>
		);
	}
}
export default withSnackbar(createUser);
