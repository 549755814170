import React, { Component } from "react";

import { Grid, Container } from "@material-ui/core";

import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
class EmailValidation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			validationState: 2,
		};
	}
	componentDidMount() {
		(async () => {
			await this.verificationEmail();
		})();
	}
	verificationEmail = async () => {
		const token = this.props.match.params.token;
		console.log(token);

		if (token) {
			var apiurl = "/api/auth/email-validation/" + token;
			axios({
				url: apiurl,
				method: "PUT",
			})
				.then((response) => {
					console.log("Data has been sent to the server");
					console.log(response);
					this.setState({
						validationState: 1,
					});
				})
				.catch((error) => {
					console.log(error);
					this.setState({
						validationState: 0,
					});
				});
		} else {
			this.setState({
				validationState: 0,
			});
		}
	};
	login = () => {
		document.getElementById("buttonLoginTop").click();
	};
	render() {
		return (
			<Container>
				<Grid container className="margin-top-10">
					<Grid item xs={12} sm={2}></Grid>
					<Grid item xs={12} sm={8}>
						{this.state.validationState === 2 ? (
							<div className=" center">
								<CloseIcon className="iconBig circleIcon" />
								<h2>Estamos validando su cuenta</h2>
							</div>
						) : this.state.validationState === 1 ? (
							<div className=" center">
								<CheckIcon className="iconBig circleIcon" />
								<h2>Su cuenta ha sido validada</h2>
							</div>
						) : (
							<div className=" center">
								<CloseIcon className="iconBig circleIcon" />
								<h2>Su cuenta no ha sido validada</h2>
							</div>
						)}
						<Grid container>
							<Grid item xs={12} sm={5}>
								<div className="center">
									<a href="/" className="buttonlink">
										<p className="button">Ir al a pagina principal</p>
									</a>
								</div>
							</Grid>
							<Grid item xs={12} sm={2}></Grid>
							<Grid item xs={12} sm={5}>
								<div className="center">
									<p className="button" onClick={() => this.login()}>
										Iniciar sesión
									</p>
								</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={2}></Grid>
				</Grid>
			</Container>
		);
	}
}
export default EmailValidation;
