import React, { Component } from 'react';
import { Navbar, Nav, Modal, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginModal from './modal/login';
import Cookies from 'js-cookie';
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import { userCompleteSteps } from './utils/manageUserData';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusProfile: 0,
      openNotLogged: false,
    };
  }

  componentDidMount() {
    (async () => {
      await this.checkUserCompleteSteps();
    })();
  }
  checkUserCompleteSteps = async () => {
    let islogged = Cookies.get('tokenLogin');
    let userInfo = Cookies.get('userInformation');
    var alreadyChecked = Cookies.get('closedCompleteProfile');

    if (userInfo !== undefined) {
      const obj = JSON.parse(userInfo);
      if (islogged) {
        if (
          (await userCompleteSteps(obj.role)) === 'incomplete' &&
          !alreadyChecked
        ) {
          this.setState({
            openNotLogged: true,
          });
        }
      }
    }
  };
  closeSession = () => {
    Cookies.remove('tokenLogin');
    Cookies.remove('closedCompleteProfile');
    Cookies.remove('userInformation');
    window.location.reload();
  };
  handleClose = () => {
    Cookies.set('closedCompleteProfile', true, { expires: 1 });
    if (this.state.openNotLogged === false) {
      this.setState({
        openNotLogged: true,
      });
    } else {
      this.setState({
        openNotLogged: false,
      });
    }
  };
  render() {
    return (
      <div className="bg-light">
        <Modal
          show={this.state.openNotLogged}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Perfil sin completar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Hola, parece que su perfil aún no está completo.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
            <Link to="/perfil">
              <Button variant="primary" onClick={this.handleClose}>
                Completar
              </Button>
            </Link>
          </Modal.Footer>
        </Modal>

        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          className="MuiContainer-maxWidthLg center headerPadding"
        >
          <Navbar.Brand href="/">
            <img
              src="/images/logo.png"
              width="200"
              className="d-inline-block align-top"
              alt="Estartaps"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/buscador-de-startups">Startups</Nav.Link>
              {/* <NavDropdown title="Inversión" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/invertir-en-startups">
                  Startups
                </NavDropdown.Item>
                <NavDropdown.Item href="/invertir-en-secundarias">
                  Secundarias
                </NavDropdown.Item>
              </NavDropdown> */}
              <Nav.Link href="/invertir-en-startups">Inversión</Nav.Link>
              <Nav.Link href="/empleos">Empleo</Nav.Link>
              <Nav.Link href="/servicios-a-startups">
                Servicios a Startups
              </Nav.Link>
              <Nav.Link href="/reto">Reto</Nav.Link>
              <Nav.Link href="/zombilan">Zombilan</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link eventKey={2}>
                {Cookies.get('tokenLogin') !== undefined ? (
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="myAccountButton"
                    >
                      <Link to="/perfil" id="myaccountTop">
                        <AccountCircleIcon /> Mi cuenta
                      </Link>
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.closeSession}
                    >
                      <ExitToAppIcon /> Cerrar Sesión
                    </Button>
                  </div>
                ) : (
                  <LoginModal />
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
export default Header;
