import React, { Component } from 'react';
import {
  Grid,
  Container,
  Button,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';
import Moment from 'react-moment';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../utils/cropImage';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
class listStartup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToken: '',
      userData: '',
      userStartup: '',
      startupToEdit: '',
      imageToUpload: '',
      croppedImage: '',
      croppedImageFile: '',
      crop: { x: 0, y: 0 },
      croppedAreaPixels: '',
      uploadedImage: '',
      croppedArea: '',
      zoom: 1,
      aspect: 1 / 1,
      cropSize: { width: 300, height: 300 },
      products_array: [],
      teams_array: [],
      open: false,
      startupToDelete: [],
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.getAllStartups();
    })();
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
    }
  };

  getAllStartups = async () => {
    var userToken = this.state.userToken;
    var apiurl = '/api/company';

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response.data);
        this.setState({
          userStartup: response.data.companies,
        });
      })
      .catch((error) => {
        console.log('No startup Found');
      });
  };
  editStartup = (startupData) => {
    this.setState({
      startupToEdit: startupData,
      startupToEditUid: startupData._id,
    });
  };
  submitAlldata = (payload) => {
    var apiurlput = '/api/company/' + this.state.startupToEdit._id;

    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.props.enqueueSnackbar('Datos guardados con éxito', {
          variant: 'success',
        });
        this.getAllStartups();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
      });
  };
  submitUpdateStartup = (event) => {
    event.preventDefault();
    var url = event.target.update_presentacion_video.value;

    if (url !== '') {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length === 11) {
        url =
          'https://www.youtube.com/embed/' +
          match[2] +
          '?autoplay=0&enablejsapi=1';
      } else {
        this.props.enqueueSnackbar('El enlace a Youtube no es correcto', {
          variant: 'error',
        });
        url = '';
      }
    }
    if (this.state.startupToEdit !== '') {
      if (this.state.croppedImageFile) {
        let formData = new FormData();
        formData.append('file', this.state.croppedImageFile);

        axios
          .put('/api/uploads/company/' + this.state.startupToEdit._id, formData)
          .then((res) => {
            console.log(res.data);
            const payload = {
              name: event.target.update_startupName.value,
              shortDescription: event.target.update_startup_description.value,
              longDescription: this.state.longDescription,
              servicesDescription: event.target.update_startup_products.value,
              teamDescription: event.target.update_startup_team.value,
              cif: event.target.update_cifStartup.value,
              industries: event.target.update_industry.value,
              videoPresentation: url,
            };
            this.submitAlldata(payload);
          })
          .catch((error) => {
            console.log(error.response);
            alert('Se ha encontrado un error y no se ha subido la imagen');
          });
      } else {
        const payload = {
          name: event.target.update_startupName.value,
          shortDescription: event.target.update_startup_description.value,
          longDescription: this.state.longDescription,
          servicesDescription: event.target.update_startup_products.value,
          teamDescription: event.target.update_startup_team.value,
          cif: event.target.update_cifStartup.value,
          industries: event.target.update_industry.value,
          videoPresentation: url,
        };
        this.submitAlldata(payload);
      }
    }
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedArea: croppedArea,
      croppedAreaPixels: croppedAreaPixels,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  handleChange = (event) => {
    var image = event.target.files[0];
    if (event.target.files[0]) {
      this.setState({
        uploadedImage: event.target.files[0],
        imageSrc: URL.createObjectURL(image),
      });
    } else {
      this.setState({
        uploadedImage: '',
        imageSrc: '',
      });
    }
  };
  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
      this.state.rotation
    );

    let response = await fetch(croppedImage);
    let data = await response.blob();
    let metadata = {
      type: 'image/png',
    };
    let file = new File([data], 'Startup.png', metadata);

    this.setState({
      croppedImageFile: file,
    });
    this.setState({
      croppedImage: croppedImage,
    });
  };
  handleShow = (startupData) => {
    console.log(startupData);
    if (this.state.open === false) {
      this.setState({
        open: true,
        startupToDelete: startupData,
      });
    } else {
      this.setState({
        open: false,
        startupToDelete: '',
      });
    }
  };
  deleteStartup = async () => {
    var userToken = this.state.userToken;
    var apiurl = '/api/company/' + this.state.startupToDelete._id;

    await axios({
      url: apiurl,
      method: 'DELETE',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);

        this.setState({
          startupToDelete: '',
          open: false,
        });
        this.getAllStartups();
      })
      .catch((error) => {
        console.log('No se ha eliminado la startup');
      });
  };
  render() {
    const columns = [
      {
        field: 'name',
        headerName: 'Nombre',
        editable: false,
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Acciones',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.editStartup(params.row)}
              >
                Editar
              </Button>
              <Button
                variant="secondary"
                className="button"
                onClick={() => this.handleShow(params.row)}
              >
                Borrar
              </Button>
            </div>
          );
        },
      },
    ];
    const columnsTrans = [
      {
        field: 'amount',
        headerName: 'Coste',
        editable: false,
        flex: 1,
      },

      {
        field: 'word',
        headerName: 'Palabra ',
        editable: false,
        flex: 1,
      },
      {
        field: 'date',
        headerName: 'Fecha',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Moment format="DD/MM/YYYY hh:mm:ss">{params.value}</Moment>
            </div>
          );
        },
      },
    ];

    return (
      <Container>
        <Modal
          show={this.state.open}
          onHide={this.handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Empresa a eliminar</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.startupToDelete.name}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleShow}>
              Cerrar
            </Button>
            <Button variant="secondary" onClick={() => this.deleteStartup()}>
              Borrar
            </Button>
          </Modal.Footer>
        </Modal>
        <Grid container>
          <Grid item xs={12}>
            <div>
              {this.state.userStartup !== '' ? (
                <DataGrid
                  rows={this.state.userStartup}
                  getRowId={(row) => row._id}
                  autoHeight
                  columns={columns}
                  pageSize={5}
                />
              ) : (
                <p>Cargando</p>
              )}
            </div>
            <div key={this.state.startupToEditUid + this.state.userStartup}>
              {this.state.startupToEdit !== '' ? (
                <Grid container>
                  <form
                    onSubmit={this.submitUpdateStartup}
                    className="form"
                    key={this.state.startupToEditUid}
                  >
                    <Grid Container>
                      <Grid xs={12} sm={12} className="startupCover">
                        <div className="statupCoverTitle center">
                          <h2 key={this.state.userStartup}>
                            {this.state.startupToEdit.name}
                          </h2>
                        </div>

                        {this.state.startupToEdit.img ? (
                          <div className="startupCoverCircle">
                            <img
                              src={
                                process.env.REACT_APP_API_URL_PROD +
                                '/api/uploads/company/' +
                                this.state.startupToEdit._id
                              }
                              alt="startup profile cover"
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Información Básica</Typography>
                      </AccordionSummary>
                      <AccordionDetails key={this.state.startupToEditUid}>
                        <Grid container key={this.state.startupToEditUid}>
                          <Grid item={true} sm={12} xs={12}>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor="update_startupName"
                                shrink={true}
                              >
                                Lo más importante, ¿por qué nombre quieres que
                                conozcamos a tu Empresa?{' '}
                              </InputLabel>
                              <Input
                                id="update_startupName"
                                name="update_startupName"
                                aria-describedby="update_startupName"
                                type="text"
                                defaultValue={this.state.startupToEdit.name}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor="update_industry"
                                shrink={true}
                              >
                                ¿Cuál es la industria a la que pertenece tu
                                Empresa?
                              </InputLabel>
                              <Input
                                id="update_industry"
                                name="update_industry"
                                aria-describedby="update_industry"
                                type="text"
                                defaultValue={
                                  this.state.startupToEdit.industries
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                Nos gustaría que nos hicieses una breve
                                descripción de a qué se dedica tu Empresa{' '}
                              </p>
                              <TextareaAutosize
                                aria-label="minimum height"
                                id="update_startup_description"
                                name="update_startup_description"
                                rowsMin={3}
                                defaultValue={
                                  this.state.startupToEdit.shortDescription
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                Queremos acercar tu idea al mayor número de
                                clientes posibles. Para ello, un equipo de
                                Estartaps.com va a revisar todo lo que nos
                                cuentes de tu solución, intentando entender
                                hasta el mínimo detalle para que el mundo lo
                                conozca
                              </p>
                              <ReactQuill
                                name="longDescription"
                                modules={this.modules}
                                formats={this.formats}
                                onChange={this.handleChangeDescription}
                                defaultValue={this.state.longDescription}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                Ahora nos gustaría que nos contases los
                                diferentes productos o servicios que ofrece tu
                                Empresa
                              </p>
                              <TextareaAutosize
                                aria-label="minimum height"
                                id="update_startup_products"
                                name="update_startup_products"
                                rowsMin={3}
                                defaultValue={
                                  this.state.startupToEdit.servicesDescription
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            <FormControl className="width-100">
                              <InputLabel htmlFor="update_presentacion_video">
                                Video de presentación (Solo admite youtube)
                              </InputLabel>
                              <Input
                                id="update_presentacion_video"
                                name="update_presentacion_video"
                                aria-describedby="update_presentacion_video"
                                type="url"
                                defaultValue={
                                  this.state.startupToEdit.videoPresentation
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>
                                Nos gustaría conocer a tu equipo, cuéntanos
                                brevemente sobre ellos.
                              </p>
                              <TextareaAutosize
                                aria-label="minimum height"
                                id="update_startup_team"
                                name="update_startup_team"
                                rowsMin={3}
                                defaultValue={
                                  this.state.startupToEdit.teamDescription
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item={true} sm={12} xs={12}>
                            <FormControl className="width-100">
                              <InputLabel htmlFor="update_cifStartup">
                                CIF de la empresa
                              </InputLabel>
                              <Input
                                id="update_cifStartup"
                                name="update_cifStartup"
                                aria-describedby="update_cifStartup"
                                type="text"
                                defaultValue={this.state.startupToEdit.cif}
                              />
                            </FormControl>
                          </Grid>
                          {this.state.startupToEdit.img ? (
                            <Grid item={true} sm={12} xs={12}>
                              <img
                                src={
                                  process.env.REACT_APP_API_URL_PROD +
                                  '/api/uploads/company/' +
                                  this.state.startupToEdit._id
                                }
                                alt="startup profile"
                                className="profile-image "
                              />
                            </Grid>
                          ) : (
                            ''
                          )}

                          <Grid item={true} sm={12} xs={12}>
                            {' '}
                            <FormControl className="width-100">
                              <p>Logo de su Empresa</p>

                              <Input
                                id="update_image"
                                name="update_image"
                                ref="update_image"
                                aria-describedby="update_image"
                                type="file"
                                inputProps={{
                                  accept: [
                                    'image/png',
                                    'image/jpg',
                                    'image/jpeg',
                                  ],
                                }}
                                onChange={this.handleChange}
                              />
                            </FormControl>
                            {this.state.uploadedImage !== '' ? (
                              <div>
                                <p>
                                  Ajuste la imagen en el cuadrado blanco y
                                  acepte para guardar la imagen, puede hacer uso
                                  del zoom o mover la imagen
                                </p>
                                <div className="crop-container">
                                  <Cropper
                                    image={this.state.imageSrc}
                                    crop={this.state.crop}
                                    minZoom={0.4}
                                    maxZoom={15}
                                    zoom={this.state.zoom}
                                    aspect={this.state.aspect}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.onZoomChange}
                                    cropSize={this.state.cropSize}
                                    restrictPosition={false}
                                  />
                                </div>
                                <Button
                                  onClick={this.showCroppedImage}
                                  variant="contained"
                                  color="primary"
                                >
                                  Aceptar y ver resultado
                                </Button>
                              </div>
                            ) : (
                              ''
                            )}
                          </Grid>
                          <Grid item={true} sm={12} xs={12}>
                            {this.state.croppedImage !== '' ? (
                              <img
                                src={this.state.croppedImage}
                                alt="Startup"
                                className="profile-image "
                              ></img>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Grid container>
                      <Grid item={true} sm={12} xs={12} className="center">
                        {' '}
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button center"
                          ref="buttonRegister"
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                  <Grid item={true} sm={12} xs={12} className="center">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Transacciones</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <DataGrid
                          rows={this.state.startupToEdit.transactions}
                          getRowId={(row) => row.date}
                          autoHeight
                          columns={columnsTrans}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              ) : (
                <p>Debe seleccionar o crear una Empresa</p>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(listStartup);
