import React, { Component } from 'react';
import axios from 'axios';
import { Container } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import './../../../styles/styles.css';
import 'react-quill/dist/quill.snow.css';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';

class JobApplications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startup: props.startup,
      userToken: props.userToken,
      userId: props.userData.uid,
      userData: props.userData,
      jobs: [],
    };
  }

  componentDidMount() {
    this.getJobs();
  }

  getJobs = async () => {
    const apiurl = '/api/users/' + this.state.userId + '/job-applications';
    const { data } = await axios.get(apiurl);
    this.setState({
      jobs: data.jobs,
    });

    console.log('jobs data', data.jobs);
  };

  // viewCompany = (job) => {
  //   console.log('ver mas de ', job);
  //   const newProps = {
  //     jobId: job._id,
  //   };

  //   this.props.getClickedElement(
  //     'oferta_empleo_usuario_ver_ver_empresa',
  //     newProps
  //   );
  // };

  render() {
    const columns = [
      {
        field: 'startupName',
        headerName: 'Empresa',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return <div>{params.row.startupName || ''}</div>;
        },
      },
      {
        field: 'job',
        headerName: 'Oferta de empleo',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return <div>{params.row.job || ''}</div>;
        },
      },
      {
        field: 'email',
        headerName: 'Email de contacto',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{params.row.email}</div>;
        },
      },
      {
        field: 'phone',
        headerName: 'Teléfono de contacto',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{params.row.phone}</div>;
        },
      },
      {
        field: 'state',
        headerName: 'Estado',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              {params.row.candidates.map((candi) => {
                if (candi.userId === this.props.userData.uid) {
                  console.log(candi);
                  return candi.state;
                } else {
                  console.log(candi);
                  return 'REVISIÓN';
                }
              })}
            </div>
          );
        },
      },
    ];

    return (
      <Container>
        <h1 className="job-page-title">Inscripciones de empleo</h1>
        <Grid container>
          <Grid item={true} sm={12} xs={12}>
            {this.state.jobs ? (
              <DataGrid
                rows={this.state.jobs ? this.state.jobs : []}
                getRowId={(row) => row._id}
                autoHeight
                columns={columns}
                pageSize={5}
              />
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withSnackbar(JobApplications);
