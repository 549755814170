import React, { Component } from 'react';
import axios from 'axios';
import { Container } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import './../../../styles/styles.css';
import 'react-quill/dist/quill.snow.css';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { getUserData } from '../../utils/manageUserData';
class JobCandidates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startup: this.props.startup,
      userToken: this.props.userToken,
      jobId: this.props.extraProps.jobId,
      userData: JSON.parse(getUserData()),
      job: [],
      subject: 'Contacto oferta de empleo',
    };
  }

  componentDidMount() {
    this.getJob();
  }

  getJob = async () => {
    const apiurl = '/api/jobs/' + this.state.jobId;
    const { data } = await axios.get(apiurl);
    this.setState({
      job: data.job,
    });

    console.log('job data', data.job);
  };

  viewMore = (candidate) => {
    console.log('ver mas de ', candidate);
    const newProps = {
      userId: candidate.userId,
    };

    this.props.getClickedElement(
      'oferta_empleo_ver_candidatos_single',
      newProps
    );
  };

  handleChangeState = async (jobApplication, newValue) => {
    console.log(jobApplication);
    console.log(newValue);

    const apiurl =
      '/api/jobs/' +
      this.state.jobId +
      '/candidate/' +
      jobApplication.userId +
      '/state';

    console.log(apiurl);

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const payload = {
      state: newValue,
    };

    const { data } = await axios.patch(apiurl, payload, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.getJob();
      this.props.enqueueSnackbar('Estado de candidatura actualizada', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  };

  openChat = async (jobApplication) => {
    // jobApplication = candidate dentro de candidates[] en job model

    console.log(jobApplication); // user2
    console.log(this.state.userData); // user1

    // FRAN: solo falta rellenar user1, user2 con datos. sendMessageJobApplication hace el resto.
    // por otro lado, el subject esta puesto en la inicializacion del state

    const user1 = {
      _id: this.state.userData.uid,
      type: this.state.userData.role,
      name: this.state.userData.name,
      lastname: this.state.userData.lastname,
      phone: this.state.userData.phone,
      email: this.state.userData.email,
    };

    const user2 = {
      _id: jobApplication.userId,
      type: 'FINAL_USER_STARTUP', // deberiamos coger el role de este tipo
      name: jobApplication.name,
      // company: this.state.user2company, espero que esto sea opcional
      lastname: jobApplication.lastname,
      phone: jobApplication.phone,
      email: jobApplication.email,
    };

    this.sendmessageJobApplication(user1, user2);
  };

  sendmessageJobApplication = (infoUser1, infoUser2) => {
    console.log('sending msg job app');
    console.log('infoUser1', infoUser1);
    console.log('infoUser2', infoUser2);

    var apiurlpost = '/api/messages';
    const payload = {
      subject: this.state.subject,
      user1: infoUser1,
      user2: infoUser2,
    };
    axios({
      url: apiurlpost,
      method: 'POST',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.props.enqueueSnackbar(
          'Conversacion iniciada, redirigiendo al chat',
          {
            variant: 'success',
          }
        );
        window.location.href = '/perfil?goChat=true';
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          'Hemos encontrado un error y no se han guardado los cambios',
          {
            variant: 'error',
          }
        );
      });
  };

  render() {
    const columns = [
      {
        field: 'name',
        headerName: 'Nombre',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              {params.row.name || ''} {params.row.lastname || ''}
            </div>
          );
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{params.row.email}</div>;
        },
      },
      {
        field: 'phone',
        headerName: 'Teléfono',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{params.row.phone}</div>;
        },
      },
      {
        field: 'state',
        headerName: 'Estado',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={params.row.state}
              label="Age"
              onChange={(e) =>
                this.handleChangeState(params.row, e.target.value)
              }
            >
              <MenuItem value={'REVISIÓN'}>REVISIÓN</MenuItem>
              <MenuItem value={'ACEPTADA'}>ACEPTADA</MenuItem>
              <MenuItem value={'RECHAZADA'}>RECHAZADA</MenuItem>
            </Select>
          );
        },
      },
      {
        field: 'chat',
        headerName: 'Chat',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.openChat(params.row)}
              >
                Chat
              </Button>
            </div>
          );
        },
      },
      {
        field: 'view',
        headerName: 'Ver más',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.viewMore(params.row)}
              >
                Ver más
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <Container>
        <h1 className="job-page-title">Candidatos a {this.state.job.job}</h1>
        <Grid container>
          <Grid item={true} sm={12} xs={12}>
            <DataGrid
              rows={this.state.job.candidates ? this.state.job.candidates : []}
              getRowId={(row) => row.userId}
              autoHeight
              columns={columns}
              pageSize={5}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withSnackbar(JobCandidates);
