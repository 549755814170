import Cookies from 'js-cookie';
import axios from 'axios';

const setLogin = async (data) => {
  Cookies.set('tokenLogin', data.token, { expires: 7 });
  Cookies.set('userInformation', data.user, { expires: 7 });
};

const updateDataUser = async (data) => {
  Cookies.remove('userInformation');

  Cookies.set('userInformation', data.user, { expires: 7 });
};
const getUserData = () => {
  if (Cookies.get('userInformation')) {
    return Cookies.get('userInformation');
  } else {
    return false;
  }
};
const getUserToken = () => {
  if (Cookies.get('tokenLogin')) {
    return Cookies.get('tokenLogin');
  } else {
    return false;
  }
};
const logout = () => {
  Cookies.remove('tokenLogin');
  Cookies.remove('userInformation');
};

const userCompleteSteps = async (userRole) => {
  var userToken = getUserToken();
  var userData = Cookies.get('userInformation');
  const objUserData = JSON.parse(userData);
  console.log(objUserData);
  var apiurl = '/api/users/' + objUserData.uid + '/startups';
  var userStartup = [];
  await axios({
    url: apiurl,
    method: 'GET',
    headers: {
      'x-token': userToken,
      'content-type': 'application/json',
    },
  })
    .then((response) => {
      if (response.data.startups.length) {
        userStartup = response.data.startups[0];
      }
    })
    .catch((error) => {
      console.log('No startup Found');
    });
  var userCompany = [];

  var apiurl2 = '/api/users/' + objUserData.uid + '/company';

  await axios({
    url: apiurl2,
    method: 'GET',
    headers: {
      'x-token': userToken,
      'content-type': 'application/json',
    },
  })
    .then((response) => {
      if (response.data.companies.length) {
        userCompany = response.data.companies[0];
      }
    })
    .catch((error) => {});
  console.log(objUserData);
  if (userRole === 'CROWDFUNDING_USER') {
    if (
      objUserData.streetName !== '' &&
      objUserData.flatNumber !== '' &&
      objUserData.postCode !== '' &&
      objUserData.locality !== '' &&
      objUserData.province !== '' &&
      objUserData.country !== '' &&
      //objUserData.nameCompanyCrowfunding !== '' &&
      objUserData.streetName &&
      objUserData.flatNumber &&
      objUserData.postCode &&
      objUserData.locality &&
      objUserData.province &&
      objUserData.country
      //&& objUserData.dni &&
      // objUserData.name &&
      //objUserData.lastName
      //&& objUserData.nameCompanyCrowfunding
    ) {
      return 'complete';
    } else {
      return 'incomplete';
    }
  } else if (userRole === 'FINAL_USER_COMPANY') {
    if (
      objUserData.jobLocation !== '' &&
      objUserData.streetName !== '' &&
      objUserData.flatNumber !== '' &&
      objUserData.postCode !== '' &&
      objUserData.locality !== '' &&
      objUserData.province !== '' &&
      objUserData.country !== '' &&
      objUserData.jobField !== '' &&
      objUserData.acceptOffersEmail !== '' &&
      objUserData.acceptTerms !== '' &&
      objUserData.jobLocation &&
      objUserData.streetName &&
      objUserData.flatNumber &&
      objUserData.postCode &&
      objUserData.locality &&
      objUserData.province &&
      objUserData.country &&
      objUserData.jobField
    ) {
      return 'complete';
    } else {
      return 'incomplete';
    }
  } else if (userRole === 'FINAL_USER_STARTUP') {
    if (
      (objUserData.acceptTerms === true || objUserData.acceptTerms === false) &&
      objUserData.studiesInformation.length > 0 &&
      objUserData.internshipInformation.length > 0
    ) {
      return 'complete';
    } else {
      return 'incomplete';
    }
  } else if (userRole === 'SECONDARY_USER_ADMIN') {
    if (
      true
      // userCompany.name &&
      // userCompany.shortDescription &&
      // dejar este comentado userCompany.longDescription &&
      //  objUserData.streetName &&
      //objUserData.flatNumber &&
      //objUserData.postCode &&
      //objUserData.locality &&
      // objUserData.province &&
      // objUserData.country &&
      // userCompany.name !== '' &&
      // userCompany.shortDescription !== '' &&
      // objUserData.streetName !== '' &&
      // objUserData.flatNumber !== '' &&
      // objUserData.postCode !== '' &&
      // objUserData.locality !== '' &&
      // objUserData.province !== '' &&
      // objUserData.country !== ''
      //	dejar este comentado  && userCompany.longDescription !== ""
    ) {
      return 'complete';
    } else {
      return 'incomplete';
    }
  } else if (userRole === 'SECONDARY_USER') {
    if (
      objUserData.name !== '' &&
      objUserData.lastName !== '' &&
      objUserData.name &&
      objUserData.lastName
    ) {
      return 'complete';
    } else {
      return 'incomplete';
    }
  } else if (userRole === 'FINAL_USER') {
    if (
      userStartup.name &&
      userStartup.shortDescription &&
      userStartup.longDescription &&
      objUserData.streetName &&
      objUserData.flatNumber &&
      objUserData.postCode &&
      objUserData.locality &&
      objUserData.province &&
      objUserData.country &&
      userStartup.name !== '' &&
      userStartup.shortDescription !== '' &&
      objUserData.streetName !== '' &&
      objUserData.flatNumber !== '' &&
      objUserData.postCode !== '' &&
      objUserData.locality !== '' &&
      objUserData.province !== '' &&
      objUserData.country !== '' &&
      userStartup.longDescription !== ''
    ) {
      console.log('Completo');
      return 'complete';
    } else {
      console.log('aqui 2');
      console.log(objUserData);
      console.log(userStartup);
      return 'incomplete';
    }
  } else if (userRole === 'ADMIN') {
    if (
      objUserData.name !== '' &&
      objUserData.lastName !== '' &&
      objUserData.name &&
      objUserData.lastName
    ) {
      return 'complete';
    } else {
      return 'incomplete';
    }
  } else if (userRole === 'USER_OFFER_SERVICES') {
    if (
      userCompany.name &&
      userCompany.shortDescription &&
      userCompany.longDescription &&
      objUserData.streetName &&
      objUserData.flatNumber &&
      objUserData.postCode &&
      objUserData.locality &&
      objUserData.province &&
      objUserData.country &&
      userCompany.name !== '' &&
      userCompany.shortDescription !== '' &&
      objUserData.streetName !== '' &&
      objUserData.flatNumber !== '' &&
      objUserData.postCode !== '' &&
      objUserData.locality !== '' &&
      objUserData.province !== '' &&
      objUserData.country !== '' &&
      userCompany.longDescription !== ''
    ) {
      return 'complete';
    } else {
      return 'incomplete';
    }
  } else if (
    userRole === 'CROWDFUNDING_ADMIN' ||
    userRole === 'SELLACTIONS_ADMIN' ||
    userRole === 'RESTRICTED_ADMIN'
  ) {
    return 'complete';
  }
};

const forumVisible = (role) => {
  return (
    role === 'ADMIN' ||
    role === 'FINAL_USER' ||
    role === 'SECONDARY_USER_ADMIN' ||
    role === 'CROWDFUNDING_USER' ||
    role === 'RESTRICTED_ADMIN'
  );
};

const getStartupFounderData = async (idUser) => {
  const apiurl = '/api/users/' + idUser + '/startups';

  let data = {};

  await axios({
    url: apiurl,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((response) => {
      console.log(response);
      data = response.data.startups[0].name;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });

  console.log('DATA STARTUP', data);

  return data;
};

const getCompanyFounderData = async (idUser) => {
  const apiurl = '/api/users/' + idUser + '/company';

  let data = {};

  await axios({
    url: apiurl,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((response) => {
      console.log(response);
      data = response.data.company[0].name;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
  console.log('DATA COMPANY', data);
  return data;
};

const getFounderData = async (role, idUser) => {
  //  console.log(role, idUser);
  switch (role) {
    case 'ADMIN':
      return 'Administrador';

    case 'RESTRICTED_ADMIN':
      return 'Administrador';

    case 'FINAL_USER':
      return getStartupFounderData(idUser).then((result) => result);

    case 'FINAL_USER_COMPANY':
      return getCompanyFounderData(idUser).then((result) => result);

    case 'SECONDARY_USER_ADMIN':
      return getCompanyFounderData(idUser).then((result) => result);

    case 'CROWDFUNDING_USER':
      return getCompanyFounderData(idUser).then((result) => result);

    default:
      return console.log(1);
  }
};
export {
  setLogin,
  logout,
  getUserData,
  getUserToken,
  updateDataUser,
  userCompleteSteps,
  forumVisible,
  getFounderData,
};
