import React, { Component } from 'react';
import axios from 'axios';
import { Container } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import './../../../../styles/styles.css';
import 'react-quill/dist/quill.snow.css';
import { withSnackbar } from 'notistack';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  esES,
} from '@material-ui/data-grid';

class Newsletter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: props.startup,
      userToken: props.userToken,
      newsletter: [],
    };

    console.log('newsletter component');
  }

  componentDidMount() {
    this.getnewsletters();
  }

  getnewsletters = async () => {
    const apiurl = '/api/newsletter';
    const headers = {
      headers: {
        'x-token': this.state.userToken,
      },
    };

    const { data } = await axios.get(apiurl, headers);

    console.log(data.newsletter);

    this.setState({
      newsletter: data.newsletter,
    });
  };

  deletenewsletter = async (id) => {
    const headers = {
      headers: {
        'x-token': this.state.userToken,
      },
    };

    const { data } = await axios.delete('/api/newsletter/' + id, headers);

    if (Number(data.code) === 200) {
      this.props.enqueueSnackbar('Eliminado de la newsletter', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }

    this.getnewsletters();
  };

  exportNewsletter = async () => {
    console.log('going to export newsletter');
  };

  render() {
    const columns = [
      {
        field: 'email',
        headerName: 'Email',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return <div>{params.row.email || ''}</div>;
        },
      },
      {
        field: 'name',
        headerName: 'Nombre',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{params.row.name || ''}</div>;
        },
      },
      {
        field: 'lastname',
        headerName: 'Apellidos',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{params.row.lastname ? params.row.lastname : ''}</div>;
        },
      },
      {
        field: 'delete',
        headerName: 'Borrar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() =>
                  window.confirm(
                    '¿Estás seguro de borrar este usuario de la newsletter?'
                  )
                    ? this.deletenewsletter(params.row._id)
                    : ''
                }
              >
                Borrar
              </Button>
            </div>
          );
        },
      },
    ];

    function CustomToolbar() {
      const csvOptions = {
        delimiter: ';',
        utf8WithBom: true,
        fields: ['email', 'name', 'lastname'],
        fileName: 'Estartaps_newsletter',
      };

      console.log('custom toolbar');

      return (
        <GridToolbarContainer>
          <GridToolbarExport csvOptions={csvOptions} />
        </GridToolbarContainer>
      );
    }

    return (
      <Container>
        <h1 className="job-page-title">Newsletter</h1>
        <Grid container>
          <Grid item={true} sm={12} xs={12}>
            <DataGrid
              rows={this.state.newsletter ? this.state.newsletter : []}
              getRowId={(row) => row._id}
              autoHeight
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              components={{ Toolbar: CustomToolbar }}
              localeText={esES.props.MuiDataGrid.localeText}
              className="data-grid-newsletter"
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withSnackbar(Newsletter);
