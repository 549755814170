import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import 'react-alice-carousel/lib/alice-carousel.css';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HashLink as Link } from 'react-router-hash-link';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { Container } from '@material-ui/core';
import { getUserToken } from '../components/utils/manageUserData';
import ContactEmail from '../components/utils/contactEmail';
class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      baseSearchUrl: '/buscador-de-startups',
    };
  }

  componentDidMount() {
    this.getUserToken();
  }
  submitContact = (event) => {
    event.preventDefault();
    var apiurlput = '/api/users/contact-home';

    const payload = {
      email: event.target.email_contact.value.toLowerCase(),
      name: event.target.name_contact.value,
      lastname: event.target.lastname_contact.value,
    };
    axios({
      url: apiurlput,
      method: 'POST',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        this.props.enqueueSnackbar('Añadido a la lista', {
          variant: 'success',
        });
      })
      .catch((error) => {
        console.log(error.message);
        alert('Mensaje no enviado');
      });
  };
  getUserToken = () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  login = () => {
    if (document.getElementById('buttonRegistrationTop')) {
      document.getElementById('buttonRegistrationTop').click();
    } else if (document.getElementById('myaccountTop')) {
      document.getElementById('myaccountTop').click();
    }
  };
  render() {
    const responsive = {
      0: { items: 1 },
      568: { items: 3 },
      1024: { items: 5 },
    };
    const items = [
      <div className="item" data-value="1">
        <img
          src="/images/logo.png"
          alt="logo Estartaps"
          className="width-100"
        />
      </div>,
      <div className="item" data-value="2">
        <img
          src="/images/logo.png"
          alt="logo Estartaps"
          className="width-100"
        />
      </div>,
      <div className="item" data-value="3">
        <img
          src="/images/logo.png"
          alt="logo Estartaps"
          className="width-100"
        />
      </div>,
      <div className="item" data-value="4">
        <img
          src="/images/logo.png"
          alt="logo Estartaps"
          className="width-100"
        />
      </div>,
      <div className="item" data-value="5">
        <img
          src="/images/logo.png"
          alt="logo Estartaps"
          className="width-100"
        />
      </div>,
    ];

    return (
      <div>
        <Grid container className="home">
          <Grid container className="presentationImage">
            <Grid item={true} xs={12} sm={12} className="center">
              <div className="logo-home">
                <img
                  src="/images/logo.png"
                  alt="logo Estartaps"
                  className="width-100"
                />
              </div>
              <Grid container>
                <Grid item={true} xs={12} sm={3} className="center"></Grid>
                <Grid item={true} xs={12} sm={6} className="center">
                  <div>
                    <FormControl className="search-home">
                      <form
                        method="GET"
                        action="/buscador-de-startups"
                        style={{ width: '100%' }}
                      >
                        <Input
                          name="q"
                          placeholder="Buscar Startups"
                          className="formOutline"
                          value={this.state.searchString}
                          onChange={(e) =>
                            this.setState({ searchString: e.target.value })
                          }
                          style={{ width: '100%' }}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />
                      </form>
                      <div>
                        <p>Toda la innovación en un solo click</p>
                      </div>
                      <Grid container>
                        <Grid item={true} xs={12} sm={6}>
                          {' '}
                          <RouterLink
                            to={{
                              pathname: this.state.baseSearchUrl,
                              search: '?q=' + this.state.searchString,
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              className="button"
                            >
                              Buscar
                            </Button>
                          </RouterLink>
                        </Grid>
                        <Grid item={true} xs={12} sm={6}>
                          <Link to="/blog">
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              className="button"
                            >
                              Blog
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item={true} xs={12} sm={3} className="center"></Grid>
              </Grid>
            </Grid>

            <Grid item={true} xs={12} sm={12}>
              <div className="arrowContainer">
                <div className="arrowNext">
                  <Link to="#solutions" smooth>
                    <ExpandMoreIcon fontSize="large" />
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Container className="bodyWrapper">
          <Grid container>
            <Grid container item={true} id="solutions">
              <Grid item={true} xs={12} sm={12} md={12} lg={9}>
                <div>
                  <img
                    src="/images/meetings.jpg"
                    alt="meeting"
                    className="width-100 radiusImage"
                  ></img>
                </div>
              </Grid>
              <Grid
                item={true}
                xs={12}
                sm={12}
                md={12}
                lg={6}
                className="boxHome"
              >
                <div>
                  <h2>Grandes soluciones para tus objetivos concretos</h2>
                  <p>
                    En ESTARTAPS deseamos acompañarte en tu camino hacia el
                    emprendimiento, dando a conocer tu idea a través del
                    posicionamiento en los principales buscadores, dotando a tu
                    Startup de la inversión necesaria en el momento oportuno.
                    Promovemos el talento para llevar tu idea al éxito y te
                    ofrecemos todos aquellos servicios que puedas necesitar en
                    tu desarrollo, beneficiándote de un precio reducido a la
                    innovación.
                  </p>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="button"
                  >
                    Desliza para conocer mas
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container className="sectionLogo">
              <AliceCarousel
                autoPlay
                mouseTracking
                infinite
                autoPlayStrategy="none"
                autoPlayInterval={2000}
                animationDuration={1000}
                items={items}
                responsive={responsive}
                disableDotsControls
                disableButtonsControls
                controlsStrategy="alternate"
              />
            </Grid>
            <Grid item={true} xs={12} sm={12}>
              <div className="bgDetailImage center homeFullWidthSection">
                <h2>STARTUPS</h2>
                <p>
                  El valor de la innovación no está en evitar que te copien,
                  sino en conseguir que todos te quieran copiar.
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="bold pointer button-white"
                  onClick={() => this.login()}
                >
                  ¡Regístrate ahora! {'>'}
                </Button>
              </div>
            </Grid>
            <Grid container item={true} xs={12} sm={12}>
              <Grid
                container
                item={true}
                xs={12}
                md={12}
                className="homeTwoColumns"
              >
                <Box clone order={{ xs: 1, md: 1 }}>
                  <Grid item={true} xs={12} md={6}>
                    <div className="home-text">
                      <h1 className="secondaryHeader">Buscador de Startups</h1>
                      <p>
                        Si buscas una solución innovadora, estás en el sitio
                        correcto. Escribe dentro de nuestro buscador tu consulta
                        y te proporcionará las mejores soluciones innovadoras.
                      </p>
                      <a href="/buscador-de-startups">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button"
                        >
                          Buscar Startups
                        </Button>
                      </a>
                    </div>
                  </Grid>
                </Box>
                <Box clone order={{ xs: 2, md: 2 }}>
                  <Grid item={true} xs={12} md={6}>
                    <img
                      src="/images/illustration-8.png"
                      alt="meeting"
                      className="width-100 radiusImage"
                    ></img>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                container
                item={true}
                xs={12}
                md={12}
                className="homeTwoColumns"
              >
                <Box clone order={{ xs: 2, md: 1 }}>
                  <Grid item={true} xs={12} md={6}>
                    <img
                      src="/images/illustration-5.png"
                      alt="meeting"
                      className="width-100 radiusImage"
                    ></img>
                  </Grid>
                </Box>

                <Box clone order={{ xs: 1, md: 2 }}>
                  <Grid item={true} xs={12} md={6}>
                    <div className="home-text">
                      <h2 className="secondaryHeader">Inversión</h2>
                      <p>
                        Invertir en Startups es invertir en futuro. Descubre
                        nuestro apartado de inversión en crowdfunding y
                        secundarias. Cada vez son más personas las que invierten
                        en Startups, tú decides la candidata con la que empezar
                        y seguro repetirás.
                      </p>
                      <a href="/invertir-en-startups">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button"
                        >
                          Buscar Startups para invertir
                        </Button>
                      </a>
                    </div>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                container
                item={true}
                xs={12}
                sm={12}
                className="homeTwoColumns"
              >
                <Box clone order={{ xs: 1, md: 1 }}>
                  <Grid item={true} xs={12} md={6}>
                    <div className="home-text">
                      <h2 className="secondaryHeader">Empleo o prácticas</h2>
                      <p>
                        Si buscas trabajo o prácticas, quizás nunca te hayas
                        planteado que fuese en una Startup. Actualmente, este
                        modelo de negocio es cada vez mayor y el crecimiento de
                        estas empresas es exponencial. ¿Estás dispuesto a crear
                        el mañana?
                      </p>
                      <a href="/empleos">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button"
                        >
                          Ver ofertas de empleo y prácticas
                        </Button>
                      </a>
                    </div>
                  </Grid>
                </Box>
                <Box clone order={{ xs: 2, md: 2 }}>
                  <Grid item={true} xs={12} md={6}>
                    <img
                      src="/images/illustration-2.png"
                      alt="meeting"
                      className="width-100 radiusImage"
                    ></img>
                  </Grid>
                </Box>
              </Grid>

              <Grid
                container
                item={true}
                xs={12}
                sm={12}
                className="homeTwoColumns"
              >
                <Box clone order={{ xs: 2, md: 1 }}>
                  <Grid item={true} xs={12} md={6}>
                    <img
                      src="/images/illustration-4.png"
                      alt="meeting"
                      className="width-100 radiusImage"
                    ></img>
                  </Grid>
                </Box>
                <Box clone order={{ xs: 1, md: 2 }}>
                  <Grid item={true} xs={12} md={6}>
                    <div className="home-text">
                      <h2 className="secondaryHeader">Servicios a Startups</h2>
                      <p>
                        Las Startups podrán solicitar los servicios de otras
                        empresas ya consolidadas, beneficiándose de un precio
                        reducido para crecer más rápido y mejor.
                      </p>
                      <a href="/servicios-a-startups">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button"
                        >
                          Ver ofertas a Startups
                        </Button>
                      </a>
                    </div>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                container
                item={true}
                xs={12}
                sm={12}
                className="homeTwoColumns"
              >
                <Box clone order={{ xs: 1, md: 1 }}>
                  <Grid item={true} xs={12} md={6}>
                    <div className="home-text">
                      <h2 className="secondaryHeader">
                        ¿Cómo crear una Startup?
                      </h2>
                      <p>
                        Si tienes una idea innovadora y quieres hacerla
                        realidad, entra en nuestro apartado de Crea tu primera
                        Startup. Te ayudaremos guiándote paso a paso para que
                        consigas ser tu propio jefe y consigas hacer tu idea
                        realidad.
                      </p>
                      <a href="/crea-tu-primera-startup">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button"
                        >
                          Crea tu primera Startup
                        </Button>
                      </a>
                    </div>
                  </Grid>
                </Box>
                <Box clone order={{ xs: 2, md: 2 }}>
                  <Grid item={true} xs={12} md={6}>
                    <img
                      src="/images/illustration-9.png"
                      alt="meeting"
                      className="width-100 radiusImage"
                    ></img>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                container
                item={true}
                xs={12}
                sm={12}
                className="homeTwoColumns"
              >
                <Box clone order={{ xs: 2, md: 1 }}>
                  <Grid item={true} xs={12} md={6}>
                    <img
                      src="/images/illustration-6.png"
                      alt="meeting"
                      className="width-100 radiusImage"
                    ></img>
                  </Grid>
                </Box>
                <Box clone order={{ xs: 1, md: 2 }}>
                  <Grid item={true} xs={12} md={6}>
                    <div className="home-text">
                      <h2 className="secondaryHeader">Reto</h2>
                      <p>
                        Gracias a nuestros acuerdos con Corporates, podrás
                        presentar tu idea a los retos propuestos. Estas empresas
                        plantearán las características que debe cumplir la
                        solución innovadora que se presente a cada reto. La
                        empresa será la encargada de seleccionar los mejores
                        proyectos y dotarlos de determinados medios para que se
                        pueda llevar a cabo.
                      </p>
                      <a href="/reto">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button"
                        >
                          Ver retos disponibles
                        </Button>
                      </a>
                    </div>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid container item={true}>
              <Grid
                container
                item={true}
                xs={12}
                sm={4}
                className="cardWrapper"
              >
                <Card
                  className="cardCustom cardCustom-1"
                  onClick={() => (window.location = '/buscador-de-startups')}
                >
                  <CardContent className=" center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="button center"
                    >
                      Startups
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item={true}
                xs={12}
                sm={4}
                className="cardWrapper"
              >
                <Card
                  className="cardCustom cardCustom-2"
                  onClick={() => (window.location = '/invertir-en-startups')}
                >
                  <CardContent className=" center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="button center"
                    >
                      Inversores
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item={true}
                xs={12}
                sm={4}
                className="cardWrapper"
              >
                <Card
                  className="cardCustom cardCustom-3"
                  onClick={() => (window.location = '/servicios-a-startups')}
                >
                  <CardContent className="center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="button center"
                    >
                      Empresas
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                spacing={3}
                className="separator-30"
                id="contact_home"
              >
                <Grid container item={true} xs={12} sm={6}>
                  <Grid item={true} xs={12} sm={2}></Grid>
                  <ContactEmail userToken={this.state.userToken} />

                  <Grid item={true} xs={12} sm={2}></Grid>
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  sm={6}
                  className="contactImageFooter"
                ></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
export default withSnackbar(Homepage);
