import React, { Component } from 'react';
import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
  Checkbox,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../utils/cropImage';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import docDownload from '../../../../assets/docs/Investor_Deck.docx';
class EditStartup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startupFounder: [],
      editStartup: '',
      choosenOptionMaturity: '',
      userToken: '',
      userData: '',
      imageSrc: '',
      imageToUpload: '',
      croppedImage: '',
      croppedImageFile: '',
      crop: { x: 0, y: 0 },
      croppedAreaPixels: '',
      uploadedImage: '',
      croppedArea: '',
      zoom: 1,
      aspect: 1 / 1,
      cropSize: { width: 300, height: 300 },
      products_array: [],
      teams_array: [],
      state: '',
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
    })();
    this.setState({
      editStartup: this.props.informationStartup,
      longDescription: this.props.informationStartup.longDescription,
      state: this.props.informationStartup.state,
    });
    var products = [];
    var teams = [];

    var startup = this.props.informationStartup;
    if (startup.products) {
      startup.products.forEach((element, index, array) => {
        var name = 'product-' + index;
        products.push(name);
      });
    }
    if (startup.managementTeam) {
      startup.managementTeam.forEach((element, index, array) => {
        var name = 'team-' + index;
        teams.push(name);
      });
    }
    this.setState({
      products_array: products,
      teams_array: teams,
    });
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
      this.getUserFounder(obj);
    }
  };
  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo.uid + '/startups';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.startups.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.startups,
          });
        }
      })
      .catch((error) => {});
  };

  chooseOptionMature = (option) => {
    var element = document.getElementById('opt1');
    var element2 = document.getElementById('opt2');
    var element3 = document.getElementById('opt3');
    var element4 = document.getElementById('opt4');
    if (option === 1) {
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element.classList.add('choosenOption');
    } else if (option === 2) {
      element.classList.remove('choosenOption');

      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element2.classList.add('choosenOption');
    } else if (option === 3) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');

      element4.classList.remove('choosenOption');
      element3.classList.add('choosenOption');
    } else if (option === 4) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.add('choosenOption');
    }
    this.setState({
      choosenOptionMaturity: option,
    });
  };
  submitAlldata = (payload) => {
    var apiurlput = '/api/startups/' + this.state.editStartup.uid;

    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.props.enqueueSnackbar('Datos guardados con éxito', {
          variant: 'success',
        });
        this.getUserFounder(this.state.userData);
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
      });
  };
  submitUpdateStartup = async (event) => {
    event.preventDefault();
    let pdfDownloaded;
    if (document.getElementById('register_investorDoc').files.length) {
      console.log('hay fichero');
      let formData = new FormData();
      formData.append(
        'file',
        document.getElementById('register_investorDoc').files[0]
      );
      pdfDownloaded = await axios
        .post('/api/uploads', formData)
        .then((res) => {
          return res.data.fileName;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido la imagen');
        });
    }
    if (this.state.editStartup !== '') {
      if (this.state.choosenOptionMaturity !== '') {
        var choosedOption;
        if (this.state.choosenOptionMaturity === 1) {
          choosedOption = 'Pre-semilla';
        } else if (this.state.choosenOptionMaturity === 2) {
          choosedOption = 'Semilla';
        } else if (this.state.choosenOptionMaturity === 3) {
          choosedOption = 'Temprana';
        } else if (this.state.choosenOptionMaturity === 4) {
          choosedOption = 'Consolidada';
        }
      } else {
        choosedOption = this.state.editStartup.maturityInfo;
      }

      var update_startup_test_company;
      if (event.target.update_startup_test_company.value === 'True') {
        update_startup_test_company = true;
      } else {
        update_startup_test_company = false;
      }
      var update_startup_aceleration;
      if (event.target.update_startup_aceleration.value === 'True') {
        update_startup_aceleration = true;
      } else {
        update_startup_aceleration = false;
      }
      let listProducts = [];
      var getProducts = new Promise((resolve, reject) => {
        this.state.products_array.forEach((element, index, array) => {
          if (
            document.getElementById(element + '-name') !== null &&
            document.getElementById(element + '-name') !== 'undefined'
          ) {
            var name = document.getElementById(element + '-name').value;
            var description = document.getElementById(
              element + '-description'
            ).value;

            let obj = {
              name: name,
              description: description,
            };
            listProducts.push(obj);
          }
          if (index === array.length - 1) resolve();
        });
      });
      let listTeam = [];
      var getTeams = new Promise((resolve, reject) => {
        this.state.teams_array.forEach((element, index, array) => {
          if (
            document.getElementById(element + '-name') !== null &&
            document.getElementById(element + '-name') !== 'undefined'
          ) {
            var name = document.getElementById(element + '-name').value;
            var position = document.getElementById(element + '-position').value;
            var order = document.getElementById(element + '-order').value;
            var linkedin = document.getElementById(element + '-linkedin').value;

            let obj = {
              name: name,
              position: position,
              order: order,
              linkedinUrl: linkedin,
            };
            listTeam.push(obj);
          }
          if (index === array.length - 1) resolve();
        });
      });
      getProducts.then(() => {
        console.log('All done!');
      });
      getTeams.then(() => {
        console.log('All done!');
      });
      //console.log(listProducts);
      //check youtube url
      var url = event.target.update_presentacion_video.value;

      if (url !== '') {
        var regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length === 11) {
          url =
            'https://www.youtube.com/embed/' +
            match[2] +
            '?autoplay=0&enablejsapi=1';
        } else {
          this.props.enqueueSnackbar('El enlace a Youtube no es correcto', {
            variant: 'error',
          });
          url = '';
        }
      }
      if (this.state.croppedImageFile) {
        let formData = new FormData();
        formData.append('file', this.state.croppedImageFile);

        axios
          .put('/api/uploads/startups/' + this.state.editStartup.uid, formData)
          .then((res) => {
            const payload = {
              name: event.target.update_startupName.value,
              testedInfoBoolean: update_startup_test_company,
              shortDescription: event.target.update_startup_description.value,
              longDescription: this.state.longDescription,
              testedInfoText: event.target.update_startup_opinion_test.value,
              servicesDescription: event.target.update_startup_products.value,
              teamDescription: event.target.update_startup_team.value,
              cif: event.target.update_cifStartup.value,
              maturityInfo: choosedOption,
              accelerationBoolean: update_startup_aceleration,
              industries: event.target.update_industry.value,
              accelerationProcessInfo:
                event.target.update_startup_aceleration_description.value,
              products: listProducts,
              managementTeam: listTeam,
              mainPhrase: event.target.update_mainPhrase.value,
              workers: event.target.update_workerstartup.value,
              videoPresentation: url,
              investorDoc: pdfDownloaded
                ? pdfDownloaded
                : this.state.editStartup.investorDoc,
              state: this.state.state,
            };
            this.submitAlldata(payload);

            this.props.getStartupInformation();
          })
          .catch((error) => {
            alert('Se ha encontrado un error y no se ha subido la imagen');
          });
      } else {
        const payload = {
          name: event.target.update_startupName.value,
          testedInfoBoolean: update_startup_test_company,
          shortDescription: event.target.update_startup_description.value,
          longDescription: this.state.longDescription,
          testedInfoText: event.target.update_startup_opinion_test.value,
          servicesDescription: event.target.update_startup_products.value,
          teamDescription: event.target.update_startup_team.value,
          cif: event.target.update_cifStartup.value,
          maturityInfo: choosedOption,
          accelerationBoolean: update_startup_aceleration,
          industries: event.target.update_industry.value,
          accelerationProcessInfo:
            event.target.update_startup_aceleration_description.value,
          products: listProducts,
          managementTeam: listTeam,
          mainPhrase: event.target.update_mainPhrase.value,
          workers: event.target.update_workerstartup.value,
          videoPresentation: url,
          investorDoc: pdfDownloaded
            ? pdfDownloaded
            : this.state.editStartup.investorDoc,
          state: this.state.state,
        };
        this.submitAlldata(payload);

        this.props.getStartupInformation();
      }
    }
  };
  handleClick = () => {
    this.props.enqueueSnackbar('Successfully fetched the data.');
  };
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedArea: croppedArea,
      croppedAreaPixels: croppedAreaPixels,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  handleChange = (event) => {
    var image = event.target.files[0];
    if (event.target.files[0]) {
      this.setState({
        uploadedImage: event.target.files[0],
        imageSrc: URL.createObjectURL(image),
      });
    } else {
      this.setState({
        uploadedImage: '',
        imageSrc: '',
      });
    }
  };
  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
      this.state.rotation
    );

    let response = await fetch(croppedImage);
    let data = await response.blob();
    let metadata = {
      type: 'image/png',
    };
    let file = new File([data], 'Startup.png', metadata);

    //croppedImageFile.lastModifiedDate = new Date();
    //croppedImageFile.name = "uploadfile";

    this.setState({
      croppedImageFile: file,
    });
    this.setState({
      croppedImage: croppedImage,
    });
  };
  appendInput_product() {
    var newInput = `product-${this.state.products_array.length}`;

    this.setState((prevState) => ({
      products_array: prevState.products_array.concat([newInput]),
    }));
  }

  appendInput_team() {
    var newInput = `team-${this.state.teams_array.length}`;

    this.setState((prevState) => ({
      teams_array: prevState.teams_array.concat([newInput]),
    }));
  }

  deleteProduct = async (producto) => {
    alert('vas a borrar el producto: ' + producto);

    document.getElementById('product-' + producto).remove();
  };

  deleteTeam = async (team) => {
    alert('vas a borrar el miembro: ' + team);

    document.getElementById('team-' + team).remove();
  };

  handleChangeDescription = (value) => {
    this.setState({ longDescription: value });
  };
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
    ],
  };
  formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];
  render() {
    console.log(this.state);
    return (
      <Container>
        <Grid container className="borderShadow">
          <Grid item xs={12}>
            <div>
              {this.state.editStartup !== '' ? (
                <form
                  onSubmit={this.submitUpdateStartup}
                  className="form"
                  key={this.state.editStartup}
                >
                  <Grid Container>
                    <Grid xs={12} sm={12} className="startupCover">
                      <div className="statupCoverTitle center">
                        <h2>{this.state.editStartup.name}</h2>
                      </div>

                      {this.state.editStartup.img ? (
                        <div className="startupCoverCircle">
                          <img
                            src={
                              process.env.REACT_APP_API_URL_PROD +
                              '/api/uploads/startups/' +
                              this.state.editStartup.uid
                            }
                            alt="startup profile cover"
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Información Básica</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="update_startupName"
                              shrink={true}
                            >
                              Lo más importante, ¿por qué nombre quieres que
                              conozcamos a tu Startup?{' '}
                            </InputLabel>
                            <Input
                              id="update_startupName"
                              name="update_startupName"
                              aria-describedby="update_startupName"
                              type="text"
                              defaultValue={this.state.editStartup.name}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <InputLabel htmlFor="update_industry" shrink={true}>
                              ¿Cuál es la industria a la que pertenece tu
                              Startup?
                            </InputLabel>
                            <Input
                              id="update_industry"
                              name="update_industry"
                              aria-describedby="update_industry"
                              type="text"
                              defaultValue={this.state.editStartup.industries}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>
                              Nos gustaría que nos hicieses una breve
                              descripción de a qué se dedica tu Startup{' '}
                            </p>
                            <TextareaAutosize
                              aria-label="minimum height"
                              id="update_startup_description"
                              name="update_startup_description"
                              rowsMin={3}
                              defaultValue={
                                this.state.editStartup.shortDescription
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>
                              Queremos acercar tu idea al mayor número de
                              clientes posibles. Para ello, un equipo de
                              Estartaps.com va a revisar todo lo que nos cuentes
                              de tu solución, intentando entender hasta el
                              mínimo detalle para que el mundo lo conozca
                            </p>
                            <ReactQuill
                              name="longDescription"
                              modules={this.modules}
                              formats={this.formats}
                              onChange={this.handleChangeDescription}
                              defaultValue={this.state.longDescription}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>
                              Ahora nos gustaría que nos contases los diferentes
                              productos o servicios que ofrece tu Startup
                            </p>
                            <TextareaAutosize
                              aria-label="minimum height"
                              id="update_startup_products"
                              name="update_startup_products"
                              rowsMin={3}
                              defaultValue={
                                this.state.editStartup.servicesDescription
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>
                              Nos gustaría conocer a tu equipo, cuéntanos
                              brevemente sobre ellos.
                            </p>
                            <TextareaAutosize
                              aria-label="minimum height"
                              id="update_startup_team"
                              name="update_startup_team"
                              rowsMin={3}
                              defaultValue={
                                this.state.editStartup.teamDescription
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>¿Has testeado tu solución con alguna empresa?</p>
                            <RadioGroup
                              aria-label="test_company"
                              name="update_startup_test_company"
                              defaultValue={
                                this.state.editStartup.testedInfoBoolean === ''
                                  ? ''
                                  : this.state.editStartup.testedInfoBoolean ===
                                    true
                                  ? 'True'
                                  : 'False'
                              }
                            >
                              <FormControlLabel
                                value="True"
                                control={<Radio />}
                                label="Si"
                              />
                              <FormControlLabel
                                value="False"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>
                              En caso de que sea que sí, nos gustaría saber cómo
                              te fue.
                            </p>
                            <TextareaAutosize
                              aria-label="minimum height"
                              id="update_startup_opinion_test"
                              name="update_startup_opinion_test"
                              rowsMin={3}
                              defaultValue={
                                this.state.editStartup.testedInfoText
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="update_mainPhrase"
                              shrink={true}
                            >
                              Frase para mostrar en la web
                            </InputLabel>
                            <Input
                              id="update_mainPhrase"
                              name="update_mainPhrase"
                              aria-describedby="update_mainPhrase"
                              type="text"
                              defaultValue={this.state.editStartup.mainPhrase}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel htmlFor="update_cifStartup">
                              CIF De Startup
                            </InputLabel>
                            <Input
                              id="update_cifStartup"
                              name="update_cifStartup"
                              aria-describedby="update_cifStartup"
                              type="text"
                              defaultValue={this.state.editStartup.cif}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel htmlFor="update_workerstartup">
                              Número de empleados
                            </InputLabel>
                            <Input
                              id="update_workerstartup"
                              name="update_workerstartup"
                              aria-describedby="update_workerstartup"
                              type="number"
                              required
                              defaultValue={this.state.editStartup.workers}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel htmlFor="update_presentacion_video">
                              Video de presentación (Solo admite youtube)
                            </InputLabel>
                            <Input
                              id="update_presentacion_video"
                              name="update_presentacion_video"
                              aria-describedby="update_presentacion_video"
                              type="url"
                              defaultValue={
                                this.state.editStartup.videoPresentation
                              }
                            />
                          </FormControl>
                        </Grid>
                        {this.state.editStartup.img ? (
                          <Grid item={true} sm={12} xs={12}>
                            <img
                              src={
                                process.env.REACT_APP_API_URL_PROD +
                                '/api/uploads/startups/' +
                                this.state.editStartup.uid
                              }
                              alt="startup profile"
                              className="profile-image "
                            />
                          </Grid>
                        ) : (
                          ''
                        )}

                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>Logo de su Startup</p>

                            <Input
                              id="update_image"
                              name="update_image"
                              ref="update_image"
                              aria-describedby="update_image"
                              type="file"
                              onChange={this.handleChange}
                            />
                          </FormControl>
                          {this.state.uploadedImage !== '' ? (
                            <div>
                              <p>
                                Ajuste la imagen en el cuadrado blanco y acepte
                                para guardar la imagen, puede hacer uso del zoom
                                o mover la imagen
                              </p>
                              <div className="crop-container">
                                <Cropper
                                  image={this.state.imageSrc}
                                  crop={this.state.crop}
                                  minZoom={0.4}
                                  maxZoom={15}
                                  zoom={this.state.zoom}
                                  aspect={this.state.aspect}
                                  onCropChange={this.onCropChange}
                                  onCropComplete={this.onCropComplete}
                                  onZoomChange={this.onZoomChange}
                                  cropSize={this.state.cropSize}
                                  restrictPosition={false}
                                />
                              </div>
                              <Button
                                onClick={this.showCroppedImage}
                                variant="contained"
                                color="primary"
                              >
                                Aceptar y ver resultado
                              </Button>
                            </div>
                          ) : (
                            ''
                          )}
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {this.state.croppedImage !== '' ? (
                            <img
                              src={this.state.croppedImage}
                              alt="Startup"
                              className="profile-image "
                            ></img>
                          ) : (
                            ''
                          )}
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <br />
                          <p>
                            Siga las instrucciones de este documento para enviar
                            el documento de inversión:
                          </p>
                          <p>
                            <a
                              href={docDownload}
                              target="_blank"
                              download
                              rel="noreferrer"
                            >
                              <DownloadIcon fontSize="large" />
                            </a>
                          </p>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="register_investorDoc"
                              shrink={true}
                            >
                              Puedes adjuntar tu documento de inversión
                            </InputLabel>
                            <Input
                              id={'register_investorDoc'}
                              name={'register_investorDoc'}
                              aria-describedby={'register_investorDoc'}
                              type="file"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Madurez de la Startup</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item={true} sm={12} xs={12}>
                          <Grid container>
                            <Grid
                              item={true}
                              sm={6}
                              xs={12}
                              className="containerOptionSquare"
                            >
                              <div
                                className={
                                  'optionSquare ' +
                                  (this.state.editStartup.maturityInfo ===
                                  'Pre-semilla'
                                    ? 'choosenOption'
                                    : '')
                                }
                                onClick={() => this.chooseOptionMature(1)}
                                id="opt1"
                              >
                                <h5 className="center">Fase Pre-Semilla</h5>
                                <p>
                                  Compañias muy jovenes, en general con menos de
                                  un año desde su creación, creados por equipos
                                  excelentes.
                                </p>
                                <ul>
                                  <li>Pre-money: {'<1€'}1M</li>
                                  <li>Antigüedad: Ninguna</li>
                                </ul>
                              </div>
                            </Grid>
                            <Grid
                              item={true}
                              sm={6}
                              xs={12}
                              className="containerOptionSquare"
                            >
                              <div
                                className={
                                  'optionSquare ' +
                                  (this.state.editStartup.maturityInfo ===
                                  'Semilla'
                                    ? 'choosenOption'
                                    : '')
                                }
                                onClick={() => this.chooseOptionMature(2)}
                                id="opt2"
                              >
                                <h5 className="center">Fase Semilla</h5>
                                <p>
                                  Empresas nuevas que están explorando su
                                  concepto y creado un producto mínimo(MVP) para
                                  validar.
                                </p>
                                <ul>
                                  <li>Pre-money: {'<€'}2M</li>
                                  <li>Antigüedad: 6 meses</li>
                                </ul>
                              </div>
                            </Grid>
                            <Grid
                              item={true}
                              sm={6}
                              xs={12}
                              className="containerOptionSquare"
                            >
                              <div
                                className={
                                  'optionSquare ' +
                                  (this.state.editStartup.maturityInfo ===
                                  'Temprana'
                                    ? 'choosenOption'
                                    : '')
                                }
                                onClick={() => this.chooseOptionMature(3)}
                                id="opt3"
                              >
                                <h5 className="center">Fase Temprana</h5>
                                <p>
                                  Empresas con producto y métricas que el equipo
                                  puede replicar, incoporar feedback y mejorar
                                  su estrategia.
                                </p>
                                <ul>
                                  <li>Pre-money: {'<€'}8M</li>
                                  <li>Antigüedad: 9 meses</li>
                                </ul>
                              </div>
                            </Grid>
                            <Grid
                              item={true}
                              sm={6}
                              xs={12}
                              className="containerOptionSquare"
                            >
                              <div
                                className={
                                  'optionSquare ' +
                                  (this.state.editStartup.maturityInfo ===
                                  'Consolidada'
                                    ? 'choosenOption'
                                    : '')
                                }
                                onClick={() => this.chooseOptionMature(4)}
                                id="opt4"
                              >
                                <h5 className="center">Fase Madura</h5>
                                <p>
                                  Empresas más maduras, que facturan, con
                                  mayores costes y menos retorno potencial, pero
                                  a priori menos riesgo.
                                </p>
                                <ul>
                                  <li>Pre-money: {'>€'}8M</li>
                                  <li>Antigüedad: 12 meses</li>
                                </ul>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>
                              ¿Actualmente su Startup se encuentra en algún
                              proceso de aceleración?
                            </p>
                            <RadioGroup
                              aria-label="test_company"
                              name="update_startup_aceleration"
                              defaultValue={
                                this.state.editStartup.accelerationBoolean ===
                                ''
                                  ? ''
                                  : this.state.editStartup
                                      .accelerationBoolean === true
                                  ? 'True'
                                  : 'False'
                              }
                            >
                              <FormControlLabel
                                value="True"
                                control={<Radio />}
                                label="Si"
                              />
                              <FormControlLabel
                                value="False"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>Si su respuesta es sí ¿en qué aceleradora?</p>
                            <TextareaAutosize
                              aria-label="minimum height"
                              id="update_startup_aceleration_description"
                              name="update_startup_aceleration_description"
                              rowsMin={3}
                              defaultValue={
                                this.state.editStartup.accelerationProcessInfo
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Productos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <h3>Productos</h3>
                        {this.state.products_array.map((input, index) => (
                          <Grid
                            item={true}
                            sm={12}
                            xs={12}
                            className="boxed"
                            id={'product-' + index}
                          >
                            <Button
                              onClick={() => this.deleteProduct(index)}
                              className="buttonWarning"
                              startIcon={<DeleteIcon />}
                            >
                              Borrar Producto
                            </Button>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-name'}
                                shrink={true}
                              >
                                Nombre del producto o servicio*
                              </InputLabel>
                              <Input
                                id={input + '-name'}
                                name={input + '-name'}
                                aria-describedby={input + '-name'}
                                type="text"
                                required
                                defaultValue={
                                  index + 1 <=
                                  this.state.editStartup.products.length
                                    ? this.state.editStartup.products[index]
                                        .name
                                    : ''
                                }
                              />
                            </FormControl>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-description'}
                                shrink={true}
                              >
                                Descripción
                              </InputLabel>
                              <Input
                                id={input + '-description'}
                                name={input + '-description'}
                                aria-describedby={input + '-description'}
                                type="text"
                                defaultValue={
                                  index + 1 <=
                                  this.state.editStartup.products.length
                                    ? this.state.editStartup.products[index]
                                        .description
                                    : ''
                                }
                              />
                            </FormControl>
                          </Grid>
                        ))}
                        <Grid item={true} sm={12} xs={12}>
                          <p
                            onClick={() => this.appendInput_product()}
                            className="button"
                          >
                            Añadir Producto
                          </p>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Miembros</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <h3>Miembros</h3>
                        {this.state.teams_array.map((input, index) => (
                          <Grid
                            item={true}
                            sm={12}
                            xs={12}
                            className="boxed"
                            id={'team-' + index}
                          >
                            <Button
                              onClick={() => this.deleteTeam(index)}
                              className="buttonWarning"
                              startIcon={<DeleteIcon />}
                            >
                              {' '}
                              Borrar Miembro
                            </Button>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-name'}
                                shrink={true}
                              >
                                Nombre de miembro*
                              </InputLabel>
                              <Input
                                id={input + '-name'}
                                name={input + '-name'}
                                aria-describedby={input + '-name'}
                                type="text"
                                required
                                defaultValue={
                                  index + 1 <=
                                  this.state.editStartup.managementTeam.length
                                    ? this.state.editStartup.managementTeam[
                                        index
                                      ].name
                                    : ''
                                }
                              />
                            </FormControl>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-position'}
                                shrink={true}
                              >
                                Cargo
                              </InputLabel>
                              <Input
                                id={input + '-position'}
                                name={input + '-position'}
                                aria-describedby={input + '-position'}
                                type="text"
                                defaultValue={
                                  index + 1 <=
                                  this.state.editStartup.managementTeam.length
                                    ? this.state.editStartup.managementTeam[
                                        index
                                      ].position
                                    : ''
                                }
                              />
                            </FormControl>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-linkedin'}
                                shrink={true}
                              >
                                Url Linkedin
                              </InputLabel>
                              <Input
                                id={input + '-linkedin'}
                                name={input + '-linkedin'}
                                aria-describedby={input + '-linkedin'}
                                type="text"
                                defaultValue={
                                  index + 1 <=
                                  this.state.editStartup.managementTeam.length
                                    ? this.state.editStartup.managementTeam[
                                        index
                                      ].linkedinUrl
                                    : ''
                                }
                              />
                            </FormControl>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-order'}
                                shrink={true}
                              >
                                Orden
                              </InputLabel>
                              <Input
                                id={input + '-order'}
                                name={input + '-order'}
                                aria-describedby={input + '-order'}
                                type="text"
                                defaultValue={
                                  index + 1 <=
                                  this.state.editStartup.managementTeam.length
                                    ? this.state.editStartup.managementTeam[
                                        index
                                      ].order
                                    : ''
                                }
                              />
                            </FormControl>
                          </Grid>
                        ))}
                        <Grid item={true} sm={12} xs={12}>
                          <p
                            onClick={() => this.appendInput_team()}
                            className="button"
                          >
                            Añadir Miembro
                          </p>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Zombilan</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.state === false}
                              value="0"
                              onChange={(e) => {
                                this.state.state === false
                                  ? this.setState({ state: true })
                                  : this.setState({ state: false });
                              }}
                            />
                          }
                          label="Activo"
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Grid container>
                    <Grid item={true} sm={12} xs={12} className="center">
                      {' '}
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="button center"
                        ref="buttonRegister"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <p></p>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(EditStartup);
