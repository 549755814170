import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import './../../../../styles/styles.css';
import { withSnackbar } from 'notistack';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import { InputLabel, Button, Grid, FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { DataGrid } from '@material-ui/data-grid';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
class BlogEdit extends Component {
  constructor(props) {
    super(props);

    console.log('props', props);

    this.state = {
      htmlText: '',
      userToken: props.userToken,
      post: props.extraProps.post !== undefined ? props.extraProps.post : [],
      actionSelected: '',
      title:
        props.extraProps.post.title !== undefined
          ? props.extraProps.post.title
          : '',
      htmlContent:
        props.extraProps.post.htmlContent !== undefined
          ? props.extraProps.post.htmlContent
          : '',
      headerImg:
        props.extraProps.post.headerImg !== undefined
          ? props.extraProps.post.headerImg
          : '',
      adsBanner:
        props.extraProps.post.adsBanner !== undefined
          ? props.extraProps.post.adsBanner
          : '',
      comments:
        props.extraProps.post.comments !== undefined
          ? props.extraProps.post.comments
          : [],
      auxComments:
        props.extraProps.post.comments !== undefined
          ? props.extraProps.post.comments
          : [],
      open: false,
      comment: '',
      commentID: '',
    };
  }

  componentDidMount() {}

  updatePost = async () => {
    const apiurl = '/api/blog/' + this.state.post._id;

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const { title, htmlContent, headerImg, adsBanner } = this.state;

    // eslint-disable-next-line array-callback-return
    let comments = this.state.auxComments.map((comment) => {
      if (!comment.delete) {
        return comment;
      }
    });

    const payload = {
      title,
      htmlContent,
      headerImg,
      adsBanner,
      comments: comments.filter((comment) => comment != null),
    };

    console.log(payload);

    const { data } = await axios.patch(apiurl, payload, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.props.enqueueSnackbar('Entrada actualizada', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  };

  uploaHeaderImg = async (file) => {
    if (file) {
      console.log('hay fichero');
      let formData = new FormData();
      formData.append('file', file);
      await axios
        .post('/api/uploads', formData)
        .then((res) => {
          this.setState({ headerImg: res.data.fileName });
          console.log(res.data.fileName);
          return res.data.fileName;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido el fichero');
        });
    }
  };

  uploadBannerAd = async (file) => {
    if (file) {
      console.log('hay fichero');
      let formData = new FormData();
      formData.append('file', file);
      await axios
        .post('/api/uploads', formData)
        .then((res) => {
          this.setState({ adsBanner: res.data.fileName });
          console.log(res.data.fileName);
          return res.data.fileName;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido el fichero');
        });
    }
  };

  imageHandler() {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append('file', file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Move cursor to right side of image
      this.quill.setSelection(range.index + 1);
      console.log('subida de imagen');

      const res = await axios
        .post('/api/uploads', formData)
        .then((res) => {
          console.log(res.data);
          return res.data.fileName;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido la imagen');
        });

      console.log(res);

      var url = 'https://estartaps.com/uploads/' + res;
      console.log(url);

      // Insert uploaded image

      this.quill.insertEmbed(range.index, 'image', url);
    };
  }

  handleChangeAction = async (e, commentId) => {
    const action = e.target.value;
    let { auxComments } = this.state;

    switch (action) {
      case 'APPROVE':
        auxComments = auxComments.map((comment) => {
          if (comment.id === commentId) {
            comment.approved = true;
            comment.delete = false;
            return comment;
          } else {
            return comment;
          }
        });
        break;
      case 'DELETE':
        auxComments = auxComments.map((comment) => {
          if (comment.id === commentId) {
            comment.delete = true;
            comment.approved = false;
            return comment;
          } else {
            return comment;
          }
        });

        if (auxComments.length === 0) {
          auxComments = [];
        }
        break;
      default:
        break;
    }

    this.setState({ auxComments });
  };

  getCommentStateValue = (comment) => {
    if (comment.approved) {
      return 'APPROVE';
    }
  };

  handleShow = (comment, id) => {
    if (this.state.open === false) {
      this.setState({
        open: true,
        comment: comment,
        commentID: id,
      });
    } else {
      this.setState({
        open: false,
        comment: '',
        commentID: '',
      });
    }
  };
  render() {
    const { title, htmlContent, headerImg, adsBanner } = this.state;

    const rowStyle = (comment, index) => ({
      background: comment.approved ? 'green' : 'red',
    });

    const commentColumns = [
      {
        field: 'commentText',
        headerName: 'Comentarios',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              {params.row.comment.length > 50 ? (
                <Button
                  className="button"
                  variant="secondary"
                  onClick={() =>
                    this.handleShow(params.row.comment, params.row.id)
                  }
                >
                  Revisar comentario
                </Button>
              ) : (
                params.row.comment
              )}
            </div>
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Acciones',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <FormControl className="width-100">
              <InputLabel id="demo-simple-select-label">
                Seleccionar acción
              </InputLabel>
              <Select
                labelId="update_comment"
                id="update_comment"
                name="update_comment"
                onChange={(e) => this.handleChangeAction(e, params.row.id)}
                value={this.getCommentStateValue(params.row)}
              >
                <MenuItem value={'APPROVE'}>Aprobado</MenuItem>
                <MenuItem value={'DELETE'}>Eliminar</MenuItem>
              </Select>
            </FormControl>
          );
        },
      },
    ];
    const fontSizeArr = [
      '8px',
      '10px',
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
    ];

    var Size = Quill.import('attributors/style/size');
    Size.whitelist = fontSizeArr;
    Quill.register(Size, true);
    Quill.register('modules/imageResize', ImageResize);
    return (
      <Container>
        <Modal
          show={this.state.open}
          onHide={this.handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Comentario a revisar</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.comment}</Modal.Body>
          {/* <Modal.Footer>
            <FormControl className="width-100">
              <InputLabel id="demo-simple-select-label">
                Seleccionar acción
              </InputLabel>
              <Select
                labelId="update_comment"
                id="update_comment"
                name="update_comment"
                onChange={(e) =>
                  this.handleChangeAction(e, this.state.commentID)
                }
								value={{}}
              >
                <MenuItem value={'APPROVE'}>Aprobar</MenuItem>
                <MenuItem value={'DELETE'}>Eliminar</MenuItem>
              </Select>
              <Button variant="secondary" onClick={this.handleShow}>
                Cerrar
              </Button>
            </FormControl>
          </Modal.Footer> */}
        </Modal>
        <h1 className="job-page-title">Editar entrada </h1>

        <Row>
          <Col md={12} xs={12}>
            <TextField
              id="standard-name"
              label="Título"
              value={title ? title : ''}
              margin="normal"
              className="forced-black"
              onChange={(e) => {
                this.setState({ title: e.target.value });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Imagen de cabecera
            </InputLabel>
            {this.props.extraProps.post.headerImg ? (
              <img
                src={
                  process.env.REACT_APP_API_URL_PROD + 'uploads/' + headerImg
                }
                alt="Imagen de cabecera"
                className="width-100 companyDirectoryImage"
              />
            ) : (
              ''
            )}

            <input
              type="file"
              onChange={(e) => this.uploaHeaderImg(e.target.files[0])}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Texto
            </InputLabel>
            <ReactQuill
              ref={(el) => {
                this.quill = el;
              }}
              onChange={(e) => this.setState({ htmlContent: e })}
              placeholder="Texto"
              value={htmlContent ? htmlContent : ''}
              modules={{
                imageResize: {
                  parchment: Quill.import('parchment'),
                  modules: ['Resize', 'DisplaySize'],
                },
                toolbar: {
                  container: [
                    [{ header: [1, 2, 3, 4, 5, 6] }],
                    [{ size: fontSizeArr }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link'],
                    ['image'],
                    ['clean'],
                    [{ color: [] }, { background: [] }],
                    [
                      { align: '' },
                      { align: 'center' },
                      { align: 'right' },
                      { align: 'justify' },
                    ],
                  ],
                  handlers: {
                    image: this.imageHandler,
                  },
                },
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Banner de publicidad
            </InputLabel>
            {this.props.extraProps.post.adsBanner ? (
              <img
                src={
                  process.env.REACT_APP_API_URL_PROD + 'uploads/' + adsBanner
                }
                alt="Banner de publicidad"
                className="width-100 companyDirectoryImage"
              />
            ) : (
              ''
            )}

            <input
              type="file"
              onChange={(e) => this.uploadBannerAd(e.target.files[0])}
            />

            {this.state.adsBanner !== '' &&
            this.state.adsBanner !== undefined ? (
              <Button
                variant="contained"
                style={{
                  background: '#6658ea',
                  color: 'white',
                  marginBottom: '2rem',
                  marginLeft: '1rem',
                  display: 'table',
                  margin: '0 auto',
                  marginTop: '2rem',
                }}
                onClick={() => this.setState({ adsBanner: '' })}
              >
                Borrar imagen
              </Button>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Divider style={{ marginTop: '2rem', marginBottom: '2rem' }} />
        <Row>
          <Grid item={true} sm={12} xs={12}>
            <DataGrid
              rows={this.state.comments ? this.state.comments : []}
              getRowId={(row) => row.id}
              autoHeight
              columns={commentColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              rowStyle={rowStyle}
            />
          </Grid>
        </Row>
        <Row>
          <Button
            variant="contained"
            style={{
              background: '#6658ea',
              color: 'white',
              marginBottom: '2rem',
              marginLeft: '1rem',
              display: 'table',
              margin: '0 auto',
              marginTop: '2rem',
            }}
            onClick={() => this.updatePost()}
          >
            Guardar
          </Button>
        </Row>
      </Container>
    );
  }
}

export default withSnackbar(BlogEdit);
