import React, { Component } from 'react';
import { Grid, Container, Input, FormControl, Button } from '@material-ui/core';
import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Scrollbars } from 'react-custom-scrollbars';
import Moment from 'react-moment';
import { Modal } from 'react-bootstrap';
class ChatMessages extends Component {
  constructor(props) {
    super(props);
    this.conversationsRef = React.createRef();
    this.state = {
      startupFounder: [],
      editStartup: '',
      userToken: '',
      userData: [],
      updateToPlublish: '',
      conversations: [],
      numberInArraySelected: 0,
      conversationIdSelected: '',
      getData: true,
      idUserLogged: '',
      popName: '',
      popLastname: '',
      popPhone: '',
      popEmail: '',
      popCompany: '',
      popStartupSell: '',
      popDocumentSellActions: '',
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserToken();
      await this.getUserInformation();

      //await this.getUserConversations();
    })();
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  changeScoll = () => {
    this.conversationsRef.current.scrollToBottom();
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
        idUserLogged: obj.uid,
        getData: false,
      });
      this.getUserConversations(obj);
    }
  };
  getUserConversations = (userInfo) => {
    var apiurl = '/api/messages/' + userInfo.uid;
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.messages) {
          this.setState({
            conversations: response.data.messages.reverse(),
            conversationIdSelected: response.data.messages.length
              ? response.data.messages[0]._id
              : 0,
          });
          this.setMessageAsSeen(
            response.data.messages.length ? response.data.messages[0]._id : 0,
            userInfo.uid
          );
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  updateUserConversations = () => {
    var apiurl = '/api/messages/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.messages) {
          this.setState({
            conversations: response.data.messages.reverse(),
          });
          setTimeout(this.changeScoll, 100);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  setConversitationId = (id, idConversation, allInfoConversation) => {
    this.setState({
      numberInArraySelected: id,
      conversationIdSelected: idConversation,
    });
    let idUserMessage = '';
    if (allInfoConversation.user1._id === this.state.userData.uid) {
      idUserMessage = allInfoConversation.user2._id;
    } else {
      idUserMessage = allInfoConversation.user1._id;
    }

    this.setMessageAsSeen(idConversation, idUserMessage);

    setTimeout(this.changeScoll, 100);
  };

  setMessageAsSeen = (idConversation, iduser) => {
    var apiurlput = '/api/messages/seen/' + idConversation;
    console.log('-----------------------------------');
    console.log(idConversation);
    console.log('-----------------------------------');
    const payload = {
      from: iduser,
    };
    axios({
      url: apiurlput,
      method: 'PATCH',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        console.log('mensaje visto');
      })
      .catch((error) => {});
  };
  submitMessage = (event) => {
    event.preventDefault();
    const message = event.target.messageToConversation.value;

    document.getElementById('messageToConversation').value = '';

    var apiurlput = '/api/messages/' + this.state.conversationIdSelected;

    if (message !== '' && message.replace(/\s/g, '').length) {
      const payload = {
        from: this.state.userData.uid,
        body: message,
        seen: false,
      };

      axios({
        url: apiurlput,
        method: 'PATCH',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          this.props.enqueueSnackbar('Mensaje enviado con exito', {
            variant: 'success',
          });
          this.updateUserConversations();
        })
        .catch((error) => {
          console.log(error.message);
          alert('Mensaje no enviado');
        });
    }
  };

  seeChat = (info) => {
    if (info.user1._id === this.state.userData.uid) {
      this.setState({
        popName: info.user2.name,
        popLastname: info.user2.lastname,
        popPhone: info.user2.phone,
        popEmail: info.user2.email,
        popCompany: info.user2.company,
      });
    } else {
      this.setState({
        popName: info.user1.name,
        popLastname: info.user1.lastname,
        popPhone: info.user1.phone,
        popEmail: info.user1.email,
        popCompany: info.user1.company,
      });
    }
    this.setState({
      openChat: true,
    });
  };
  seeSellActions = (info) => {
    this.setState({
      popStartupSell: info.nameStartupSellActions,
      popDocumentSellActions: info.docSellActions,
    });
    this.setState({
      openExtraInfo: true,
    });
  };
  handleClose = () => {
    this.setState({
      openChat: false,
    });
  };
  handleCloseExtrae = () => {
    this.setState({
      openExtraInfo: false,
    });
  };
  render() {
    console.log(this.state.conversations);
    const idUserLogged = this.state.idUserLogged;
    const conversationList = this.state.conversations.length
      ? this.state.conversations.map((message, index) => (
          <div
            key={index}
            onClick={() =>
              this.setConversitationId(index, message._id, message)
            }
            className={
              +this.state.numberInArraySelected === +index
                ? 'conversationLateral  activeConversation'
                : 'conversationLateral'
            }
          >
            <div className="inner width-100">
              <div className="imageProfileConversation">
                <AccountCircleIcon fontSize="large" />
              </div>
              <div className="textConversation">
                <div
                  className={
                    message.messages.some(function (messages) {
                      if (
                        messages.seen === false &&
                        idUserLogged !== messages.from
                      ) {
                        return true;
                      } else return false;
                    }) === true
                      ? 'notRead'
                      : 'read'
                  }
                >
                  <p className="conversationSubject">
                    {message.subject}
                    {message.user1._id === this.state.userData.uid
                      ? message.user2.company
                        ? ' - ' + message.user2.company
                        : ''
                      : message.user1.company
                      ? ' - ' + message.user1.company
                      : ''}
                  </p>
                  <p className="conversationName">
                    {message.user1._id === this.state.userData.uid
                      ? message.user2.name
                      : message.user1.name}
                  </p>
                </div>
                <div>
                  <p
                    onClick={() => this.seeChat(message)}
                    className="buttonSmall"
                  >
                    Más información
                  </p>
                  {message.nameStartupSellActions ? (
                    <p
                      onClick={() => this.seeSellActions(message)}
                      className="buttonSmall"
                    >
                      Información adicional
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      : '';

    const messageList = this.state.conversations.length
      ? this.state.conversations[this.state.numberInArraySelected].messages.map(
          (message, index, arr) => {
            if (arr.length - 1 === index) {
              if (message.from === this.state.userData.uid) {
                return (
                  <div className="lastMessage">
                    <div className="updatedDate">
                      <p>
                        {this.state.conversations[
                          this.state.numberInArraySelected
                        ].updatedAt !== '' ? (
                          <Moment format="DD/MM/YYYY hh:mm:ss">
                            {
                              this.state.conversations[
                                this.state.numberInArraySelected
                              ].updatedAt
                            }
                          </Moment>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                    <div className="actualUser">
                      <div className="colorChat">
                        <p> {message.body}</p>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="lastMessage">
                    <div className="updatedDate">
                      <p>
                        {this.state.conversations[
                          this.state.numberInArraySelected
                        ].updatedAt !== '' ? (
                          <Moment format="DD/MM/YYYY hh:mm:ss">
                            {
                              this.state.conversations[
                                this.state.numberInArraySelected
                              ].updatedAt
                            }
                          </Moment>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                    <div className="otherUser">
                      <div className="colorChat">
                        <p> {message.body}</p>
                      </div>
                    </div>
                  </div>
                );
              }
            } else {
              if (message.from === this.state.userData.uid) {
                return (
                  <div className="actualUser">
                    <div className="colorChat">
                      <p>{message.body}</p>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="otherUser">
                    <div className="colorChat">
                      <p>{message.body}</p>
                    </div>
                  </div>
                );
              }
            }
          }
        )
      : '';
    const topInformationActual = this.state.conversations.length ? (
      <div>
        <p>
          {this.state.conversations[this.state.numberInArraySelected].subject} -{' '}
          {this.state.conversations[this.state.numberInArraySelected].user1
            ._id === this.state.userData.uid
            ? this.state.conversations[this.state.numberInArraySelected].user2
                .company
            : this.state.conversations[this.state.numberInArraySelected].user1
                .company}
          {}
        </p>
        <p>
          {this.state.conversations[this.state.numberInArraySelected].user1
            ._id === this.state.userData.uid
            ? this.state.conversations[this.state.numberInArraySelected].user2
                .name
            : this.state.conversations[this.state.numberInArraySelected].user1
                .name}
        </p>
      </div>
    ) : (
      ''
    );

    return this.state.conversations.length < 1 ? (
      <p className="center">No hay Mensajes</p>
    ) : (
      <Container key={this.state.userData}>
        <Grid container className="borderShadow2">
          <Grid item xs={12} lg={12}>
            <Grid container>
              <Grid item xs={4} className="conversationListLateral">
                <Modal
                  show={this.state.openChat}
                  onHide={this.handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Información del usuario</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      {this.state.popName !== '' ? (
                        <p>Nombre: {this.state.popName}</p>
                      ) : (
                        ''
                      )}
                      {this.state.popLastname ? (
                        <p>Apellidos: {this.state.popLastname}</p>
                      ) : (
                        ''
                      )}
                      {this.state.popPhone ? (
                        <p>Telefono: {this.state.popPhone}</p>
                      ) : (
                        ''
                      )}
                      {this.state.popEmail ? (
                        <p>Email: {this.state.popEmail}</p>
                      ) : (
                        ''
                      )}
                      {this.state.popCompany ? (
                        <p>Empresa: {this.state.popCompany}</p>
                      ) : (
                        ''
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
                <Modal
                  show={this.state.openExtraInfo}
                  onHide={this.handleCloseExtrae}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Información de venta de acciones</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      {this.state.popStartupSell !== '' ? (
                        <p>
                          Nombre de la compañia: {this.state.popStartupSell}
                        </p>
                      ) : (
                        ''
                      )}
                      {this.state.popDocumentSellActions ? (
                        <p>
                          <a
                            href={
                              process.env.REACT_APP_API_URL_PROD +
                              '/uploads/' +
                              this.state.popDocumentSellActions
                            }
                            download="documento.pdf"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Button
                              form="formContact"
                              variant="contained"
                              color="primary"
                              type="submit"
                              className="button center width-100"
                            >
                              Descargar Fichero
                            </Button>
                          </a>
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
                <Scrollbars style={{ height: 350 }}>
                  <div key={this.state.userData}>{conversationList}</div>
                </Scrollbars>
              </Grid>
              <Grid item xs={8} className="conversationActiveMessages">
                <div className="informationActualConversation">
                  <div>{topInformationActual}</div>
                </div>
                <div className="listOfMessages" id="listOfMessages">
                  <Scrollbars
                    style={{ height: 250 }}
                    ref={this.conversationsRef}
                  >
                    {messageList}
                  </Scrollbars>
                </div>
                <div className="inputForMessage">
                  <form
                    className="formSendMessage"
                    onSubmit={this.submitMessage}
                    id="formSendMessage"
                    autoComplete="off"
                    key={this.state.userData}
                  >
                    <div className="width-80 inputMessage">
                      <FormControl className="width-100">
                        <Input
                          id="messageToConversation"
                          name="messageToConversation"
                          type="text"
                          ref="messageToConversation"
                          placeholder={'Escriba su mensaje'}
                        />
                      </FormControl>
                    </div>
                    <div className="width-20 buttonSendMessage">
                      <div className="width-100">
                        <button type="submit" className="buttonConversation">
                          Enviar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(ChatMessages);
