import React, { Component } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import ResultProduct from "./resultProduct";
class Result extends Component {
	constructor(props) {
		super(props);

		this.state = {
			startup: this.props.startup,
		};
	}

	componentDidMount() {}

	render() {
		const background = this.props.startup.promotedOrder
			? "	#FFFACD"
			: "transparent";
		return (
			<Box p={2} m={2} pl={4} style={{ background }}>
				<Grid item xs={12} sm container>
					<Grid item xs container direction="column" spacing={2}>
						<Grid item xs>
							<Typography gutterBottom variant="h6">
								<Link
									to={{
										pathname: `/startups/${this.state.startup.permalink}/`,
										props: { from: "search" },
									}}
								>
									{this.state.startup.name || ""} |{" "}
									{this.state.startup.mainPhrase || ""}
								</Link>
							</Typography>
							<Box mt={1}>
								<Typography variant="body2" gutterBottom>
									{this.state.startup.shortDescription || ""}
								</Typography>
							</Box>
							<Box mt={1}>
								{this.state.startup.products.map((product) => {
									return (
										<ResultProduct
											product={product}
											permalink={this.state.startup.permalink}
											key={product.name}
										/>
									);
								})}
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		);
	}
}
export default Result;
