import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkdeinIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Container } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import { Navbar } from 'react-bootstrap';
import { InputLabel, Input, FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { withSnackbar } from 'notistack';
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { acceptTemrms: false };
  }

  componentDidMount() {}
  changeState = (event) => {
    this.setState({
      acceptTemrms: event.target.checked,
    });
  };

  addToNewsletter = (event) => {
    event.preventDefault();
    let email = document.getElementsByName('newsletter')[0].value;

    console.log(email);

    if (email === '' || this.state.acceptTerms === false) {
      alert('faltan campos por rellenar');
    } else {
      const payload = {
        email: email.toLowerCase(),
      };
      var apiurl = '/api/newsletter';
      axios({
        url: apiurl,
        method: 'POST',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          this.props.enqueueSnackbar('Registrado a newsletter con éxito', {
            variant: 'success',
          });
        })
        .catch((error) => {
          console.log(error.response);
          this.props.enqueueSnackbar(
            'No se han podido guardar sus datos, asegurese de que la dirección de email es correcta y no la ha usado previamente.',
            {
              variant: 'error',
            }
          );

          console.log('Error');
        });
    }
  };

  render() {
    return (
      <Container className="footer">
        <Grid container>
          <div className="footerMenus">
            <Grid container className="MuiContainer-maxWidthLg centerFullWidth">
              <Grid item={true} xs={12} sm={3}>
                <div className="footerColumn">
                  <h5>Estartaps</h5>
                  <p className="smallText">
                    El valor de la innovación no está en evitar que te copien,
                    sino en conseguir que todos te quieran copiar.
                  </p>
                  <p className="smallText">2021 Espartapp</p>
                </div>
                <Grid item={true} xs={12} sm={3}>
                  <div className="footerColumn" style={{ marginTop: '-80px' }}>
                    <Navbar.Brand
                      href="/crea-tu-primera-startup"
                      className="help-create-startup"
                    >
                      <img
                        src="/images/help.png"
                        width="50"
                        className="d-inline-block align-top"
                        alt="Estartaps"
                      />
                    </Navbar.Brand>
                  </div>
                </Grid>
              </Grid>

              <Grid item={true} xs={12} sm={3}>
                <div className="footerColumn">
                  <h5>Navigation</h5>

                  <div className="navigatorFooter footerSpace">
                    <p>
                      <a href="/buscador-de-startups">Startups</a>
                    </p>
                    <p>
                      <a href="/invertir-en-startups">Inversión</a>
                    </p>
                    <p>
                      <a href="/empleos">Empleo</a>
                    </p>
                    <p>
                      <a href="/servicios-a-startups">Servicios a Startups</a>
                    </p>
                    <p>
                      <a href="/reto">Reto</a>
                    </p>
                    <p>
                      <a href="/zombilan">Zombilan</a>
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item={true} xs={12} sm={3}>
                <div className="footerColumn">
                  <h5>Contacts</h5>
                  <div className="footerSpace">
                    <p>
                      <a href="mailto:support@estartaps.com">
                        support@estartaps.com
                      </a>
                    </p>
                  </div>
                  <h5 style={{ marginTop: '1rem' }}>Privacidad y cookies</h5>
                  <div className="footerSpace">
                    <p>
                      <a href="https://estartaps.com/aviso-legal">
                        Aviso legal
                      </a>
                    </p>
                  </div>
                  <div className="footerSpace">
                    <p>
                      <a href="https://estartaps.com/politica-de-privacidad">
                        Términos y condiciones
                      </a>
                    </p>
                  </div>
                  <div className="footerSpace">
                    <p>
                      <a href="https://estartaps.com/politica-de-cookies">
                        Política de cookies
                      </a>
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item={true} xs={12} sm={3}>
                <div className="footerColumn">
                  <h5>Newsletter</h5>
                  <form onSubmit={this.addToNewsletter}>
                    <Grid container>
                      <Grid item={true} xs={12} sm={12}>
                        <FormControl className="width-100">
                          <InputLabel htmlFor="newsletter">Email </InputLabel>
                          <Input
                            id="newsletter"
                            name="newsletter"
                            aria-describedby="newsletter"
                            type="email"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item={true} xs={12} sm={12}>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.acceptTemrms}
                                onChange={this.changeState}
                                name="checkedA"
                              />
                            }
                            label={
                              <span>
                                Acepto los{' '}
                                <a
                                  href="/politica-de-privacidad"
                                  target="_blank"
                                  className="white-color"
                                >
                                  términos y condiciones
                                </a>
                              </span>
                            }
                          />
                        </FormGroup>
                      </Grid>
                      <div className="">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button-secondary"
                        >
                          ¡Adelante!
                        </Button>
                      </div>
                    </Grid>

                    <Divider />
                    <div className="center">
                      <a
                        href="https://www.linkedin.com/company/estartaps/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="noDecoration white-color"
                      >
                        <LinkdeinIcon fontSize="large" />
                      </a>
                      <a
                        href="https://www.facebook.com/Estartaps-104368368159747/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="noDecoration white-color"
                      >
                        <FacebookIcon fontSize="large" />
                      </a>
                      <a
                        href="https://twitter.com/estartaps"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="noDecoration white-color"
                      >
                        <TwitterIcon fontSize="large" />
                      </a>
                      <a
                        href="https://www.instagram.com/estartaps/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="noDecoration white-color"
                      >
                        <InstagramIcon fontSize="large" />
                      </a>
                    </div>
                  </form>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(Footer);
