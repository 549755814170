import React, { Component } from 'react';
import axios from 'axios';

import { Grid, Button } from '@material-ui/core';

import Carousel from 'react-bootstrap/Carousel';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import LabelIcon from '@material-ui/icons/Label';
import Contact from '../components/utils/contact';
import CheckIcon from '@material-ui/icons/Check';
import { Divider } from '@material-ui/core';
import { getUserToken } from '../components/utils/manageUserData';
import { Container } from '@material-ui/core';
class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      baseSearchUrl: '/buscador-de-startups',
      company: [],
      itemsPerPage: 9,
      numberOfPage: 1,
      beginItem: 0,
      endItem: 9,
      founderData: [],
      userToken: '',
    };
  }

  componentDidMount() {
    (async () => {
      await this.getCompanies();
      await this.getUserToken();
    })();
  }
  getUserToken = () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getCompanies = (userInfo) => {
    var apiurl = '/api/company/' + this.props.match.params.name;
    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        this.setState({
          company: response.data.company,
        });
        this.geFounderData(response.data.company.founder);
      })
      .catch((error) => {});
  };

  geFounderData = (userId) => {
    var apiurl = '/api/users/' + userId;
    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        this.setState({
          founderData: response.data.user,
        });
      })
      .catch((error) => {});
  };

  handlePage = (event) => {
    console.log(event.selected);

    this.setState({
      beginItem: event.selected * this.state.itemsPerPage,
      endItem: event.selected * this.state.itemsPerPage + 9,
    });
  };
  handleSelectCarousel = (selectedIndex) => {
    this.setState({ carouselIndex: selectedIndex });
  };
  render() {
    const video = (
      <div className="video-responsive" key={this.state.company}>
        <iframe
          width="420"
          height="315"
          src={this.state.company.videoPresentation}
          title="Presentación en video"
        ></iframe>
      </div>
    );
    const firstSlide = (
      <Grid container>
        <Grid item xs={12} sm={3}>
          {' '}
          {this.state.company.img ? (
            <img
              src={
                process.env.REACT_APP_API_URL_PROD +
                '/api/uploads/company/' +
                this.state.company._id
              }
              alt={'company' + this.state.company.name}
              className="width-100"
            />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12} sm={9}>
          <div className="margin-10">
            <h2>{this.state.company.name}</h2>
            <p>{this.state.company.shortDescription}</p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          className="center videoPresentation"
          key={this.state.company}
        >
          {this.state.company.videoPresentation !== '' &&
          this.state.company.videoPresentation
            ? video
            : ''}
        </Grid>
      </Grid>
    );

    console.log(this.state.company.services);
    const productsElement = this.state.company
      ? this.state.company.services
        ? this.state.company.services.map((product, index) => {
            return (
              <Grid
                item
                lg={12}
                sm={12}
                xs={12}
                className={
                  index % 2 === 0 ? 'serviceBox evenBox' : 'serviceBox oddBox'
                }
              >
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <div>
                      <h5 className="serviceName">{product.name}</h5>
                      <p>{product.description}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="boxPrice">
                      <div>
                        <p className="center">
                          <span
                            className={
                              product.discountPercentage !== ''
                                ? 'discount'
                                : 'finalPrice'
                            }
                          >
                            {product.price}€
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="center">
                          {product.discountPercentage !== '' ? (
                            <span>
                              <span className="discountText">
                                {product.discountPercentage}%
                              </span>{' '}
                              de descuento
                            </span>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                      <div>
                        {product.discountPercentage !== '' ? (
                          <p className="center">
                            <span className="priceAfterDiscount">
                              {product.discountedPrice}€
                            </span>
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        : ''
      : '';

    const industriesElement = this.state.company ? (
      this.state.company.industries ? (
        this.state.company.industries ? (
          <div className="w-100">
            <span className="startup-tag">{this.state.company.industries}</span>
          </div>
        ) : (
          ''
        )
      ) : (
        ''
      )
    ) : (
      ''
    );
    const servicesNames = this.state.company
      ? this.state.company.services
        ? this.state.company.services.map((product) => {
            return (
              <Grid item lg={12} sm={12} xs={12}>
                <p>
                  <CheckIcon /> {product.name}
                </p>
              </Grid>
            );
          })
        : ''
      : '';

    const workers = this.state.company ? (
      <Grid item lg={12} sm={12} xs={12}>
        <p>
          {this.state.company.workers
            ? this.state.company.workers
            : 'No definido'}
        </p>
      </Grid>
    ) : (
      ''
    );

    return (
      <Container className="bodyWrapper">
        <Grid
          container
          className="w-100  startup"
          style={{ background: '#F3F8FF' }}
        >
          <Grid className="float-left mainColorBg" sm={9} xs={12}>
            <Grid container className="button-carousel">
              <Grid
                sm={3}
                xs={3}
                className={
                  this.state.carouselIndex === 0
                    ? 'selectedCarousel center'
                    : 'center'
                }
              >
                {' '}
                <Button
                  className="width-100"
                  onClick={() => this.handleSelectCarousel(0)}
                  disableRipple
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div>
                    <div className="center">
                      <div className="iconCarousel">
                        <BusinessIcon fontSize="large" />
                      </div>
                    </div>
                    <div className="center">
                      <p>Presentación</p>
                    </div>
                  </div>
                </Button>
              </Grid>
              <Grid sm={3} xs={3} className="center">
                {' '}
                <Button
                  className="width-100"
                  onClick={() => this.handleSelectCarousel(1)}
                  disableRipple
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div>
                    <div
                      className={
                        this.state.carouselIndex === 1
                          ? 'selectedCarousel center'
                          : 'center'
                      }
                    >
                      <div className="iconCarousel">
                        <DescriptionIcon fontSize="large" />
                      </div>
                    </div>
                    <div className="center">
                      <p>descripción</p>
                    </div>
                  </div>
                </Button>
              </Grid>
              <Grid
                sm={3}
                xs={3}
                className={
                  this.state.carouselIndex === 2
                    ? 'selectedCarousel center'
                    : 'center'
                }
              >
                {' '}
                <Button
                  className="width-100"
                  onClick={() => this.handleSelectCarousel(2)}
                  disableRipple
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div>
                    <div className="center">
                      <div className="iconCarousel">
                        <LabelIcon fontSize="large" />
                      </div>
                    </div>
                    <div className="center">
                      <p>Servicios</p>
                    </div>
                  </div>
                </Button>
              </Grid>
            </Grid>
            <Carousel
              style={{ padding: '1rem' }}
              activeIndex={this.state.carouselIndex}
              interval={null}
              className="carouselStartup"
            >
              <Carousel.Item>
                <div className="w-45 float-left" style={{ marginRight: '5%' }}>
                  {firstSlide}
                </div>

                <div className="w-50 float-left">
                  <h2>{this.state.Company}</h2>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="w-100 float-left">
                  <h2 className="center mb-3">Descripción</h2>
                  <div
                    className="descriptionContent"
                    dangerouslySetInnerHTML={{
                      __html: this.state.company.longDescription,
                    }}
                  ></div>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="w-100 float-left">
                  <h2 className="center mb-3">Servicios</h2>

                  {this.state.company.services
                    ? productsElement
                    : 'Sin servicios'}
                </div>
              </Carousel.Item>
            </Carousel>
          </Grid>
          <Grid
            className="float-left secondaryColorBg padding-10 "
            sm={3}
            xs={12}
          >
            <div className="w-100 ">
              <h2 className="black">
                {this.state.company !== '' ? this.state.company.name : ''}
              </h2>
            </div>
            <div className="w-100  ">
              <p>
                {this.state.company !== ''
                  ? this.state.company.shortDescription
                  : ''}
              </p>
            </div>
            <div className="margin-bottom-20">
              <Contact
                key={this.state.company + this.state.founderData.name}
                subject={'Contacto Empresa'}
                user2id={this.state.company.founder}
                user2name={
                  this.state.founderData !== []
                    ? this.state.founderData.name
                    : ''
                }
                user2company={
                  this.state.company !== [] ? this.state.company.name : ''
                }
                user2email={
                  this.state.founderData !== []
                    ? this.state.founderData.email
                    : ''
                }
                user2phone={
                  this.state.founderData !== []
                    ? this.state.founderData.phone
                    : ''
                }
                user2lastname={
                  this.state.founderData !== []
                    ? this.state.founderData.lastname
                    : ''
                }
                user2type={'Company'}
                userToken={this.state.userToken}
                budget={this.state.company.budget}
                collectionId={this.state.company._id}
                fromServices={true}
              />
            </div>
            <Divider variant="middle" className="separatorSidebar" />
            <h5 className="titleSidebar">Industria</h5>
            {industriesElement}
            <Divider variant="middle" className="separatorSidebar" />
            <h5 className="titleSidebar">Servicios</h5>
            {servicesNames}
            <Divider variant="middle" className="separatorSidebar" />
            <h5 className="titleSidebar">Número de empleados</h5>
            {workers}
          </Grid>

          <Grid className="w-100"></Grid>
        </Grid>
      </Container>
    );
  }
}
export default Company;
