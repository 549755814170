import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import './../../../../styles/styles.css';
import { withSnackbar } from 'notistack';
import { InputLabel, Button, Select, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

class BlogBannerCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      htmlText: '',
      userToken: props.userToken,
      img: '',
      position: '',
      orientation: '',
      href: '',
    };
  }

  componentDidMount() {}

  createAdBanner = async () => {
    const apiurl = '/api/blog/admin/ads';

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const { img, position, orientation, href } = this.state;

    const payload = {
      img,
      position,
      orientation,
      href,
    };

    const { data } = await axios.post(apiurl, payload, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.props.enqueueSnackbar('Banner añadido', {
        variant: 'success',
        autoHideDuration: 3000,
      });

      this.props.getClickedElement('blog_ads');
    }
  };

  uploadBanner = async (file) => {
    if (file) {
      console.log('hay fichero');
      let formData = new FormData();
      formData.append('file', file);
      await axios
        .post('/api/uploads', formData)
        .then((res) => {
          this.setState({ img: res.data.fileName });
          console.log(res.data.fileName);
          return res.data.fileName;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido el fichero');
        });
    }
  };

  render() {
    return (
      <Container>
        <h1 className="job-page-title">Crear banner </h1>

        <Row>
          <Col md={6} xs={12}>
            <p>Posición</p>
            <Select
              label="Posición"
              labelId="update_account"
              id="update_account"
              name="update_account"
              onChange={(e) =>
                this.setState({
                  position: e.target.value,
                })
              }
              style={{ width: '100%' }}
            >
              <MenuItem value={'Izquierda'}>Izquierda</MenuItem>
              <MenuItem value={'Derecha'}>Derecha</MenuItem>
              <MenuItem value={'Top'}>Top</MenuItem>
            </Select>
          </Col>
          <Col md={6} xs={12}>
            <p>Orientación</p>
            <Select
              label="Orientación"
              labelId="update_account"
              id="update_account"
              name="update_account"
              onChange={(e) =>
                this.setState({
                  orientation: e.target.value,
                })
              }
              style={{ width: '100%' }}
            >
              <MenuItem value={'Vertical'}>Vertical</MenuItem>
              <MenuItem value={'Horizontal'}>Horizontal</MenuItem>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col md={6} xs={12}>
            <InputLabel
              htmlFor="upload_banner_img"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Imagen
            </InputLabel>
            <input
              type="file"
              onChange={(e) => this.uploadBanner(e.target.files[0])}
            />
          </Col>
          <Col md={6} xs={12}>
            <TextField
              id="standard-name"
              label="Link"
              margin="normal"
              className="forced-black"
              onChange={(e) => {
                this.setState({ href: e.target.value });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Button
            variant="contained"
            style={{
              background: '#6658ea',
              color: 'white',
              marginBottom: '2rem',
              marginLeft: '1rem',
              display: 'table',
              margin: '0 auto',
              marginTop: '2rem',
            }}
            onClick={() => this.createAdBanner()}
          >
            Guardar
          </Button>
        </Row>
      </Container>
    );
  }
}

export default withSnackbar(BlogBannerCreate);
