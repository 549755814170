import React, { Component } from 'react';

import Moment from 'react-moment';
import { Container, Table } from 'react-bootstrap';
import './../../../styles/styles.css';

class AuctionSpending extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auction: [],
      transactions: this.props.collection.transactions,
    };
  }

  componentDidMount() {
    console.log('auction history', this.state);
  }

  render() {
    return (
      <Container>
        <h2>Gasto por búsqueda</h2>
        <Table responsive>
          <thead>
            <tr>
              <th>Coste</th>
              <th>Palabra</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {this.state.transactions
              ? this.state.transactions.map((transaction) => {
                  if (transaction.reason === 'PROMOTED SEARCH') {
                    return (
                      <tr>
                        <td key={transaction.date + transaction.amount}>
                          {transaction.amount} €
                        </td>
                        <td key={transaction.date + transaction.word}>
                          {transaction.word}
                        </td>
                        <td key={transaction.date + transaction.date}>
                          <Moment format="DD/MM/YYYY hh:mm:ss">
                            {transaction.date}
                          </Moment>
                        </td>
                      </tr>
                    );
                  }
                  return '';
                })
              : ''}
          </tbody>
        </Table>
      </Container>
    );
  }
}

export default AuctionSpending;
