import React, { Component } from "react";

import { Grid, Container } from "@material-ui/core";

import RegistroForm from "../components/form/register";

class Registro extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: "",
			facebookName: "",
			facebookEmail: "",
			facebookImage: "",
			showPassword: false,
			password: "",
		};
	}
	componentDidMount() {}

	handleClickShowPassword = (event) => {
		if (this.state.showPassword === false) {
			this.setState({
				showPassword: true,
			});
		} else {
			this.setState({
				showPassword: false,
			});
		}
	};
	render() {
		return (
			<Container>
				<Grid container xs={12}>
					<Grid item={true} xs={3} sm={2}></Grid>
					<Grid item={true} xs={12} sm={8}>
						<div className="center clear width-100">
							<h3>Únete a nuestra comunidad</h3>
							<p>Nosotros te ayudamos a conseguir tus objetivos</p>
						</div>
						<p className="center">
							<a href="/login">
								Si ya tiene cuenta puede loguearse haciendo click aqui
							</a>
						</p>

						<RegistroForm />
					</Grid>
					<Grid item={true} xs={3} sm={2}></Grid>
				</Grid>
			</Container>
		);
	}
}
export default Registro;
