import React, { Component } from 'react';
import axios from 'axios';
import { Container } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import './../../../../styles/styles.css';
import 'react-quill/dist/quill.snow.css';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';

class BlogBanners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: props.informationUser,
      userToken: props.userToken,
      ads: [],
      adsTotal: '',
    };
  }

  componentDidMount() {
    this.getBanner();
  }

  openImg = (banner) => {
    const url =
      process.env.REACT_APP_API_URL_PROD + 'uploads/' + banner.row.img;
    window.open(url, '_blank');
  };

  getBanner = async () => {
    const headers = {
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    };
    const apiurl = '/api/blog/admin/ads';
    const { data } = await axios.get(apiurl, headers);
    this.setState({
      ads: data.ads,
      adsTotal: data.total,
    });
  };

  viewMore = (post) => {
    const newProps = {
      post: post,
      fromAdmin: true,
    };

    this.props.getClickedElement('blog_edit_banner', newProps);
  };

  deleteBanner = async (_id) => {
    const headers = {
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    };

    const { data } = await axios.delete('/api/blog/admin/ads/' + _id, headers);

    if (Number(data.code) === 200) {
      this.props.enqueueSnackbar('Banner eliminado', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }

    this.getBanner();
  };

  goToCreateBanner = async () => {
    this.props.getClickedElement('blog_create_banner');
  };

  goToEditBlogBanner = async (banner) => {
    console.log('going to edit blog banner');

    const extraProps = {
      banner,
    };
    this.props.getClickedElement('blog_edit_banner', extraProps);
  };

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const columns = [
      {
        field: 'position',
        headerName: 'Posición',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return <div>{this.capitalize(params.row.position) || ''}</div>;
        },
      },
      {
        field: 'orientation',
        headerName: 'Orientación',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{this.capitalize(params.row.orientation) || ''}</div>;
        },
      },
      {
        field: 'imgLink',
        headerName: 'Link a la imagen',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button className="button" onClick={() => this.openImg(params)}>
                Abrir
              </Button>
            </div>
          );
        },
      },
      {
        field: 'edit',
        headerName: 'Editar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.goToEditBlogBanner(params.row)}
              >
                Editar
              </Button>
            </div>
          );
        },
      },
      {
        field: 'delete',
        headerName: 'Borrar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() =>
                  window.confirm('¿Estás seguro de borrar el banner? ')
                    ? this.deleteBanner(params.row._id)
                    : ''
                }
              >
                Borrar
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <Container>
        <h1 className="job-page-title">Banners del blog</h1>
        <Grid container>
          <Grid item={true} sm={12} xs={12} style={{ marginBottom: '1.8rem' }}>
            <Button className="button" onClick={() => this.goToCreateBanner()}>
              Crear banner
            </Button>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            <DataGrid
              rows={this.state.ads ? this.state.ads : []}
              getRowId={(row) => row._id}
              autoHeight
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withSnackbar(BlogBanners);
