import React, { Component } from "react";
import { Grid, Container } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { getUserData, getUserToken } from "../../../utils/manageUserData";
import axios from "axios";
import { withSnackbar } from "notistack";

class askCrowdFunding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editStartup: "",
			alreadyCrowd: 0,
		};
	}
	componentDidMount() {
		(async () => {
			await this.getUserInformation();
			await this.getUserToken();
		})();
	}
	getUserToken = async () => {
		var userToken = getUserToken();
		if (userToken !== false) {
			const obj = userToken;
			this.setState({
				userToken: obj,
			});
			this.getUserConversations(this.state.userData);
		}
	};
	getUserInformation = async () => {
		var userData = getUserData();

		if (userData !== false) {
			const obj = JSON.parse(userData);
			this.setState({
				userData: obj,
			});
			this.getUserFounder(obj);
		}
	};
	getUserFounder = (userInfo) => {
		var apiurl = "/api/users/" + userInfo.uid + "/startups";
		axios({
			url: apiurl,
			method: "GET",
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				if (response.data.startups.length) {
					this.setState({
						isFounder: true,
						startupFounder: response.data.startups,
					});
				}
			})
			.catch((error) => {});
	};
	startCrowfunding = () => {
		console.log(this.state.userData);
		if (
			this.state.userData.uid &&
			this.state.userData.role &&
			this.state.userData.name
		) {
			this.props.enqueueSnackbar("Proceso de crowdfunding iniciando", {
				variant: "success",
				autoHideDuration: 3000,
			});
			const payload = {
				subject: "Crowdfunding",
				user1: {
					_id: this.state.userData.uid,
					startupId: this.state.startupFounder[0].uid,
					type: "Startup",
					name: this.state.userData.name,
					company: this.state.startupFounder[0].name,
					phone: this.state.userData.phone,
					email: this.state.userData.email,
				},
			};
			const apiurlpost = "/api/messages/crowdfunding";
			axios({
				url: apiurlpost,
				method: "POST",
				data: payload,
				headers: {
					"x-token": this.state.userToken,
					"content-type": "application/json",
				},
			})
				.then((response) => {
					this.props.enqueueSnackbar("Proceso iniciado, redirigiendo al chat", {
						variant: "success",
					});
					window.location.href = "/perfil?goChat=true";
				})
				.catch((error) => {
					this.props.enqueueSnackbar(
						"Hemos encontrado un error y no se han guardado los cambios",
						{
							variant: "error",
						}
					);
				});
		} else {
			this.props.enqueueSnackbar("Error", {
				variant: "error",
				autoHideDuration: 3000,
			});
		}
	};
	getUserConversations = (userInfo) => {
		console.log("Dentro de obtener mensajes");

		var apiurl = "/api/messages/" + userInfo.uid;
		axios({
			url: apiurl,
			method: "GET",
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				let alreadyCrowd = 0;
				if (response.data.messages) {
					for (const item of response.data.messages) {
						if (item.user1.startupId) {
							alreadyCrowd = 1;
						}
					}
				}
				this.setState({
					alreadyCrowd: alreadyCrowd,
				});
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	render() {
		console.log(this.state.userToken);
		return (
			<Container>
				<Grid container className="borderShadow">
					{this.state.alreadyCrowd === 0 ? (
						<Grid item xs={12}>
							<p>
								Está a punto de unirse al sistema de Crowdfunding, cuando lo
								haga se pondrá en contacto con nuestro administrador para
								conocer situación y recoger los datos necesarios para empezar el
								proceso.
							</p>
							<Button className="button" onClick={this.startCrowfunding}>
								Iniciar conversación
							</Button>
						</Grid>
					) : (
						<p>Ya ha presentado su solicitud</p>
					)}
				</Grid>
			</Container>
		);
	}
}
export default withSnackbar(askCrowdFunding);
