//Register form for FINAL_USER_COMPANY
import React, { Component } from 'react';

import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
  StepButton,
  FormLabel,
} from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  getUserData,
  getUserToken,
  updateDataUser,
} from '../../utils/manageUserData';
class RegistroForm3 extends Component {
  constructor(props) {
    super(props);

    console.log(props);
    this.state = {
      activeStep: 0,
      optionChooseProfile: '',
      userData: props.userData ? props.userData : '',
      email: '',
      usertoken: '',
      choosenOptionMaturity: '',
      startupFounder: '',
      isFounder: false,
      steps: [
        'Datos Básicos',
        'Tus Estudios',
        'Idiomas',
        'Sobre ti',
        'Completar Registro',
      ],
      step1: 0,
      step2: 0,
      step3: 0,
      step4: 0,
      step5: 0,
      step6: 0,
      studies: [],
      studies_array: [],
      courses: [],
      courses_array: [],
      languages: [],
      languages_array: [],
      degree_array: [],
      softwares: [],
      softwares_array: [],
      acceptTerms01: '',
      newsletter: false,
      inProfile: props.inProfile ? props.inProfile : 0,
      clickedAcceptedTerms: false,
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.checkCompletedSteps();
    })();
  }

  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
        acceptTerms01:
          obj.acceptOffersEmail === false && this.props.inProfile !== 1
            ? ''
            : obj.acceptOffersEmail,
      });
      this.getUserFounder(obj);
      var studies = [];
      var courses = [];
      var languages = [];
      var softwares = [];
      var degree = [];

      if (obj.studies) {
        obj.studies.forEach((element, index, array) => {
          var name = 'studies-' + index;
          studies.push(name);
        });
      }
      if (studies.length) {
        this.setState({
          studies_array: studies,
        });
      }
      if (obj.courses) {
        obj.courses.forEach((element, index, array) => {
          var name = 'courses-' + index;
          courses.push(name);
        });
      }
      if (courses.length) {
        this.setState({
          courses_array: courses,
        });
      }
      if (obj.languages) {
        obj.languages.forEach((element, index, array) => {
          var name = 'languages-' + index;
          languages.push(name);
        });
      }
      if (languages.length) {
        this.setState({
          languages_array: languages,
        });
      }
      if (obj.studiesInformation.length) {
        console.log('aqui');
        if (obj.studiesInformation[0].degrees) {
          obj.studiesInformation[0].degrees.forEach((element, index, array) => {
            var name = 'degree-' + index;
            degree.push(name);
          });
        }
      }
      if (degree.length) {
        this.setState({
          degree_array: degree,
        });
      }
      if (obj.softwares) {
        obj.softwares.forEach((element, index, array) => {
          var name = 'softwares-' + index;
          softwares.push(name);
        });
      }
      if (softwares.length) {
        this.setState({
          softwares_array: softwares,
        });
      }
    }
  };
  changeStateTerms = (event) => {
    if (this.state.acceptTerms01 === true) {
      this.setState({
        acceptTerms01: false,
      });
    } else {
      this.setState({
        acceptTerms01: true,
      });
    }
  };
  changeStateNewsletter = (event) => {
    if (this.state.newsletter === true) {
      this.setState({
        newsletter: false,
      });
    } else {
      this.setState({
        newsletter: true,
      });
    }
  };
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  appendInput_studies() {
    var newInput = `studies-${this.state.studies_array.length}`;

    this.setState((prevState) => ({
      studies_array: prevState.studies_array.concat([newInput]),
    }));
  }
  deleteStudies = async (studies) => {
    alert('vas a borrar el estudio: ' + studies);

    document.getElementById('studies-' + studies).remove();
  };
  appendInput_course() {
    var newInput = `courses-${this.state.courses_array.length}`;

    this.setState((prevState) => ({
      courses_array: prevState.courses_array.concat([newInput]),
    }));
  }
  deleteCourses = async (courses) => {
    alert('vas a borrar el curso: ' + courses);

    document.getElementById('courses-' + courses).remove();
  };
  appendInput_languages() {
    var newInput = `languages-${this.state.languages_array.length}`;

    this.setState((prevState) => ({
      languages_array: prevState.languages_array.concat([newInput]),
    }));
  }
  deleteLanguages = async (languages) => {
    alert('vas a borrar el idioma: ' + languages);

    document.getElementById('languages-' + languages).remove();
  };

  appendInput_degree() {
    var newInput = `degree-${this.state.degree_array.length}`;

    this.setState((prevState) => ({
      degree_array: prevState.degree_array.concat([newInput]),
    }));
  }
  deleteDegree = async (degree) => {
    document.getElementById('degree-' + degree).remove();
  };

  appendInput_software() {
    var newInput = `softwares-${this.state.softwares_array.length}`;

    this.setState((prevState) => ({
      softwares_array: prevState.softwares_array.concat([newInput]),
    }));
  }
  deleteSoftwares = async (software) => {
    alert('vas a borrar el software: ' + software);

    document.getElementById('softwares-' + software).remove();
  };

  changeAcceptedClickTerms = (e) => {
    this.setState({
      clickedAcceptedTerms: true,
      acceptTerms01: e.target.value === 'true' ? true : false,
    });
  };

  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo.uid + '/startups';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.startups.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.startups[0],
          });
          this.checkCompletedSteps();
        }
      })
      .catch((error) => {
        this.checkCompletedSteps();
        console.log(error.response);
        console.log('No startup Found');
      });
  };
  handleChangeProfile = (event) => {
    this.setState({
      optionChooseProfile: event.target.value,
    });
  };
  checkCompletedSteps = async () => {
    if (this.state.inProfile === 0 || this.state.inProfile === undefined) {
      var step1Completed = 0;
      var step2Completed = 0;
      var step3Completed = 1;
      var step4Completed = 0;
      var step5Completed = 0;
      if (
        this.state.userData.name !== '' &&
        this.state.userData.lastName !== '' &&
        this.state.userData.phone !== '' &&
        this.state.userData.name &&
        this.state.userData.lastName &&
        this.state.userData.phone
      ) {
        step1Completed = 1;
        this.setState({
          step1: 1,
        });
      }

      if (
        this.state.userData.studiesInformation !== '' &&
        this.state.userData.studiesInformation &&
        this.state.userData.studiesInformation.length
      ) {
        step2Completed = 1;

        this.setState({ step2: 1 });
      }
      if (
        this.state.userData.languages !== '' &&
        this.state.userData.languages &&
        this.state.userData.languages.length
      ) {
        step3Completed = 1;
        this.setState({ step3: 1 });
      }
      if (
        this.state.userData.internshipInformation !== '' &&
        this.state.userData.internshipInformation &&
        this.state.userData.internshipInformation.length
      ) {
        step4Completed = 1;
        this.setState({ step4: 1 });
      }
      if (
        this.state.userData.acceptOffersEmail === false ||
        this.state.userData.acceptOffersEmail === true
      ) {
        step5Completed = 1;
        this.setState({ step5: 1 });
      }
      console.log(step1Completed);
      console.log(step2Completed);
      console.log(step3Completed);
      console.log(step4Completed);
      console.log(step5Completed);
      console.log(this.state.userData);
      if (
        step1Completed === 1 &&
        step2Completed === 1 &&
        step3Completed === 1 &&
        step4Completed === 1 &&
        step5Completed === 1 &&
        this.state.clickedAcceptedTerms
      ) {
        window.location.reload();
      }
    }
  };
  submitRegisterStep1 = (event) => {
    event.preventDefault();

    const payload = {
      name: event.target.register_steps_name.value,
      lastName: event.target.register_steps_lastname.value,
      phone: event.target.register_steps_phone.value,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep2 = (event) => {
    event.preventDefault();
    let listDegrees = [];
    var getDegrees = new Promise((resolve, reject) => {
      this.state.degree_array.forEach((element, index, array) => {
        if (
          document.getElementById(element + '-register_steps_degree') !==
            null &&
          document.getElementById(element + '-register_steps_degree') !==
            'undefined'
        ) {
          var nameField = element + '-register_steps_degree_ended';

          var name = document.getElementById(
            element + '-register_steps_degree'
          ).value;
          var type = document.getElementById(
            element + '-register_steps_degree_type'
          ).value;
          var year = document.getElementById(
            element + '-register_steps_degree_year'
          ).value;

          var credits = document.getElementById(
            element + '-register_steps_degree_credits'
          ).value;
          console.log(
            document.getElementById(element + '-register_steps_degree_ended')
              .value
          );
          let obj = {
            name: name,
            type: type,
            year: year,
            ended: event.target[nameField].value,
            credits: credits,
          };
          listDegrees.push(obj);
          console.log(listDegrees);
        }
        if (index === array.length - 1) resolve();
      });
    });
    getDegrees.then(() => {
      console.log('All done!');
    });

    const obj = {
      countryStudies: event.target.register_steps_country_studies.value,
      nameCenterStudies: event.target.register_steps_name_center_studies.value,
      typeStudies: event.target.register_steps_type_title.value,
      titleNameStudies: event.target.register_steps_title_name.value,
      yearBeginStudies: event.target.register_steps_year_begin_studies.value,
      studiesEnded: event.target.register_steps_end_studies.value,
      creditsStudies: event.target.register_steps_credits.value,
      internshipAgreement: event.target.register_steps_convenio.value,
      degrees: listDegrees,
    };
    const payload = {
      studiesInformation: obj,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep3 = async (event) => {
    event.preventDefault();

    let listLanguages = [];
    var getLanguages = new Promise((resolve, reject) => {
      this.state.languages_array.forEach((element, index, array) => {
        if (
          document.getElementById(element + '-register_steps_language') !==
            null &&
          document.getElementById(element + '-register_steps_language') !==
            'undefined'
        ) {
          var name = document.getElementById(
            element + '-register_steps_language'
          ).value;
          var level = document.getElementById(
            element + '-register_steps_level_language'
          ).value;
          let obj = {
            name: name,
            level: level,
          };
          listLanguages.push(obj);
        }
        if (index === array.length - 1) resolve();
      });
    });
    getLanguages.then(() => {
      console.log('All done!');
    });
    const payload = {
      languages: listLanguages,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');

        this.checkCompletedSteps();

        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep4 = (event) => {
    event.preventDefault();

    const obj = {
      participationCourse: event.target.register_steps_participation_course
        ? event.target.register_steps_participation_course.value
        : '',
      knownSoftwares: event.target.register_steps_app_known.value,
      publicTalk: event.target.register_steps_public_talk.value,
      teamWork: event.target.register_steps_team_work.value,
      profesionalSkills: event.target.register_steps_professional_skills.value,
      valuesProfile: event.target.register_steps_value_profile.value,
    };
    const payload = {
      internshipInformation: obj,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep5 = (event) => {
    event.preventDefault();

    let listSoftwares = [];
    var getSoftwares = new Promise((resolve, reject) => {
      this.state.softwares_array.forEach((element, index, array) => {
        if (
          document.getElementById(
            element + '-register_steps_name_softwares'
          ) !== null &&
          document.getElementById(
            element + '-register_steps_name_softwares'
          ) !== 'undefined'
        ) {
          var name = document.getElementById(
            element + '-register_steps_name_softwares'
          ).value;
          var level = document.getElementById(
            element + '-register_steps_level_softwares'
          ).value;
          let obj = {
            name: name,
            level: level,
          };
          listSoftwares.push(obj);
        }
        if (index === array.length - 1) resolve();
      });
    });
    getSoftwares.then(() => {
      console.log('All done!');
    });
    const payload = {
      softwares: listSoftwares,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');

        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  submitRegisterStep6 = async (event) => {
    event.preventDefault();
    let pdfDownloaded;
    if (document.getElementById('register_cv').files.length) {
      console.log('hay fichero');
      let formData = new FormData();
      formData.append('file', document.getElementById('register_cv').files[0]);
      pdfDownloaded = await axios
        .post('/api/uploads', formData)
        .then((res) => {
          return res.data.uploadPath;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido la imagen');
        });
    }

    const payload = {
      acceptOffersEmail: this.state.acceptTerms01,
      cv: pdfDownloaded,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
  };
  stepN1 = () => {
    return (
      <div>
        <form
          onSubmit={this.submitRegisterStep1}
          className="form"
          key={this.state.userData}
        >
          <Grid container>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_name" shrink={true}>
                  ¿Cómo te llamas?
                </InputLabel>
                <Input
                  id="register_steps_name"
                  name="register_steps_name"
                  aria-describedby="register_steps_name"
                  defaultValue={this.state.userData.name}
                  type="text"
                  required
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_lastname">
                  ¿Cuál es tu apellido?
                </InputLabel>
                <Input
                  id="register_steps_lastname"
                  name="register_steps_lastname"
                  aria-describedby="register_steps_lastname"
                  required
                  defaultValue={this.state.userData.lastName}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_phone" shrink={true}>
                  Para tener una comunicación más directa contigo. ¿A qué
                  teléfono te llamamos?
                </InputLabel>
                <Input
                  id="register_steps_phone"
                  name="register_steps_phone"
                  aria-describedby="register_steps_phone"
                  type="text"
                  defaultValue={this.state.userData.phone}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN2 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep2} className="form">
          <h2>
            Cuéntanos tus estudios para adecuarnos a tus gustos y preferencias
          </h2>
          <Grid item={true} sm={12} xs={12}>
            {' '}
            <FormControl className="width-100">
              <p>¿En qué país cursas tus estudios?*</p>
              <select
                id={'register_steps_country_studies'}
                name={'register_steps_country_studies'}
                aria-describedby={'register_steps_country_studies'}
                required
                defaultValue={
                  this.state.userData.studiesInformation.length > 0
                    ? this.state.userData.studiesInformation[0].countryStudies
                    : ''
                }
              >
                <option value="Afganistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire">Bonaire</option>
                <option value="Bosnia & Herzegovina">
                  Bosnia & Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Ter">
                  British Indian Ocean Ter
                </option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Canary Islands">Canary Islands</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Channel Islands">Channel Islands</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Island">Cocos Island</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote DIvoire">Cote DIvoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curaco">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Ter">French Southern Ter</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Great Britain">Great Britain</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="India">India</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea North">Korea North</option>
                <option value="Korea Sout">Korea South</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Malawi">Malawi</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Midway Islands">Midway Islands</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Nambia">Nambia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherland Antilles">Netherland Antilles</option>
                <option value="Netherlands">
                  Netherlands (Holland, Europe)
                </option>
                <option value="Nevis">Nevis</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau Island">Palau Island</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Phillipines">Philippines</option>
                <option value="Pitcairn Island">Pitcairn Island</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Republic of Montenegro">
                  Republic of Montenegro
                </option>
                <option value="Republic of Serbia">Republic of Serbia</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="St Barthelemy">St Barthelemy</option>
                <option value="St Eustatius">St Eustatius</option>
                <option value="St Helena">St Helena</option>
                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                <option value="St Lucia">St Lucia</option>
                <option value="St Maarten">St Maarten</option>
                <option value="St Pierre & Miquelon">
                  St Pierre & Miquelon
                </option>
                <option value="St Vincent & Grenadines">
                  St Vincent & Grenadines
                </option>
                <option value="Saipan">Saipan</option>
                <option value="Samoa">Samoa</option>
                <option value="Samoa American">Samoa American</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Tahiti">Tahiti</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Erimates">
                  United Arab Emirates
                </option>
                <option value="United States of America">
                  United States of America
                </option>
                <option value="Uraguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State">Vatican City State</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Virgin Islands (Brit)">
                  Virgin Islands (Brit)
                </option>
                <option value="Virgin Islands (USA)">
                  Virgin Islands (USA)
                </option>
                <option value="Wake Island">Wake Island</option>
                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                <option value="Yemen">Yemen</option>
                <option value="Zaire">Zaire</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
            </FormControl>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            <FormControl className="width-100">
              <InputLabel
                htmlFor="register_steps_name_center_studies"
                shrink={true}
              >
                ¿En qué centro o universidad cursaste tus estudios?*
              </InputLabel>
              <Input
                type="text"
                id={'register_steps_name_center_studies'}
                name={'register_steps_name_center_studies'}
                aria-describedby={'register_steps_name_center_studies'}
                required={true}
                defaultValue={
                  this.state.userData.studiesInformation.length
                    ? this.state.userData.studiesInformation[0]
                        .nameCenterStudies
                    : ''
                }
              />
            </FormControl>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            <FormControl className="width-100">
              <InputLabel htmlFor="register_steps_type_title" shrink={true}>
                ¿Cuál es el tipo de título?
              </InputLabel>
              <Input
                type="text"
                id={'register_steps_type_title'}
                name={'register_steps_type_title'}
                aria-describedby={'register_steps_type_title'}
                required={true}
                defaultValue={
                  this.state.userData.studiesInformation.length
                    ? this.state.userData.studiesInformation[0].typeStudies
                    : ''
                }
              />
            </FormControl>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            {' '}
            <FormControl className="width-100">
              <InputLabel htmlFor="register_steps_title_name" shrink={true}>
                Nombre del título*
              </InputLabel>
              <Input
                type="text"
                id={'register_steps_title_name'}
                name={'register_steps_title_name'}
                aria-describedby={'register_steps_title_name'}
                defaultValue={
                  this.state.userData.studiesInformation.length
                    ? this.state.userData.studiesInformation[0].titleNameStudies
                    : ''
                }
              />
            </FormControl>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            <FormControl className="width-100">
              <InputLabel
                htmlFor="register_steps_name_center_studies"
                shrink={true}
              >
                ¿En que año comenzaste tus estudios?*
              </InputLabel>
              <Input
                type="text"
                id={'register_steps_year_begin_studies'}
                name={'register_steps_year_begin_studies'}
                aria-describedby={'register_steps_year_begin_studies'}
                required={true}
                defaultValue={
                  this.state.userData.studiesInformation.length
                    ? this.state.userData.studiesInformation[0].yearBeginStudies
                    : ''
                }
              />
            </FormControl>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            <FormControl className="width-100">
              <FormLabel component="legend">¿Los has finalizado?*</FormLabel>
              <RadioGroup
                aria-label="register_steps_end_studies"
                name="register_steps_end_studies"
                required
                defaultValue={
                  this.state.userData.studiesInformation.length
                    ? this.state.userData.studiesInformation[0].studiesEnded
                    : ''
                }
              >
                <FormControlLabel value="Si" control={<Radio />} label="Si" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            <FormControl className="width-100">
              <InputLabel htmlFor="register_steps_credits" shrink={true}>
                En caso de no haberlos finalizado, ¿qué porcentaje de créditos
                tienes aprobados?
              </InputLabel>
              <Input
                type="text"
                id={'register_steps_credits'}
                name={'register_steps_credits'}
                aria-describedby={'register_steps_credits'}
                defaultValue={
                  this.state.userData.studiesInformation.length
                    ? this.state.userData.studiesInformation[0].creditsStudies
                    : ''
                }
              />
            </FormControl>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            <FormControl className="width-100">
              <FormLabel component="legend">
                Y por último, ¿puedes firmar convenio de prácticas con tu centro
                o universidad?*
              </FormLabel>
              <RadioGroup
                aria-label="register_steps_convenio"
                name="register_steps_convenio"
                required
                defaultValue={
                  this.state.userData.studiesInformation.length
                    ? this.state.userData.studiesInformation[0]
                        .internshipAgreement
                    : ''
                }
              >
                <FormControlLabel value="Si" control={<Radio />} label="Si" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              <h2 className="center">Títulos</h2>
            </Grid>
            {this.state.degree_array.map((input, index) => (
              <Grid
                item={true}
                sm={12}
                xs={12}
                className="boxed"
                id={'degree-' + index}
              >
                <p
                  onClick={() => this.deleteDegree(index)}
                  className="buttonDelete"
                >
                  Borrar titulo
                </p>
                <FormControl className="width-100">
                  <InputLabel htmlFor="-register_steps_degree" shrink={true}>
                    Nombre del titulo*
                  </InputLabel>

                  <Input
                    type="text"
                    id={input + '-register_steps_degree'}
                    name={input + '-register_steps_degree'}
                    aria-describedby={input + '-register_steps_degree'}
                    required={true}
                    defaultValue={
                      this.state.userData.studiesInformation.length
                        ? this.state.userData.studiesInformation[0].degrees
                            .length
                          ? index + 1 <=
                            this.state.userData.studiesInformation[0].degrees
                              .length
                            ? this.state.userData.studiesInformation[0].degrees[
                                index
                              ].name
                            : ''
                          : ''
                        : ''
                    }
                  />
                </FormControl>
                <Grid item={true} sm={12} xs={12}>
                  <FormControl className="width-100">
                    <InputLabel
                      htmlFor={input + '-register_steps_degree_type'}
                      shrink={true}
                    >
                      ¿Cuál es el tipo de título?
                    </InputLabel>
                    <Input
                      type="text"
                      id={input + '-register_steps_degree_type'}
                      name={input + '-register_steps_degree_type'}
                      aria-describedby={input + '-register_steps_degree_type'}
                      required={true}
                      defaultValue={
                        this.state.userData.studiesInformation.length
                          ? this.state.userData.studiesInformation[0].degrees
                              .length
                            ? index + 1 <=
                              this.state.userData.studiesInformation[0].degrees
                                .length
                              ? this.state.userData.studiesInformation[0]
                                  .degrees[index].type
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item={true} sm={12} xs={12}>
                  <FormControl className="width-100">
                    <InputLabel
                      htmlFor={input + '-register_steps_degree_year'}
                      shrink={true}
                    >
                      ¿En que año comenzaste tus estudios?*
                    </InputLabel>
                    <Input
                      type="text"
                      id={input + '-register_steps_degree_year'}
                      name={input + '-register_steps_degree_year'}
                      aria-describedby={input + '-register_steps_degree_year'}
                      required={true}
                      defaultValue={
                        this.state.userData.studiesInformation.length
                          ? this.state.userData.studiesInformation[0].degrees
                              .length
                            ? index + 1 <=
                              this.state.userData.studiesInformation[0].degrees
                                .length
                              ? this.state.userData.studiesInformation[0]
                                  .degrees[index].year
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={12} xs={12}>
                  <FormControl className="width-100">
                    <FormLabel component="legend">
                      ¿Los has finalizado?*
                    </FormLabel>
                    <RadioGroup
                      id={input + '-register_steps_degree_ended'}
                      aria-label={input + '-register_steps_degree_ended'}
                      name={input + '-register_steps_degree_ended'}
                      required
                      defaultValue={
                        this.state.userData.studiesInformation.length
                          ? this.state.userData.studiesInformation[0].degrees
                              .length
                            ? index + 1 <=
                              this.state.userData.studiesInformation[0].degrees
                                .length
                              ? this.state.userData.studiesInformation[0]
                                  .degrees[index].ended
                              : ''
                            : ''
                          : ''
                      }
                    >
                      <FormControlLabel
                        value="Si"
                        control={<Radio />}
                        label="Si"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item={true} sm={12} xs={12}>
                  <FormControl className="width-100">
                    <InputLabel
                      htmlFor="-register_steps_degree_credits"
                      shrink={true}
                    >
                      En caso de no haberlos finalizado, ¿qué porcentaje de
                      créditos tienes aprobados?
                    </InputLabel>
                    <Input
                      type="text"
                      id={input + '-register_steps_degree_credits'}
                      name={'input +-register_steps_degree_credits'}
                      aria-describedby={
                        input + '-register_steps_degree_credits'
                      }
                      defaultValue={
                        this.state.userData.studiesInformation.length
                          ? this.state.userData.studiesInformation[0].degrees
                              .length
                            ? index + 1 <=
                              this.state.userData.studiesInformation[0].degrees
                                .length
                              ? this.state.userData.studiesInformation[0]
                                  .degrees[index].credits
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item={true} sm={12} xs={12} className="center">
            <p onClick={() => this.appendInput_degree()} className="button">
              Añadir Estudios
            </p>
          </Grid>
          <Grid container>
            <Grid item={true} sm={12} xs={12} className="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN3 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep3} className="form">
          <h2>Nivel de idiomas</h2>

          <Grid container>
            {this.state.languages_array.map((input, index) => (
              <Grid
                item={true}
                sm={12}
                xs={12}
                className="boxed"
                id={'languages-' + index}
              >
                <p
                  onClick={() => this.deleteLanguages(index)}
                  className="buttonDelete"
                >
                  Borrar Idioma
                </p>
                <FormControl className="width-100">
                  <InputLabel htmlFor="register_steps_language" shrink={true}>
                    Idioma*
                  </InputLabel>

                  <Input
                    type="text"
                    id={input + '-register_steps_language'}
                    name={input + '-register_steps_language'}
                    aria-describedby={input + '-register_steps_language'}
                    required={true}
                    defaultValue={
                      this.state.userData.languages
                        ? index + 1 <= this.state.userData.languages.length
                          ? this.state.userData.languages[index].name
                          : ''
                        : ''
                    }
                  />
                </FormControl>

                <Grid item={true} sm={12} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <p>Nivel</p>
                    <select
                      id={input + '-register_steps_level_language'}
                      name={input + '-register_steps_level_language'}
                      aria-describedby={
                        input + '-register_steps_level_language'
                      }
                      defaultValue={
                        this.state.userData.languages
                          ? index + 1 <= this.state.userData.languages.length
                            ? this.state.userData.languages[index].level
                            : ''
                          : ''
                      }
                    >
                      <option>A1</option>
                      <option>A2</option>
                      <option>B1</option>
                      <option>B2</option>
                      <option>C1</option>
                      <option>C2</option>
                      <option>Nativo</option>
                    </select>
                  </FormControl>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item={true} sm={12} xs={12} className="center">
            <p onClick={() => this.appendInput_languages()} className="button">
              Añadir Idioma
            </p>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="button center"
              ref="buttonRegister"
            >
              Guardar y continuar
            </Button>
          </Grid>
        </form>
      </div>
    );
  };
  stepN4 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep4} className="form">
          <h2>Información sobre ti</h2>

          <Grid container>
            <Grid item={true} sm={12} xs={12} id={'about-you'}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_participation_course"
                  shrink={true}
                >
                  ¿Has participado en algún curso o seminario?*
                </InputLabel>

                <Input
                  type="text"
                  id={'register_steps_participation_course'}
                  name={'register_steps_participation_course'}
                  aria-describedby={'register_steps_participation_course'}
                  required
                  defaultValue={
                    this.state.userData.internshipInformation.length
                      ? this.state.userData.internshipInformation[0]
                          .participationCourse
                      : ''
                  }
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12} id={'about-you'}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_app_known" shrink={true}>
                  ¿Que herramientas tecnológicas o aplicaciones conoces?
                </InputLabel>

                <Input
                  type="text"
                  id={'register_steps_app_known'}
                  name={'register_steps_app_known'}
                  aria-describedby={'register_steps_app_known'}
                  defaultValue={
                    this.state.userData.internshipInformation.length
                      ? this.state.userData.internshipInformation[0]
                          .knownSoftwares
                      : ''
                  }
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12} id={'about-you'}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_public_talk" shrink={true}>
                  ¿Cómo te desarrollas hablando en público o defendiendo un
                  proyecto?¿Te sientes cómodo?*
                </InputLabel>

                <Input
                  type="text"
                  id={'register_steps_public_talk'}
                  name={'register_steps_public_talk'}
                  aria-describedby={'register_steps_public_talk'}
                  required
                  defaultValue={
                    this.state.userData.internshipInformation.length
                      ? this.state.userData.internshipInformation[0].publicTalk
                      : ''
                  }
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12} id={'about-you'}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_team_work" shrink={true}>
                  ¿Puedes describirnos cómo es tu capacidad de liderazgo y el
                  trabajo en equipo?*
                </InputLabel>

                <Input
                  type="text"
                  id={'register_steps_team_work'}
                  name={'register_steps_team_work'}
                  required
                  aria-describedby={'register_steps_team_work'}
                  defaultValue={
                    this.state.userData.internshipInformation.length
                      ? this.state.userData.internshipInformation[0].teamWork
                      : ''
                  }
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12} id={'about-you'}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_professional_skills"
                  shrink={true}
                >
                  ¿Que competencias profesionales posees?
                </InputLabel>

                <Input
                  type="text"
                  id={'register_steps_professional_skills'}
                  name={'register_steps_professional_skills'}
                  aria-describedby={'register_steps_professional_skills'}
                  defaultValue={
                    this.state.userData.internshipInformation.length
                      ? this.state.userData.internshipInformation[0]
                          .profesionalSkills
                      : ''
                  }
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12} id={'about-you'}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_value_profile"
                  shrink={true}
                >
                  Por último, cuéntanos intereses o características que puedas
                  aportar valor a tu perfil.
                </InputLabel>

                <Input
                  type="text"
                  id={'register_steps_value_profile'}
                  name={'register_steps_value_profile'}
                  aria-describedby={'register_steps_value_profile'}
                  defaultValue={
                    this.state.userData.internshipInformation.length
                      ? this.state.userData.internshipInformation[0]
                          .valuesProfile
                      : ''
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item={true} sm={12} xs={12} className="center">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="button center"
              ref="buttonRegister"
            >
              Guardar y continuar
            </Button>
          </Grid>
        </form>
      </div>
    );
  };
  stepN5 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep6} className="form">
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_cv" shrink={true}>
                  Si quieres puedes adjuntar documentos como tu CV o carta de
                  presentación
                </InputLabel>
                <Input
                  id={'register_cv'}
                  name={'register_cv'}
                  aria-describedby={'register_cv'}
                  type="file"
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {this.state.userData.cv ? (
                <FormControl className="width-100">
                  <a
                    href={this.state.userData.cv}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Link CV actual
                  </a>
                </FormControl>
              ) : (
                ''
              )}
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_offers" shrink={true}>
                  ¿Quieres recibir por correo electrónico ofertas acordes a tu
                  perfil?
                </InputLabel>
                <RadioGroup
                  aria-label="register_steps_offers"
                  name="register_steps_offers"
                  id="register_steps_offers"
                  value={this.state.acceptTerms01}
                  onChange={this.changeAcceptedClickTerms}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Si"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  chooseOptionMature = (option) => {
    var element = document.getElementById('opt1');
    var element2 = document.getElementById('opt2');
    var element3 = document.getElementById('opt3');
    var element4 = document.getElementById('opt4');
    if (option === 1) {
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element.classList.add('choosenOption');
    } else if (option === 2) {
      element.classList.remove('choosenOption');

      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element2.classList.add('choosenOption');
    } else if (option === 3) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');

      element4.classList.remove('choosenOption');
      element3.classList.add('choosenOption');
    } else if (option === 4) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.add('choosenOption');
    }
    this.setState({
      choosenOptionMaturity: option,
    });
  };

  responseGoogle = (response) => {
    console.log('aqui');
    this.setState({
      userData: response.profileObj,
      email: response.profileObj.email,
    });
    console.log(response);
  };
  responseGoogleFail = (response) => {
    console.log(response);
  };
  logout = (response) => {
    this.setState({
      userData: '',
    });
    alert('Logout');
  };

  responseFacebook = (response) => {
    this.setState({
      facebookName: response.name,
      facebookEmail: response.email,
      facebookImage: response.picture.data.url,
    });
    console.log(response);
  };
  socialLogin = () => {
    return (
      <div>
        <p className="center">Registro con Redes Sociales</p>
        <div className="center clear width-100">
          <GoogleLogin
            clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogleFail}
            cookiePolicy={'single_host_origin'}
          />
          <br></br>
          <FacebookLogin
            appId="238403055163743"
            autoLoad={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
            icon="fa-facebook"
          />
        </div>
      </div>
    );
  };

  getStepContent = (step) => {
    var stepReal;

    stepReal = step;

    switch (stepReal) {
      case 0:
        return this.stepN1();
      case 1:
        return this.stepN2();
      case 2:
        return this.stepN3();
      case 3:
        return this.stepN4();
      case 4:
        return this.stepN5();
      default:
        return 'Paso no reconocido';
    }
  };
  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };
  handleNext = () => {
    if (this.state.activeStep + 1 >= this.state.steps.length) {
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };
  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };
  submitRegister = (event) => {
    event.preventDefault();
    this.refs.buttonRegister.setAttribute('disabled', 'disabled');
    console.log(event.target.register_steps_email.value);
    console.log(event.target.register_steps_name.value);
    console.log(event.target.register_steps_lastname.value);
    if (
      event.target.register_steps_name.value === '' ||
      event.target.register_steps_lastname.value === '' ||
      event.target.register_steps_email.value === ''
    ) {
      alert('Faltan campos requeridos por completar');
      this.refs.buttonRegister.removeAttribute('disabled');
    } else {
      const payload = {
        name: event.target.register_steps_name.value,
        lastName: event.target.register_steps_lastname.value,
        companyName: event.target.register_steps_company.value,
        industryInterested: event.target.register_steps_industry.value,
        phone: event.target.register_steps_phone.value,
        email: event.target.register_steps_email.value,
        publicContactEmail: event.target.register_steps_email.value,
        pwd: event.target.register_steps_password.value,
        role: 'ADMIN',
        streetName: event.target.register_steps_address.value,
        postCode: event.target.register_steps_postalcode.value,
        locality: event.target.register_steps_locality.value,
        province: event.target.register_steps_province.value,
        country: event.target.register_steps_country.value,
        tyc: 1,
        newsletter: 1,
      };

      axios({
        url: '/api/users',
        method: 'POST',
        data: payload,
      })
        .then(() => {
          console.log('Data has been sent to the server');
        })
        .catch((error) => {
          console.log(error.response);
          console.log('Error');
        });

      console.log(event.target.register_steps_email.value);
      console.log(event.target.register_steps_name.value);
      console.log(event.target.register_steps_lastname.value);
      console.log(event.target.register_steps_phone.value);
      console.log(event.target.register_steps_password.value);
      //console.log(event.target.register_steps_startup.value);
      console.log(event.target.register_steps_company.value);
      console.log(event.target.register_steps_industry.value);
      console.log(event.target.register_steps_address.value);
      console.log(event.target.register_steps_postalcode.value);
      console.log(event.target.register_steps_locality.value);
      console.log(event.target.register_steps_province.value);
      console.log(event.target.register_steps_country.value);
      console.log(event.target.register_steps_newsletter.value);
      console.log(event.target.register_steps_terms.value);
      console.log('registro');

      this.refs.buttonRegister.removeAttribute('disabled');
      //this.buttonRegister.removeAttribute("disabled");
    }
  };
  handleStep = (event) => {
    var stepReal;

    stepReal = event;

    this.setState({
      activeStep: stepReal,
    });
  };
  render() {
    return (
      <Container>
        <Grid container xs={12}>
          <Grid
            item={true}
            xs={12}
            sm={12}
            className="formDelimiter"
            key={this.state.userData}
          >
            <Stepper
              nonLinear
              alternativeLabel
              activeStep={this.state.activeStep}
            >
              {this.state.steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => this.handleStep(index)}>
                    {label}
                    {index === 0 && this.state.step1 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 1 && this.state.step2 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 2 && this.state.step3 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 3 && this.state.step4 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 4 &&
                    this.state.step5 === 1 &&
                    this.state.clickedAcceptedTerms ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 5 && this.state.step6 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                  </StepButton>
                </Step>
              ))}
            </Stepper>

            <div>
              {this.state.activeStep === this.state.steps.length ? (
                <div>
                  <Typography>Ya no quedan mas pasos</Typography>
                </div>
              ) : (
                <div>
                  {this.getStepContent(this.state.activeStep)}

                  <div className="buttons-registration">
                    <Button
                      disabled={this.state.activeStep === 0}
                      onClick={() => {
                        this.handleBack();
                      }}
                    >
                      Anterior
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="button-nextStep"
                      onClick={() => {
                        this.handleNext();
                      }}
                    >
                      {this.state.activeStep === this.state.length - 1
                        ? 'Finalizar'
                        : 'Siguiente'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default RegistroForm3;
