import React, { Component } from 'react';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import axios from 'axios';
import LabelIcon from '@material-ui/icons/Label';
import Contact from '../../components/utils/contact';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { Container, Grid, Button } from '@material-ui/core';
import Carousel from 'react-bootstrap/Carousel';
import {
  getUserData,
  getUserToken,
} from '../../components/utils/manageUserData.js';

class JobPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      job: [],
      jobId: props.match.params.id || null,
      userToken: '',
      dataLoaded: false,
      carouselIndex: 0,
      userData: '',
      startup: [],
      founderData: [],
    };
  }

  componentDidMount() {
    this.getJob();
    this.getUserInformation();
    this.setState({ userToken: getUserToken() });
  }

  getJob = async () => {
    console.log('userToken', getUserToken());
    const userToken = '';
    const { jobId } = this.state;

    const apiurl = '/api/jobs/' + jobId;

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.setState({
          job: response.data.job,
          dataLoad: true,
        });

        this.getStartup(response.data.job.startupPermalink);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  getUserInformation = async () => {
    var userData = await getUserData();
    this.setState({
      loadingData: true,
    });
    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
    }
  };

  handleSelectCarousel = (selectedIndex) => {
    this.setState({ carouselIndex: selectedIndex });
  };

  getFounderData = (userId) => {
    var apiurl = '/api/users/' + userId;
    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        this.setState({
          founderData: response.data.user,
        });
        console.log('founder Data', response.data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getStartup = async (startupPermalink) => {
    const { userToken } = this.state;

    const apiurl = '/api/startups/' + startupPermalink;
    console.log(apiurl);

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.setState({
          startup: response.data.startup,
        });
        console.log('get startup', response.data.startup);
        this.getFounderData(response.data.startup.founder);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const handleSelect = (selectedIndex, e) => {
      this.setState({ carouselIndex: selectedIndex });
    };

    const { job } = this.state;

    return this.state.dataLoad !== true ? (
      <Container className="bodyWrapper">
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </Container>
    ) : (
      <Container className="bodyWrapper">
        <Grid
          container
          className="w-100 float-left  startup"
          style={{ background: '#F3F8FF' }}
        >
          <Grid className="float-left mainColorBg padding-10" sm={9} xs={12}>
            <h1 className="job-page-title">Oferta de empleo</h1>
            <h2 className="job-page-subtitle">{job.job}</h2>
            <Grid className="float-left mainColorBg w-100" sm={12} xs={12}>
              <Grid container className="button-carousel w-100">
                <Grid
                  sm={3}
                  xs={6}
                  className={
                    this.state.carouselIndex === 0
                      ? 'selectedCarousel center'
                      : 'center'
                  }
                >
                  {' '}
                  <Button
                    className="width-100"
                    onClick={() => this.handleSelectCarousel(0)}
                    disableRipple
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <div>
                      <div className="center">
                        <div className="iconCarousel">
                          <BusinessIcon fontSize="large" />
                        </div>
                      </div>
                      <div className="center">
                        <p>Descripción</p>
                      </div>
                    </div>
                  </Button>
                </Grid>
                <Grid sm={3} xs={6} className="center">
                  {' '}
                  <Button
                    className="width-100"
                    onClick={() => this.handleSelectCarousel(1)}
                    disableRipple
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <div>
                      <div
                        className={
                          this.state.carouselIndex === 1
                            ? 'selectedCarousel center'
                            : 'center'
                        }
                      >
                        <div className="iconCarousel">
                          <DescriptionIcon fontSize="large" />
                        </div>
                      </div>
                      <div className="center">
                        <p>Requerimientos</p>
                      </div>
                    </div>
                  </Button>
                </Grid>
                <Grid
                  sm={3}
                  xs={6}
                  className={
                    this.state.carouselIndex === 2
                      ? 'selectedCarousel center'
                      : 'center'
                  }
                >
                  {' '}
                  <Button
                    className="width-100"
                    onClick={() => this.handleSelectCarousel(2)}
                    disableRipple
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <div>
                      <div className="center">
                        <div className="iconCarousel">
                          <LabelIcon fontSize="large" />
                        </div>
                      </div>
                      <div className="center">
                        <p>Condiciones</p>
                      </div>
                    </div>
                  </Button>
                </Grid>
                <Grid
                  sm={3}
                  xs={6}
                  className={
                    this.state.carouselIndex === 3
                      ? 'selectedCarousel center'
                      : 'center'
                  }
                >
                  {' '}
                  <Button
                    className="width-100"
                    onClick={() => this.handleSelectCarousel(3)}
                    disableRipple
                    style={{ backgroundColor: 'transparent' }}
                  >
                    {/* <div>
                    <div className="center">
                      <div className="iconCarousel">
                        <GroupIcon fontSize="large" />
                      </div>
                    </div>
                     <div className="center">
                      <p>Equipo</p>
                    </div> 
                  </div> */}
                  </Button>
                </Grid>
              </Grid>
              <Carousel
                style={{ padding: '1rem' }}
                activeIndex={this.state.carouselIndex}
                onSelect={handleSelect}
                interval={null}
                className="carouselStartup"
              >
                <Carousel.Item>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <h2>Descripción</h2>

                      <div
                        className="descriptionContent"
                        dangerouslySetInnerHTML={{
                          __html: job.jobDescription,
                        }}
                      ></div>
                    </Grid>
                  </Grid>
                </Carousel.Item>
                <Carousel.Item>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <h2>Requerimientos</h2>

                      <div
                        className="formationRequirements"
                        dangerouslySetInnerHTML={{
                          __html: job.formationRequirements,
                        }}
                      ></div>
                    </Grid>
                  </Grid>
                </Carousel.Item>

                <Carousel.Item>
                  <div className="w-100 float-left">
                    <h2 className="center mb-3">Condiciones</h2>
                    <div className="job-conditions">
                      <Grid container spacing={2}>
                        <Grid item sm={3} style={{ fontWeight: 'bold' }}>
                          País
                        </Grid>
                        <Grid item sm={9}>
                          {job.country}
                        </Grid>
                        <Grid item sm={3} style={{ fontWeight: 'bold' }}>
                          Ciudad:
                        </Grid>
                        <Grid item sm={9}>
                          {job.town}
                        </Grid>
                        <Grid item sm={3} style={{ fontWeight: 'bold' }}>
                          Salario
                        </Grid>
                        <Grid item sm={9}>
                          {job.salary}
                        </Grid>
                        <Grid item sm={3} style={{ fontWeight: 'bold' }}>
                          Tipo de contrato:
                        </Grid>
                        <Grid item sm={9}>
                          {job.type}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Grid>
          </Grid>
          <Grid
            className="float-left secondaryColorBg  padding-10 "
            sm={3}
            xs={12}
          >
            <div className="w-100 ">
              <h2 className="black">
                {this.state.startup.name ? this.state.startup.name : ''}
              </h2>
            </div>
            <div className="w-100  ">
              <p>{}</p>
            </div>

            <p>
              {this.state.startup.shortDescription
                ? this.state.startup.shortDescription
                : ''}
            </p>

            <div className="margin-bottom-20">
              {this.state.founderData.uid !== undefined ? (
                <Contact
                  key={job._id + job.job}
                  jobId={job._id}
                  subject={'Contacto Trabajo'}
                  user2id={this.state.founderData.uid}
                  user2name={this.state.founderData.name}
                  user2company={
                    this.state.startup !== [] ? this.state.startup.name : ''
                  }
                  user2type={'Startup'}
                  user2email={this.state.founderData.email}
                  user2phone={this.state.founderData.phone}
                  user2lastname={this.state.founderData.lastname}
                  collectionId={this.state.startup.uid}
                  userToken={this.state.userToken}
                  alternativeContactText="Deseo inscribirme"
                  alternativeContactTextLogin="Inicia sesión para inscribirte"
                  fromJob={true}
                />
              ) : (
                ''
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default JobPage;
