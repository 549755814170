import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import { DataGrid } from '@material-ui/data-grid';
import Moment from 'react-moment';
class highlightCrowd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startupFounder: [],
      editStartup: '',
      userToken: '',
      userData: '',
      payments: [],
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.getPayments();
    })();
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
      this.getUserFounder(obj);
    }
  };

  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo.uid + '/startups';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.startups.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.startups,
          });
        }
      })
      .catch((error) => {});
  };

  getPayments = async () => {
    const apiurl = '/api/users/' + this.state.userData.uid + '/payments';
    const { data } = await axios.get(apiurl);
    this.setState({
      payments: data.payments,
    });

    console.log('payments data', data.payments);
  };

  buyPack = (packChoosed) => {
    let price = '';
    let description = '';
    if (packChoosed === 1) {
      price = 4.99;
      description = 'inversion-5';
    } else if (packChoosed === 2) {
      price = 11.99;
      description = 'inversion-15';
    }

    const payload = {
      userId: this.state.userData.uid,
      amount: price * 100,
      description,
    };

    var apiurl = '/api/payments';
    axios({
      url: apiurl,
      method: 'POST',
      data: payload,
      headers: {
        'x-token': this.props.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response.data);
        this.setState({
          mechParam: response.data.paymentParameters.merchantParameters,
          signature: response.data.paymentParameters.signature,
        });
        this.props.enqueueSnackbar(
          'Se le va ha redireccionar a la plataforma de pago',
          {
            variant: 'success',
          }
        );
        setTimeout(function () {
          document.getElementById('goToPaymentPlatform').submit();
        }, 2000);
        //window.location.reload();
        //this.emailVerification(email);
      })
      .catch((error) => {
        this.props.enqueueSnackbar('créditos no añadidos', {
          variant: 'error',
        });
        console.log(error.response);
        console.log('Error');
      });
    /*
			const payload = {
				credits: event.target.number_credits.value,
				role: this.state.userData.role,
			};

			var apiurl = "/api/users/credits/" + this.state.userData.uid;
			axios({
				url: apiurl,
				method: "PUT",
				data: payload,
				headers: {
					"x-token": this.props.userToken,
					"content-type": "application/json",
				},
			})
				.then((response) => {
					console.log("Data has been sent to the server");

					this.setState({
						userData: response.data.user2,
					});
					this.props.enqueueSnackbar("créditos añadidos", {
						variant: "success",
					});
					//window.location.reload();
					//this.emailVerification(email);
				})
				.catch((error) => {
					this.props.enqueueSnackbar("créditos no añadidos", {
						variant: "error",
					});
					console.log(error.response);
					console.log("Error");
				});
				*/
  };

  render() {
    const getFormattedDescription = (description) => {
      let r = '';

      switch (description) {
        case 'inversion-5':
          r = 'Inversión 5 días';
          break;

        case 'inversion-15':
          r = 'Inversión 15 días';
          break;

        default:
          r = 'Pago';
      }

      return r;
    };
    const columns = [
      {
        field: 'orderId',
        headerName: ' Nº Pedido',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return <div>{params.row.orderId || ''}</div>;
        },
      },
      {
        field: 'createdAt',
        headerName: 'Fecha',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              {' '}
              <Moment format="DD/MM/YYYY ">{params.row.createdAt}</Moment>
            </div>
          );
        },
      },
      {
        field: 'description',
        headerName: 'Descripción',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{getFormattedDescription(params.row.description)}</div>;
        },
      },
      {
        field: 'amount',
        headerName: 'Precio',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{params.row.amount / 100}</div>;
        },
      },
    ];

    return (
      <Container>
        <Grid container className="borderShadow">
          <Grid item xs={12}>
            <Grid container>
              <Grid item={true} sm={6} xs={12} className="padding-10">
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography gutterBottom>
                      <h4 className="center">Destacado durante 5 días</h4>
                    </Typography>
                    <Typography variant="h5" component="div"></Typography>
                    <Typography sx={{ mb: 1.5 }} className="center">
                      4,99€
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <div className="center">
                      <Button
                        variant="contained"
                        color="primary"
                        className="button center"
                        ref="buttonSubmit"
                        onClick={() => this.buyPack(1)}
                      >
                        Proceder al pago
                      </Button>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item={true} sm={6} xs={12} className="padding-10">
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography gutterBottom>
                      <h4 className="center">Destacado durante 15 días</h4>
                    </Typography>
                    <Typography variant="h5" component="div"></Typography>
                    <Typography sx={{ mb: 1.5 }} className="center">
                      11,99€
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <div className="center">
                      <Button
                        variant="contained"
                        color="primary"
                        className="button center"
                        ref="buttonSubmit"
                        onClick={() => this.buyPack(2)}
                      >
                        Proceder al pago
                      </Button>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
              <form
                action="https://sis.redsys.es/sis/realizarPago"
                method="POST"
                id="goToPaymentPlatform"
                hidden
              >
                <label>Ds_SignatureVersion</label>
                <input
                  type="text"
                  id="Ds_SignatureVersion"
                  name="Ds_SignatureVersion"
                  value="HMAC_SHA256_V1"
                />

                <br />
                <br />

                <label>Ds_MerchantParameters</label>
                <input
                  type="text"
                  name="Ds_MerchantParameters"
                  id="Ds_MerchantParameters"
                  value={this.state.mechParam}
                />

                <label>Ds_Signature</label>
                <input
                  type="text"
                  name="Ds_Signature"
                  id="Ds_Signature"
                  value={this.state.signature}
                />

                <br />
                <br />

                <button type="submit">Enviar test</button>
              </form>
            </Grid>
          </Grid>
        </Grid>

        {this.state.payments && this.state.payments.length > 0 ? (
          <Grid container className="borderShadow">
            <Grid item xs={12}>
              <DataGrid
                rows={this.state.payments ? this.state.payments : []}
                getRowId={(row) => row._id}
                autoHeight
                columns={columns}
                pageSize={5}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Container>
    );
  }
}
export default withSnackbar(highlightCrowd);
