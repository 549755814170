import React, { Component } from 'react';
import axios from 'axios';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, Box } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { Container } from '@material-ui/core';
import ReactPaginate from 'react-paginate';

class Homepage extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(props.location.search);

    this.state = {
      searchString: urlParams.get('q') || '',
      baseSearchUrl: '/buscador-de-startups',
      companies: [],
      showCompanies: [],
      industries: [],
      itemsPerPage: 9,
      numberOfPage: 1,
      beginItem: 0,
      endItem: 9,
      page: urlParams.get('page') || 1,
      limit: urlParams.get('limit') || 10,
      skip: urlParams.get('skip') || 0,
      filterTags: [],
    };
  }

  componentDidMount() {
    (async () => {
      await this.getCompanies();
    })();
  }

  getCompanies = (userInfo) => {
    const { searchString, page, skip, limit } = this.state;

    let params =
      '?q=' +
      searchString +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&skip=' +
      skip;

    var apiurl = '/api/company/getCompaniesServices' + params;

    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        var pages;
        if (response.data.total) {
          pages = response.data.total / this.state.itemsPerPage;
        } else {
          pages = 1;
        }

        console.log(response);
        this.setState({
          companies: response.data.companies,
          showCompanies: response.data.companies,
          industries: response.data.industries,
          numberOfItems: response.data.total,
          numberOfPage: pages,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePage = (event) => {
    console.log(event.selected);

    this.setState({
      beginItem: event.selected * this.state.itemsPerPage,
      endItem: event.selected * this.state.itemsPerPage + 9,
    });
  };

  addFilterTag = (tag) => {
    let tempFilterTags = this.state.filterTags;

    if (!tempFilterTags.includes(tag)) {
      tempFilterTags.push(tag);

      this.setState({ filterTags: tempFilterTags });
    }

    this.filterCompanies(tempFilterTags);
  };

  removeFilterTag = (tag) => {
    let tempFilterTags = this.state.filterTags.filter((filterTag) => {
      if (filterTag !== tag) {
        return filterTag;
      }
      return '';
    });

    this.setState({ filterTags: tempFilterTags });

    this.filterCompanies(tempFilterTags);
  };

  filterCompanies = (filterTags) => {
    let tempCompanies = this.state.companies;

    tempCompanies = tempCompanies.map((company) => {
      if (filterTags.includes(company.industries)) {
        return company;
      }
      return '';
    });

    if (filterTags.length === 0) {
      this.setState({ showCompanies: this.state.companies });
    } else {
      this.setState({ showCompanies: tempCompanies });
    }
  };

  getDiscount = (services) => {
    try {
      const percentage = Math.max.apply(
        Math,
        services.map(function (o) {
          return o.discountPercentage;
        })
      );
      return (
        <p className="topDiscount center">
          Hasta un <span className="dicountPercentage">{percentage}%</span> de
          descuento
        </p>
      );
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const companies = this.state.showCompanies
      .slice(this.state.beginItem, this.state.endItem)
      .map((company, index) => {
        if (company) {
          return (
            <Grid item lg={3} sm={3} xs={12} key={index}>
              <a
                href={'/servicios-a-startups/' + company.permalink}
                className="noDecoration companyBoxWrapper"
              >
                <div className="companyBox">
                  <Grid container>
                    <Grid item sm={12} xs={12}>
                      <h4>{company.name}</h4>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      {company.img ? (
                        <img
                          src={
                            process.env.REACT_APP_API_URL_PROD +
                            '/api/uploads/company/' +
                            company._id
                          }
                          alt={'company' + company.name}
                          className="width-100 companyDirectoryImage"
                        />
                      ) : (
                        <div className="noImage">
                          <BrokenImageIcon className="icon" fontSize="large" />
                        </div>
                      )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <div className="title_company">
                        <p>
                          {company.shortDescription
                            ? company.shortDescription
                            : ''}
                        </p>
                      </div>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      {this.getDiscount(company.services)}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <div className=" button">
                        <span>Ver más...</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </a>
            </Grid>
          );
        } else {
          return '';
        }
      });

    return (
      <Container className="bodyWrapper">
        <section>
          <Grid container>
            <Grid item={true} xs={12} sm={12} className="left">
              <Box m={2}>
                <h1 className="seo-h1">Servicios a Startups</h1>
                <h2 className="seo-h2">
                  Encuentra las mejores empresas de servicios a Startups
                </h2>
                <form method="GET">
                  <FormControl className="search-home">
                    <Input
                      style={{ marginTop: '2.5rem' }}
                      placeholder="Buscar Empresas"
                      className="formOutline"
                      name="q"
                      value={this.state.searchString}
                      onChange={(e) =>
                        this.setState({ searchString: e.target.value })
                      }
                      onKeyPress={(event) =>
                        event.key === 'Enter' ? this.getCompanies : ''
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />

                    <Grid container xs={12} sm={12}>
                      <p style={{ fontSize: '0.9rem', margin: '0.5rem' }}>
                        {this.state.filterTags.length > 0
                          ? ' Filtrando por:'
                          : ''}
                      </p>
                      {this.state.filterTags.map((filterTag) => {
                        return (
                          <div
                            className="industry-tag-selected"
                            style={{ position: 'relative' }}
                            onClick={() => {
                              this.removeFilterTag(filterTag);
                            }}
                          >
                            {filterTag} <span className="equis-oscar"> X </span>
                          </div>
                        );
                      })}
                    </Grid>

                    <Button type="submit" style={{ display: 'none' }}></Button>
                  </FormControl>
                </form>
              </Box>
            </Grid>

            <Grid container xs={12} sm={12}>
              <Grid container xs={12} sm={12}>
                {this.state.industries.map((industry) => {
                  return (
                    <div
                      className="industry-tag"
                      style={
                        this.state.filterTags.includes(industry)
                          ? { opacity: '0.6' }
                          : {}
                      }
                      onClick={() => {
                        this.addFilterTag(industry);
                      }}
                    >
                      {industry}
                    </div>
                  );
                })}
              </Grid>
              {companies}
            </Grid>
            <ReactPaginate
              previousLabel={'←'}
              nextLabel={'→'}
              pageCount={this.state.numberOfPage}
              onPageChange={this.handlePage}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          </Grid>
        </section>
      </Container>
    );
  }
}
export default Homepage;
