import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import axios from 'axios';
import Cookies from 'js-cookie';
import Carousel from 'react-bootstrap/Carousel';
import CheckIcon from '@material-ui/icons/Check';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import ManagementTeam from '../components/startup/managementTeam';
import Products from '../components/startup/products';
import { getUserData } from '../components/utils/manageUserData.js';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import LabelIcon from '@material-ui/icons/Label';
import GroupIcon from '@material-ui/icons/Group';
import Divider from '@material-ui/core/Divider';
import Contact from '../components/utils/contact';
import { Container } from '@material-ui/core';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
class Startups extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let fromSearchBool = false;

    try {
      fromSearchBool = props.location.props.from === 'search' ? true : false;
    } catch (error) {
      console.log('not from search');
    }

    const permalink = window.location.pathname.split('/');
    let lastPartPermalink = permalink.pop();

    if (lastPartPermalink === '') {
      lastPartPermalink = permalink[permalink.length - 1];
    }

    console.log('lastPartPermalink', lastPartPermalink);

    this.state = {
      userToken: '',
      startup: {},
      permalink: lastPartPermalink,
      fromSearch: fromSearchBool,
      stayTime: false,
      startupImg: '',
      carouselIndex: 0,
      dataLoad: false,
      userData: '',
      founderData: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getStartup();
    this.getUserInformation();
  }

  componentDidUpdate() {
    if (this.state.fromSearch && this.state.stayTime) {
      this.setState({ fromSearch: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  getUserInformation = async () => {
    var userData = await getUserData();
    this.setState({
      loadingData: true,
    });
    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
    }
  };

  getStartup = async () => {
    const { permalink, userToken } = this.state;

    const apiurl = '/api/startups/' + permalink;

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            startup: response.data.startup,
            dataLoad: true,
          });
          this.geFounderData(response.data.startup.founder);
          this.addVisit(response.data.startup.uid);
        }
      })
      .catch((error) => {});
  };

  addVisit = async (uid) => {
    const { userToken } = this.state;
    const apiurl = '/api/startups/' + uid;
    const payload = {
      organicPositioning: {
        visits: true,
      },
    };

    await axios({
      url: apiurl,
      method: 'PATCH',
      data: payload,
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (this._isMounted) {
          console.log('visit added', response);
          setTimeout(() => {
            if (this._isMounted) {
              this.addStayTime(uid);
            }
          }, 20000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log('No startup Found');
      });
  };

  addStayTime = async (uid) => {
    console.log('add stay time');

    const { userToken } = this.state;
    const apiurl = '/api/startups/' + uid;
    const payload = {
      organicPositioning: {
        stayTime: true,
      },
    };

    await axios({
      url: apiurl,
      method: 'PATCH',
      data: payload,
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (this._isMounted) {
          console.log('stay time added', response);
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log('No startup Found');
      });
  };

  addContact = async (uid) => {
    const { userToken } = this.state;
    const apiurl = '/api/startups/' + uid;
    const payload = {
      organicPositioning: {
        contacts: true,
      },
      searcher: Cookies.get('searcher'),
    };

    await axios({
      url: apiurl,
      method: 'PATCH',
      data: payload,
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (this._isMounted) {
          console.log('contact added', response);
          Cookies.set('searcher', '');
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log('No startup Found');
      });
  };

  onContactClick = (uid) => {
    this.addContact(uid);
    console.log('cookies', Cookies.get('searcher'));
  };

  submitContact = (event) => {
    event.preventDefault();

    this.onContactClick(this.state.startup.uid);
  };

  handleSelectCarousel = (selectedIndex) => {
    this.setState({ carouselIndex: selectedIndex });
  };

  geFounderData = (userId) => {
    var apiurl = '/api/users/' + userId;
    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        this.setState({
          founderData: response.data.user,
        });
      })
      .catch((error) => {});
  };

  render() {
    const {
      uid,
      name,
      servicesDescription,
      shortDescription,
      longDescription,
      maturityInfo,
      testedInfoBoolean,
      img,
      managementTeam,
      products,
      industries,
      founder,
      workers,
      videoPresentation,
    } = this.state.startup;
    const { fromSearch } = this.state;

    let testedInfoElement;
    let managementTeamElement;
    let productsElement;
    let industriesElement;
    let workers1;
    let video;

    if (uid) {
      if (fromSearch) {
        let startupsVisited = Cookies.get('searcher');

        if (startupsVisited === 'undefined') {
          startupsVisited = [uid];
          console.log('searcher is undefined');
        } else {
          startupsVisited = startupsVisited.split('\n');
          startupsVisited.push(uid);
        }
        Cookies.set('searcher', startupsVisited.toString(), { expires: 7 });
      }

      testedInfoElement =
        testedInfoBoolean === true ? (
          <div>
            <h5 className="titleSidebar">Solución testeada</h5>
            <p>
              <CheckIcon className="green" />
            </p>
          </div>
        ) : (
          ''
        );
      workers1 = workers ? (
        <div>
          <h5 className="titleSidebar">Número de empleados</h5>
          <p>{workers}</p>
        </div>
      ) : (
        <div>
          <h5 className="titleSidebar">Número de empleados</h5>
          <p>No definido</p>
        </div>
      );
      managementTeamElement =
        managementTeam.length > 0 ? (
          <div className="w-100 float-left">
            <Grid container>
              {' '}
              {managementTeam
                .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
                .map((member) => {
                  return <ManagementTeam member={member} key={member.name} />;
                })}
            </Grid>
          </div>
        ) : (
          ''
        );
      video =
        videoPresentation !== '' && videoPresentation ? (
          <div className="video-responsive">
            {' '}
            <iframe
              width="420"
              height="315"
              src={videoPresentation}
              title="Presentación en video"
            ></iframe>
          </div>
        ) : (
          ''
        );

      productsElement =
        products.length > 0 ? (
          <div className="w-100 float-left">
            {products.map((product) => {
              return (
                <div>
                  <Products product={product} key={product.name} />{' '}
                  <Divider style={{ marginBottom: '2rem' }} />
                </div>
              );
            })}
          </div>
        ) : (
          ''
        );

      industriesElement = industries ? (
        <div className="w-100 float-left">
          <span className="startup-tag">{industries}</span>
        </div>
      ) : (
        ''
      );
    }

    const handleSelect = (selectedIndex, e) => {
      this.setState({ carouselIndex: selectedIndex });
    };
    console.log(this.state.startup);
    return this.state.dataLoad !== true ? (
      <Container className="bodyWrapper">
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </Container>
    ) : (
      <Container className="bodyWrapper">
        <Grid
          container
          className="w-100  startup"
          style={{ background: '#F3F8FF' }}
        >
          <Grid className="float-left mainColorBg" sm={9} xs={12}>
            <Grid container className="button-carousel">
              <Grid
                sm={3}
                xs={6}
                className={
                  this.state.carouselIndex === 0
                    ? 'selectedCarousel center'
                    : 'center'
                }
              >
                {' '}
                <Button
                  className="width-100"
                  onClick={() => this.handleSelectCarousel(0)}
                  disableRipple
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div>
                    <div className="center">
                      <div className="iconCarousel">
                        <BusinessIcon fontSize="large" />
                      </div>
                    </div>
                    <div className="center">
                      <p>Presentación</p>
                    </div>
                  </div>
                </Button>
              </Grid>
              <Grid sm={3} xs={6} className="center">
                {' '}
                <Button
                  className="width-100"
                  onClick={() => this.handleSelectCarousel(1)}
                  disableRipple
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div>
                    <div
                      className={
                        this.state.carouselIndex === 1
                          ? 'selectedCarousel center'
                          : 'center'
                      }
                    >
                      <div className="iconCarousel">
                        <DescriptionIcon fontSize="large" />
                      </div>
                    </div>
                    <div className="center">
                      <p>descripción</p>
                    </div>
                  </div>
                </Button>
              </Grid>
              <Grid
                sm={3}
                xs={6}
                className={
                  this.state.carouselIndex === 2
                    ? 'selectedCarousel center'
                    : 'center'
                }
              >
                {' '}
                <Button
                  className="width-100"
                  onClick={() => this.handleSelectCarousel(2)}
                  disableRipple
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div>
                    <div className="center">
                      <div className="iconCarousel">
                        <LabelIcon fontSize="large" />
                      </div>
                    </div>
                    <div className="center">
                      <p>Productos</p>
                    </div>
                  </div>
                </Button>
              </Grid>
              <Grid
                sm={3}
                xs={6}
                className={
                  this.state.carouselIndex === 3
                    ? 'selectedCarousel center'
                    : 'center'
                }
              >
                {' '}
                <Button
                  className="width-100"
                  onClick={() => this.handleSelectCarousel(3)}
                  disableRipple
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div>
                    <div className="center">
                      <div className="iconCarousel">
                        <GroupIcon fontSize="large" />
                      </div>
                    </div>
                    <div className="center">
                      <p>Equipo</p>
                    </div>
                  </div>
                </Button>
              </Grid>
            </Grid>
            <Carousel
              style={{ padding: '1rem' }}
              activeIndex={this.state.carouselIndex}
              onSelect={handleSelect}
              interval={null}
              className="carouselStartup"
            >
              <Carousel.Item>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    {' '}
                    <div
                      className="w-45 float-left"
                      style={{ marginRight: '5%' }}
                    >
                      <img
                        className="d-block w-100 width-100"
                        src={img}
                        alt="First slide"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <div className="float-left">
                      <h2>{name}</h2>
                      <p>{shortDescription}</p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className="center videoPresentation"
                  >
                    {video}
                  </Grid>
                </Grid>
              </Carousel.Item>
              <Carousel.Item>
                <div className="w-100 float-left">
                  <h2 className="center mb-3">Descripción</h2>
                  <div
                    className="descriptionContent"
                    dangerouslySetInnerHTML={{ __html: longDescription }}
                  ></div>
                  <div key={this.state.startup}>
                    {this.state.startup.achievements.length ? (
                      <div>
                        <br></br>
                        <h2 className="center">Logros</h2>

                        {this.state.startup.achievements
                          ? this.state.startup.achievements.map(
                              (ach, index) => {
                                return (
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>
                                        {ach[0].title ? ach[0].title : ''}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: ach[0].content,
                                        }}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              }
                            )
                          : ''}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="w-100 float-left">
                  <h2 className="center mb-3">Productos</h2>

                  {productsElement}
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="w-100 float-left">
                  <h2 className="center mb-3">Equipo</h2>
                  {managementTeamElement}
                </div>
              </Carousel.Item>
            </Carousel>
          </Grid>
          <Grid
            className="float-left secondaryColorBg padding-10 "
            sm={3}
            xs={12}
          >
            <div className="w-100 ">
              <h2 className="black">{name}</h2>
            </div>
            <div className="w-100  ">
              <p>{servicesDescription}</p>
            </div>
            <div className="margin-bottom-20">
              <Contact
                key={uid + name + this.state.founderData}
                subject={'Contacto Startup'}
                user2id={founder}
                user2name={
                  this.state.founderData !== []
                    ? this.state.founderData.name
                    : ''
                }
                user2company={
                  this.state.startup !== [] ? this.state.startup.name : ''
                }
                user2type={'Startup'}
                user2email={
                  this.state.founderData !== []
                    ? this.state.founderData.email
                    : ''
                }
                user2phone={
                  this.state.founderData !== []
                    ? this.state.founderData.phone
                    : ''
                }
                user2lastname={
                  this.state.founderData !== []
                    ? this.state.founderData.lastname
                    : ''
                }
                collectionId={uid}
                userToken={this.state.userToken}
                budget={this.state.startup.budget}
                fromStartup={true}
              />
            </div>

            <div className="w-100 ">
              <Divider variant="middle" />
              <h5 className="titleSidebar">Madurez</h5>
              <p className="textSidebar"> {maturityInfo}</p>
            </div>
            <Divider />
            <div className="w-100 ">{testedInfoElement}</div>
            <Divider />
            <div className="w-100 ">{workers1}</div>

            <Divider />
            <h5 className="titleSidebar">Industria</h5>
            {industriesElement}
          </Grid>

          <Grid className="w-100"></Grid>
        </Grid>
      </Container>
    );
  }
}
export default Startups;
