import React, { Component } from 'react';

import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import MessageIcon from '@material-ui/icons/Message';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import { getUserToken } from '../../utils/manageUserData';
class MenuTopProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actualRole: this.props.roleUser,
      menuProfileOpen: 0,
      anchorEl: '',
      numberOfNotSeenMsgs: '',
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserToken();
    })();
  }

  handleDrawerClose = () => {
    if (this.state.open === true) {
      this.setState({
        open: false,
        open1: false,
      });
    } else {
      this.setState({
        open: true,
        open1: true,
      });
    }
  };
  openOption = (id) => {
    if (id === 1) {
      if (this.state.open1 === false) {
        this.setState({ open1: true });
      } else {
        this.setState({ open1: false });
      }
    }
    if (id === 2) {
      if (this.state.open2 === false) {
        this.setState({ open2: true });
      } else {
        this.setState({ open2: false });
      }
    }
  };

  chooseOption = (component) => {
    /*
		let urlToGo =
			"http://" + window.location.hostname + ":3000/perfil/" + component;
		window.location.href = urlToGo;
		*/
    if (!component.includes('noData')) {
      this.props.getClickedElement(component);
      this.setState({
        locationSidebar: component,
      });
    }
  };
  setIsSidebarOpen = () => {
    if (this.state.isopen === true) {
      this.setState({
        isopen: false,
      });
    } else {
      this.setState({
        isopen: true,
      });
    }
  };
  handleOpenMenu = (event) => {
    if (this.state.menuProfileOpen === 0) {
      this.setState({
        menuProfileOpen: 1,
        anchorEl: event.currentTarget,
      });
    } else {
      this.setState({
        menuProfileOpen: 0,
        anchorEl: '',
      });
    }
  };
  handleChooseOption = (option) => {
    if (option) {
      this.props.getClickedElement(option);
    }
  };
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
      this.getNotSeenMessages(obj);
    }
  };
  getNotSeenMessages = (userInfo) => {
    var apiurl = '/api/messages/notseen/number';
    console.log('Contar usuario');
    console.log(userInfo);
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userInfo,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('respuesta mensaje no vistos');
        console.log(response);
        this.setState({
          numberOfNotSeenMsgs: response.data.numberOfNotSeenMsgs,
        });
      })
      .catch((error) => {});
  };
  render() {
    const topMenu1 = (
      <div>
        {' '}
        <div className="buttonInformationProfile">
          <div className="boxbuttons">
            <div
              className="buttonMessage"
              onClick={() => {
                this.handleChooseOption('chat');
              }}
            >
              <span className="messageToRead">
                <MessageIcon />
                {this.state.numberOfNotSeenMsgs !== '' ? (
                  <span className="messageBubble">
                    {this.state.numberOfNotSeenMsgs}
                  </span>
                ) : (
                  ''
                )}
              </span>
              <span>Mensajes</span>
            </div>
            <div className="buttonProfile" onClick={this.handleOpenMenu}>
              <span>
                <AccountCircleIcon />
              </span>
              <span aria-controls="menu-profile-top" aria-haspopup="true">
                {this.props.nameUser !== '' || this.props.nameUser !== undefined
                  ? this.props.nameUser
                  : 'Perfil'}
              </span>
              <Menu
                key={this.state.menuProfileOpen}
                anchorEl={this.state.anchorEl}
                id="menu-profile-top"
                className="menuProfileTop"
                keepMounted
                open={this.state.menuProfileOpen}
                onClose={() => this.handleOpenMenu()}
              >
                <MenuItem
                  onClick={() => {
                    this.handleOpenMenu();
                    this.handleChooseOption('editar_usuario');
                  }}
                >
                  Editar Datos personales
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    this.handleOpenMenu();
                    this.handleChooseOption('anadir_credito');
                  }}
                >
                  Añadir crédito
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    );
    const topMenu2 = (
      <div>
        <div className="buttonInformationProfile">
          <div className="boxbuttons">
            <div
              className="buttonMessage"
              onClick={() => {
                this.handleChooseOption('chat');
              }}
            >
              <span className="messageToRead">
                <MessageIcon />
                {this.state.numberOfNotSeenMsgs !== '' ? (
                  <span className="messageBubble">
                    {this.state.numberOfNotSeenMsgs}
                  </span>
                ) : (
                  ''
                )}
              </span>
              <span>Mensajes</span>
            </div>
            <div className="buttonProfile" onClick={this.handleOpenMenu}>
              <span>
                <AccountCircleIcon />
              </span>
              <span aria-controls="menu-profile-top" aria-haspopup="true">
                {this.props.nameUser !== '' || this.props.nameUser !== undefined
                  ? this.props.nameUser
                  : 'Perfil'}
              </span>
              <Menu
                key={this.state.menuProfileOpen}
                anchorEl={this.state.anchorEl}
                id="menu-profile-top"
                className="menuProfileTop"
                keepMounted
                open={this.state.menuProfileOpen}
                onClose={() => this.handleOpenMenu()}
              >
                <MenuItem
                  onClick={() => {
                    this.handleOpenMenu();
                    this.handleChooseOption('editar_usuario');
                  }}
                >
                  Editar Datos personales
                </MenuItem>
                {this.state.actualRole === 'SECONDARY_USER' ||
                this.state.actualRole === 'FINAL_USER_STARTUP' ? (
                  ' '
                ) : (
                  <MenuItem
                    onClick={() => {
                      this.handleOpenMenu();
                      this.handleChooseOption('publicar_actualizacion');
                    }}
                  >
                    Publicar Actualizacion
                  </MenuItem>
                )}
                {this.state.actualRole === 'SECONDARY_USER' ||
                this.state.actualRole === 'FINAL_USER_STARTUP' ? (
                  ' '
                ) : (
                  <MenuItem
                    onClick={() => {
                      this.handleOpenMenu();
                      this.handleChooseOption('anadir_credito');
                    }}
                  >
                    Añadir crédito
                  </MenuItem>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    );
    const topMenu3 = (
      <div>
        <div className="buttonInformationProfile">
          <div className="boxbuttons">
            <div
              className="buttonMessage"
              onClick={() => {
                this.handleChooseOption('chat');
              }}
            >
              <span className="messageToRead">
                <MessageIcon />
                {this.state.numberOfNotSeenMsgs !== '' ? (
                  <span className="messageBubble">
                    {this.state.numberOfNotSeenMsgs}
                  </span>
                ) : (
                  ''
                )}
              </span>
              <span>Mensajes</span>
            </div>
            <div className="buttonProfile" onClick={this.handleOpenMenu}>
              <span>
                <AccountCircleIcon />
              </span>
              <span aria-controls="menu-profile-top" aria-haspopup="true">
                {this.props.nameUser !== '' || this.props.nameUser !== undefined
                  ? this.props.nameUser
                  : 'Perfil'}
              </span>
              <Menu
                key={this.state.menuProfileOpen}
                anchorEl={this.state.anchorEl}
                id="menu-profile-top"
                className="menuProfileTop"
                keepMounted
                open={this.state.menuProfileOpen}
                onClose={() => this.handleOpenMenu()}
              >
                <MenuItem
                  onClick={() => {
                    this.handleOpenMenu();
                    this.handleChooseOption('editar_usuario');
                  }}
                >
                  Editar Datos personales
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    );
    const topMenuCrowd = (
      <div>
        <div className="buttonInformationProfile">
          <div className="boxbuttons">
            <div
              className="buttonMessage"
              onClick={() => {
                this.handleChooseOption('chat');
              }}
            >
              <span className="messageToRead">
                <MessageIcon />
                {this.state.numberOfNotSeenMsgs !== '' ? (
                  <span className="messageBubble">
                    {this.state.numberOfNotSeenMsgs}
                  </span>
                ) : (
                  ''
                )}
              </span>
              <span>Mensajes</span>
            </div>
            <div className="buttonProfile" onClick={this.handleOpenMenu}>
              <span>
                <AccountCircleIcon />
              </span>
              <span aria-controls="menu-profile-top" aria-haspopup="true">
                {this.props.nameUser !== '' || this.props.nameUser !== undefined
                  ? this.props.nameUser
                  : 'Perfil'}
              </span>
              <Menu
                key={this.state.menuProfileOpen}
                anchorEl={this.state.anchorEl}
                id="menu-profile-top"
                className="menuProfileTop"
                keepMounted
                open={this.state.menuProfileOpen}
                onClose={() => this.handleOpenMenu()}
              >
                <MenuItem
                  onClick={() => {
                    this.handleOpenMenu();
                    this.handleChooseOption('editar_usuario_crowd');
                  }}
                >
                  Editar Datos personales
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    );
    const topMenu4 = (
      <div>
        <div className="buttonInformationProfile">
          <div className="boxbuttons">
            <div className="buttonProfile" onClick={this.handleOpenMenu}>
              <span>
                <AccountCircleIcon />
              </span>
              <span aria-controls="menu-profile-top" aria-haspopup="true">
                {this.props.nameUser !== '' || this.props.nameUser !== undefined
                  ? this.props.nameUser
                  : 'Perfil'}
              </span>
              <Menu
                key={this.state.menuProfileOpen}
                anchorEl={this.state.anchorEl}
                id="menu-profile-top"
                className="menuProfileTop"
                keepMounted
                open={this.state.menuProfileOpen}
                onClose={() => this.handleOpenMenu()}
              >
                <MenuItem
                  onClick={() => {
                    this.handleOpenMenu();
                    this.handleChooseOption('editar_usuario');
                  }}
                >
                  Editar Datos personales
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <div className="topBarMenu">
        <div>
          {this.props.roleUser === 'USER_OFFER_SERVICES' ? topMenu1 : ''}
        </div>

        <div>
          {this.props.roleUser === 'SECONDARY_USER' ||
          this.props.roleUser === 'FINAL_USER_STARTUP' ||
          this.props.roleUser === 'FINAL_USER'
            ? topMenu2
            : ''}
        </div>
        <div>
          {this.props.roleUser === 'FINAL_USER_COMPANY' ||
          this.props.roleUser === 'SELLACTIONS_ADMIN' ||
          this.props.roleUser === 'SECONDARY_USER_ADMIN'
            ? topMenu3
            : ''}
        </div>
        <div>{this.props.roleUser === 'ADMIN' ? topMenu4 : ''}</div>
        <div>
          {this.props.roleUser === 'CROWDFUNDING_USER' ? topMenuCrowd : ''}
        </div>
        <div>
          {this.props.roleUser === 'CROWDFUNDING_ADMIN' ? topMenu3 : ''}
        </div>
      </div>
    );
  }
}
export default MenuTopProfile;
