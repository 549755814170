import React, { Component } from 'react';
import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { updateDataUser } from '../../../utils/manageUserData';
import { withSnackbar } from 'notistack';

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      newPassword: null,
    };
  }
  componentDidMount() {
    this.setState({
      userData: this.props.informationUser,
    });
  }
  updateUserData = (event) => {
    event.preventDefault();

    const payload = {
      name: event.target.update_user_name.value,
      lastName: event.target.update_user_lastname.value,
      phone: event.target.update_user_phone.value,
      streetName: event.target.update_user_address.value,
      flatNumber: event.target.update_user_address_number.value,
      postCode: event.target.update_user_pc.value,
      locality: event.target.update_user_locality.value,
      province: event.target.update_user_province.value,
      country: event.target.update_user_country.value,
      role: this.state.userData.role,
    };

    if (event.target.update_user_pwd.value !== null) {
      payload.pwd = event.target.update_user_pwd.value;
    }

    console.log('Aqui');
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.props.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        this.props.enqueueSnackbar('Datos guardados con éxito', {
          variant: 'success',
        });
        //window.location.reload();
        //this.emailVerification(email);
      })
      .catch((error) => {
        this.props.enqueueSnackbar('Error al guardar los datos', {
          variant: 'error',
        });
        console.log(error.response);
        console.log('Error');
      });
  };
  render() {
    return (
      <Container>
        <Grid container className="borderShadow">
          <Grid item xs={12}>
            {' '}
            <form
              onSubmit={this.updateUserData}
              className="form"
              key={this.state.userData}
            >
              <Grid container>
                <Grid item={true} sm={6} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_name">Nombre </InputLabel>
                    <Input
                      id="update_user_name"
                      name="update_user_name"
                      aria-describedby="update_user_name"
                      defaultValue={this.state.userData.name}
                      type="text"
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_lastname">
                      Apellidos{' '}
                    </InputLabel>
                    <Input
                      id="update_user_lastname"
                      name="update_user_lastname"
                      aria-describedby="update_user_lastname"
                      type="text"
                      defaultValue={this.state.userData.lastName}
                    />
                  </FormControl>
                </Grid>

                <Grid item={true} sm={12} xs={12}>
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_phone" shrink={true}>
                      Nos gustaría tener una comunicación más directa contigo.
                      ¿A qué teléfono te llamamos?
                    </InputLabel>
                    <Input
                      id="update_user_phone"
                      name="update_user_phone"
                      aria-describedby="update_user_phone"
                      type="text"
                      defaultValue={this.state.userData.phone}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={12} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_address">
                      Dirección
                    </InputLabel>
                    <Input
                      id="update_user_address"
                      name="update_user_address"
                      aria-describedby="update_user_address"
                      type="text"
                      defaultValue={this.state.userData.streetName}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_address_number">
                      N/Piso{' '}
                    </InputLabel>
                    <Input
                      id="update_user_address_number"
                      name="update_user_address_number"
                      aria-describedby="update_user_address_number"
                      type="text"
                      defaultValue={this.state.userData.flatNumber}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_pc">
                      Código Postal{' '}
                    </InputLabel>
                    <Input
                      id="update_user_pc"
                      name="update_user_pc"
                      aria-describedby="update_user_pc"
                      type="number"
                      defaultValue={this.state.userData.postCode}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_locality">
                      Localidad
                    </InputLabel>
                    <Input
                      id="update_user_locality"
                      name="update_user_locality"
                      aria-describedby="update_user_locality"
                      type="text"
                      defaultValue={this.state.userData.locality}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_province">
                      Provincia
                    </InputLabel>
                    <Input
                      id="update_user_province"
                      name="update_user_province"
                      aria-describedby="update_user_province"
                      type="text"
                      defaultValue={this.state.userData.province}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_country">País</InputLabel>
                    <Input
                      id="update_user_country"
                      name="update_user_country"
                      aria-describedby="update_user_country"
                      type="text"
                      defaultValue={this.state.userData.country}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={6} xs={12}>
                  <FormControl className="width-100">
                    <InputLabel htmlFor="update_user_country">
                      Contraseña
                    </InputLabel>
                    <Input
                      id="update_user_pwd"
                      name="update_user_pwd"
                      aria-describedby="update_user_pwd"
                      type="password"
                      value={this.state.newPassword}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} sm={12} xs={12} className="center">
                  {' '}
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="button center"
                    ref="buttonRegister"
                  >
                    Guardar y continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(EditUser);
