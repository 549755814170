import React, { Component } from "react";
import {
	Grid,
	Container,
	InputLabel,
	FormControl,
	Input,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { getUserData, getUserToken } from "../../../utils/manageUserData";
import axios from "axios";
import { withSnackbar } from "notistack";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
class publishUpdate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startupFounder: [],
			editStartup: "",
			userToken: "",
			userData: "",
			updateToPlublish: "",
		};
	}
	componentDidMount() {
		(async () => {
			await this.getUserInformation();
			await this.getUserToken();
		})();
	}
	getUserToken = async () => {
		var userToken = getUserToken();
		if (userToken !== false) {
			const obj = userToken;
			this.setState({
				userToken: obj,
			});
		}
	};
	getUserInformation = async () => {
		var userData = getUserData();

		if (userData !== false) {
			const obj = JSON.parse(userData);
			this.setState({
				userData: obj,
			});
			this.getUserFounder(obj);
		}
	};
	getUserFounder = (userInfo) => {
		var apiurl = "/api/users/" + userInfo.uid + "/startups";
		axios({
			url: apiurl,
			method: "GET",
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				if (response.data.startups.length) {
					this.setState({
						isFounder: true,
						startupFounder: response.data.startups,
					});
				}
			})
			.catch((error) => {});
	};

	submitUpdate = (event) => {
		event.preventDefault();

		if (this.state.startupFounder.length) {
			if (this.state.startupFounder[0].achievements) {
				var actualAchievements = this.state.startupFounder[0].achievements;
				var objUpdate = [
					{
						title: event.target.title_update.value,
						content: this.state.updateToPlublish,
					},
				];

				actualAchievements.push(objUpdate);
				console.log(this.state.startupFounder);
				console.log(this.state.userToken);
				console.log(this.state.startupFounder[0].founder);
				var apiurlput = "/api/startups/" + this.state.startupFounder[0].uid;
				const payload = {
					founder: this.state.startupFounder[0].founder,
					achievements: actualAchievements,
					name: this.state.startupFounder[0].name,
				};
				console.log(payload);
				axios({
					url: apiurlput,
					method: "PUT",
					data: payload,
					headers: {
						"x-token": this.state.userToken,
						"content-type": "application/json",
					},
				})
					.then((response) => {
						console.log(response);
						this.props.enqueueSnackbar("Datos guardados con éxito", {
							variant: "success",
						});
					})
					.catch((error) => {
						console.log(error.message);
						alert("Hemos encontrado un error y no se han guardado los cambios");
					});
			}
		} else {
			this.props.enqueueSnackbar("Error. Informaciòn no Guardada", {
				variant: "error",
			});
		}
	};
	handleChangeUpdate = (value) => {
		this.setState({ updateToPlublish: value });
	};
	modules = {
		toolbar: [
			["bold", "italic", "underline", "strike", "blockquote"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
			],
			["link"],
			["clean"],
		],
	};
	formats = [
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
	];
	render() {
		return (
			<Container>
				<Grid container className="borderShadow">
					<Grid item xs={12}>
						<form
							onSubmit={this.submitUpdate}
							className="form"
							key={this.state.userData}
						>
							<Grid container>
								<Grid item={true} sm={12} xs={12}>
									{" "}
									<FormControl className="width-100">
										<p>
											Publicar actualización con los últimos avances en tu
											Startup:
										</p>
										<Grid item={true} sm={12} xs={12}>
											{" "}
											<FormControl className="width-100">
												<InputLabel htmlFor="title_update" shrink={true}>
													Título de la actualización
												</InputLabel>
												<Input
													id="title_update"
													name="title_update"
													aria-describedby="title_update"
													type="text"
												/>
											</FormControl>
										</Grid>
										<Grid item={true} sm={12} xs={12}>
											<br />
											<p>Contenido de la actualización</p>
											<ReactQuill
												name="longDescription"
												modules={this.modules}
												formats={this.formats}
												onChange={this.handleChangeUpdate}
												defaultValue={this.state.updateToPlublish}
											/>
										</Grid>
									</FormControl>
								</Grid>
								<Grid item={true} sm={12} xs={12} className="center">
									{" "}
									<Button
										variant="contained"
										color="primary"
										type="submit"
										className="button center"
										ref="buttonSubmit"
									>
										Publicar
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</Container>
		);
	}
}
export default withSnackbar(publishUpdate);
