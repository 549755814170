import React, { Component } from 'react';
import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import imageRedsys from '../../../../assets/images/redsys.jpg';

class addCredit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startupFounder: [],
      editStartup: '',
      userToken: '',
      userData: '',
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
    })();
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
      this.getUserFounder(obj);
    }
  };
  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo.uid + '/startups';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.startups.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.startups,
          });
        }
      })
      .catch((error) => {});
  };

  submitPayment = (event) => {
    event.preventDefault();

    let num = event.target.number_credits.value;
    num = num.replace(/\./g, '').replace(',', '.');

    const formattedAmount = parseFloat(num).toFixed(2);

    const minimun = formattedAmount < 1.2;

    const mod10 = formattedAmount.substring(formattedAmount.length - 1);

    if (minimun || mod10 > 0) {
      this.props.enqueueSnackbar(
        'Se ha producido un error. Recuerde que es en euros y que la cantidad mínima es de 1’20€, ya que es el coste del click de una startup interesada en tu servicio en concepto de contraprestación para que comercialmente cierres la venta. Se recomienda que la cantidad introducida vaya de 1’20€ en 1’20€',
        {
          variant: 'warning',
          persist: false,
        }
      );
    } else {
      let description = '';
      if (
        this.state.userData.role === 'SECONDARY_USER' ||
        this.state.userData.role === 'SECONDARY_USER_ADMIN' ||
        this.state.userData.role === 'USER_OFFER_SERVICES'
      ) {
        description = 'company';
        console.log('IS COMPANY');
      } else {
        console.log('IS STARTUP');
        description = 'startup';
      }
      const payload = {
        userId: this.state.userData.uid,
        amount: formattedAmount * 100,
        description,
      };
      console.log(this.state.userData.uid);
      console.log(formattedAmount * 100);
      console.log(this.state.userData);
      var apiurl = '/api/payments';
      axios({
        url: apiurl,
        method: 'POST',
        data: payload,
        headers: {
          'x-token': this.props.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          console.log('Data has been sent to the server');
          console.log(response.data);
          this.setState({
            mechParam: response.data.paymentParameters.merchantParameters,
            signature: response.data.paymentParameters.signature,
          });
          this.props.enqueueSnackbar(
            'Se le va ha redireccionar a la plataforma de pago',
            {
              variant: 'success',
            }
          );

          setTimeout(function () {
            document.getElementById('goToPaymentPlatform').submit();
          }, 2000);

          //window.location.reload();
          //this.emailVerification(email);
        })
        .catch((error) => {
          this.props.enqueueSnackbar('créditos no añadidos', {
            variant: 'error',
          });
          console.log(error.response);
          console.log('Error');
        });
      /*
			const payload = {
				credits: event.target.number_credits.value,
				role: this.state.userData.role,
			};

			var apiurl = "/api/users/credits/" + this.state.userData.uid;
			axios({
				url: apiurl,
				method: "PUT",
				data: payload,
				headers: {
					"x-token": this.props.userToken,
					"content-type": "application/json",
				},
			})
				.then((response) => {
					console.log("Data has been sent to the server");

					this.setState({
						userData: response.data.user2,
					});
					this.props.enqueueSnackbar("créditos añadidos", {
						variant: "success",
					});
					//window.location.reload();
					//this.emailVerification(email);
				})
				.catch((error) => {
					this.props.enqueueSnackbar("créditos no añadidos", {
						variant: "error",
					});
					console.log(error.response);
					console.log("Error");
				});
				*/
    }
  };

  render() {
    return (
      <Container>
        <Grid container className="borderShadow">
          <Grid item xs={12}>
            <p>
              Créditos disponibles actualmente:{' '}
              {this.props.informationStartup.budget
                ? parseFloat(this.props.informationStartup.budget).toFixed(2)
                : '0'}{' '}
              €
            </p>
            <form
              onSubmit={this.submitPayment}
              className="form"
              key={this.state.userData}
            >
              <Grid container>
                <Grid item={true} sm={12} xs={12}>
                  {' '}
                  <FormControl className="width-100">
                    <InputLabel htmlFor="number_credits" shrink={true}>
                      Introduzca la cantidad de crédito que deseas recargar.
                    </InputLabel>
                    <Input
                      id="number_credits"
                      name="number_credits"
                      aria-describedby="number_credits"
                      type="text"
                    />
                  </FormControl>
                  <p className="smallText">
                    <br></br>
                    Recuerde que es en euros y que la cantidad mínima es de
                    1’20€, ya que es el coste del click de una startup
                    interesada en tu servicio en concepto de contraprestación
                    para que comercialmente cierres la venta. Se recomienda que
                    la cantidad introducida vaya de 1’20€ en 1’20€'
                  </p>
                </Grid>
                <Grid item={true} sm={12} xs={12} className="center">
                  {' '}
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="button center"
                    ref="buttonSubmit"
                  >
                    Proceder al pago
                  </Button>
                  <br></br>
                  <img
                    src={imageRedsys}
                    alt="Pago seguro"
                    className="image-payment"
                  ></img>
                </Grid>
                <form
                  action="https://sis.redsys.es/sis/realizarPago"
                  method="POST"
                  id="goToPaymentPlatform"
                  hidden
                >
                  <label>Ds_SignatureVersion</label>
                  <input
                    type="text"
                    id="Ds_SignatureVersion"
                    name="Ds_SignatureVersion"
                    value="HMAC_SHA256_V1"
                  />

                  <br />
                  <br />

                  <label>Ds_MerchantParameters</label>
                  <input
                    type="text"
                    name="Ds_MerchantParameters"
                    id="Ds_MerchantParameters"
                    value={this.state.mechParam}
                  />

                  <label>Ds_Signature</label>
                  <input
                    type="text"
                    name="Ds_Signature"
                    id="Ds_Signature"
                    value={this.state.signature}
                  />

                  <br />
                  <br />

                  <button type="submit">Enviar test</button>
                </form>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(addCredit);
