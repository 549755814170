import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row } from 'react-bootstrap';
import { DataGrid } from '@material-ui/data-grid';
import './../../../styles/styles.css';
import { withSnackbar } from 'notistack';
import { Button } from '@material-ui/core';

class ProfileJob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobs: [],
      startup: this.props.startup,
    };
  }

  getJobs = async () => {
    const apiurl = '/api/jobs/startups/' + this.state.startup.uid;
    const { data } = await axios.get(apiurl);
    this.setState({ jobs: data.jobs });
  };

  componentDidMount() {
    this.getJobs();
  }

  editJob = (job) => {
    console.log('edit job ' + job);

    const newProps = {
      jobId: job._id,
    };

    this.props.getClickedElement('editar_oferta_empleo', newProps);
  };

  deleteJob = async (job) => {
    console.log('delete job ' + job);

    const headers = {
      headers: {
        'x-token': this.props.userToken,
      },
    };

    const { data } = await axios.delete('/api/jobs/' + job._id, headers);

    if (Number(data.code) === 200) {
      this.props.enqueueSnackbar('Oferta de empleo eliminada', {
        variant: 'success',
        autoHideDuration: 3000,
      });

      this.getJobs();
    }
  };

  goToCreateJob = async () => {
    console.log('going to create job');

    const payload = {
      startupId: this.state.startup.uid,
      startupImg: this.state.startup.img || '',
      startupIndustry: this.state.startup.industry,
      startupPermalink: this.state.startup.permalink,
      startupName: this.state.startup.name,
    };

    const headers = {
      headers: {
        'x-token': this.props.userToken,
      },
    };

    const { data } = await axios.post('/api/jobs/', payload, headers);

    const jobId = data.jobModel._id;

    console.log(data);
    if (jobId) {
      console.log('going to job ' + jobId);
    }

    const newProps = {
      jobId,
    };

    this.props.getClickedElement('editar_oferta_empleo', newProps);

    return data;
  };

  viewCandidates = (job) => {
    console.log('ver candidatos');

    const newProps = {
      jobId: job._id,
    };

    console.log('jobid ', newProps);
    this.props.getClickedElement('oferta_empleo_ver_candidatos', newProps);
  };
  render() {
    const columns = [
      {
        field: 'job',
        headerName: 'Título',
        editable: false,
        flex: 1,
      },
      {
        field: 'viewCandidates',
        headerName: 'Ver candidatos',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.viewCandidates(params.row)}
              >
                Ver candidatos
              </Button>
            </div>
          );
        },
      },
      {
        field: 'edit',
        headerName: 'Editar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.editJob(params.row)}
              >
                Editar
              </Button>
            </div>
          );
        },
      },
      {
        field: 'delete',
        headerName: 'Borrar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() =>
                  window.confirm('¿Estás seguro de borrar la oferta de empleo?')
                    ? this.deleteJob(params.row)
                    : ''
                }
              >
                Borrar
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <Container>
        <Row>
          <Button
            variant="contained"
            style={{
              background: '#6658ea',
              color: 'white',
              marginBottom: '2rem',
              marginLeft: '1rem',
            }}
            onClick={() => this.goToCreateJob()}
          >
            Crear nueva oferta de empleo
          </Button>
        </Row>

        <Row>
          <DataGrid
            rows={this.state.jobs}
            getRowId={(row) => row._id}
            autoHeight
            columns={columns}
            pageSize={5}
          />
        </Row>
      </Container>
    );
  }
}
export default withSnackbar(ProfileJob);
