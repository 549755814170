import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import './../../../styles/styles.css';

class ProfileLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: '',
      startup: '',
    };
  }
  componentDidMount() {
    this.setState({
      userData: this.props.informationUser,
      startup: this.props.informationStartup,
    });
  }
  componentWillReceiveProps(newProps) {
    this.setState({ startup: this.props.informationStartup });
  }
  chooseOption = (component) => {
    console.log('aqui');

    if (!component.includes('noData')) {
      this.props.getClickedElement(component);
    }
  };

  render() {
    return (
      <Container>
        <Grid container className="borderShadow">
          <Grid item xs={12}>
            {this.state.userData.role !== 'FINAL_USER' ? (
              <Grid item xs={12} sm={12}>
                <h4>Bienvenido al panel de administración de Estartaps.</h4>
              </Grid>
            ) : (
              ''
            )}
            {this.state.startup !== '' ? (
              <Grid container>
                <Grid item xs={12} sm={!this.state.startup.img ? 12 : 3}>
                  {!this.state.startup.img ? (
                    ' '
                  ) : this.state.startup.uid ? (
                    <img
                      src={
                        process.env.REACT_APP_API_URL_PROD +
                        '/api/uploads/startups/' +
                        this.state.startup.uid
                      }
                      alt="company"
                      className="profile-image"
                    />
                  ) : (
                    <img
                      src={
                        process.env.REACT_APP_API_URL_PROD +
                        '/api/uploads/company/' +
                        this.state.startup._id
                      }
                      alt="company"
                      className="profile-image"
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={9}>
                  <div className="nameCompanyProfile">
                    <p>Bienvenido al panel de administración </p>
                    <h2>{this.state.startup.name}</h2>
                    <div
                      className="descriptionContent"
                      dangerouslySetInnerHTML={{
                        __html: this.state.startup.shortDescription,
                      }}
                    ></div>
                  </div>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default ProfileLanding;
