import React, { Component } from 'react';
import axios from 'axios';
import { Container } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import './../../../../styles/styles.css';
import 'react-quill/dist/quill.snow.css';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';

class BlogEntries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: props.informationUser,
      userToken: props.userToken,
      blog: [],
      blogTotal: '',
    };

    console.log('blog component');
  }

  componentDidMount() {
    this.getBlog();
  }

  getBlog = async () => {
    const headers = {
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    };
    const apiurl = '/api/blog/admin';
    const { data } = await axios.get(apiurl, headers);
    this.setState({
      blog: data.blog,
      blogTotal: data.total,
    });
  };

  viewMore = (post) => {
    const newProps = {
      post: post,
      fromAdmin: true,
    };

    this.props.getClickedElement('blog_edit_post', newProps);
  };

  deletePost = async (_id) => {
    const headers = {
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    };

    const { data } = await axios.delete('/api/blog/' + _id, headers);

    if (Number(data.code) === 200) {
      this.props.enqueueSnackbar('Reto eliminado', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }

    this.getBlog();
  };

  goToCreatePost = async () => {
    console.log('going to create post');

    const newProps = {
      challenge: '',
    };

    this.props.getClickedElement('blog_create_post', newProps);
  };

  render() {
    const columns = [
      {
        field: 'title',
        headerName: 'Título',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return <div>{params.row.title || ''}</div>;
        },
      },
      {
        field: 'created_date',
        headerName: 'Fecha de creación',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>{new Date(params.row.createdAt).toLocaleDateString()}</div>
          );
        },
      },
      {
        field: 'comments',
        headerName: 'Comentarios a revisar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              {params.row.commentsToView ? params.row.commentsToView : '0'}
            </div>
          );
        },
      },
      {
        field: 'view',
        headerName: 'Ver más',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.viewMore(params.row)}
              >
                Ver más
              </Button>
            </div>
          );
        },
      },
      {
        field: 'delete',
        headerName: 'Borrar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() =>
                  window.confirm(
                    '¿Estás seguro de borrar la entrada ' +
                      params.row.title +
                      '?'
                  )
                    ? this.deletePost(params.row._id)
                    : ''
                }
              >
                Borrar
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <Container>
        <h1 className="job-page-title">Entradas del blog</h1>
        <Grid container>
          <Grid item={true} sm={12} xs={12} style={{ marginBottom: '1.8rem' }}>
            <Button className="button" onClick={() => this.goToCreatePost()}>
              Crear entrada
            </Button>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            <DataGrid
              rows={this.state.blog ? this.state.blog : []}
              getRowId={(row) => row._id}
              autoHeight
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withSnackbar(BlogEntries);
