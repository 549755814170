import React, { Component } from 'react';

import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
  FormHelperText,
  StepButton,
} from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  getUserData,
  getUserToken,
  updateDataUser,
} from '../utils/manageUserData';

class RegistroForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      optionChooseProfile: '',
      userData: '',
      email: '',
      usertoken: '',
      choosenOptionMaturity: '',
      startupFounder: '',
      isFounder: false,
      steps: [
        'Datos Básicos',
        'Tu Startup',
        'Información de tu Startup',
        'Madurez de tu Startup',
        'Ayudas',
        'Información Personal',
      ],
      step1: 0,
      step2: 0,
      step3: 0,
      step4: 0,
      step5: 0,
      step6: 0,
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.checkCompletedSteps();
    })();
  }

  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
      this.getUserFounder(obj);
    }
  };
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo.uid + '/startups';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.startups.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.startups[0],
          });
          this.checkCompletedSteps();
        }
      })
      .catch((error) => {
        this.checkCompletedSteps();
      });
  };
  handleChangeProfile = (event) => {
    this.setState({
      optionChooseProfile: event.target.value,
    });
  };
  checkCompletedSteps = async () => {
    var step1c = 0;
    var step2c = 0;
    var step3c = 0;
    var step4c = 0;
    var step5c = 0;
    var step6c = 0;
    if (
      this.state.userData.email !== '' &&
      this.state.userData.name !== '' &&
      this.state.userData.lastName !== '' &&
      this.state.userData.phone !== '' &&
      this.state.userData.email &&
      this.state.userData.name &&
      this.state.userData.lastName &&
      this.state.userData.phone
    ) {
      step1c = 1;
      this.setState({
        step1: 1,
      });
    }

    if (
      this.state.startupFounder.name !== '' &&
      this.state.startupFounder.industries !== '' &&
      this.state.startupFounder.shortDescription !== '' &&
      this.state.startupFounder.longDescription !== '' &&
      this.state.startupFounder.name &&
      this.state.startupFounder.industries &&
      this.state.startupFounder.shortDescription &&
      this.state.startupFounder.longDescription
    ) {
      step2c = 1;
      this.setState({ step2: 1 });
    }
    if (
      this.state.startupFounder.servicesDescription !== '' &&
      this.state.startupFounder.teamDescription !== '' &&
      this.state.startupFounder.testedInfoBoolean !== '' &&
      this.state.startupFounder.cif !== '' &&
      this.state.startupFounder.workers !== '' &&
      this.state.startupFounder.servicesDescription &&
      this.state.startupFounder.teamDescription &&
      this.state.startupFounder.testedInfoBoolean &&
      this.state.startupFounder.cif &&
      this.state.startupFounder.workers
    ) {
      step3c = 1;
      this.setState({ step3: 1 });
    }
    if (
      this.state.startupFounder.maturityInfo !== '' &&
      this.state.startupFounder.maturityInfo
    ) {
      step4c = 1;
      this.setState({ step4: 1 });
    }
    if (
      this.state.startupFounder.accelerationBoolean !== '' &&
      this.state.startupFounder.accelerationProcessInfo !== '' &&
      this.state.startupFounder.accelerationBoolean &&
      this.state.startupFounder.accelerationProcessInfo
    ) {
      step5c = 1;
      this.setState({ step5: 1 });
    }
    if (this.state.startupFounder.accelerationBoolean === false) {
      step5c = 1;
      this.setState({ step5: 1 });
    }

    if (this.state.startupFounder.testedInfoBoolean === false) {
      step3c = 1;
      this.setState({ step3: 1 });
    }
    if (
      this.state.userData.streetName !== '' &&
      this.state.userData.flatNumber !== '' &&
      this.state.userData.locality !== '' &&
      this.state.userData.province !== '' &&
      this.state.userData.country !== '' &&
      this.state.userData.postCode !== '' &&
      this.state.userData.streetName &&
      this.state.userData.flatNumber &&
      this.state.userData.locality &&
      this.state.userData.province &&
      this.state.userData.country &&
      this.state.userData.postCode
    ) {
      step6c = 1;
      this.setState({ step6: 1 });
    }

    if (
      step1c === 1 &&
      step2c === 1 &&
      step3c === 1 &&
      step4c === 1 &&
      step5c === 1 &&
      step6c === 1
    ) {
      window.location.reload();
    }
  };
  submitRegisterStep1 = (event) => {
    event.preventDefault();

    const payload = {
      name: event.target.register_steps_name.value,
      lastName: event.target.register_steps_lastname.value,
      email: event.target.register_steps_email.value.toLowerCase(),
      phone: event.target.register_steps_phone.value,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
      });

    //
  };
  submitRegisterStep2 = (event) => {
    event.preventDefault();

    alert(
      'Se van a guardar estos datos: ' +
        event.target.register_steps_startupName.value +
        ' ' +
        event.target.register_steps_startup_description_1.value +
        ' ' +
        event.target.register_steps_startup_description2.value
    );
    const payload = {
      name: event.target.register_steps_startupName.value,
      industries: event.target.register_steps_industry.value,
      shortDescription: event.target.register_steps_startup_description_1.value,
      longDescription: event.target.register_steps_startup_description2.value,
      founder: this.state.userData.uid,
    };

    if (this.state.isFounder === true) {
      var apiurlput = '/api/startups/' + this.state.startupFounder.uid;
      axios({
        url: apiurlput,
        method: 'PUT',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          //updateDataUser(response.data);
          alert('Datos guardados con éxito');
          this.getUserFounder(this.state.userData);
          //window.location.reload();
          //this.emailVerification(email);
          this.handleNext();
        })
        .catch((error) => {
          alert('Hemos encontrado un error y no se han guardado los cambios');
        });
    } else {
      var apiurlpost = '/api/startups';
      axios({
        url: apiurlpost,
        method: 'POST',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          //updateDataUser(response.data);
          alert('Datos guardados con éxito');
          this.getUserFounder(this.state.userData);
          //window.location.reload();
          //this.emailVerification(email);
          this.checkCompletedSteps();
          this.handleNext();
        })
        .catch((error) => {
          alert('Hemos encontrado un error y no se han guardado los cambios');
        });
    }

    //this.handleNext();
  };
  submitRegisterStep3 = (event) => {
    event.preventDefault();

    var register_steps_startup_test_company;
    if (event.target.register_steps_startup_test_company.value === 'True') {
      register_steps_startup_test_company = true;
    } else {
      register_steps_startup_test_company = false;
    }

    const payload = {
      name: this.state.startupFounder.name,
      testedInfoBoolean: register_steps_startup_test_company,
      testedInfoText: event.target.register_steps_startup_opinion_test.value,
      servicesDescription: event.target.register_steps_startup_products.value,
      teamDescription: event.target.register_steps_startup_team.value,
      cif: event.target.register_steps_cifStartup.value,
      workers: event.target.register_steps_workerstartup.value,
    };

    var apiurlput = '/api/startups/' + this.state.startupFounder.uid;
    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        //updateDataUser(response.data);
        alert('Datos guardados con éxito');

        this.getUserFounder(this.state.userData);
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
      });
    /*
		var apiurlput = "/api/startups/" + this.state.startupFounder.uid;
		axios({
			url: apiurlput,
			method: "PUT",
			data: payload,
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				console.log("Data has been sent to the server");
				console.log(response);
				//updateDataUser(response.data);
				alert("Datos guardados con éxito");
				//window.location.reload();
				//this.emailVerification(email);
				this.handleNext();
			})
			.catch((error) => {
				alert("Hemos encontrado un error y no se han guardado los cambios");
				console.log(error.response);
				console.log("Error");
			});
			*/
  };
  submitRegisterStep4 = (event) => {
    event.preventDefault();

    if (this.state.choosenOptionMaturity === '') {
      alert('No ha elegido opcion');
    } else {
      alert('Ha elegido la opcion: ' + this.state.choosenOptionMaturity);
      var choosedOption;
      if (this.state.choosenOptionMaturity === 1) {
        choosedOption = 'Pre-semilla';
      } else if (this.state.choosenOptionMaturity === 2) {
        choosedOption = 'Semilla';
      } else if (this.state.choosenOptionMaturity === 3) {
        choosedOption = 'Temprana';
      } else if (this.state.choosenOptionMaturity === 4) {
        choosedOption = 'Consolidada';
      }

      const payload = {
        name: this.state.startupFounder.name,
        maturityInfo: choosedOption,
      };

      var apiurlput = '/api/startups/' + this.state.startupFounder.uid;
      axios({
        url: apiurlput,
        method: 'PUT',
        data: payload,
        headers: {
          'x-token': this.state.userToken,
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          //updateDataUser(response.data);
          this.getUserFounder(this.state.userData);
          alert('Datos guardados con éxito');
          //window.location.reload();
          //this.emailVerification(email);
          this.getUserInformation();
          this.checkCompletedSteps();
          this.handleNext();
        })
        .catch((error) => {
          alert('Hemos encontrado un error y no se han guardado los cambios');
        });
    }
  };
  submitRegisterStep5 = (event) => {
    event.preventDefault();

    alert(
      'Se van a guardar estos datos: ' +
        event.target.register_steps_startup_aceleration.value +
        ' ' +
        event.target.register_steps_startup_aceleration_description.value
    );
    var register_steps_startup_aceleration;
    if (event.target.register_steps_startup_aceleration.value === 'True') {
      register_steps_startup_aceleration = true;
    } else {
      register_steps_startup_aceleration = false;
    }
    const payload = {
      name: this.state.startupFounder.name,
      accelerationBoolean: register_steps_startup_aceleration,
      accelerationProcessInfo:
        event.target.register_steps_startup_aceleration_description.value,
    };

    var apiurlput = '/api/startups/' + this.state.startupFounder.uid;
    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        //updateDataUser(response.data);
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.getUserInformation();
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
      });
  };
  submitRegisterStep6 = (event) => {
    event.preventDefault();

    const payload = {
      streetName: event.target.register_steps_address.value,
      flatNumber: event.target.register_steps_address_number.value,
      postCode: event.target.register_steps_pc.value,
      locality: event.target.register_steps_locality.value,
      province: event.target.register_steps_province.value,
      country: event.target.register_steps_country.value,

      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.getUserInformation();
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
      });
  };
  stepN1 = () => {
    return (
      <div>
        <form
          onSubmit={this.submitRegisterStep1}
          className="form"
          key={this.state.userData}
        >
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_email" shrink={true}>
                  Correo al que está asociada su cuenta
                </InputLabel>
                <Input
                  id="register_steps_email"
                  name="register_steps_email"
                  aria-describedby="register_steps_email"
                  type="email"
                  defaultValue={this.state.userData.email}
                  readOnly
                />
                <FormHelperText id="helper-email">
                  No compartiremos tu email
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_name">Nombre </InputLabel>
                <Input
                  id="register_steps_name"
                  name="register_steps_name"
                  aria-describedby="register_steps_name"
                  defaultValue={this.state.userData.name}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_lastname">
                  Apellidos{' '}
                </InputLabel>
                <Input
                  id="register_steps_lastname"
                  name="register_steps_lastname"
                  aria-describedby="register_steps_lastname"
                  type="text"
                  defaultValue={this.state.userData.lastName}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_phone" shrink={true}>
                  Para tener una comunicación más directa contigo. ¿A qué
                  teléfono te llamamos?
                </InputLabel>
                <Input
                  id="register_steps_phone"
                  name="register_steps_phone"
                  aria-describedby="register_steps_phone"
                  type="text"
                  defaultValue={this.state.userData.phone}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN2 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep2} className="form">
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_startupName" shrink={true}>
                  Lo más importante, ¿por qué nombre quieres que conozcamos a tu
                  Startup?{' '}
                </InputLabel>
                <Input
                  id="register_steps_startupName"
                  name="register_steps_startupName"
                  aria-describedby="register_steps_startupName"
                  type="text"
                  defaultValue={this.state.startupFounder.name}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_industry" shrink={true}>
                  ¿Cuál es la industria a la que pertenece tu Startup?
                </InputLabel>
                <Input
                  id="register_steps_industry"
                  name="register_steps_industry"
                  aria-describedby="register_steps_industry"
                  type="text"
                  defaultValue={this.state.startupFounder.industries}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <p>
                  Nos gustaría que nos hicieses una breve descripción de a qué
                  se dedica tu Startup{' '}
                </p>
                <TextareaAutosize
                  aria-label="minimum height"
                  id="register_steps_startup_description_1"
                  name="register_steps_startup_description_1"
                  rowsMin={3}
                  defaultValue={this.state.startupFounder.shortDescription}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <p>
                  Queremos acercar tu idea al mayor número de clientes posibles.
                  Para ello, un equipo de Estartaps.com va a revisar todo lo que
                  nos cuentes de tu solución, intentando entender hasta el
                  mínimo detalle para que el mundo lo conozca
                </p>
                <TextareaAutosize
                  aria-label="minimum height"
                  id="register_steps_startup_description2"
                  name="register_steps_startup_description2"
                  rowsMin={3}
                  defaultValue={this.state.startupFounder.longDescription}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN3 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep3} className="form">
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <p>
                  Ahora nos gustaría que nos contases los diferentes productos o
                  servicios que ofrece tu Startup
                </p>
                <TextareaAutosize
                  aria-label="minimum height"
                  id="register_steps_startup_products"
                  name="register_steps_startup_products"
                  rowsMin={3}
                  defaultValue={this.state.startupFounder.servicesDescription}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <p>
                  Nos gustaría conocer a tu equipo, cuéntanos brevemente sobre
                  ellos.
                </p>
                <TextareaAutosize
                  key="startupTeam"
                  aria-label="minimum height"
                  id="register_steps_startup_team"
                  name="register_steps_startup_team"
                  rowsMin={3}
                  defaultValue={this.state.startupFounder.teamDescription}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <p>¿Has testeado tu solución con alguna empresa?</p>
                <RadioGroup
                  aria-label="test_company"
                  name="register_steps_startup_test_company"
                  defaultValue={
                    this.state.startupFounder.testedInfoBoolean === ''
                      ? ''
                      : this.state.startupFounder.testedInfoBoolean === true
                      ? 'True'
                      : 'False'
                  }
                >
                  <FormControlLabel
                    value="True"
                    control={<Radio />}
                    label="Si"
                  />
                  <FormControlLabel
                    value="False"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <p>
                  En caso de que sea que sí, nos gustaría saber Cómo te fue.
                </p>
                <TextareaAutosize
                  key="opinion"
                  aria-label="minimum height"
                  id="register_steps_startup_opinion_test"
                  name="register_steps_startup_opinion_test"
                  defaultValue={this.state.startupFounder.testedInfoText}
                  rowsMin={3}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_cifStartup">
                  CIF De Startup
                </InputLabel>
                <Input
                  id="register_steps_cifStartup"
                  name="register_steps_cifStartup"
                  aria-describedby="register_steps_cifStartup"
                  type="text"
                  defaultValue={this.state.startupFounder.cif}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_workerstartup">
                  Número de empleados
                </InputLabel>
                <Input
                  id="register_steps_workerstartup"
                  name="register_steps_workerstartup"
                  aria-describedby="register_steps_workerstartup"
                  type="number"
                  required
                  defaultValue={this.state.startupFounder.workers}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN4 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep4} className="form">
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              <Grid container>
                <Grid
                  item={true}
                  sm={6}
                  xs={12}
                  className="containerOptionSquare"
                >
                  <div
                    className={
                      'optionSquare ' +
                      (this.state.startupFounder.maturityInfo === 'Pre-semilla'
                        ? 'choosenOption'
                        : '')
                    }
                    onClick={() => this.chooseOptionMature(1)}
                    id="opt1"
                  >
                    <h5 className="center">Fase Pre-Semilla</h5>
                    <p>
                      Compañías muy jóvenes, en general con menos de un año
                      desde su creación, creados por equipos excelentes.
                    </p>
                    <ul>
                      <li>Pre-money: {'<€'}1M</li>
                      <li>Antigüedad: Ninguna</li>
                    </ul>
                  </div>
                </Grid>
                <Grid
                  item={true}
                  sm={6}
                  xs={12}
                  className="containerOptionSquare"
                >
                  <div
                    className={
                      'optionSquare ' +
                      (this.state.startupFounder.maturityInfo === 'Semilla'
                        ? 'choosenOption'
                        : '')
                    }
                    onClick={() => this.chooseOptionMature(2)}
                    id="opt2"
                  >
                    <h5 className="center">Fase Semilla</h5>
                    <p>
                      Empresas nuevas que están explorando su concepto y creando
                      un producto mínimo(MVP) para validar.
                    </p>
                    <ul>
                      <li>Pre-money: {'<€'}2M</li>
                      <li>Antigüedad: 6 meses</li>
                    </ul>
                  </div>
                </Grid>
                <Grid
                  item={true}
                  sm={6}
                  xs={12}
                  className="containerOptionSquare"
                >
                  <div
                    className={
                      'optionSquare ' +
                      (this.state.startupFounder.maturityInfo === 'Temprana'
                        ? 'choosenOption'
                        : '')
                    }
                    onClick={() => this.chooseOptionMature(3)}
                    id="opt3"
                  >
                    <h5 className="center">Fase Temprana</h5>
                    <p>
                      Empresas con producto y métricas que el equipo puede
                      replicar, incoporar feedback y mejorar su estrategia.
                    </p>
                    <ul>
                      <li>Pre-money: {'<€'}8M</li>
                      <li>Antigüedad: 9 meses</li>
                    </ul>
                  </div>
                </Grid>
                <Grid
                  item={true}
                  sm={6}
                  xs={12}
                  className="containerOptionSquare"
                >
                  <div
                    className={
                      'optionSquare ' +
                      (this.state.startupFounder.maturityInfo === 'Consolidada'
                        ? 'choosenOption'
                        : '')
                    }
                    onClick={() => this.chooseOptionMature(4)}
                    id="opt4"
                  >
                    <h5 className="center">Fase Madura</h5>
                    <p>
                      Empresas más maduras, que facturan, con mayores costes y
                      menos retorno potencial, pero a priori menos riesgo.
                    </p>
                    <ul>
                      <li>Pre-money: {'>€'}8M</li>
                      <li>Antigüedad: 12 meses</li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN5 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep5} className="form">
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <p>
                  ¿Actualmente su Startup se encuentra en algún proceso de
                  aceleración?
                </p>
                <RadioGroup
                  aria-label="test_company"
                  name="register_steps_startup_aceleration"
                  defaultValue={
                    this.state.startupFounder.accelerationBoolean === ''
                      ? ''
                      : this.state.startupFounder.accelerationBoolean === true
                      ? 'True'
                      : 'False'
                  }
                >
                  <FormControlLabel
                    value="True"
                    control={<Radio />}
                    label="Si"
                  />
                  <FormControlLabel
                    value="False"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <p>Si su respuesta es sí ¿en qué aceleradora?</p>
                <TextareaAutosize
                  aria-label="minimum height"
                  id="register_steps_startup_aceleration_description"
                  name="register_steps_startup_aceleration_description"
                  rowsMin={3}
                  defaultValue={
                    this.state.startupFounder.accelerationProcessInfo
                  }
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  stepN6 = () => {
    return (
      <div>
        <form onSubmit={this.submitRegisterStep6} className="form">
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_address">
                  Dirección
                </InputLabel>
                <Input
                  id="register_steps_address"
                  name="register_steps_address"
                  aria-describedby="register_steps_address"
                  type="text"
                  defaultValue={this.state.userData.streetName}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_address_number">
                  N/Piso{' '}
                </InputLabel>
                <Input
                  id="register_steps_address_number"
                  name="register_steps_address_number"
                  aria-describedby="register_steps_address_number"
                  type="text"
                  defaultValue={this.state.userData.flatNumber}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_pc">
                  Código Postal{' '}
                </InputLabel>
                <Input
                  id="register_steps_pc"
                  name="register_steps_pc"
                  aria-describedby="register_steps_pc"
                  type="number"
                  defaultValue={this.state.userData.postCode}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_locality">
                  Localidad
                </InputLabel>
                <Input
                  id="register_steps_locality"
                  name="register_steps_locality"
                  aria-describedby="register_steps_locality"
                  type="text"
                  defaultValue={this.state.userData.locality}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_province">
                  Provincia
                </InputLabel>
                <Input
                  id="register_steps_province"
                  name="register_steps_province"
                  aria-describedby="register_steps_province"
                  type="text"
                  defaultValue={this.state.userData.province}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_country">País</InputLabel>
                <Input
                  id="register_steps_country"
                  name="register_steps_country"
                  aria-describedby="register_steps_country"
                  type="text"
                  defaultValue={this.state.userData.country}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
  chooseOptionMature = (option) => {
    var element = document.getElementById('opt1');
    var element2 = document.getElementById('opt2');
    var element3 = document.getElementById('opt3');
    var element4 = document.getElementById('opt4');
    if (option === 1) {
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element.classList.add('choosenOption');
    } else if (option === 2) {
      element.classList.remove('choosenOption');

      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element2.classList.add('choosenOption');
    } else if (option === 3) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');

      element4.classList.remove('choosenOption');
      element3.classList.add('choosenOption');
    } else if (option === 4) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.add('choosenOption');
    }
    this.setState({
      choosenOptionMaturity: option,
    });
  };

  responseGoogle = (response) => {
    this.setState({
      userData: response.profileObj,
      email: response.profileObj.email,
    });
  };
  responseGoogleFail = (response) => {};
  logout = (response) => {
    this.setState({
      userData: '',
    });
    alert('Logout');
  };

  responseFacebook = (response) => {
    this.setState({
      facebookName: response.name,
      facebookEmail: response.email,
      facebookImage: response.picture.data.url,
    });
  };
  socialLogin = () => {
    return (
      <div>
        <p className="center">Registro con Redes Sociales</p>
        <div className="center clear width-100">
          <GoogleLogin
            clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogleFail}
            cookiePolicy={'single_host_origin'}
          />
          <br></br>
          <FacebookLogin
            appId={955945428502335}
            autoLoad={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
            icon="fa-facebook"
          />
        </div>
      </div>
    );
  };

  getStepContent = (step) => {
    var stepReal;
    if (
      (step === 2 || step === 3 || step === 4) &&
      this.state.isFounder === false
    ) {
      stepReal = 1;
    } else {
      stepReal = step;
    }

    switch (stepReal) {
      case 0:
        return this.stepN1();
      case 1:
        return this.stepN2();
      case 2:
        return this.stepN3();
      case 3:
        return this.stepN4();
      case 4:
        return this.stepN5();
      case 5:
        return this.stepN6();
      default:
        return 'Paso no reconocido';
    }
  };
  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };
  handleNext = () => {
    if (this.state.activeStep + 1 >= this.state.steps.length) {
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };
  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };
  submitRegister = (event) => {
    event.preventDefault();
    this.refs.buttonRegister.setAttribute('disabled', 'disabled');

    if (
      event.target.register_steps_name.value === '' ||
      event.target.register_steps_lastname.value === '' ||
      event.target.register_steps_email.value === ''
    ) {
      alert('Faltan campos requeridos por completar');
      this.refs.buttonRegister.removeAttribute('disabled');
    } else {
      const payload = {
        name: event.target.register_steps_name.value,
        lastName: event.target.register_steps_lastname.value,
        companyName: event.target.register_steps_company.value,
        industryInterested: event.target.register_steps_industry.value,
        phone: event.target.register_steps_phone.value,
        email: event.target.register_steps_email.value,
        publicContactEmail: event.target.register_steps_email.value,
        pwd: event.target.register_steps_password.value,
        role: this.state.userData.role,
        streetName: event.target.register_steps_address.value,
        postCode: event.target.register_steps_postalcode.value,
        locality: event.target.register_steps_locality.value,
        province: event.target.register_steps_province.value,
        country: event.target.register_steps_country.value,
        tyc: 1,
        newsletter: 1,
      };

      axios({
        url: '/api/users',
        method: 'POST',
        data: payload,
      })
        .then(() => {})
        .catch((error) => {});

      this.refs.buttonRegister.removeAttribute('disabled');
      //this.buttonRegister.removeAttribute("disabled");
    }
  };
  handleStep = (event) => {
    var stepReal;
    if (
      (event === 2 || event === 3 || event === 4) &&
      this.state.isFounder === false
    ) {
      stepReal = 1;
    } else {
      stepReal = event;
    }
    this.setState({
      activeStep: stepReal,
    });
  };
  render() {
    return (
      <Container>
        <Grid container xs={12}>
          <Grid item={true} xs={12} sm={12} className="formDelimiter">
            <Stepper
              nonLinear
              alternativeLabel
              activeStep={this.state.activeStep}
              key={
                this.state.step1 +
                this.state.step2 +
                this.state.step3 +
                this.state.step4 +
                this.state.step5 +
                this.state.step6
              }
            >
              {this.state.steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => this.handleStep(index)}>
                    {label}
                    {index === 0 && this.state.step1 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 1 && this.state.step2 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 2 && this.state.step3 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 3 && this.state.step4 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 4 && this.state.step5 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                    {index === 5 && this.state.step6 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                  </StepButton>
                </Step>
              ))}
            </Stepper>

            <div>
              {this.state.activeStep === this.state.steps.length ? (
                <div>
                  <Typography>Ya no quedan mas pasos</Typography>
                </div>
              ) : (
                <div>
                  {this.getStepContent(this.state.activeStep)}

                  <div className="buttons-registration">
                    <Button
                      disabled={this.state.activeStep === 0}
                      onClick={() => {
                        this.handleBack();
                      }}
                    >
                      Anterior
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="button-nextStep"
                      onClick={() => {
                        this.handleNext();
                      }}
                    >
                      {this.state.activeStep === this.state.length - 1
                        ? 'Finzalizar'
                        : 'Siguiente'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default RegistroForm;
