import React, { Component } from 'react';
import axios from 'axios';
import { Container } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import './../../../styles/styles.css';
import 'react-quill/dist/quill.snow.css';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';
import HelpIcon from '@material-ui/icons/Help';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
class Challenge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: props.startup,
      userToken: props.userToken,
      challenges: [],
      openPopOver: false,
    };

    console.log('challenge component');
  }

  componentDidMount() {
    this.getChallenges();
  }

  getChallenges = async () => {
    const apiurl = '/api/company/' + this.state.company._id + '/challenges';
    const { data } = await axios.get(apiurl);
    this.setState({
      challenges: data.challenges,
    });
  };

  viewMore = (challenge) => {
    const newProps = {
      challenge: challenge,
    };

    this.props.getClickedElement('reto_ver_single', newProps);
  };

  deleteChallenge = async (id) => {
    const headers = {
      headers: {
        'x-token': this.state.userToken,
      },
    };

    const { data } = await axios.delete(
      '/api/company/' + this.state.company._id + '/challenge/' + id,
      headers
    );

    if (Number(data.code) === 200) {
      this.props.enqueueSnackbar('Reto eliminado', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }

    this.getChallenges();
  };

  goToCreateChallenge = async () => {
    console.log('going to create challenge');

    const payload = {
      companyId: this.state.company._id,
      companyImg: this.state.company.img || '',
      companyDescription: this.state.company.description || '',
      companyName: this.state.company.name,
    };

    const headers = {
      headers: {
        'x-token': this.props.userToken,
      },
    };

    const apiurl = '/api/company/' + this.state.company._id + '/challenge';

    const { data } = await axios.post(apiurl, payload, headers);

    const challenge = data.challenge;

    console.log(data);

    const newProps = {
      challenge: challenge,
    };

    this.props.getClickedElement('reto_ver_single', newProps);
  };
  handlePopoverOpen = () => {
    if (this.state.openPopOver === false) {
      this.setState({
        openPopOver: true,
      });
    } else {
      this.setState({
        openPopOver: false,
      });
    }
  };

  render() {
    const columns = [
      {
        field: 'title',
        headerName: 'Título',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return <div>{params.row.challengeTitle || ''}</div>;
        },
      },
      {
        field: 'created_date',
        headerName: 'Fecha de creación',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>{new Date(params.row.createdAt).toLocaleDateString()}</div>
          );
        },
      },
      {
        field: 'expiration_date',
        headerName: 'Fecha de expiración',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              {params.row.expirationDate
                ? new Date(params.row.expirationDate).toLocaleDateString()
                : ''}
            </div>
          );
        },
      },
      {
        field: 'active',
        headerName: 'Activo',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{params.row.active ? 'Sí' : 'No'}</div>;
        },
      },
      {
        field: 'view',
        headerName: 'Ver más',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.viewMore(params.row)}
              >
                Ver más
              </Button>
            </div>
          );
        },
      },
      {
        field: 'delete',
        headerName: 'Borrar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() =>
                  window.confirm('¿Estás seguro de borrar el reto?')
                    ? this.deleteChallenge(params.row._id)
                    : ''
                }
              >
                Borrar
              </Button>
            </div>
          );
        },
      },
    ];
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Si ocurre que no encuentras un servicio innovador concreto para tu
        empresa, en este apartado podrás crear un reto. Nos llegarán tus datos
        para ponernos en contacto contigo para validarlo y lanzarlo a todo
        nuestro Ecosistema Estartaps. Las Startups podrán postular ofreciendo la
        mejor idea para la resolución del problema que planteas
      </Tooltip>
    );
    return (
      <Container>
        <h1 className="job-page-title">
          Acuerdos con Corporates, podrás presentar tu idea a los retos
          propuestos
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <HelpIcon></HelpIcon>
          </OverlayTrigger>
        </h1>

        <Grid container>
          <Grid item={true} sm={12} xs={12} style={{ marginBottom: '1.8rem' }}>
            <Button
              className="button"
              onClick={() => this.goToCreateChallenge()}
            >
              Crear reto
            </Button>
          </Grid>
          <Grid item={true} sm={12} xs={12}>
            <DataGrid
              rows={this.state.challenges ? this.state.challenges : []}
              getRowId={(row) => row._id}
              autoHeight
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withSnackbar(Challenge);
