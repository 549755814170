import React, { Component } from "react";
import {
	Grid,
	Container,
	InputLabel,
	Input,
	FormControl,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { getUserData, getUserToken } from "../../../utils/manageUserData";
import axios from "axios";
import { withSnackbar } from "notistack";

class addCredit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startupFounder: [],
			editStartup: "",
			userToken: "",
			userData: "",
		};
	}
	componentDidMount() {
		(async () => {
			await this.getUserInformation();
			await this.getUserToken();
		})();
	}
	getUserToken = async () => {
		var userToken = getUserToken();
		if (userToken !== false) {
			const obj = userToken;
			this.setState({
				userToken: obj,
			});
		}
	};
	getUserInformation = async () => {
		var userData = getUserData();

		if (userData !== false) {
			const obj = JSON.parse(userData);
			this.setState({
				userData: obj,
			});
			this.getUserFounder(obj);
		}
	};
	getUserFounder = (userInfo) => {
		var apiurl = "/api/users/" + userInfo.uid + "/startups";
		axios({
			url: apiurl,
			method: "GET",
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				if (response.data.startups.length) {
					this.setState({
						isFounder: true,
						startupFounder: response.data.startups,
					});
				}
			})
			.catch((error) => {});
	};

	submitConversation = (event) => {
		event.preventDefault();
	};

	render() {
		return (
			<Container>
				<Grid container className="borderShadow">
					<Grid item xs={12}>
						<form
							onSubmit={this.submitConversation}
							className="form"
							key={this.state.userData}
						>
							<Grid container>
								<Grid item={true} sm={12} xs={12}>
									{" "}
									<FormControl className="width-100">
										<InputLabel htmlFor="title_conversation" shrink={true}>
											Para cerar un hilo nueve rellene el campo con el título de
											su hilo y confirme
										</InputLabel>
										<Input
											id="title_conversation"
											name="title_conversation"
											aria-describedby="title_conversation"
											type="text"
										/>
									</FormControl>
								</Grid>
								<Grid item={true} sm={12} xs={12} className="center">
									{" "}
									<Button
										variant="contained"
										color="primary"
										type="submit"
										className="button center"
										ref="buttonSubmit"
									>
										Proceder al pago (WIP)
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</Container>
		);
	}
}
export default withSnackbar(addCredit);
