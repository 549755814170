import React, { Component } from "react";
import {
	Grid,
	Container,
	InputLabel,
	Input,
	FormControl,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { getUserData, getUserToken } from "../../../utils/manageUserData";
import axios from "axios";
import { withSnackbar } from "notistack";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Alert from "react-bootstrap/Alert";

class editServices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startupFounder: [],
			editStartup: "",
			choosenOptionMaturity: "",
			userToken: "",
			userData: "",
			imageSrc: "",
			imageToUpload: "",
			croppedImage: "",
			croppedImageFile: "",
			crop: { x: 0, y: 0 },
			croppedAreaPixels: "",
			uploadedImage: "",
			croppedArea: "",
			zoom: 1,
			aspect: 1 / 1,
			cropSize: { width: 300, height: 300 },
			products_array: [],
			teams_array: [],
			presentationServices: "",
			update_change_offer:
				this.props.informationStartup.offerServicesOtherCompanies,
		};
	}
	componentDidMount() {
		(async () => {
			await this.getUserInformation();
			await this.getUserToken();
		})();
		console.log("aqui esta");
		console.log(this.props.informationStartup);
		this.setState({
			editStartup: this.props.informationStartup,
		});
		var services = [];
		var teams = [];
		var startup = this.props.informationStartup;
		if (startup.services) {
			startup.services.forEach((element, index, array) => {
				var name = "product-" + index;
				services.push(name);
			});
		}
		if (startup.managementTeam) {
			startup.managementTeam.forEach((element, index, array) => {
				var name = "team-" + index;
				teams.push(name);
			});
		}
		this.setState({
			products_array: services,
			teams_array: teams,
		});
	}
	getUserToken = async () => {
		var userToken = getUserToken();
		if (userToken !== false) {
			const obj = userToken;
			this.setState({
				userToken: obj,
			});
		}
	};
	getUserInformation = async () => {
		var userData = getUserData();

		if (userData !== false) {
			const obj = JSON.parse(userData);
			this.setState({
				userData: obj,
			});
			this.getUserFounder(obj);
		}
	};
	getUserFounder = (userInfo) => {
		var apiurl = "/api/users/" + userInfo.uid + "/company";
		axios({
			url: apiurl,
			method: "GET",
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				if (response.data.startups.length) {
					this.setState({
						isFounder: true,
						startupFounder: response.data.startups,
					});
				}
			})
			.catch((error) => {});
	};

	submitAlldata = (payload) => {
		var apiurlput = "/api/company/" + this.state.editStartup._id;
		axios({
			url: apiurlput,
			method: "PUT",
			data: payload,
			headers: {
				"x-token": this.state.userToken,
				"content-type": "application/json",
			},
		})
			.then((response) => {
				console.log(response);
				this.props.enqueueSnackbar("Datos guardados con éxito", {
					variant: "success",
				});
				this.getUserFounder(this.state.userData);
			})
			.catch((error) => {
				console.log(error.response);
				alert("Hemos encontrado un error y no se han guardado los cambios");
			});
	};
	submitUpdateStartup = (event) => {
		event.preventDefault();
		if (this.state.editStartup !== "") {
			let listProducts = [];
			var getProducts = new Promise((resolve, reject) => {
				this.state.products_array.forEach((element, index, array) => {
					if (
						document.getElementById(element + "-name") !== null &&
						document.getElementById(element + "-name") !== "undefined"
					) {
						var name = document.getElementById(element + "-name").value;
						var description = document.getElementById(
							element + "-description"
						).value;
						var price = document.getElementById(element + "-price").value;
						var discountPercentage = document.getElementById(
							element + "-discountPercentage"
						).value;
						var discountedPrice = document.getElementById(
							element + "-discountedPrice"
						).value;
						let obj = {
							name: name,
							description: description,
							price: price,
							discountPercentage: discountPercentage,
							discountedPrice: discountedPrice,
						};
						listProducts.push(obj);
					}
					if (index === array.length - 1) resolve();
				});
			});

			getProducts.then(() => {
				console.log("All done!");
			});
			let updateChange = "";
			if (
				this.state.update_change_offer !== true &&
				this.state.update_change_offer !== false
			) {
				console.log("entra aqui");
				updateChange = false;
			} else {
				console.log("aqui");
				updateChange = this.state.update_change_offer;
			}
			const payload = {
				name_contact_company_services: event.target.name_contact_company.value,

				services: listProducts,
				name: this.state.editStartup.name,
				offerServicesOtherCompanies: updateChange,
			};
			console.log(this.state.update_change_offer);
			console.log(updateChange);
			this.submitAlldata(payload);

			this.props.getStartupInformation();
		}
	};

	handleClick = () => {
		this.props.enqueueSnackbar("Successfully fetched the data.");
	};

	appendInput_product() {
		var newInput = `product-${this.state.products_array.length}`;

		this.setState((prevState) => ({
			products_array: prevState.products_array.concat([newInput]),
		}));
	}

	deleteProduct = async (producto) => {
		alert("vas a borrar el producto: " + producto);

		document.getElementById("product-" + producto).remove();
	};
	handleChangeOffer = (event) => {
		this.setState({
			update_change_offer: event.target.value,
		});
	};

	render() {
		console.log(this.state.editStartup);
		return (
			<Container>
				<Grid container className="borderShadow">
					<Grid item xs={12}>
						<div>
							{this.state.editStartup !== "" ? (
								<form
									onSubmit={this.submitUpdateStartup}
									className="form"
									key={this.state.editStartup}
								>
									<Grid container>
										<Grid container>
											<Grid item={true} xs={12}>
												<div key={this.state.editStartup}>
													{this.state.editStartup
														.statusOfferServicesOtherCompanies ? (
														this.state.editStartup
															.statusOfferServicesOtherCompanies ===
														"Rechazada" ? (
															<Alert variant={"danger"}>
																Su petición para ofrecer servicios ha sido
																rechazada
															</Alert>
														) : (
															""
														)
													) : (
														""
													)}
													{this.state.editStartup
														.statusOfferServicesOtherCompanies ? (
														this.state.editStartup
															.statusOfferServicesOtherCompanies ===
														"Aceptada" ? (
															<Alert variant={"success"}>
																Su petición para ofrecer servicios ha sido
																aceptada
															</Alert>
														) : (
															""
														)
													) : (
														""
													)}
													{this.state.editStartup
														.statusOfferServicesOtherCompanies ? (
														this.state.editStartup
															.statusOfferServicesOtherCompanies ===
														"Revision" ? (
															<Alert variant={"warning"}>
																Su petición para ofrecer servicios esta en
																Revision
															</Alert>
														) : (
															""
														)
													) : (
														""
													)}
												</div>
											</Grid>
											<Grid item={true} xs={12}>
												<p>
													¿Desea prestar sus servicios a otras empresas?
													Recuerde que su petición será revisada por
													administración antes de ser aceptada.
												</p>
												<Select
													labelId="update_change_offer"
													id="update_change_offer"
													name="update_change_offer"
													value={this.state.update_account}
													onChange={this.handleChangeOffer}
													placeholder="Seleccionar opcion"
													defaultValue={this.state.update_change_offer}
												>
													<MenuItem value={true}>Si</MenuItem>
													<MenuItem value={false}>No</MenuItem>
												</Select>

												<FormControl className="width-100">
													<InputLabel
														htmlFor={"name_contact_company"}
														shrink={true}
													>
														Nombre del Responsable*
													</InputLabel>
													<Input
														id={"name_contact_company"}
														name={"name_contact_company"}
														aria-describedby={"name_contact_company"}
														defaultValue={
															this.state.editStartup
																.name_contact_company_services
														}
														type="text"
														required
													/>
												</FormControl>
											</Grid>
											<h3>Servicios ofrecidos</h3>
											{this.state.products_array.map((input, index) => (
												<Grid
													item={true}
													sm={12}
													xs={12}
													className="boxed"
													id={"product-" + index}
												>
													<Button
														onClick={() => this.deleteProduct(index)}
														className="buttonWarning"
														startIcon={<DeleteIcon />}
													>
														{" "}
														Borrar Producto
													</Button>

													<FormControl className="width-100">
														<InputLabel htmlFor={input + "-name"} shrink={true}>
															Nombre del producto o servicio*
														</InputLabel>
														<Input
															id={input + "-name"}
															name={input + "-name"}
															aria-describedby={input + "-name"}
															type="text"
															required
															defaultValue={
																this.state.editStartup.services
																	? index + 1 <=
																	  this.state.editStartup.services.length
																		? this.state.editStartup.services[index]
																				.name
																		: ""
																	: ""
															}
														/>
													</FormControl>
													<FormControl className="width-100">
														<InputLabel
															htmlFor={input + "-description"}
															shrink={true}
														>
															Descripción
														</InputLabel>
														<Input
															id={input + "-description"}
															name={input + "-description"}
															aria-describedby={input + "-description"}
															type="text"
															defaultValue={
																this.state.editStartup.services
																	? index + 1 <=
																	  this.state.editStartup.services.length
																		? this.state.editStartup.services[index]
																				.description
																		: ""
																	: ""
															}
														/>
													</FormControl>
													<FormControl className="width-100">
														<InputLabel
															htmlFor={input + "-price"}
															shrink={true}
														>
															Precio
														</InputLabel>
														<Input
															id={input + "-price"}
															name={input + "-price"}
															aria-describedby={input + "-price"}
															type="text"
															defaultValue={
																this.state.editStartup.services
																	? index + 1 <=
																	  this.state.editStartup.services.length
																		? this.state.editStartup.services[index]
																				.price
																		: ""
																	: ""
															}
														/>
													</FormControl>
													<FormControl className="width-100">
														<InputLabel
															htmlFor={input + "-discountPercentage"}
															shrink={true}
														>
															Descuento
														</InputLabel>
														<Input
															id={input + "-discountPercentage"}
															name={input + "-discountPercentage"}
															aria-describedby={input + "-discountPercentage"}
															type="text"
															defaultValue={
																this.state.editStartup.services
																	? index + 1 <=
																	  this.state.editStartup.services.length
																		? this.state.editStartup.services[index]
																				.discountPercentage
																		: ""
																	: ""
															}
														/>
													</FormControl>
													<FormControl className="width-100">
														<InputLabel
															htmlFor={input + "-discountedPrice"}
															shrink={true}
														>
															Precio con Descuento
														</InputLabel>
														<Input
															id={input + "-discountedPrice"}
															name={input + "-discountedPrice"}
															aria-describedby={input + "-discountedPrice"}
															type="text"
															defaultValue={
																this.state.editStartup.services
																	? index + 1 <=
																	  this.state.editStartup.services.length
																		? this.state.editStartup.services[index]
																				.discountedPrice
																		: ""
																	: ""
															}
														/>
													</FormControl>
												</Grid>
											))}
										</Grid>
										<p
											onClick={() => this.appendInput_product()}
											className="button"
										>
											Añadir Servicio
										</p>

										<Grid item={true} sm={12} xs={12} className="center">
											{" "}
											<Button
												variant="contained"
												color="primary"
												type="submit"
												className="button center"
												ref="buttonRegister"
											>
												Guardar
											</Button>
										</Grid>
									</Grid>
								</form>
							) : (
								""
							)}
						</div>
					</Grid>
				</Grid>
			</Container>
		);
	}
}
export default withSnackbar(editServices);
