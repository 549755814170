import React, { Component } from 'react';
import {
  Grid,
  Container,
  Button,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';
import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';
import getCroppedImg from '../../../utils/cropImage';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Switch from '@material-ui/core/Switch';

import DeleteIcon from '@material-ui/icons/Delete';
class listCrowdfunding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToken: '',
      userData: '',
      userStartup: '',
      startupToEdit: '',
      imageToUpload: '',
      croppedImage: '',
      croppedImageFile: '',
      crop: { x: 0, y: 0 },
      croppedAreaPixels: '',
      uploadedImage: '',
      croppedArea: '',
      zoom: 1,
      aspect: 1 / 1,
      cropSize: { width: 300, height: 300 },
      products_array: [],
      teams_array: [],
      editStartup: '',
      longDescription: 0,
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.getAllStartups();
    })();
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
      this.getUserConversations(this.state.userData);
    }
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
    }
  };
  getUserConversations = (userInfo) => {
    console.log('Dentro de obtener mensajes');

    var apiurl = '/api/messages/' + userInfo.uid;
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response.data);
        this.setState({
          listCrowdStartups: response.data.messages,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  getAllStartups = async () => {
    var userToken = this.state.userToken;
    var apiurl = '/api/startups';

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        let array = [];
        for (let item of response.data.startups) {
          console.log(item.crowdFundingInformation);

          if (
            typeof item.crowdFundingInformation !== 'undefined' &&
            item.crowdFundingInformation.length
          ) {
            array.push(item);
          }
        }
        console.log(array);
        this.setState({
          userStartup: array,
        });
      })
      .catch((error) => {
        console.log('No startup Found');
      });
  };
  editStartup = (startupData) => {
    var startup = startupData;
    var teams = [];
    console.log('agui');
    console.log(startupData);
    startup.crowdFundingInformation[0].managementTeam.forEach(
      (element, index, array) => {
        var name = 'team-' + index;
        teams.push(name);
      }
    );
    console.log(startupData);
    this.setState({
      teams_array: teams,
      startupToEdit: startupData,
      startupToEditUid: startupData.uid,
    });
  };
  submitAlldata = (data) => {
    var apiurlput = '/api/startups/' + this.state.startupToEditUid;

    const payload = {
      name: this.state.startupToEdit.name,
      crowdFundingInformation: data.crowdFundingInformation,
    };
    console.log(payload);

    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        this.props.enqueueSnackbar('Datos guardados con éxito', {
          variant: 'success',
        });
        this.getAllStartups();
      })
      .catch((error) => {
        console.log(error.response);
        alert('Hemos encontrado un error y no se han guardado los cambios');
      });
  };
  submitUpdateStartup = async (event) => {
    event.preventDefault();
    if (this.state.startupToEdit !== '') {
      let listTeam = [];
      var getTeams = new Promise((resolve, reject) => {
        this.state.teams_array.forEach((element, index, array) => {
          if (
            document.getElementById(element + '-name') !== null &&
            document.getElementById(element + '-name') !== 'undefined'
          ) {
            var name = document.getElementById(element + '-name').value;
            var position = document.getElementById(element + '-position').value;
            var order = document.getElementById(element + '-order').value;
            var linkedin = document.getElementById(element + '-linkedin').value;

            let obj = {
              name: name,
              position: position,
              order: order,
              linkedinUrl: linkedin,
            };
            listTeam.push(obj);
          }
          if (index === array.length - 1) resolve();
        });
      });

      getTeams.then(() => {
        console.log('All done!');
      });
      //console.log(listProducts);
      //check youtube url
      var url = event.target.crowd_startupVideo.value;

      if (url !== '') {
        var regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length === 11) {
          url =
            'https://www.youtube.com/embed/' +
            match[2] +
            '?autoplay=0&enablejsapi=1';
        } else {
          this.props.enqueueSnackbar('El enlace a Youtube no es correcto', {
            variant: 'error',
          });
          url = '';
        }
      }
      let longDescriptionCheck = '';
      if (this.state.longDescription !== 0) {
        longDescriptionCheck = this.state.longDescription;
      } else {
        longDescriptionCheck =
          this.state.startupToEdit.crowdFundingInformation[0].otherInvestors;
      }
      const crowdFundingInformation = {
        type: 'Crowdfunding',
        oportunityExplanation: event.target.crowd_startupOportunity.value,
        stage: event.target.crowd_startupStage.value,
        industry: event.target.crowd_startupIndustry.value,
        otherInvestors: longDescriptionCheck,
        managementTeam: listTeam,
        accelaeration: event.target.crowd_startupAceleraciones.value,
        reasons: event.target.crowd_startupMotivos.value,
        keywords: event.target.crowd_startupKeywords.value,
        videoPresentation: url,
        accepted: this.state.startupToEdit.crowdFundingInformation[0].accepted,
        permalinkStartupCrowd: this.state.startupToEdit.permalink,
      };

      const payload = {
        crowdFundingInformation,
      };
      console.log(payload);
      this.submitAlldata(payload);

      //this.props.getStartupInformation();
    }
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedArea: croppedArea,
      croppedAreaPixels: croppedAreaPixels,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  handleChangeStatus = (event, id, name, startup) => {
    console.log(event.target.checked);
    console.log(id);
    console.log(startup);
    let checked = 0;
    if (event.target.checked === true) {
      checked = 1;
    } else {
      checked = 0;
    }
    const crowdFundingInformation = {
      type: 'Crowdfunding',
      oportunityExplanation:
        startup.crowdFundingInformation[0].oportunityExplanation,
      stage: startup.crowdFundingInformation[0].stage,
      industry: startup.crowdFundingInformation[0].industry,
      otherInvestors: startup.crowdFundingInformation[0].otherInvestors,
      managementTeam: startup.crowdFundingInformation[0].managementTeam,
      accelaeration: startup.crowdFundingInformation[0].accelaeration,
      reasons: startup.crowdFundingInformation[0].reasons,
      keywords: startup.crowdFundingInformation[0].keywords,
      videoPresentation: startup.crowdFundingInformation[0].videoPresentation,
      accepted: checked,
      permalinkStartupCrowd: this.state.startupToEdit.permalink,
    };

    var apiurlput = '/api/startups/' + id;

    const payload = {
      name: name,
      crowdFundingInformation: crowdFundingInformation,
    };
    console.log(payload);

    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        this.props.enqueueSnackbar('Cambiado el estado de la startup', {
          variant: 'success',
        });
        this.getAllStartups();
      })
      .catch((error) => {
        console.log(error.response);
        this.props.enqueueSnackbar(
          'No se ha podido cambiar el estado de la startup',
          {
            variant: 'warning',
          }
        );
      });
  };
  handleChange = (event) => {
    var image = event.target.files[0];
    if (event.target.files[0]) {
      this.setState({
        uploadedImage: event.target.files[0],
        imageSrc: URL.createObjectURL(image),
      });
    } else {
      this.setState({
        uploadedImage: '',
        imageSrc: '',
      });
    }
  };
  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
      this.state.rotation
    );

    let response = await fetch(croppedImage);
    let data = await response.blob();
    let metadata = {
      type: 'image/png',
    };
    let file = new File([data], 'Startup.png', metadata);

    this.setState({
      croppedImageFile: file,
    });
    this.setState({
      croppedImage: croppedImage,
    });
  };

  appendInput_team() {
    var newInput = `team-${this.state.teams_array.length}`;

    this.setState((prevState) => ({
      teams_array: prevState.teams_array.concat([newInput]),
    }));
  }
  deleteTeam = async (team) => {
    alert('vas a borrar el miembro: ' + team);

    document.getElementById('team-' + team).remove();
  };

  handleChangeDescription = (value) => {
    this.setState({ longDescription: value });
  };
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
    ],
  };
  formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];
  render() {
    const columns = [
      {
        field: 'name',
        headerName: 'Nombre',
        editable: false,
        flex: 1,
      },

      {
        field: 'permalinkStartupCrowd',
        headerName: 'Ver Startup',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <a
                href={'/startups/' + params.value}
                target="_blank"
                rel="noreferrer"
              >
                Ir a Startup
              </a>
            </div>
          );
        },
      },
      {
        field: 'permalinkStartupCrowd',
        headerName: 'Ver Startup',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <a
                href={'/startups/' + params.row.permalink}
                target="_blank"
                rel="noreferrer"
              >
                Ir a Startup
              </a>
            </div>
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Editar',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="button"
                onClick={() => this.editStartup(params.row)}
              >
                Editar
              </Button>
            </div>
          );
        },
      },
      {
        field: 'accepted',
        headerName: 'Estado',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Switch
                defaultChecked={params.row.crowdFundingInformation[0].accepted}
                onChange={(event) =>
                  this.handleChangeStatus(
                    event,
                    params.row.uid,
                    params.row.name,
                    params.row
                  )
                }
                inputProps={{ 'aria-label': 'check-status' }}
              />
            </div>
          );
        },
      },
    ];

    return (
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <div>
              {this.state.userStartup !== '' ? (
                <DataGrid
                  rows={this.state.userStartup}
                  getRowId={(row) => row.uid}
                  autoHeight
                  columns={columns}
                  pageSize={5}
                />
              ) : (
                <p>Cargando</p>
              )}
            </div>
            <div key={this.state.startupToEditUid + this.state.userStartup}>
              {this.state.startupToEdit !== '' ? (
                <form
                  onSubmit={this.submitUpdateStartup}
                  className="form"
                  key={this.state.editStartup}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Ficha técnica</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupOportunity"
                              shrink={true}
                            >
                              En qué consiste la oportunidad de inversión
                            </InputLabel>
                            <Input
                              id="crowd_startupOportunity"
                              name="crowd_startupOportunity"
                              aria-describedby="crowd_startupOportunity"
                              type="text"
                              defaultValue={
                                this.state.startupToEdit
                                  .crowdFundingInformation[0]
                                  .oportunityExplanation
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupStage"
                              shrink={true}
                            >
                              Stage de la inversión
                            </InputLabel>
                            <Input
                              id="crowd_startupStage"
                              name="crowd_startupStage"
                              aria-describedby="crowd_startupStage"
                              type="text"
                              defaultValue={
                                this.state.startupToEdit
                                  .crowdFundingInformation[0].stage
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupIndustry"
                              shrink={true}
                            >
                              Mercados/ industria
                            </InputLabel>
                            <Input
                              id="crowd_startupIndustry"
                              name="crowd_startupIndustry"
                              aria-describedby="crowd_startupIndustry"
                              type="text"
                              defaultValue={
                                this.state.startupToEdit
                                  .crowdFundingInformation[0].industry
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupVideo"
                              shrink={true}
                            >
                              Video de presentación (Solo admite youtube)
                            </InputLabel>
                            <Input
                              id="crowd_startupVideo"
                              name="crowd_startupVideo"
                              aria-describedby="crowd_startupVideo"
                              type="url"
                              defaultValue={
                                this.state.startupToEdit
                                  .crowdFundingInformation[0].videoPresentation
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <p>Otros inversores actuales de la empresa</p>
                            <ReactQuill
                              name="longDescription"
                              modules={this.modules}
                              formats={this.formats}
                              onChange={this.handleChangeDescription}
                              defaultValue={
                                this.state.startupToEdit
                                  .crowdFundingInformation[0].otherInvestors
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupAceleraciones"
                              shrink={true}
                            >
                              Aceleraciones
                            </InputLabel>
                            <Input
                              id="crowd_startupAceleraciones"
                              name="crowd_startupAceleraciones"
                              aria-describedby="crowd_startupAceleraciones"
                              type="text"
                              defaultValue={
                                this.state.startupToEdit
                                  .crowdFundingInformation[0].accelaeration
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupMotivos"
                              shrink={true}
                            >
                              Motivos por los que invertir
                            </InputLabel>
                            <Input
                              id="crowd_startupMotivos"
                              name="crowd_startupMotivos"
                              aria-describedby="crowd_startupMotivos"
                              type="text"
                              defaultValue={
                                this.state.startupToEdit
                                  .crowdFundingInformation[0].reasons
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="crowd_startupKeywords"
                              shrink={true}
                            >
                              Palabras clave
                            </InputLabel>
                            <Input
                              id="crowd_startupKeywords"
                              name="crowd_startupKeywords"
                              aria-describedby="crowd_startupKeywords"
                              type="text"
                              defaultValue={
                                this.state.startupToEdit
                                  .crowdFundingInformation[0].keywords
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {this.state.startupToEdit.investorDoc ? (
                            <FormControl className="width-100">
                              <a
                                href={
                                  process.env.REACT_APP_API_URL_PROD +
                                  'uploads/' +
                                  this.state.startupToEdit.investorDoc
                                }
                                rel="noreferrer"
                                target="_blank"
                              >
                                Documento de inversión
                              </a>
                            </FormControl>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Miembros</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <h3>Miembros</h3>
                        {this.state.teams_array.map((input, index) => (
                          <Grid
                            item={true}
                            sm={12}
                            xs={12}
                            className="boxed"
                            id={'team-' + index}
                          >
                            <Button
                              onClick={() => this.deleteTeam(index)}
                              className="buttonWarning"
                              startIcon={<DeleteIcon />}
                            >
                              {' '}
                              Borrar Miembro
                            </Button>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-name'}
                                shrink={true}
                              >
                                Nombre de miembro*
                              </InputLabel>
                              <Input
                                id={input + '-name'}
                                name={input + '-name'}
                                aria-describedby={input + '-name'}
                                type="text"
                                defaultValue={
                                  index + 1 <=
                                  this.state.startupToEdit
                                    .crowdFundingInformation[0].managementTeam
                                    .length
                                    ? this.state.startupToEdit
                                        .crowdFundingInformation[0]
                                        .managementTeam[index].name
                                    : ''
                                }
                                required
                              />
                            </FormControl>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-position'}
                                shrink={true}
                              >
                                Cargo
                              </InputLabel>
                              <Input
                                id={input + '-position'}
                                name={input + '-position'}
                                aria-describedby={input + '-position'}
                                type="text"
                                defaultValue={
                                  index + 1 <=
                                  this.state.startupToEdit
                                    .crowdFundingInformation[0].managementTeam
                                    .length
                                    ? this.state.startupToEdit
                                        .crowdFundingInformation[0]
                                        .managementTeam[index].position
                                    : ''
                                }
                              />
                            </FormControl>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-linkedin'}
                                shrink={true}
                              >
                                Url Linkedin
                              </InputLabel>
                              <Input
                                id={input + '-linkedin'}
                                name={input + '-linkedin'}
                                aria-describedby={input + '-linkedin'}
                                type="text"
                                defaultValue={
                                  index + 1 <=
                                  this.state.startupToEdit
                                    .crowdFundingInformation[0].managementTeam
                                    .length
                                    ? this.state.startupToEdit
                                        .crowdFundingInformation[0]
                                        .managementTeam[index].linkedin
                                    : ''
                                }
                              />
                            </FormControl>
                            <FormControl className="width-100">
                              <InputLabel
                                htmlFor={input + '-order'}
                                shrink={true}
                              >
                                Orden
                              </InputLabel>
                              <Input
                                id={input + '-order'}
                                name={input + '-order'}
                                aria-describedby={input + '-order'}
                                type="text"
                                defaultValue={
                                  index + 1 <=
                                  this.state.startupToEdit
                                    .crowdFundingInformation[0].managementTeam
                                    .length
                                    ? this.state.startupToEdit
                                        .crowdFundingInformation[0]
                                        .managementTeam[index].order
                                    : ''
                                }
                              />
                            </FormControl>
                          </Grid>
                        ))}
                        <Grid item={true} sm={12} xs={12}>
                          <p
                            onClick={() => this.appendInput_team()}
                            className="button"
                          >
                            Añadir Miembro
                          </p>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Grid container>
                    <Grid item={true} sm={12} xs={12} className="center">
                      {' '}
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="button center"
                        ref="buttonRegister"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <p></p>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(listCrowdfunding);
