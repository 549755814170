import React, { Component } from 'react';

import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
  StepButton,
} from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';

import {
  getUserData,
  getUserToken,
  updateDataUser,
} from '../../utils/manageUserData';

class RegisterSecondaryUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      optionChooseProfile: '',
      userData: '',
      email: '',
      usertoken: '',
      choosenOptionMaturity: '',
      startupFounder: '',
      isFounder: false,
      steps: ['Datos Básicos'],
      step1: 0,
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.checkCompletedSteps();
    })();
  }

  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
      this.getUserFounder(obj);
    }
  };
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo.uid + '/startups';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.startups.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.startups[0],
          });
          this.checkCompletedSteps();
        }
      })
      .catch((error) => {
        this.checkCompletedSteps();
        console.log(error.response);
        console.log('No startup Found');
      });
  };
  handleChangeProfile = (event) => {
    this.setState({
      optionChooseProfile: event.target.value,
    });
  };
  checkCompletedSteps = async () => {
    var step1Completed = 0;
    if (
      this.state.userData.nameCompanyCrowfunding !== '' &&
      this.state.userData.industry !== '' &&
      this.state.userData.nameCompanyCrowfunding &&
      this.state.userData.industry
    ) {
      this.setState({
        step1: 1,
      });
    }
    if (step1Completed === 1) {
      window.location.reload();
    }
  };
  submitRegisterStep1 = (event) => {
    event.preventDefault();

    const payload = {
      name: event.target.register_steps_name.value,
      lastName: event.target.register_steps_lastname.value,
      role: this.state.userData.role,
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });
    //
  };

  stepN1 = () => {
    return (
      <div>
        <form
          onSubmit={this.submitRegisterStep1}
          className="form"
          key={this.state.userData}
        >
          <Grid container>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_name" shrink={true}>
                  ¿Cómo te llamas?
                </InputLabel>
                <Input
                  id="register_steps_name"
                  name="register_steps_name"
                  aria-describedby="register_steps_name"
                  defaultValue={this.state.userData.name}
                  type="text"
                  required
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_lastname">
                  ¿Cuál es tu apellido?
                </InputLabel>
                <Input
                  id="register_steps_lastname"
                  name="register_steps_lastname"
                  aria-describedby="register_steps_lastname"
                  required
                  defaultValue={this.state.userData.lastName}
                  type="text"
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };

  logout = (response) => {
    this.setState({
      userData: '',
    });
    alert('Logout');
  };

  getStepContent = (step) => {
    var stepReal;
    if (
      (step === 2 || step === 3 || step === 4) &&
      this.state.isFounder === false
    ) {
      stepReal = 1;
    } else {
      stepReal = step;
    }

    switch (stepReal) {
      case 0:
        return this.stepN1();
      default:
        return 'Paso no reconocido';
    }
  };
  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };
  handleNext = () => {
    if (this.state.activeStep + 1 >= this.state.steps.length) {
      window.location.reload();
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };
  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  handleStep = (event) => {
    var stepReal;
    if (
      (event === 2 || event === 3 || event === 4) &&
      this.state.isFounder === false
    ) {
      stepReal = 1;
    } else {
      stepReal = event;
    }
    this.setState({
      activeStep: stepReal,
    });
  };
  render() {
    return (
      <Container>
        <Grid container xs={12}>
          <Grid item={true} xs={12} sm={12} className="formDelimiter">
            <Stepper
              nonLinear
              alternativeLabel
              activeStep={this.state.activeStep}
            >
              {this.state.steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => this.handleStep(index)}>
                    {label}
                    {index === 0 && this.state.step1 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                  </StepButton>
                </Step>
              ))}
            </Stepper>

            <div>
              {this.state.activeStep === this.state.steps.length ? (
                <div>
                  <Typography>Ya no quedan mas pasos</Typography>
                </div>
              ) : (
                <div>{this.getStepContent(this.state.activeStep)}</div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default RegisterSecondaryUser;
