import React, { Component } from 'react';

import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
  FormHelperText,
  StepButton,
} from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  getUserData,
  getUserToken,
  updateDataUser,
} from '../../utils/manageUserData';

class RegistroForm5 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      optionChooseProfile: '',
      userData: '',
      email: '',
      usertoken: '',
      choosenOptionMaturity: '',
      startupFounder: '',
      isFounder: false,
      steps: ['Datos Requeridos'],
      step1: 0,
      acceptTerms01: false,
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
      await this.checkCompletedSteps();
    })();
  }

  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
      this.getUserFounder(obj);
    }
  };

  changeStateTerms = (event) => {
    if (this.state.acceptTerms01 === true) {
      this.setState({
        acceptTerms01: false,
      });
    } else {
      this.setState({
        acceptTerms01: true,
      });
    }
  };

  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo.uid + '/startups';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.startups.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.startups[0],
          });
          this.checkCompletedSteps();
        }
      })
      .catch((error) => {
        this.checkCompletedSteps();
        console.log(error.response);
        console.log('No startup Found');
      });
  };
  handleChangeProfile = (event) => {
    this.setState({
      optionChooseProfile: event.target.value,
    });
  };
  checkCompletedSteps = async () => {
    var step1Completed = 0;
    if (
      this.state.userData.email !== '' &&
      this.state.userData.streetName !== '' &&
      this.state.userData.postCode !== '' &&
      this.state.userData.locality !== '' &&
      this.state.userData.province !== '' &&
      this.state.userData.country !== '' &&
      //    this.state.userData.nameCompanyCrowfunding !== '' &&
      this.state.userData.email &&
      this.state.userData.streetName &&
      this.state.userData.postCode &&
      this.state.userData.province &&
      this.state.userData.country &&
      //  this.state.userData.nameCompanyCrowfunding &&
      this.state.userData.locality &&
      this.state.userData.name !== '' &&
      this.state.userData.lastname !== '' &&
      // this.state.userData.acceptTerms2 !== false &&
      this.state.userData.dni !== ''
    ) {
      step1Completed = 1;
      this.setState({
        step1: 1,
      });
    }
    if (step1Completed === 1) {
      window.location.reload();
    }
  };
  submitRegisterStep1 = (event) => {
    event.preventDefault();

    const payload = {
      email: event.target.register_steps_email.value,
      nameCompanyCrowfunding:
        event.target.register_steps_nameCompanyCrowfunding.value,
      role: this.state.userData.role,
      streetName: event.target.register_steps_address.value,
      flatNumber: event.target.register_steps_address_number.value,
      postCode: event.target.register_steps_pc.value,
      locality: event.target.register_steps_locality.value,
      province: event.target.register_steps_province.value,
      country: event.target.register_steps_country.value,
      name: event.target.register_steps_name.value,
      lastName: event.target.register_steps_lastname.value,
      dni: event.target.register_steps_startup_cifStartup.value,
      acceptTerms2: this.state.acceptTerms01,
      insertCifIfCompany: true, // trigger api to add cif to company
    };
    var apiurl = '/api/users/' + this.state.userData.uid;
    axios({
      url: apiurl,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log('Data has been sent to the server');
        console.log(response);
        updateDataUser(response.data);
        this.setState({
          userData: response.data.user,
        });
        alert('Datos guardados con éxito');
        //window.location.reload();
        //this.emailVerification(email);
        this.checkCompletedSteps();
        this.handleNext();
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
        console.log(error.response);
        console.log('Error');
      });

    //
  };

  stepN1 = () => {
    return (
      <div>
        <form
          onSubmit={this.submitRegisterStep1}
          className="form"
          key={this.state.userData}
        >
          <Grid container>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_email" shrink={true}>
                  Correo al que está asociada su cuenta
                </InputLabel>
                <Input
                  id="register_steps_email"
                  name="register_steps_email"
                  aria-describedby="register_steps_email"
                  type="email"
                  defaultValue={this.state.userData.email}
                  required
                  readOnly
                />
                <FormHelperText id="helper-email">
                  No compartiremos tu email
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_nameCompanyCrowfunding">
                  ¿Cuál es el nombre de tu empresa?{' '}
                </InputLabel>
                <Input
                  id="register_steps_nameCompanyCrowfunding"
                  name="register_steps_nameCompanyCrowfunding"
                  aria-describedby="register_steps_nameCompanyCrowfunding"
                  defaultValue={this.state.userData.nameCompanyCrowfunding}
                  type="text"
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_address">
                  Dirección *
                </InputLabel>
                <Input
                  id="register_steps_address"
                  name="register_steps_address"
                  aria-describedby="register_steps_address"
                  type="text"
                  defaultValue={this.state.userData.streetName}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_address_number">
                  N/Piso *
                </InputLabel>
                <Input
                  id="register_steps_address_number"
                  name="register_steps_address_number"
                  aria-describedby="register_steps_address_number"
                  type="text"
                  required
                  defaultValue={this.state.userData.flatNumber}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_pc">
                  Código Postal *
                </InputLabel>
                <Input
                  id="register_steps_pc"
                  name="register_steps_pc"
                  aria-describedby="register_steps_pc"
                  type="number"
                  required
                  defaultValue={this.state.userData.postCode}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_locality">
                  Localidad *
                </InputLabel>
                <Input
                  id="register_steps_locality"
                  name="register_steps_locality"
                  aria-describedby="register_steps_locality"
                  type="text"
                  required
                  defaultValue={this.state.userData.locality}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_province">
                  Provincia *
                </InputLabel>
                <Input
                  id="register_steps_province"
                  name="register_steps_province"
                  aria-describedby="register_steps_province"
                  type="text"
                  required
                  defaultValue={this.state.userData.province}
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_country">País *</InputLabel>
                <Input
                  id="register_steps_country"
                  name="register_steps_country"
                  aria-describedby="register_steps_country"
                  type="text"
                  required
                  defaultValue={this.state.userData.country}
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={6} xs={6}>
              <FormControl className="width-100">
                <InputLabel
                  htmlFor="register_steps_startup_cifStartup"
                  shrink={true}
                >
                  CIF o DNI *
                </InputLabel>
                <Input
                  id="register_steps_startup_cifStartup"
                  name="register_steps_startup_cifStartup"
                  aria-describedby="register_steps_startup_cifStartup"
                  type="text"
                  required
                  defaultValue={
                    this.state.startupFounder.cif
                      ? this.state.startupFounder.cif
                      : this.state.userData.dni
                  }
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_name">Nombre* </InputLabel>
                <Input
                  id="register_steps_name"
                  name="register_steps_name"
                  aria-describedby="register_steps_name"
                  defaultValue={this.state.userData.name}
                  type="text"
                  required
                />
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {' '}
              <FormControl className="width-100">
                <InputLabel htmlFor="register_steps_lastname">
                  Apellidos*
                </InputLabel>
                <Input
                  id="register_steps_lastname"
                  name="register_steps_lastname"
                  aria-describedby="register_steps_lastname"
                  type="text"
                  defaultValue={this.state.userData.lastName}
                  required
                />
              </FormControl>
            </Grid>

            <Grid item={true} sm={6} xs={12} style={{ marginLeft: '1rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.acceptTerms01}
                    onClick={this.changeStateTerms}
                    name="acceptTerms"
                    required
                  />
                }
                label={
                  <span onClick={this.changeStateTerms}>
                    Acepto los{' '}
                    <a href="/politica-de-privacidad" target="_blank">
                      términos y condiciones
                    </a>
                  </span>
                }
              />
            </Grid>
            <Grid item={true} sm={12} xs={12} className="center">
              {' '}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button center"
                ref="buttonRegister"
              >
                Guardar y continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };

  responseGoogle = (response) => {
    this.setState({
      userData: response.profileObj,
      email: response.profileObj.email,
    });
    console.log(response);
  };
  responseGoogleFail = (response) => {
    console.log(response);
  };
  logout = (response) => {
    this.setState({
      userData: '',
    });
    alert('Logout');
  };

  responseFacebook = (response) => {
    this.setState({
      facebookName: response.name,
      facebookEmail: response.email,
      facebookImage: response.picture.data.url,
    });
    console.log(response);
  };
  socialLogin = () => {
    return (
      <div>
        <p className="center">Registro con Redes Sociales</p>
        <div className="center clear width-100">
          <GoogleLogin
            clientId="634887955444-3qoc85l4n3c5s4e7p3mbudutjlskjhdb.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogleFail}
            cookiePolicy={'single_host_origin'}
          />
          <br></br>
          <FacebookLogin
            appId={238403055163743}
            autoLoad={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
            icon="fa-facebook"
          />
        </div>
      </div>
    );
  };

  getStepContent = (step) => {
    var stepReal;
    if (
      (step === 2 || step === 3 || step === 4) &&
      this.state.isFounder === false
    ) {
      stepReal = 1;
    } else {
      stepReal = step;
    }

    switch (stepReal) {
      case 0:
        return this.stepN1();
      case 1:
        return this.stepN2();
      case 2:
        return this.stepN3();
      case 3:
        return this.stepN4();
      case 4:
        return this.stepN5();
      case 5:
        return this.stepN6();
      default:
        return 'Paso no reconocido';
    }
  };
  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };
  handleNext = () => {
    if (this.state.activeStep + 1 >= this.state.steps.length) {
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };
  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };
  submitRegister = (event) => {
    event.preventDefault();
    this.refs.buttonRegister.setAttribute('disabled', 'disabled');
    console.log(event.target.register_steps_email.value);
    console.log(event.target.register_steps_name.value);
    console.log(event.target.register_steps_lastname.value);
    if (
      event.target.register_steps_name.value === '' ||
      event.target.register_steps_lastname.value === '' ||
      event.target.register_steps_email.value === ''
    ) {
      alert('Faltan campos requeridos por completar');
      this.refs.buttonRegister.removeAttribute('disabled');
    } else {
      const payload = {
        name: event.target.register_steps_name.value,
        lastName: event.target.register_steps_lastname.value,
        companyName: event.target.register_steps_company.value,
        industryInterested: event.target.register_steps_industry.value,
        phone: event.target.register_steps_phone.value,
        email: event.target.register_steps_email.value,
        publicContactEmail: event.target.register_steps_email.value,
        pwd: event.target.register_steps_password.value,
        role: 'ADMIN',
        streetName: event.target.register_steps_address.value,
        postCode: event.target.register_steps_postalcode.value,
        locality: event.target.register_steps_locality.value,
        province: event.target.register_steps_province.value,
        country: event.target.register_steps_country.value,
        tyc: 1,
        newsletter: 1,
      };

      axios({
        url: '/api/users',
        method: 'POST',
        data: payload,
      })
        .then(() => {
          console.log('Data has been sent to the server');
        })
        .catch((error) => {
          console.log(error.response);
          console.log('Error');
        });

      console.log(event.target.register_steps_email.value);
      console.log(event.target.register_steps_name.value);
      console.log(event.target.register_steps_lastname.value);
      console.log(event.target.register_steps_phone.value);
      console.log(event.target.register_steps_password.value);
      //console.log(event.target.register_steps_startup.value);
      console.log(event.target.register_steps_company.value);
      console.log(event.target.register_steps_industry.value);
      console.log(event.target.register_steps_address.value);
      console.log(event.target.register_steps_postalcode.value);
      console.log(event.target.register_steps_locality.value);
      console.log(event.target.register_steps_province.value);
      console.log(event.target.register_steps_country.value);
      console.log(event.target.register_steps_newsletter.value);
      console.log(event.target.register_steps_terms.value);
      console.log('registro');

      this.refs.buttonRegister.removeAttribute('disabled');
      //this.buttonRegister.removeAttribute("disabled");
    }
  };
  handleStep = (event) => {
    var stepReal;
    if (
      (event === 2 || event === 3 || event === 4) &&
      this.state.isFounder === false
    ) {
      stepReal = 1;
    } else {
      stepReal = event;
    }
    this.setState({
      activeStep: stepReal,
    });
  };
  render() {
    return (
      <Container>
        <Grid container xs={12}>
          <Grid item={true} xs={12} sm={12} className="formDelimiter">
            <Stepper
              nonLinear
              alternativeLabel
              activeStep={this.state.activeStep}
            >
              {this.state.steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => this.handleStep(index)}>
                    {label}
                    {index === 0 && this.state.step1 === 1 ? (
                      <p className="informationSmall confirmationMessage">
                        Completado
                      </p>
                    ) : (
                      ''
                    )}
                  </StepButton>
                </Step>
              ))}
            </Stepper>

            <div>
              {this.state.activeStep === this.state.steps.length ? (
                <div>
                  <Typography>Ya no quedan mas pasos</Typography>
                </div>
              ) : (
                <div>{this.getStepContent(this.state.activeStep)}</div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default RegistroForm5;
