import React, { Component } from 'react';
import {
  Grid,
  Container,
  InputLabel,
  Input,
  FormControl,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { getUserData, getUserToken } from '../../../utils/manageUserData';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../utils/cropImage';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
class EditCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startupFounder: [],
      editStartup: '',
      choosenOptionMaturity: '',
      userToken: '',
      userData: '',
      imageSrc: '',
      imageToUpload: '',
      croppedImage: '',
      croppedImageFile: '',
      crop: { x: 0, y: 0 },
      croppedAreaPixels: '',
      uploadedImage: '',
      croppedArea: '',
      zoom: 1,
      aspect: 1 / 1,
      cropSize: { width: 300, height: 300 },
      products_array: [],
      teams_array: [],
    };
  }
  componentDidMount() {
    (async () => {
      await this.getUserInformation();
      await this.getUserToken();
    })();
    this.setState({
      editStartup: this.props.informationStartup,
      longDescription: this.props.informationStartup.longDescription,
    });
    var products = [];
    var teams = [];

    var startup = this.props.informationStartup;
    if (startup.products) {
      startup.products.forEach((element, index, array) => {
        var name = 'product-' + index;
        products.push(name);
      });
    }
    if (startup.managementTeam) {
      startup.managementTeam.forEach((element, index, array) => {
        var name = 'team-' + index;
        teams.push(name);
      });
    }
    this.setState({
      products_array: products,
      teams_array: teams,
    });
  }
  getUserToken = async () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getUserInformation = async () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
      this.getUserFounder(obj);
    }
  };
  getUserFounder = (userInfo) => {
    var apiurl = '/api/users/' + userInfo._id + '/company';
    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.startups.length) {
          this.setState({
            isFounder: true,
            startupFounder: response.data.startups,
          });
        }
      })
      .catch((error) => {});
  };

  chooseOptionMature = (option) => {
    var element = document.getElementById('opt1');
    var element2 = document.getElementById('opt2');
    var element3 = document.getElementById('opt3');
    var element4 = document.getElementById('opt4');
    if (option === 1) {
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element.classList.add('choosenOption');
    } else if (option === 2) {
      element.classList.remove('choosenOption');

      element3.classList.remove('choosenOption');
      element4.classList.remove('choosenOption');
      element2.classList.add('choosenOption');
    } else if (option === 3) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');

      element4.classList.remove('choosenOption');
      element3.classList.add('choosenOption');
    } else if (option === 4) {
      element.classList.remove('choosenOption');
      element2.classList.remove('choosenOption');
      element3.classList.remove('choosenOption');
      element4.classList.add('choosenOption');
    }
    this.setState({
      choosenOptionMaturity: option,
    });
  };
  submitAlldata = (payload) => {
    console.log('estoy aqui');
    console.log(this.state.editStartup);
    var apiurlput = '/api/company/' + this.state.editStartup._id;
    console.log(payload);

    axios({
      url: apiurlput,
      method: 'PUT',
      data: payload,
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.props.enqueueSnackbar('Datos guardados con éxito', {
          variant: 'success',
        });
        this.getUserFounder(this.state.userData);
      })
      .catch((error) => {
        alert('Hemos encontrado un error y no se han guardado los cambios');
      });
  };
  submitUpdateStartup = (event) => {
    event.preventDefault();
    var url = event.target.update_presentacion_video.value;

    if (url !== '') {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length === 11) {
        url =
          'https://www.youtube.com/embed/' +
          match[2] +
          '?autoplay=0&enablejsapi=1';
      } else {
        this.props.enqueueSnackbar('El enlace a Youtube no es correcto', {
          variant: 'error',
        });
        url = '';
      }
    }
    if (this.state.editStartup !== '') {
      if (this.state.croppedImageFile) {
        let formData = new FormData();
        formData.append('file', this.state.croppedImageFile);

        axios
          .put('/api/uploads/company/' + this.state.editStartup._id, formData)
          .then((res) => {
            console.log(res.data);
            const payload = {
              name: event.target.update_startupName.value,
              shortDescription: event.target.update_startup_description.value,
              longDescription: this.state.longDescription,
              servicesDescription: event.target.update_startup_products.value,
              teamDescription: event.target.update_startup_team.value,
              cif: event.target.update_cifStartup.value,
              industries: event.target.update_industry.value,
              videoPresentation: url,
            };
            this.submitAlldata(payload);

            this.props.getStartupInformation();
          })
          .catch((error) => {
            console.log(error.response);
            alert('Se ha encontrado un error y no se ha subido la imagen');
          });
      } else {
        const payload = {
          name: event.target.update_startupName.value,
          shortDescription: event.target.update_startup_description.value,
          longDescription: this.state.longDescription,
          servicesDescription: event.target.update_startup_products.value,
          teamDescription: event.target.update_startup_team.value,
          cif: event.target.update_cifStartup.value,
          industries: event.target.update_industry.value,
          videoPresentation: url,
        };
        this.submitAlldata(payload);

        this.props.getStartupInformation();
      }
    }
  };
  handleClick = () => {
    this.props.enqueueSnackbar('Successfully fetched the data.');
  };
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedArea: croppedArea,
      croppedAreaPixels: croppedAreaPixels,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  handleChange = (event) => {
    var image = event.target.files[0];
    if (event.target.files[0]) {
      this.setState({
        uploadedImage: event.target.files[0],
        imageSrc: URL.createObjectURL(image),
      });
    } else {
      this.setState({
        uploadedImage: '',
        imageSrc: '',
      });
    }
  };
  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
      this.state.rotation
    );

    let response = await fetch(croppedImage);
    let data = await response.blob();
    let metadata = {
      type: 'image/png',
    };
    let file = new File([data], 'Startup.png', metadata);

    //croppedImageFile.lastModifiedDate = new Date();
    //croppedImageFile.name = "uploadfile";

    this.setState({
      croppedImageFile: file,
    });
    this.setState({
      croppedImage: croppedImage,
    });
  };
  appendInput_product() {
    var newInput = `product-${this.state.products_array.length}`;

    this.setState((prevState) => ({
      products_array: prevState.products_array.concat([newInput]),
    }));
  }

  appendInput_team() {
    var newInput = `team-${this.state.teams_array.length}`;

    this.setState((prevState) => ({
      teams_array: prevState.teams_array.concat([newInput]),
    }));
  }

  deleteProduct = async (producto) => {
    alert('vas a borrar el producto: ' + producto);

    document.getElementById('product-' + producto).remove();
  };

  deleteTeam = async (team) => {
    alert('vas a borrar el miembro: ' + team);

    document.getElementById('team-' + team).remove();
  };

  handleChangeDescription = (value) => {
    this.setState({ longDescription: value });
  };
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
    ],
  };
  formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];
  render() {
    return (
      <Container>
        <Grid container className="borderShadow">
          <Grid item xs={12}>
            <div>
              {this.state.editStartup !== '' ? (
                <form
                  onSubmit={this.submitUpdateStartup}
                  className="form"
                  key={this.state.editStartup}
                >
                  <Grid Container>
                    <Grid xs={12} sm={12} className="startupCover">
                      <div className="statupCoverTitle center">
                        <h2>{this.state.editStartup.name}</h2>
                      </div>

                      {this.state.editStartup.img ? (
                        <div className="startupCoverCircle">
                          <img
                            src={
                              process.env.REACT_APP_API_URL_PROD +
                              '/api/uploads/company/' +
                              this.state.editStartup._id
                            }
                            alt="startup profile cover"
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  <Accordion expanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Información Básica</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel
                              htmlFor="update_startupName"
                              shrink={true}
                            >
                              Lo más importante, ¿por qué nombre quieres que
                              conozcamos a tu Empresa?{' '}
                            </InputLabel>
                            <Input
                              id="update_startupName"
                              name="update_startupName"
                              aria-describedby="update_startupName"
                              type="text"
                              defaultValue={this.state.editStartup.name}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <InputLabel htmlFor="update_industry" shrink={true}>
                              ¿Cuál es la industria a la que pertenece tu
                              Empresa?
                            </InputLabel>
                            <Input
                              id="update_industry"
                              name="update_industry"
                              aria-describedby="update_industry"
                              type="text"
                              defaultValue={this.state.editStartup.industries}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>
                              Nos gustaría que nos hicieses una breve
                              descripción de a qué se dedica tu Empresa{' '}
                            </p>
                            <TextareaAutosize
                              aria-label="minimum height"
                              id="update_startup_description"
                              name="update_startup_description"
                              rowsMin={3}
                              defaultValue={
                                this.state.editStartup.shortDescription
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>
                              Queremos acercar tu idea al mayor número de
                              clientes posibles. Para ello, un equipo de
                              Estartaps.com va a revisar todo lo que nos cuentes
                              de tu solución, intentando entender hasta el
                              mínimo detalle para que el mundo lo conozca
                            </p>
                            <ReactQuill
                              name="longDescription"
                              modules={this.modules}
                              formats={this.formats}
                              onChange={this.handleChangeDescription}
                              defaultValue={this.state.longDescription}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>
                              Ahora nos gustaría que nos contases los diferentes
                              productos o servicios que ofrece tu Empresa
                            </p>
                            <TextareaAutosize
                              aria-label="minimum height"
                              id="update_startup_products"
                              name="update_startup_products"
                              rowsMin={3}
                              defaultValue={
                                this.state.editStartup.servicesDescription
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel htmlFor="update_presentacion_video">
                              Video de presentación (Solo admite youtube)
                            </InputLabel>
                            <Input
                              id="update_presentacion_video"
                              name="update_presentacion_video"
                              aria-describedby="update_presentacion_video"
                              type="url"
                              defaultValue={
                                this.state.editStartup.videoPresentation
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>
                              Nos gustaría conocer a tu equipo, cuéntanos
                              brevemente sobre ellos.
                            </p>
                            <TextareaAutosize
                              aria-label="minimum height"
                              id="update_startup_team"
                              name="update_startup_team"
                              rowsMin={3}
                              defaultValue={
                                this.state.editStartup.teamDescription
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <InputLabel htmlFor="update_cifStartup">
                              CIF de la empresa
                            </InputLabel>
                            <Input
                              id="update_cifStartup"
                              name="update_cifStartup"
                              aria-describedby="update_cifStartup"
                              type="text"
                              defaultValue={this.state.editStartup.cif}
                            />
                          </FormControl>
                        </Grid>
                        {this.state.editStartup.img ? (
                          <Grid item={true} sm={12} xs={12}>
                            <img
                              src={
                                process.env.REACT_APP_API_URL_PROD +
                                '/api/uploads/company/' +
                                this.state.editStartup._id
                              }
                              alt="startup profile"
                              className="profile-image "
                            />
                          </Grid>
                        ) : (
                          ''
                        )}

                        <Grid item={true} sm={12} xs={12}>
                          {' '}
                          <FormControl className="width-100">
                            <p>Logo de su Empresa</p>

                            <Input
                              id="update_image"
                              name="update_image"
                              ref="update_image"
                              aria-describedby="update_image"
                              type="file"
                              inputProps={{
                                accept: [
                                  'image/png',
                                  'image/jpg',
                                  'image/jpeg',
                                ],
                              }}
                              onChange={this.handleChange}
                            />
                          </FormControl>
                          {this.state.uploadedImage !== '' ? (
                            <div>
                              <p>
                                Ajuste la imagen en el cuadrado blanco y acepte
                                para guardar la imagen, puede hacer uso del zoom
                                o mover la imagen
                              </p>
                              <div className="crop-container">
                                <Cropper
                                  image={this.state.imageSrc}
                                  crop={this.state.crop}
                                  minZoom={0.4}
                                  maxZoom={15}
                                  zoom={this.state.zoom}
                                  aspect={this.state.aspect}
                                  onCropChange={this.onCropChange}
                                  onCropComplete={this.onCropComplete}
                                  onZoomChange={this.onZoomChange}
                                  cropSize={this.state.cropSize}
                                  restrictPosition={false}
                                />
                              </div>
                              <Button
                                onClick={this.showCroppedImage}
                                variant="contained"
                                color="primary"
                              >
                                Aceptar y ver resultado
                              </Button>
                            </div>
                          ) : (
                            ''
                          )}
                        </Grid>
                        <Grid item={true} sm={12} xs={12}>
                          {this.state.croppedImage !== '' ? (
                            <img
                              src={this.state.croppedImage}
                              alt="Startup"
                              className="profile-image "
                            ></img>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Grid container>
                    <Grid item={true} sm={12} xs={12} className="center">
                      {' '}
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="button center"
                        ref="buttonRegister"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <p></p>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withSnackbar(EditCompany);
