import React, { Component } from 'react';
import axios from 'axios';
import { Container } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import './../../../styles/styles.css';
import 'react-quill/dist/quill.snow.css';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
class ChallengeStartup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startup: props.startup,
      userToken: props.userToken,
      challenges: [],
    };

    console.log('challenge  startup component');
  }

  componentDidMount() {
    this.getChallenges();
  }

  getChallenges = async () => {
    const apiurl = '/api/company/challenges/startups/' + this.state.startup.uid;
    const { data } = await axios.get(apiurl);
    this.setState({
      challenges: data.challenges,
    });
  };

  viewMore = (challenge) => {
    const newProps = {
      challenge: challenge,
    };

    this.props.getClickedElement('reto_ver_single', newProps);
  };

  render() {
    const columns = [
      {
        field: 'title',
        headerName: 'Título',
        editable: false,
        flex: 1,
        renderCell: (params) => {
          return <div>{params.row.challengeTitle || ''}</div>;
        },
      },
      {
        field: 'created_date',
        headerName: 'Fecha de creación',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>{new Date(params.row.createdAt).toLocaleDateString()}</div>
          );
        },
      },
      {
        field: 'expiration_date',
        headerName: 'Fecha de expiración',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              {params.row.expirationDate
                ? new Date(params.row.expirationDate).toLocaleDateString()
                : ''}
            </div>
          );
        },
      },
      {
        field: 'active',
        headerName: 'Activo',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{params.row.active ? 'Sí' : 'No'}</div>;
        },
      },
      {
        field: 'view',
        headerName: 'Ver más',
        sortable: false,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Link to={'/reto/' + params.row._id}>
                <Button className="button">Ver más</Button>
              </Link>
            </div>
          );
        },
      },
    ];

    return (
      <Container>
        <h1 className="job-page-title">Retos</h1>
        <Grid container>
          <Grid item={true} sm={12} xs={12}>
            <DataGrid
              rows={this.state.challenges ? this.state.challenges : []}
              getRowId={(row) => row._id}
              autoHeight
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withSnackbar(ChallengeStartup);
