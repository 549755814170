import React, { Component } from 'react';
import axios from 'axios';

import { Grid, Button } from '@material-ui/core';
import { Container, InputLabel, Input, FormControl } from '@material-ui/core';
import Carousel from 'react-bootstrap/Carousel';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import Contact from '../components/utils/contact';
import { Divider } from '@material-ui/core';
import { getUserToken, getUserData } from '../components/utils/manageUserData';

class Inversion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      baseSearchUrl: '/buscador-de-startups',
      startup: [],
      itemsPerPage: 9,
      numberOfPage: 1,
      beginItem: 0,
      endItem: 9,
      founderData: [],
      userToken: '',
      userData: '',
    };
  }

  componentDidMount() {
    console.log('inversion');

    (async () => {
      await this.getCompanies();
      await this.getUserToken();
      await this.getUserInformation();
    })();
  }
  getUserToken = () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };
  getCompanies = (userInfo) => {
    var apiurl = '/api/startups/' + this.props.match.params.name;
    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        console.log(response);
        this.setState({
          startup: response.data.startup,
        });
        console.log(response.data.startup);
        this.geFounderData(response.data.startup.founder);
      })
      .catch((error) => {});
  };

  geFounderData = (userId) => {
    var apiurl = '/api/users/' + userId;
    axios({
      url: apiurl,
      method: 'GET',
    })
      .then((response) => {
        this.setState({
          founderData: response.data.user,
        });
      })
      .catch((error) => {});
  };

  handlePage = (event) => {
    console.log(event.selected);

    this.setState({
      beginItem: event.selected * this.state.itemsPerPage,
      endItem: event.selected * this.state.itemsPerPage + 9,
    });
  };
  handleSelectCarousel = (selectedIndex) => {
    this.setState({ carouselIndex: selectedIndex });
  };

  getUserInformation = () => {
    var userData = getUserData();

    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
    }
  };
  render() {
    console.log(this.state.Startup);
    const video = (
      <div className="video-responsive" key={this.state.startup}>
        <iframe
          width="420"
          height="315"
          src={this.state.startup.videoPresentation}
          title="Presentación en video"
        ></iframe>
      </div>
    );
    const firstSlide = (
      <Grid container>
        <Grid item xs={12} sm={3}>
          {' '}
          {this.state.startup.img ? (
            <img
              src={
                process.env.REACT_APP_API_URL_PROD +
                'uploads/startups/' +
                this.state.startup.img
              }
              alt={'startup' + this.state.startup.name}
              className="width-100 companyDirectoryImage"
            />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12} sm={9}>
          <div className="margin-10">
            <h2>{this.state.startup.name}</h2>
            <p>{this.state.startup.shortDescription}</p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          className="center videoPresentation"
          key={this.state.startup}
        >
          {this.state.startup.videoPresentation !== '' &&
          this.state.startup.videoPresentation
            ? video
            : ''}
        </Grid>
      </Grid>
    );

    const industriesElement = this.state.startup ? (
      this.state.startup.industries ? (
        this.state.startup.industries ? (
          <div className="w-100">
            <span className="startup-tag">{this.state.startup.industries}</span>
          </div>
        ) : (
          ''
        )
      ) : (
        ''
      )
    ) : (
      ''
    );

    const workers = this.state.startup ? (
      <Grid item lg={12} sm={12} xs={12}>
        <p>
          {this.state.startup.workers
            ? this.state.startup.workers
            : 'No definido'}
        </p>
      </Grid>
    ) : (
      ''
    );
    const pdfInvestors = this.state.startup ? (
      <Grid item lg={12} sm={12} xs={12}>
        {this.state.startup.investorDoc ? (
          <div>
            <a
              href={
                process.env.REACT_APP_API_URL_PROD +
                'uploads/' +
                this.state.startup.investorDoc
              }
              download="documento.pdf"
              rel="noreferrer"
            >
              <Button
                form="formContact"
                variant="contained"
                color="primary"
                type="submit"
                className="button center width-100"
              >
                Descargar Fichero
              </Button>
            </a>
          </div>
        ) : (
          'No definido'
        )}
      </Grid>
    ) : (
      ''
    );
    return (
      <Container className="bodyWrapper">
        {this.state.startup.length !== 0 ? (
          <Grid
            container
            className="w-100  startup"
            style={{ background: '#F3F8FF' }}
          >
            <Grid className="float-left mainColorBg" sm={9} xs={12}>
              <Grid container className="button-carousel">
                <Grid
                  sm={3}
                  xs={3}
                  className={
                    this.state.carouselIndex === 0
                      ? 'selectedCarousel center'
                      : 'center'
                  }
                >
                  {' '}
                  <Button
                    className="width-100"
                    onClick={() => this.handleSelectCarousel(0)}
                    disableRipple
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <div>
                      <div className="center">
                        <div className="iconCarousel">
                          <BusinessIcon fontSize="large" />
                        </div>
                      </div>
                      <div className="center">
                        <p>Presentación</p>
                      </div>
                    </div>
                  </Button>
                </Grid>
                <Grid sm={3} xs={3} className="center">
                  {' '}
                  <Button
                    className="width-100"
                    onClick={() => this.handleSelectCarousel(1)}
                    disableRipple
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <div>
                      <div
                        className={
                          this.state.carouselIndex === 1
                            ? 'selectedCarousel center'
                            : 'center'
                        }
                      >
                        <div className="iconCarousel">
                          <DescriptionIcon fontSize="large" />
                        </div>
                      </div>
                      <div className="center">
                        <p>Ficha técnica</p>
                      </div>
                    </div>
                  </Button>
                </Grid>
              </Grid>
              <Carousel
                style={{ padding: '1rem' }}
                activeIndex={this.state.carouselIndex}
                interval={null}
                className="carouselStartup"
              >
                <Carousel.Item>
                  <div
                    className="w-45 float-left"
                    style={{ marginRight: '5%' }}
                  >
                    {firstSlide}
                  </div>

                  <div className="w-50 float-left">
                    <h2>{this.state.Company}</h2>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="w-100 float-left">
                    <h2 className="center mb-3">Ficha técnica</h2>
                    <Grid container className="formSpacing">
                      <Grid item={true} sm={12} xs={12}>
                        <FormControl className="width-100">
                          <InputLabel
                            htmlFor="crowd_startupOportunity"
                            shrink={true}
                          >
                            En qué consiste la oportunidad de inversión
                          </InputLabel>
                          <Input
                            id="crowd_startupOportunity"
                            name="crowd_startupOportunity"
                            aria-describedby="crowd_startupOportunity"
                            type="text"
                            disabled
                            defaultValue={
                              this.state.startup.crowdFundingInformation[0]
                                .oportunityExplanation
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item={true} sm={12} xs={12}>
                        {' '}
                        <FormControl className="width-100">
                          <InputLabel
                            htmlFor="crowd_startupStage"
                            shrink={true}
                          >
                            Stage de la inversión
                          </InputLabel>
                          <Input
                            id="crowd_startupStage"
                            name="crowd_startupStage"
                            disabled
                            aria-describedby="crowd_startupStage"
                            type="text"
                            defaultValue={
                              this.state.startup.crowdFundingInformation[0]
                                .stage
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item={true} sm={12} xs={12}>
                        {' '}
                        <FormControl className="width-100">
                          <InputLabel
                            htmlFor="crowd_startupIndustry"
                            shrink={true}
                          >
                            Mercados/ industria
                          </InputLabel>
                          <Input
                            id="crowd_startupIndustry"
                            name="crowd_startupIndustry"
                            aria-describedby="crowd_startupIndustry"
                            type="text"
                            disabled
                            defaultValue={
                              this.state.startup.crowdFundingInformation[0]
                                .industry
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item={true} sm={12} xs={12}>
                        <FormControl className="width-100">
                          <InputLabel
                            htmlFor="crowd_startupAceleraciones"
                            shrink={true}
                          >
                            Aceleraciones
                          </InputLabel>
                          <Input
                            id="crowd_startupAceleraciones"
                            name="crowd_startupAceleraciones"
                            aria-describedby="crowd_startupAceleraciones"
                            type="text"
                            disabled
                            defaultValue={
                              this.state.startup.crowdFundingInformation[0]
                                .accelaeration
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item={true} sm={12} xs={12}>
                        <FormControl className="width-100">
                          <InputLabel
                            htmlFor="crowd_startupMotivos"
                            shrink={true}
                          >
                            Motivos por los que invertir
                          </InputLabel>
                          <Input
                            id="crowd_startupMotivos"
                            name="crowd_startupMotivos"
                            disabled
                            aria-describedby="crowd_startupMotivos"
                            type="text"
                            defaultValue={
                              this.state.startup.crowdFundingInformation[0]
                                .reasons
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item={true} sm={12} xs={12}>
                        <Grid item={true} sm={12} xs={12}>
                          <FormControl className="width-100">
                            <p className="textDisabled">
                              Otros inversores actuales de la empresa
                            </p>

                            <div
                              className="descriptionContent textDisabled"
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state.startup.crowdFundingInformation[0]
                                    .otherInvestors,
                              }}
                            ></div>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Grid>
            <Grid
              className="float-left secondaryColorBg padding-10 "
              sm={3}
              xs={12}
            >
              <div className="w-100 ">
                <h2 className="black">
                  {this.state.startup !== '' ? this.state.startup.name : ''}
                </h2>
              </div>
              <div className="w-100  ">
                <p>
                  {this.state.startup !== ''
                    ? this.state.startup.shortDescription
                    : ''}
                </p>
              </div>
              <div className="margin-bottom-20">
                <Contact
                  key={this.state.startup + this.state.founderData.name}
                  subject={'Búsqueda trabajo'}
                  user2id={this.state.startup.founder}
                  user2name={
                    this.state.founderData !== []
                      ? this.state.founderData.name
                      : ''
                  }
                  user2company={
                    this.state.startup !== [] ? this.state.startup.name : ''
                  }
                  user2email={
                    this.state.founderData !== []
                      ? this.state.founderData.email
                      : ''
                  }
                  user2phone={
                    this.state.founderData !== []
                      ? this.state.founderData.phone
                      : ''
                  }
                  user2lastname={
                    this.state.founderData !== []
                      ? this.state.founderData.lastname
                      : ''
                  }
                  user2type={'LookJob'}
                  userToken={this.state.userToken}
                  budget={this.state.startup.budget}
                  collectionId={this.state.startup._id}
                  fromInversion={true}
                  startupIdCrowd={this.state.startup.uid}
                  startupNameCrowd={this.state.startup.name}
                  startupPermalinkCrowd={this.state.startup.permalink}
                  type={this.state.startup.crowdFundingInformation[0].type}
                />
              </div>
              <Divider variant="middle" className="separatorSidebar" />
              <h5 className="titleSidebar">Industria</h5>
              {industriesElement}
              <Divider variant="middle" className="separatorSidebar" />

              <h5 className="titleSidebar">Número de empleados</h5>
              {workers}
              <Divider variant="middle" className="separatorSidebar" />

              {this.state.userData.role === 'CROWDFUNDING_USER' ? (
                <div>
                  <h5 className="titleSidebar">Documento de inversión</h5>
                  {pdfInvestors}
                </div>
              ) : (
                ''
              )}
            </Grid>

            <Grid className="w-100"></Grid>
          </Grid>
        ) : (
          ''
        )}
      </Container>
    );
  }
}
export default Inversion;
