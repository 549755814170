import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';

class AvisoLegal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <Container>
        <Grid container>
          <Grid item={true} xs={12} sm={12} className="aviso-legal">
            <h3>
              <center>
                <span style={{ fontWeight: 400 }} className="bold">
                  AVISO LEGAL
                </span>
              </center>
            </h3>
            <ol>
              <li>
                <h4 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  DATOS IDENTIFICATIVOS
                </h4>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                De acuerdo con la exigencia legal establecida, se cumple con la
                obligación de informar a todos los usuarios e interesados que la
                responsabilidad y titularidad del dominio web
                <b>
                  <a href="www.estartaps.com">www.estartaps.com</a>
                </b>
                , así como las redes sociales y el sitio web asociadas, en lo
                adelante el “sitio web” le pertenecen a Tamber Solutions S.L,
                con domicilio social en Calle de los Jilgueros, 6,
                Rivas-Vaciamadrid (Madrid) 28523, España, con Número de
                Identificación Fiscal B-88632997, en lo adelante EL GESTOR DEL
                SITIO WEB, y cuyo correo electrónico de contacto es{' '}
                <u>support@estartaps.com</u>
              </span>
            </p>
            <h3>
              <center>
                <span style={{ fontWeight: 400 }} className="bold">
                  TÉRMINOS Y CONDICIONES
                </span>
              </center>
            </h3>
            <ol>
              <li>
                <h4 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  USO Y APLICACIÓN
                </h4>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Los presentes términos y condiciones aplican tanto para la
                página principal de{' '}
                <b>
                  <a href="www.estartaps.com">www.estartaps.com</a>
                </b>{' '}
                como para aquellas otras que hayan sido creadas por{' '}
                <b>
                  <u>Tamber Solutions S.L</u>
                </b>{' '}
                e indiquen de manera expresa e inequívoca que forman parte
                integrante de este sitio web.
              </span>
            </p>
            <ol>
              <li>
                <h4 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  USUARIOS
                </h4>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Se entenderá por usuario a toda aquella persona que navegue por
                el sitio web, accediendo a cualquiera de sus páginas o
                secciones, bastando con el simple acceso y/o uso de este sitio
                web, aceptando desde dicho acceso y/o uso, los términos y
                condiciones aquí reflejados.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                En caso de que el usuario desee contratar alguno de los
                servicios ofrecidos en el sitio web, se aplicarán también las
                políticas de contratación, las cuales serán de obligatorio
                cumplimiento.
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  FORMAS DE CONTACTO
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Si un usuario deseara contactar al GESTOR DEL SITIO WEB podrá
                enviar un correo electrónico a la dirección
                <b>
                  <a href="support@estartaps.com">support@estartaps.com</a>
                </b>
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  USO DEL SITIO WEB
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Toda la información gráfica, textual, de multimedia,
                fotografías, imágenes, videos, o cualquier otro formato de
                archivo digital que se encuentran en el sitio web, entendidos de
                aquí en adelante como “los contenidos”, le pertenecen al EL
                GESTOR DEL SITIO WEB y está protegido por las leyes de derecho
                de autor a nivel nacional e internacional.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                El usuario asume la responsabilidad del uso del sitio web, no
                pudiendo emplearlos para:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                1. Realizar actividades ilícitas, ilegales o contrarias a la
                moral, buenas costumbres, buena fe y al orden público;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                2. Realizar comentarios o difundir contenidos o propaganda de
                carácter racista, xenófobo, pornográfico, de apología del
                terrorismo, que atente contra los derechos humanos o civiles en
                general;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                3. Provocar daños en la persona física, intelectual o moral del
                GESTOR DEL SITIO WEB, de sus proveedores o de terceras personas;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                4. Introducir o difundir en la red virus informáticos o
                cualesquiera otros sistemas físicos o lógicos que sean
                susceptibles de provocar los daños anteriormente mencionados;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5. Intentar acceder y/o utilizar las cuentas de correo
                electrónico de otros usuarios y modificarlos o manipular sus
                mensajes.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB se reserva el derecho de retirar todos
                aquellos comentarios que vulneren el respeto a la dignidad de la
                persona, que sean discriminatorios, xenófobos, racistas,
                pornográficos, que atenten contra la juventud o la infancia, el
                orden o la seguridad pública o que, a su juicio, no resultan
                adecuados para su publicación. En cualquier caso, EL GESTOR DEL
                SITIO WEB no será responsable de las opiniones dadas por los
                usuarios en cualquier herramienta de opinión que contenga el
                sitio web.
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  PROPIEDAD INTELECTUAL
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB es el titular de todos los derechos de
                propiedad intelectual e industrial del sitio web, así como de
                los elementos contenidos en la misma, los cuales, a título
                enunciativo, pero no limitativo, son: imágenes, sonido,
                fotografías, audio, vídeo, software, textos, marcas, logotipos,
                combinaciones de colores, estructura, diseño, selección de
                materiales usados, programas necesarios para su funcionamiento,
                acceso y uso, quedando expresamente prohibida la reproducción,
                distribución y comunicación pública, incluida su modalidad de
                puesta a disposición, de la totalidad o parte de los contenidos
                del sitio web, en cualquier soporte y por cualquier medio
                técnico, con o sin fines comerciales sin la autorización del
                GESTOR DEL SITIO WEB.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                El usuario se compromete a respetar los derechos de autor
                titularidad del GESTOR DEL SITIO WEB, podrá en consecuencia
                visualizar, imprimir, almacenar en un dispositivo electrónico o
                virtual, pero única y exclusivamente para su uso personal y
                privado, no pudiendo en ningún momento distribuirlos,
                reproducirlos, onerosa o gratuitamente, a terceros ni realizar
                transformación alguna de los mismos. En todo caso, deberá
                siempre hacer mención a la autoría del GESTOR DEL SITIO WEB y al
                sitio web cuando decida compartir en cualquier red social alguno
                de los contenidos, no pudiendo en ningún momento modificar,
                total o parcialmente, cualquiera de los contenidos.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                5.3. Cuando el usuario se registra en el SITIO WEB debe aceptar
                estos t&eacute;rminos y condiciones y la pol&iacute;tica de
                privacidad. En cualquier momento el usuario podr&aacute;
                modificar y actualizar su informaci&oacute;n de contacto a
                trav&eacute;s del SITIO WEB.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                No se podrá compartir ningún extracto de un curso, libro,
                entrada del blog o de cualquier parte del sitio web, ya sea
                incluso una mención a terceros.
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB ha adoptado todas las medidas
                tecnológicas, necesarias en el sitio web, para evitar daños y
                perjuicios de cualquier naturaleza que pudieran ocasionar, a
                título enunciativo: errores u omisiones en los contenidos, falta
                de disponibilidad del sitio web o la transmisión de virus o
                programas maliciosos o lesivos en los contenidos o en los datos
                de los usuarios, y no se hace responsable si en algún momento
                una de estas medidas falla como consecuencia de un uso indebido
                y/o abusivo por parte de un tercero.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                El usuario deberá en todo momento abstenerse de suprimir,
                alterar, eludir o manipular cualquier dispositivo de protección
                o sistema de seguridad que estuviera instalado en el sitio web.
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  MODIFICACIONES
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB se reserva el derecho de efectuar, sin
                previo aviso, las modificaciones que considere oportunas en el
                sitio web, pudiendo cambiar, suprimir o añadir tanto los
                contenidos y servicios que se presten a través de la misma, como
                la forma en la que éstos aparezcan presentados o localizados,
                así como el diseño y estructura del mismo.
              </span>
            </p>

            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  ENLACES
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB no tiene control alguno sobre los
                enlaces y/o contenidos externos que puedan existir en el sitio
                web, por lo que no se hace responsable por los mismos, ni
                garantizará la disponibilidad técnica, calidad, fiabilidad,
                exactitud, amplitud, veracidad o validez de cualquier material o
                información allí contenida, y no serán entendidos estos enlaces
                externos como una posible asociación, fusión, colaboración o
                participación con el titular y/o responsable del dominio web al
                cual estén asociados.
              </span>
            </p>

            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  DERECHO DE EXCLUSIÓN
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB se reserva el derecho a denegar o
                retirar el acceso al sitio web y/o los servicios ofrecidos sin
                necesidad de preaviso, a instancia propia o de un tercero, a
                aquellos usuarios que incumplan los presentes términos y
                condiciones.
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  GARANTÍA DE RESULTADOS
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB no se hará responsable por el éxito o
                fracaso de los usuarios en la aplicación de la información que
                reciban del sitio web o de los servicios que ofrece EL GESTOR
                DEL SITIO WEB. El usuario será el único responsable por la
                aplicación de lo aprendido con el material suministrado por EL
                GESTOR DEL SITIO WEB.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Los contenidos que se suministran, se imparten de manera
                informativa, no queriendo en ningún momento EL GESTOR DEL SITIO
                WEB sustituir alguna asesoría profesional personalizada que el
                usuario, cliente o participante requiera en un tema determinado,
                y no se hará responsable por los problemas que pueda tener éste
                por un uso, correcto o no, de la información suministrada o por
                no tomar en cuenta problemas de salud, ideológicos, económicos o
                de cualquier otra índole que pudieran ser afectados por el
                contenido que se encuentre en de forma gratuita o no expuesto
                dentro del sitio web.
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  RESOLUCIÓN DE LITIGIOS EN LÍNEA
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                El usuario o el cliente podrá escribir a{' '}
                <b>
                  <u>support@estartaps.com</u>
                </b>
                para resolver cualquier problema que tenga con el sitio web o
                con el servicio prestado.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                También podrá utilizar la plataforma europea de Resolución de
                Litigios en Línea aquí{' '}
                <a href="ec.europa.eu/consumers/odr">
                  ec.europa.eu/consumers/odr
                </a>
                .
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  MODIFICACIÓN DEL PRESENTE AVISO LEGAL Y DURACIÓN
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB podrá modificar, en cualquier momento,
                los términos y condiciones aquí expuestos, sin previo aviso y
                estarán vigentes mientras se encuentren debidamente publicadas.
              </span>
            </p>
            <ol>
              <li>
                <h3 style={{ fontWeight: 400 }} className="bold">
                  {' '}
                  ACCIONES LEGALES
                </h3>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                EL GESTOR DEL SITIO WEB puede, en cualquier momento, ejercer
                acciones legales de carácter civil o penal, contra cualquier
                persona que realice un uso indebido del contenido expuesto en el
                sitio web, o por incumplir con estos términos y condiciones. Se
                aplicará en todo momento la legislación del territorio de España
                y en caso de algún conflicto, las partes se someterán a la
                jurisdicción de Madrid, España, por ser el domicilio del titular
                del sitio web.
              </span>
            </p>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default AvisoLegal;
