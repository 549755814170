import React, { Component } from 'react';

import { Container, Grid } from '@material-ui/core';

import {
  getUserData,
  getUserToken,
  userCompleteSteps,
} from '../../utils/manageUserData';
import RegistroForm from '../../form/register';
//import RegistroForm1 from "../../form/basicInformation/register_user_1";
import RegistroForm2 from '../../form/basicInformation/register_user_2';
import RegistroForm3 from '../../form/basicInformation/register_user_3';
import RegistroForm4 from '../../form/basicInformation/register_user_4';
import RegistroForm5 from '../../form/basicInformation/register_user_5';
import RegisterSecondaryUser from '../../form/basicInformation/register_secondary_user';
import Cookies from 'js-cookie';
import ProfileUser from '../profile';
import Loader from 'react-loader-spinner';

class CheckProfileUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: '',
      userToken: '',
      completedProfile: false,
      loadingData: true,
    };
  }
  componentDidMount() {
    this.getUserInformation();
  }
  checkUserCompleteSteps = async (userRole) => {
    let islogged = Cookies.get('tokenLogin');

    if (islogged) {
      if ((await userCompleteSteps(userRole)) === 'incomplete') {
        this.setState({
          completedProfile: false,
          loadingData: false,
        });
      } else {
        console.log('Perfil completado');
        this.setState({
          completedProfile: true,
          loadingData: false,
        });
      }
    }
  };
  getUserInformation = async () => {
    var userData = await getUserData();
    this.setState({
      loadingData: true,
    });
    if (userData !== false) {
      const obj = JSON.parse(userData);
      this.setState({
        userData: obj,
      });
      this.checkUserCompleteSteps(obj.role);
    }
  };
  getUserToken = () => {
    var userToken = getUserToken();
    if (userToken !== false) {
      const obj = userToken;
      this.setState({
        userToken: obj,
      });
    }
  };

  render() {
    console.log(this.state.userData.role);
    return (
      <Container className="noPadding">
        <Grid
          container
          key={this.state.userData + this.state.completedProfile}
          className="profileBlock"
        >
          {this.state.loadingData === true ? (
            <Grid item xs={12}>
              <div className="centerloader">
                <Loader type="Oval" color="#00BFFF" height={100} width={100} />
                <h3>Cargando información...</h3>
              </div>
            </Grid>
          ) : (
            <div>
              {this.state.userData.role === 'CROWDFUNDING_USER' &&
              this.state.completedProfile === true ? (
                <ProfileUser userData={this.state.userData} />
              ) : this.state.userData.role === 'CROWDFUNDING_USER' &&
                this.state.completedProfile === false ? (
                <RegistroForm5 />
              ) : (
                ''
              )}
              {this.state.userData.role === 'CROWDFUNDING_ADMIN' &&
              this.state.completedProfile === true ? (
                <ProfileUser userData={this.state.userData} />
              ) : this.state.userData.role === 'CROWDFUNDING_ADMIN' &&
                this.state.completedProfile === false ? (
                <RegistroForm5 />
              ) : (
                ''
              )}
              {this.state.userData.role === 'FINAL_USER_COMPANY' &&
              this.state.completedProfile === true ? (
                <ProfileUser userData={this.state.userData} />
              ) : this.state.userData.role === 'FINAL_USER_COMPANY' &&
                this.state.completedProfile === false ? (
                <RegistroForm2 />
              ) : (
                ''
              )}
              {this.state.userData.role === 'FINAL_USER' &&
              this.state.completedProfile === true ? (
                <ProfileUser userData={this.state.userData} />
              ) : this.state.userData.role === 'FINAL_USER' &&
                this.state.completedProfile === false ? (
                <RegistroForm />
              ) : (
                ''
              )}
              {this.state.userData.role === 'FINAL_USER_STARTUP' &&
              this.state.completedProfile === true ? (
                <ProfileUser userData={this.state.userData} />
              ) : this.state.userData.role === 'FINAL_USER_STARTUP' &&
                this.state.completedProfile === false ? (
                <RegistroForm3 />
              ) : (
                ''
              )}
              {/* aqui profile secondary user */}
              {this.state.userData.role === 'SECONDARY_USER' &&
              this.state.completedProfile === true ? (
                <ProfileUser userData={this.state.userData} />
              ) : this.state.userData.role === 'SECONDARY_USER' &&
                this.state.completedProfile === false ? (
                <RegisterSecondaryUser />
              ) : (
                ''
              )}
              {this.state.userData.role === 'SECONDARY_USER_ADMIN' &&
              this.state.completedProfile === true ? (
                <ProfileUser userData={this.state.userData} />
              ) : this.state.userData.role === 'SECONDARY_USER_ADMIN' &&
                this.state.completedProfile === false ? (
                <RegistroForm4 />
              ) : (
                ''
              )}
              {this.state.userData.role === 'USER_OFFER_SERVICES' &&
              this.state.completedProfile === true ? (
                <ProfileUser userData={this.state.userData} />
              ) : this.state.userData.role === 'USER_OFFER_SERVICES' &&
                this.state.completedProfile === false ? (
                <RegistroForm4 />
              ) : (
                ''
              )}
              {this.state.userData.role === 'ADMIN' ||
              this.state.userData.role === 'SELLACTIONS_ADMIN' ||
              this.state.userData.role === 'RESTRICTED_ADMIN' ? (
                <ProfileUser userData={this.state.userData} />
              ) : (
                ''
              )}
            </div>
          )}
        </Grid>
      </Container>
    );
  }
}
export default CheckProfileUser;
