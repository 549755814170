import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import './../../../../styles/styles.css';
import { withSnackbar } from 'notistack';
import ReactQuill, { Quill } from 'react-quill';
import { InputLabel, Button } from '@material-ui/core';
import ImageResize from 'quill-image-resize-module-react';

class CreateFirstStartup extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      htmlText: '',
      userToken: props.userToken,
      admin: [],
      editorHtml: '',
    };
  }

  componentDidMount() {
    this.getAdmin();
  }

  getAdmin = async () => {
    const apiurl = '/api/admin';

    await axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        this.setState({
          admin: response.data.admin,
          htmlText: response.data.admin.createFirstStartup,
        });
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  updateCreateFirstStartup = async () => {
    const apiurl = '/api/admin/create-first-startup';

    console.log(apiurl);

    const headers = {
      'x-token': this.state.userToken,
      'content-type': 'application/json',
    };

    const { htmlText } = this.state;

    const payload = {
      createFirstStartup: htmlText,
    };

    const { data } = await axios.put(apiurl, payload, { headers });

    console.log(data.code);

    if (data.code === 200) {
      this.props.enqueueSnackbar('"Crea tu primera startup" actualizado', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  };
  handleChange(html) {
    this.setState({ editorHtml: html });
  }

  apiPostNewsImage() {
    // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
  }

  imageHandler() {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append('file', file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Move cursor to right side of image
      this.quill.setSelection(range.index + 1);
      console.log('subida de imagen');

      const res = await axios
        .post('/api/uploads', formData)
        .then((res) => {
          console.log(res.data);
          return res.data.fileName;
        })
        .catch((error) => {
          console.log(error.response);
          alert('Se ha encontrado un error y no se ha subido la imagen');
        });

      console.log(res);

      var url = 'https://estartaps.com/uploads/' + res;
      console.log(url);

      // Insert uploaded image

      this.quill.insertEmbed(range.index, 'image', url);
    };
  }
  // uploadLegalBases = async (file) => {
  //   if (file) {
  //     console.log('hay fichero');
  //     let formData = new FormData();
  //     formData.append('file', file);
  //     await axios
  //       .post('/api/uploads', formData)
  //       .then((res) => {
  //         this.setState({ legalBasesDoc: res.data.fileName });
  //         console.log(res.data.fileName);
  //         return res.data.fileName;
  //       })
  //       .catch((error) => {
  //         console.log(error.response);
  //         alert('Se ha encontrado un error y no se ha subido el fichero');
  //       });
  //   }
  // };

  render() {
    const { htmlText } = this.state;
    const fontSizeArr = [
      '8px',
      '10px',
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
    ];

    var Size = Quill.import('attributors/style/size');
    Size.whitelist = fontSizeArr;
    Quill.register(Size, true);
    Quill.register('modules/imageResize', ImageResize);
    return (
      <Container>
        <h1 className="job-page-title">Crea tu primera startup </h1>

        <Row>
          <Col md={12} xs={12}>
            <InputLabel
              htmlFor="register_steps_name_company_crowfunding"
              shrink={true}
              style={{ marginTop: '1.8rem' }}
            >
              Texto
            </InputLabel>

            <ReactQuill
              ref={(el) => {
                this.quill = el;
              }}
              onChange={(e) => this.setState({ htmlText: e })}
              placeholder={this.props.placeholder}
              value={htmlText ? htmlText : ''}
              modules={{
                imageResize: {
                  parchment: Quill.import('parchment'),
                  modules: ['Resize', 'DisplaySize'],
                },
                toolbar: {
                  container: [
                    [{ header: [1, 2, 3, 4, 5, 6] }],
                    [{ size: fontSizeArr }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link'],
                    ['image'],
                    ['clean'],
                    [{ color: [] }, { background: [] }],
                    [
                      { align: '' },
                      { align: 'center' },
                      { align: 'right' },
                      { align: 'justify' },
                    ],
                  ],
                  handlers: {
                    image: this.imageHandler,
                  },
                },
              }}
            />
          </Col>
        </Row>

        <Row>
          <Button
            variant="contained"
            style={{
              background: '#6658ea',
              color: 'white',
              marginBottom: '2rem',
              marginLeft: '1rem',
              display: 'table',
              margin: '0 auto',
              marginTop: '2rem',
            }}
            onClick={() => this.updateCreateFirstStartup()}
          >
            Guardar
          </Button>
        </Row>
      </Container>
    );
  }
}

export default withSnackbar(CreateFirstStartup);
