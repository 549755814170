import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { Grid, Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Result from '../components/searcher/result';
import { Container } from '@material-ui/core';

class Buscador extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(props.location.search);

    this.state = {
      userToken: '',
      searchString: urlParams.get('q') || '',
      page: urlParams.get('page') || 1,
      limit: urlParams.get('limit') || 10,
      skip: urlParams.get('skip') || 0,
      startups: [],
      total: 0,
    };

    console.log('searcherString', Cookies.get('searcherString'));
    console.log('qparam', urlParams.get('q'));

    if (urlParams.get('q') !== Cookies.get('searcherString')) {
      console.log('delete old searcher');
      Cookies.set('searcher', '');
      Cookies.set('searcherString', urlParams.get('q'));
    }
  }

  componentDidMount() {
    this.searchStartups();
  }

  searchStartups = async () => {
    const { searchString, page, skip, limit } = this.state;

    let params =
      '?q=' +
      searchString +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&skip=' +
      skip;

    var apiurl = '/api/startups/search' + params;

    axios({
      url: apiurl,
      method: 'GET',
      headers: {
        'x-token': this.state.userToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.startups.length) {
          this.setState({
            total: response.data.total,
            startups: response.data.startups,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log('No startup Found');
      });
  };

  render() {
    const showStartups = () => {
      try {
        return this.state.startups.map((startup) => (
          <Result startup={startup} key={startup.uid} />
        ));
      } catch (error) {
        console.log(error);
        return '';
      }
    };

    return (
      <Container className="bodyWrapper">
        <section>
          <Grid container>
            <Grid container item={true} xs={12} sm={12}>
              <Grid item={true} xs={12} sm={12} className="left">
                <Box m={2}>
                  <h1 className="seo-h1">Startups</h1>
                  <h2 className="seo-h2">Encuentra las mejores startups</h2>
                  <form method="GET">
                    <FormControl className="search-home">
                      <Input
                        style={{ marginTop: '2.5rem' }}
                        placeholder="Buscar Startups"
                        className="formOutline"
                        name="q"
                        value={this.state.searchString}
                        onChange={(e) =>
                          this.setState({ searchString: e.target.value })
                        }
                        onKeyPress={(event) =>
                          event.key === 'Enter' ? this.searchStartups : ''
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />

                      <Button
                        type="submit"
                        style={{ display: 'none' }}
                      ></Button>

                      <Grid container></Grid>
                    </FormControl>
                  </form>
                </Box>
              </Grid>

              <Grid item={true} xs={12} sm={12} className="left">
                {showStartups()}
              </Grid>
            </Grid>
          </Grid>
        </section>
      </Container>
    );
  }
}
export default Buscador;
